import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppComponent } from '../app.component';
import { FormularioGenerico } from '../_models/formularioGenerico.interface';
import { BehaviorSubject, Observable } from 'rxjs';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json; charset=utf-8',
  }),
};

@Injectable({
  providedIn: 'root'
})
export class FormulariosService {
  baseUrl: string;
  private currentFormCabSubject: BehaviorSubject<any>;

  public currentFormCab: Observable<any>;

  constructor(
    private http: HttpClient
  ) {
    this.baseUrl = AppComponent.baseUrl;
    this.currentFormCabSubject = new BehaviorSubject<any>(
      JSON.parse(sessionStorage.getItem('currentPatient'))
    );
    this.currentFormCab = this.currentFormCabSubject.asObservable();
  }

  setCurrentFormularioCabecera(formularioCabecera) {
    sessionStorage.setItem('currentFormularioCabecera', JSON.stringify(formularioCabecera));
    this.currentFormCabSubject.next(formularioCabecera);
  }

  removeCurrentFormularioCabecera() {
    sessionStorage.removeItem('currentFormularioCabecera');
    this.currentFormCabSubject.next(null);
  }

  getCurrentFormularioCabecera() {
    return this.currentFormCabSubject.value;
  }

  getRndInteger(min, max) {
    return Math.floor(Math.random() * (max - min)) + min;
  }

  getFormulariosBoleta(form) {
    return this.http.post<any>(this.baseUrl + '/formularios/getFormulariosBoleta', form);
  }

  getFormulario(idForm) {
    return this.http.get<any>(this.baseUrl + '/formularios/getFormulario/' + idForm);
  }

  guardarFormularioValores(listaC, cabeceraF) {
    const body = {
      listaCampos: listaC,
      cabeceraFormulario: cabeceraF
    };
    return this.http.post<any>(
      this.baseUrl + '/formularios/guardarFormulario',
      body,
      httpOptions);
  }

  imprimirFormulario(idFormularioCabecera, usuario) {
    return (
      this.baseUrl +
      '/formularios/imprimir_formulario/pdf/' +
      idFormularioCabecera +
      '/' +
      usuario +
      '/' +
      this.getRndInteger(1000000000000, 99999999999999)
    );
  }

  imprimirFormulariosEpisodio(idEpisodio, usuario) {
    return (
      this.baseUrl +
      '/formularios/imprimir_formularios_episodio/pdf/' +
      idEpisodio +
      '/' +
      usuario +
      '/' +
      this.getRndInteger(1000000000000, 99999999999999)
    );
  }

  crearFormulario(formulario: FormularioGenerico) {
    return this.http.post<any>(this.baseUrl + '/formularios/crearFormulario', formulario);
  }

  getAllFormularios() {
    return this.http.get<any>(this.baseUrl + '/formularios/getAllFormularios');
  }

  getFormulariosPorTipoEpisodio(idTipoEpisodio) {
    return this.http.get<any>(this.baseUrl + '/formularios/getFormulariosPorTipoEpisodio/' + idTipoEpisodio);
  }

  getTiposCampo() {
    return this.http.get<any>(this.baseUrl + '/formularios/getTiposCampo');
  }

  getFormularioValores(idFormularioCabecera) {
    return this.http.get<any>(this.baseUrl + '/formularios/seguro/getFormularioValores/' + idFormularioCabecera);
  }

  imprimirEpisodio(idEpisodio, usuario) {
    return (
      this.baseUrl +
      '/formularios/imprimir_episodio/pdf/' +
      idEpisodio +
      '/' +
      usuario +
      '/' +
      this.getRndInteger(1000000000000, 99999999999999)
    );
  }

}
