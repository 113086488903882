import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthenticationService } from '../_services/authentication.service';
import { AdministracionAgendasService } from './../_services/administracion-agendas.service';
import { Sucursal } from '../administracion-agendas/administracion-agendas.component';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { toMinutesPipe } from '../_pipes/toMinutes.pipe';
import { MAT_DATE_LOCALE, DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import {
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import { DialogConfirmacionComponent } from './../dialog-confirmacion/dialog-confirmacion.component';
import {
  Respuesta,
  DialogMensajeComponent,
} from './../dialog-mensaje/dialog-mensaje.component';
import { DialogAgregarConsultorioComponent } from '../dialog-agregar-consultorio/dialog-agregar-consultorio.component';
import { EmbedUrlsService } from '../_services/embedUrls.service';
export interface Consultorio {
  id: number;
  descripcion: string;
  ubicacion: string;
}
export interface RespuestaConsultorio {
  ok: boolean;
  mensaje?: string;
  consultorios: Consultorio[];
}
export interface Dia {
  nombre: string;
  valor: number;
}
export interface Servicio {
  id: number;
  n: string;
}
export interface Especialidad {
  codigo: number;
  nombre: string;
}
export interface RespuestaServicio {
  ok: boolean;
  mensaje?: string;
  servicios: Servicio[];
}

@Component({
  selector: 'app-agregar-agenda',
  templateUrl: './agregar-agenda.component.html',
  styleUrls: ['./agregar-agenda.component.css'],
  providers: [
    toMinutesPipe,
    { provide: MAT_DATE_LOCALE, useValue: 'es_AR' },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],
})
export class AgregarAgendaComponent implements OnInit {
  private matricula: number;
  private listaSucursales: Sucursal[];
  private respuestaConsultorios: RespuestaConsultorio;
  private respuestaEspecialidades: Especialidad[];
  private listaDias: Dia[];
  private respuestaServiciosProfesional: RespuestaServicio;
  horaInicioValida = true;
  horaFinValida = true;
  loading = false;
  agendaForm: UntypedFormGroup;
  submitted = false;
  sucursalActual: number;
  mostrarMas = false;
  cargando = false;
  profesional: string;
  mostrar = false;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private authService: AuthenticationService,
    private administradorService: AdministracionAgendasService,
    public dialog: MatDialog,
    private router: Router,
    private toMinutes: toMinutesPipe,
    private embedUrlService: EmbedUrlsService
  ) {}

  ngOnInit() {
    this.embedUrlService.embedUrls.subscribe((funciones) => {
      if (
        history.state.matricula &&
        funciones.find((funcion) => {
          return funcion.nombreMenu === 'Agendas';
        })
      ) {
        this.buildForm();
        this.profesional = history.state.profesional;
        this.sucursalActual = history.state.sucursalActual;
        this.matricula = history.state.matricula;
        this.mostrar = true;
        this.obtenerDatosProfesional(this.matricula);
        this.cargarServicios(this.matricula);
        this.cargarSucursales(this.matricula);
        this.cargarEspecialidades();
        this.listaDias = [
          {
            nombre: 'domingo',
            valor: 1,
          },
          {
            nombre: 'lunes',
            valor: 2,
          },
          {
            nombre: 'martes',
            valor: 3,
          },
          {
            nombre: 'miercoles',
            valor: 4,
          },
          {
            nombre: 'jueves',
            valor: 5,
          },
          {
            nombre: 'viernes',
            valor: 6,
          },
          {
            nombre: 'sabado',
            valor: 7,
          },
        ];
      } else {
        this.router.navigate(['/administracionAgendas']);
      }
    });
  }
  obtenerDatosProfesional(matricula) {
    this.cargando = true;
    this.administradorService.getDatosProfesional(matricula).subscribe(
      (data) => {
        if (data) {
          try {
            this.agendaForm
              .get('codEspecialidad')
              .setValue(data.paginador.elementos[0].especialidades[0].codigo);
          } catch (error) {
            //no tengo especialidad, queda en null
          } finally {
            this.cargando = false;
          }
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  cargarConsultorios(idSucursal, consultorioSeleccionado) {
    if (idSucursal !== '') {
      this.administradorService.getConsultoriosSucursal(idSucursal).subscribe(
        (data) => {
          this.respuestaConsultorios = data;
          if (consultorioSeleccionado) {
            this.agendaForm
              .get('consultorio')
              .setValue(
                this.respuestaConsultorio.consultorios.find(
                  (obj) => obj.descripcion === consultorioSeleccionado
                ).id
              );
          } else {
            if (this.respuestaConsultorio.ok) {
              this.agendaForm
                .get('consultorio')
                .setValue(this.respuestaConsultorio.consultorios[0].id);
            }
          }
          /*  } */
        },
        (error) => {
          console.log(error);
        }
      );
    }
  }
  cargarSucursales(matricula) {
    this.administradorService.getSucursalesProfesional(matricula).subscribe(
      (data) => {
        if (data) {
          this.listaSucursales = data.elementos;
          if (!this.sucursalActual) {
            this.sucursalActual =
              this.listaSucursales.length > 1
                ? this.listaSucursales[1].id
                : null;
          }
          this.agendaForm.get('establecimiento').setValue(this.sucursalActual);
          if (this.sucursalActual != null) {
            this.cargarConsultorios(this.sucursalActual, null);
          }
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  cargarEspecialidades() {
    this.administradorService.getEspecialidades().subscribe(
      (data) => {
        if (data) {
          this.respuestaEspecialidades = data;
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  cargarServicios(matricula) {
    this.administradorService.getServiciosProfesional(matricula).subscribe(
      (data) => {
        if (data.ok) {
          this.respuestaServiciosProfesional = data;
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  private buildForm() {
    this.agendaForm = this.formBuilder.group({
      establecimiento: [this.sucursalActual, Validators.required],
      consultorio: [, Validators.required],
      diasAtencion: [, Validators.required],
      desdeHora: [null, Validators.required],
      hastaHora: [null, Validators.required],
      desdeVigencia: [new Date(), Validators.required],
      hastaVigencia: [null],
      cantidadTurnos: [null, [Validators.required, Validators.min(1)]],
      duracionTurno: [20, [Validators.required, Validators.min(1)]],
      diasAnticipacion: [30, [Validators.required, Validators.min(0)]],
      servicio: ['1', Validators.required],
      codEspecialidad: [, Validators.required],
      habilitadoTurnosWeb: ['S', Validators.required],
    });
  }
  public get sucursales(): Sucursal[] {
    return this.listaSucursales;
  }
  public get respuestaConsultorio(): RespuestaConsultorio {
    return this.respuestaConsultorios;
  }
  public get dias(): Dia[] {
    return this.listaDias;
  }
  public get especialidades(): Especialidad[] {
    return this.respuestaEspecialidades;
  }
  public get respuestaServicios(): RespuestaServicio {
    return this.respuestaServiciosProfesional;
  }
  public cancelar() {
    this.dialog
      .open(DialogConfirmacionComponent, {
        data: `¿Seguro desea cancelar la carga de agenda?`,
      })
      .afterClosed()
      .subscribe((confirmado: boolean) => {
        if (confirmado) {
          this.router.navigate(['/administracionAgendas']);
        }
      });
  }

  openDialog(respuestaAgenda: Respuesta): void {
    const dialogRef = this.dialog.open(DialogMensajeComponent, {
      width: '500px',
      data: respuestaAgenda,
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (respuestaAgenda.ok) {
        this.router.navigate(['/administracionAgendas']);
      }
    });
  }
  onSubmit() {
    this.submitted = true;
    if (this.agendaForm.valid) {
      this.loading = true;

      this.administradorService
        .setAgendaProfesional(this.matricula, this.agendaForm)
        .subscribe(
          (res) => {
            this.loading = false;
            this.openDialog(res);
          },
          (error) => {
            console.log('error' + error);
          }
        );
    }
  }
  setHoraInicioAtencion() {
    if (this.agendaForm.get('hastaHora').value) {
      if (
        this.toMinutes.transform(this.agendaForm.get('desdeHora').value) >
        this.toMinutes.transform(this.agendaForm.get('hastaHora').value)
      ) {
        this.horaInicioValida = false;
        this.horaFinValida = false;
      } else {
        this.horaInicioValida = true;
        this.horaFinValida = true;
      }
    } else {
      this.agendaForm
        .get('hastaHora')
        .setValue(this.agendaForm.get('desdeHora').value);
    }
  }
  setHoraFinAtencion() {
    if (this.agendaForm.get('desdeHora').value) {
      if (
        this.toMinutes.transform(this.agendaForm.get('hastaHora').value) <
        this.toMinutes.transform(this.agendaForm.get('desdeHora').value)
      ) {
        this.horaFinValida = false;
        this.horaInicioValida = false;
      } else {
        this.horaFinValida = true;
        this.horaInicioValida = true;
        this.setDuracion();
      }
    } else {
      this.agendaForm
        .get('desdeHora')
        .setValue(this.agendaForm.get('hastaHora').value);
    }
  }
  setCantidadTurnos() {
    this.agendaForm
      .get('duracionTurno')
      .setValue(
        (
          (this.toMinutes.transform(this.agendaForm.get('hastaHora').value) -
            this.toMinutes.transform(this.agendaForm.get('desdeHora').value)) /
          this.agendaForm.get('cantidadTurnos').value
        ).toFixed(1)
      );
  }
  setDuracion() {
    this.agendaForm
      .get('cantidadTurnos')
      .setValue(
        (
          (this.toMinutes.transform(this.agendaForm.get('hastaHora').value) -
            this.toMinutes.transform(this.agendaForm.get('desdeHora').value)) /
          this.agendaForm.get('duracionTurno').value
        ).toFixed(1)
      );
  }
  agregarNuevoConsultorio() {
    const dialogRef = this.dialog.open(DialogAgregarConsultorioComponent, {
      width: '400px',
      data: {
        sucursalSeleccionada: this.agendaForm.get('establecimiento').value,
      },
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.sucursalActual = res.sucursalId;
        this.agendaForm.get('establecimiento').setValue(res.sucursalId);
        this.cargarConsultorios(this.sucursalActual, res.descripcion);
      }
    });
  }
  seleccionoConsultorio(evento) {
    if (evento === '') {
      this.agendaForm.get('consultorio').setValue(null);
      this.agregarNuevoConsultorio();
    }
  }
}
