import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import { UserService } from '../_services/user.service';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms'
import { DialogMensajeComponent } from '../dialog-mensaje/dialog-mensaje.component';

@Component({
  selector: 'app-dialog-cambiar-correo',
  templateUrl: './dialog-cambiar-correo.component.html',
  styleUrls: ['./dialog-cambiar-correo.component.css']
})
export class DialogCambiarCorreoComponent implements OnInit {
  userData;

  cambiarCorreoForm: FormGroup = new FormGroup({
    emailActual: new FormControl(),
    emailNuevo: new FormControl('', [Validators.required, Validators.email]),
    confirmarEmail: new FormControl('', [Validators.required, Validators.email])
  })

  loadButton: boolean = true;
  errorUsuario: boolean = false;
  jsonUsuario: any;
  actualizo: boolean;
  hayMsje: boolean = false;
  emailsDistintos: boolean = false;
  mailActualizado: boolean = false;
  guardandoMail: boolean = false;

  constructor(
    public userService: UserService,
    public dialogRef: MatDialogRef<DialogCambiarCorreoComponent>,
    private formBuilder: FormBuilder,
    public dialogo: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data,
  ) {
    this.userData = data;
   }

  ngOnInit(): void {
    this.obtenerUsuario();
  }

  async obtenerUsuario() {
    await this.userService.getUsuarioPorUsername(this.userData.usuario).toPromise().then(
      (res) => {
        this.jsonUsuario = res.paginador.elementos[0];
        this.mailActualizado = this.jsonUsuario.mailActualizado;
        this.iniciarForm()
      }, (error) => {
        this.errorUsuario = true;
      }
    )      //DESHABILITAR EL BOTÓN CUANDO TOCO MODIFICAR MAIL GBHNURJFEUWRFIUERFNIUEWFRIUEBRIUTGEWIURHT
  }

  async iniciarForm() {
    this.cambiarCorreoForm = this.formBuilder.group({
      emailActual: [{ value: this.jsonUsuario.email ? this.jsonUsuario.email : 'No tiene', disabled: true}],
      emailNuevo: new FormControl('', [Validators.required, Validators.email]),
      confirmarEmail: new FormControl('', [Validators.required, Validators.email])
    })
    this.loadButton = false;
  }

  async cambiarCorreo() {
    this.guardandoMail = true;
    this.emailsDistintos = false;
    if ((this.cambiarCorreoForm.get('emailNuevo').hasError("required")) || (this.cambiarCorreoForm.get('confirmarEmail').hasError("required"))) {
      //console.log('1 :'+this.cambiarCorreoForm.get("emailNuevo").value);

      this.desplegarPopup(false, "Los campos no pueden estar vacíos.");
      this.guardandoMail = false;
    } else {
      //console.log('2 :'+this.cambiarCorreoForm.get("emailNuevo").value);

    if (this.cambiarCorreoForm.get('emailNuevo').value != this.cambiarCorreoForm.get('confirmarEmail').value) {
      this.emailsDistintos = true;
      this.loadButton = false;
      this.guardandoMail = false;
      this.desplegarPopup(false, "El correo nuevo y la confirmación deben ser iguales.");
    } else if (!this.cambiarCorreoForm.invalid) {
      //console.log('3 :'+this.cambiarCorreoForm.get("emailNuevo").value);
      
      this.jsonUsuario.email = this.cambiarCorreoForm.get('emailNuevo').value;
      this.jsonUsuario.mailActualizado = true;
      await this.userService.guardarUsuario(this.jsonUsuario).toPromise().then(resp => {
        // console.log(resp)
        if (resp.ok) {
          this.desplegarPopup(resp.ok, "El correo se ha modificado correctamente.");
        } else {
          this.desplegarPopup(resp.ok, "Ocurrió un error al modificar el correo.");
        }
        
        this.guardandoMail = false;
      }, error => {
        console.log(error)
        this.actualizo = false;
        this.hayMsje = true;
        this.loadButton = false;
      })
    } else {
      this.loadButton = false;
      this.guardandoMail = false;
      // console.log('error')
    }
   }
  }

  // confirmarMail(): ValidatorFn {
  //   return (control: AbstractControl): ValidationErrors | null => {
  //     // const forbidden = nameRe.test(control.value);
  //     // return forbidden ? {forbiddenName: {value: control.value}} : null;
  //     console.log(control.value)
  //     return this.cambiarCorreoForm.get('emailNuevo').value != control.value ? {confirmarEmail: {value: control.value}} : null;
  //   };
  // }

  desplegarPopup(estado: boolean, mensaje: String) {
    this.dialogo.open(DialogMensajeComponent,{
      width: '450px',
      data:{
        ok: estado,
        mensaje: mensaje,
      }
    }).afterClosed().subscribe(result => { if (estado) { this.dialogRef.close() } });
  }

  getErrorMessage(formControl) {
    if (this.cambiarCorreoForm.get(formControl).hasError('required')) {
      return "Este campo no puede estar vacio.";
    }

    if (this.cambiarCorreoForm.get(formControl).hasError('email')) {
      return 'El correo no es valido.';
    }
  }

  cerrarPopup() {
    sessionStorage.setItem('cerroPopup', 'true');
  }

}
