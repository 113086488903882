<button mat-icon-button style="float: right" (click)="cancelar()">
  <mat-icon class="close-icon" color="warn">close</mat-icon>
</button>
<h1 mat-dialog-title>Editar datos del paciente</h1>
<div mat-dialog-content>
  <form
    class="ui form"
    [formGroup]="editarPacienteForm"
    enctype="multipart/form-data"
  >
    <div class="form-row align-items-center">
      <div
        class="
          form-group
          col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6
          mx-auto
          mb-3
        "
      >
        <mat-form-field>
          <mat-label>Fecha de nacimiento</mat-label>
          <input
            matInput
            [matDatepicker]="picker2"
            formControlName="fechaNacimiento"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker2"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker2></mat-datepicker>
          <mat-error
            *ngIf="
              submitted &&
              f.fechaNacimiento.errors &&
              f.fechaNacimiento.errors.required
            "
          >
            Este campo es requerido
          </mat-error>
          <mat-error
            *ngIf="
              submitted &&
              f.fechaNacimiento.errors &&
              f.fechaNacimiento.errors.maxDate
            "
          >
            La fecha no puede ser mayor a la actual
          </mat-error>
          <mat-error
            *ngIf="
              submitted &&
              f.fechaNacimiento.errors &&
              f.fechaNacimiento.errors.minDate
            "
          >
            La fecha no puede ser menor a la 1900
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="form-row align-items-center">
      <div class="form-group col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-3">
        <mat-form-field>
          <mat-label>Telefono</mat-label>
          <input
            matInput
            type="text"
            formControlName="telefono"
            [ngClass]="{ 'is-invalid': submitted && f.telefono.errors }"
          />
          <mat-error
            *ngIf="submitted && f.telefono.errors && f.telefono.errors.required"
          >
            Este campo es requerido
          </mat-error>
          <mat-error
            *ngIf="
              submitted && f.telefono.errors && f.telefono.errors.minlength
            "
          >
            Debe tener al menos 7 digitos
          </mat-error>
          <mat-error
            *ngIf="
              submitted && f.telefono.errors && f.telefono.errors.maxlength
            "
          >
            Debe tener como maximo 20 digitos
          </mat-error>
          <mat-error
            *ngIf="submitted && f.telefono.errors && f.telefono.errors.pattern"
          >
            No debe tener caracteres especiales ni letras
          </mat-error>
        </mat-form-field>
      </div>
      <div class="form-group col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-3">
        <mat-form-field>
          <mat-label>E-mail</mat-label>
          <input
            matInput
            type="text"
            formControlName="mail"
            [ngClass]="{ 'is-invalid': submitted && f.mail.errors }"
          />
          <mat-error
            *ngIf="submitted && f.mail.errors && f.mail.errors.required"
            >Este campo es requerido</mat-error
          >
          <mat-error *ngIf="submitted && f.mail.errors && f.mail.errors.pattern"
            >Debe ser un mail valido</mat-error
          >
        </mat-form-field>
      </div>
    </div>
    <div class="form-row align-items-center">
      <div class="form-group col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-3">
        <mat-form-field>
          <mat-label>Domicilio</mat-label>
          <input
            matInput
            type="text"
            formControlName="domicilio"
            [ngClass]="{ 'is-invalid': submitted && f.domicilio.errors }"
          />
          <mat-error
            *ngIf="
              submitted && f.domicilio.errors && f.domicilio.errors.required
            "
          >
            Este campo es requerido
          </mat-error>
          <mat-error
            *ngIf="
              submitted && f.domicilio.errors && f.domicilio.errors.pattern
            "
          >
            El domicilio no debe tener caracteres especiales
          </mat-error>
        </mat-form-field>
      </div>
      <div class="form-group col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-3">
        <mat-form-field>
          <mat-label>Localidad</mat-label>
          <input
            matInput
            type="text"
            formControlName="localidad"
            [ngClass]="{ 'is-invalid': submitted && f.localidad.errors }"
          />
          <mat-error
            *ngIf="
              submitted && f.localidad.errors && f.localidad.errors.required
            "
          >
            Este campo es requerido
          </mat-error>

          <mat-error
            *ngIf="
              submitted && f.localidad.errors && f.localidad.errors.pattern
            "
          >
            La localidad no debe tener caracteres especiales
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="form-row align-items-center">
      <div
        class="form-group col-xs-8 col-sm-8 col-md-8 col-lg-8 col-xl-8 m-auto"
      >
        <mat-radio-group
          class="d-flex justify-content-center"
          aria-label="Sexo"
          name="sexo"
          formControlName="sexo"
        >
          <mat-radio-button
            color="primary"
            value="FEMENINO"
            [ngClass]="{
              'is-invalid': submitted && f.sexo.errors
            }"
            >Femenino</mat-radio-button
          >
          <mat-radio-button
            color="primary"
            value="MASCULINO"
            [ngClass]="{
              'is-invalid': submitted && f.sexo.errors
            }"
            >Masculino</mat-radio-button
          >
        </mat-radio-group>
        <mat-error *ngIf="submitted && f.sexo.errors && f.sexo.errors.required"
          >Este campo es requerido</mat-error
        >
      </div>
    </div>
    <div mat-dialog-actions class="justify-content-center mb-1">
      <button mat-raised-button color="primary" (click)="cancelar()">
        cancelar
      </button>
      <button mat-raised-button color="warn" (click)="onSubmit()">
        confirmar
      </button>
      <mat-spinner
        diameter="22"
        [hidden]="!loading"
        style="display: inline-block; margin-left: 5%; margin-top: 1%"
      ></mat-spinner>
    </div>
  </form>
</div>
