import { Injectable } from '@angular/core';
import { Adapter } from '../_adapters/adapter';

export class EpisodioHistorialInfo {
  fechaEgreso;
  formaEgreso;
  estudiosPaciente;
  texto;
  especialidadAtencion;
  numero;
}

@Injectable({
  providedIn: 'root',
})
export class EpisodioHistorialInfoGuardiaAdapter
  implements Adapter<EpisodioHistorialInfo> {
  adapt(item: any): EpisodioHistorialInfo {
    const eh = new EpisodioHistorialInfo();
    eh.formaEgreso = item.formaEgreso;
    eh.fechaEgreso = item.fechaEgreso;
    return eh;
  }
}

@Injectable({
    providedIn: 'root',
  })
export class EpisodioHistorialInfoEstudioAdapter
  implements Adapter<EpisodioHistorialInfo> {
  adapt(item: any): EpisodioHistorialInfo {
    const eh = new EpisodioHistorialInfo();
    eh.numero = item.numero;
    eh.texto = item.texto;
    eh.estudiosPaciente = item.estudiosPaciente;
    return eh;
  }
}
