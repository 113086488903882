<button
  mat-icon-button
  style="float: right; top: -24px; right: -24px"
  [mat-dialog-close]="false"
>
  <mat-icon class="close-icon" color="warn">close</mat-icon>
</button>
<h1 mat-dialog-title>Etiquetas</h1>
<div mat-dialog-content *ngIf="!loading">
  <mat-form-field>
    <mat-label>Etiquetas</mat-label>
    <mat-select [formControl]="etiquetasFormControl" >
      <mat-option
        *ngFor="let a of etiquetas"
        [value]="a"
      >
        {{ a.descripcion | toTitleCase }}
      </mat-option>
    </mat-select>
  </mat-form-field>
 <br>
  

  <button mat-raised-button (click)="onSubmit()" color="primary">Guardar
  </button>
</div>
