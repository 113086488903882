<h3 mat-dialog-title>Seleccione el subepisodio para el que quiere asociar la nota médica</h3>

<mat-dialog-content>
    <mat-radio-group [(ngModel)]="opcion" class="dialog-radio-buttons"
    style="display: flex; flex-direction: column; margin: 15px 0; align-items: flex-start;">
        <mat-radio-button value="1" [checked]="opcion === '1'">Historia clínica del receptor</mat-radio-button>
        <mat-radio-button value="2" [checked]="opcion === '2'">Historia clínica del dador vivo</mat-radio-button>
    </mat-radio-group>
    <p>Si no desea publicar en trasplante, desactive el botón "Publicar en trasplante" debajo de la casilla de la nota médica.</p> 
</mat-dialog-content>

<mat-dialog-actions
style="justify-content: flex-end; margin-top: 20px;">
    <button mat-button color="warn" (click)="cancelar()">Cancelar</button>
    <button mat-button color="primary" (click)="guardar()">Guardar</button>
</mat-dialog-actions>
