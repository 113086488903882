import { EventEmitter, Injectable, Output, Directive } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from '../_models/user.model';
import { Router } from '@angular/router';
import { AppComponent } from '../app.component';
import { EpisodioHistorial, EpisodioHistorialAdapter } from '../_models/episodioHistorial.model';
import { EvolucionPaciente, EvolucionPacienteAdapter } from '../_models/evolucionPaciente.model';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json; charset=utf-8',
  }),
};

@Directive()
@Injectable({ providedIn: 'root' })
export class PacienteService {
  baseUrl: string;

  private currentPatientSubject: BehaviorSubject<any>;

  public currentPatient: Observable<any>;

  @Output() recargarHistorialEmitter: EventEmitter<boolean> = new EventEmitter();

  constructor(
    private http: HttpClient,
    private episodioHistorialAdapter: EpisodioHistorialAdapter,
    private evolucionPacienteAdapter: EvolucionPacienteAdapter,
    private router: Router
  ) {
    this.baseUrl = AppComponent.baseUrl;
    this.currentPatientSubject = new BehaviorSubject<any>(
      JSON.parse(sessionStorage.getItem('currentPatient'))
    );
    this.currentPatient = this.currentPatientSubject.asObservable();
  }

  setCurrentPatient(paciente) {
    sessionStorage.setItem('currentPatient', JSON.stringify(paciente));
    this.currentPatientSubject.next(paciente);
  }

  getEtiquetaPaciente(paciente) {
    const body = JSON.stringify(paciente);
    return this.http.post<any>(
      this.baseUrl + '/pacientes/seguro/getetiquetaspaciente',
      body
    );
  }


  fusionarPacientes(tipoDniTemporal:any,nroDniTemporal:any,tipoDniConfirmado:any,nroDniConfirmado:any) {
    const params={tipoDniTemporal,nroDniTemporal,tipoDniConfirmado, nroDniConfirmado,};
    const body = JSON.stringify(params);
   return this.http.post<any>(
      this.baseUrl + '/pacientes/seguro/fusionarPacientes/',
      body
    );
  }

  removeCurrentPatient() {
    sessionStorage.removeItem('currentPatient');
    this.currentPatientSubject.next(null);
  }

  getCurrentPatient() {
    return this.currentPatientSubject.value;
  }

  buscarPaciente(nombre, apellido, numeroDoc) {
    return this.http.get<any>(
      this.baseUrl +
        '/pacientes/seguro/buscarPacientesPorDniApellido/' +
        apellido +
        '/' +
        nombre +
        '/' +
        numeroDoc
    );
  }

  crearPaciente(
    nombre,
    apellido,
    sexo,
    fechaNac,
    mail,
    tel,
    domicilio,
    loc,
    cod,
    tipoDocumento,
    nroDocumento
  ) {
    const body = {
      nombre,
      apellido,
      sexo,
      fechaNacimiento: fechaNac,
      mail,
      telefono: tel,
      domicilio,
      localidad: loc,
      codigoPostal: cod,
      tipoDocumento:tipoDocumento,
      numeroDocumento:nroDocumento,
    };
    return this.http.post<any>(
      this.baseUrl + '/pacientes/seguro/crear_paciente',
      body,
      httpOptions
    );
  }

  datosPaciente(numeroDoc, tipoDoc) {
    return this.http.get<any>(
      this.baseUrl +
        '/pacientes/seguro/datosDePaciente/' +
        numeroDoc +
        '/' +
        tipoDoc
    );
  }

  getTiposDocumentos() {
    return this.http.get<any>(
      this.baseUrl +
        '/pacientes/seguro/getTiposDocumentos/' 
    );
  }

  historialPaciente(numeroDoc: any, tipoDoc: any, matricula: any, fechaDesde: any, fechaHasta: any) {
    return this.http
      .get<EpisodioHistorial>(
        this.baseUrl +
          '/episodioPaciente/seguro/historialDePacienteConsultorios/' +
          numeroDoc +
          '/' +
          tipoDoc +
          '/' +
          matricula +
          '/' +
          fechaDesde +
          '/' +
          fechaHasta
      )
      .pipe(
        map((data: any) =>
          data.paginador.elementos.map((item) => 
             this.episodioHistorialAdapter.adapt(item)
          )
        )
      );
  }

  getRegistrosHCEPaciente(numeroDoc: any, tipoDoc: any, idTipoReg: any, fechaDesde: any, fechaHasta: any) {
    return this.http.get<any>(this.baseUrl + '/episodioPaciente/seguro/getRegistrosHCEDelPaciente/' +
      numeroDoc +
      '/' +
      tipoDoc +
      '/' +
      idTipoReg +
      '/' +
      fechaDesde +
      '/' +
      fechaHasta
    )
  }

  recargarHistorial() {
    this.recargarHistorialEmitter.emit();
  }

  evolucionesPaciente(numeroDoc: any, tipoDoc: any, matricula: any) {
    const body = {
      matricula,
      numeroDocumento: numeroDoc,
      tipoDocumento: tipoDoc,
    };
    return this.http
      .post<EvolucionPaciente>(
        this.baseUrl +
          '/turnos/fichamedica/seguro/getfichaambulatoriasmatpaciente',
        body
      )
      .pipe(
        map((data: any) =>
          data.paginador.elementos.map((item) =>
            this.evolucionPacienteAdapter.adapt(item)
          )
        )
      );
  }

  guardarEvolucion(
    anotacion,
    fechaTurnoSt,
    matricula,
    nombreMedico,
    numeroDocumento,
    privada,
    tipo,
    tipoDocumento,
    id?,
    version?
  ) {
    const body = {
      anotacion,
      fechaTurnoSt,
      matricula,
      nombreMedico,
      numeroDocumento,
      privada,
      tipo,
      tipoDocumento,
      id,
      version,
    };

    return this.http.post<any>(
      this.baseUrl + '/turnos/fichamedica/seguro/guardarNota',
      body,
      httpOptions
    );
  }

  borrarEvolucion(evolucion) {
    evolucion.fecha = null;
    const body = JSON.stringify(evolucion);
    return this.http.post<any>(
      this.baseUrl + '/turnos/fichamedica/seguro/borrarelemento',
      body,
      httpOptions
    );
  }

  getEtiquetas() {
    return this.http.get<any>(
      this.baseUrl + '/pacientes/seguro/getetiquetas',
      httpOptions
    );
  }

  setEtiquetaPaciente(etiqueta) {
    return this.http.post<any>(
      this.baseUrl + '/pacientes/seguro/setetiquetaspaciente',
      etiqueta,
      httpOptions
    );
  }

  getAntecedentes() {
    return this.http.get<any>(
      this.baseUrl + '/antecedente/seguro/getAntecedentes',
      httpOptions
    );
  }

  guardarAntecedentes(nroDocumento, tipoDocumento, antecedentes, login) {
    const body = { nroDocumento, tipoDocumento, antecedentes, login };
    return this.http.post<any>(
      this.baseUrl + '/pacientes/seguro/guardarAntecedentes',
      body,
      httpOptions
    );
  }

  mutualesDePaciente(dni, tipoDni) {
    return this.http.get<any>(
      this.baseUrl + '/mutuales/getCoberturasPaciente/' + tipoDni + '/' + dni
    );
  }

  editPaciente(value) {
    return this.http.post<any>(
      this.baseUrl + '/pacientes/seguro/editarPaciente',
      value,
      httpOptions
    );
  }

  setEstadoTriagePaciente(jsonTriage) {
    return this.http.post<any>(this.baseUrl + '/pacientes/seguro/setEstadoTriagePaciente', jsonTriage);
  }
  getConsentimientos(){
    const body = {
      servicio:null,
      estado: 'ACT',
      tipo:3
    }
    return this.http.post<any>(
      this.baseUrl + '/hcdigital/seguro/consentimientos',
      body
    );
  }

  setCoberturaPaciente(jsonPrivado) {
    const body = JSON.stringify(jsonPrivado);
    return this.http.post<any>(this.baseUrl + '/mutuales/setCoberturaPaciente', body, httpOptions);
  }

  setEstadoCoberturaPaciente(jsonPrivado) {
    const body = JSON.stringify(jsonPrivado);
    return this.http.post<any>(this.baseUrl + '/mutuales/setEstadoCoberturaPaciente', body, httpOptions);
  }
}
