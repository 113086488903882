<div class="container-fluid" style="margin-top: 1%" *ngIf="!cargandoRedireccion">
  <mat-card [tabindex]="0" class="card-principal">
    <div class="container-fluid">
      <div class="row">
        <div class="col">
          <h3 style="margin-bottom:5%; margin-top:2.5%">
            Episodios
          </h3>
        </div>
        <div class="col">
          <button 
            mat-icon-button 
            color="primary" 
            (click)="irCrearEpisodio(null)" 
            matTooltip="Crear Episodio"
          >
          <mat-icon >library_add</mat-icon>
          </button>          
        </div>
        
        <div class="col-xs-4 col-sm-6 col-md-8 col-lg-9 col-xl-9">
          <button mat-icon-button 
                  class="float-right"
                  color="primary" 
                  (click)="volver()"
                  style="margin-right: 8%">
            <mat-icon>keyboard_backspace</mat-icon> Volver
          </button>   
        </div>
      </div>

      <div
        class="d-flex justify-content-center"
        style="margin-top: 10%"
        *ngIf="loading">
        <mat-spinner [diameter]="60"></mat-spinner>
      </div>
      
      <div [hidden]="loading" class="row">
        <div class="col">
          <mat-divider></mat-divider>
          <mat-card-header style="margin-left: -16px;margin-top:1.5%">
           
            <mat-card-title>
              {{ currentPatient.str_paciente | toTitleCase }}
            </mat-card-title>

            <mat-card-subtitle>
              <span>
                <span *ngIf="this.currentPatient.tipoDoc == 9">Temporal</span>
                <span *ngIf="this.currentPatient.tipoDoc == 0"> DNI:</span>
                 {{ currentPatient.numeroDoc }}
              </span>
              |
              <span *ngIf="currentPatient.coberturas.length > 0"
                >{{ currentPatient.coberturas[0].mutual | toTitleCase }} -
              </span>
              <span *ngIf="currentPatient.coberturas.length > 0">{{
                currentPatient.coberturas[0].nroCarnet
              }}</span>
            </mat-card-subtitle>

            <mat-card-subtitle>
              <span>Edad: {{ currentPatient.edad }} | </span>
              <span>{{ currentPatient.sexo | toTitleCase }}</span>
              |
              <span>Nacimiento: {{ currentPatient.fechaNacimiento }}</span>
            </mat-card-subtitle>

          </mat-card-header>
          
        </div>
        <div class="col">
          <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <span style="float: right; margin-right: 2.5%;margin-top: 8%;">
               <!--  <button mat-raised-button 
                        color="primary" 
                        (click)="irCrearEpisodio(null)" 
                        style="border-radius: 8px;">
                  <mat-icon >library_add</mat-icon>
                  Crear episodio
                </button> -->
              </span>
            </div>
          </div>
        </div>
      </div>

      <mat-card-content>
        
      <div  class="row" style="margin-top: 1%;">
        <div [hidden]="loading" class="col">
          
          <div style="text-align: center; margin-top: 5%;" *ngIf="sinEpisodios">
            <mat-card class="sin-episodio-card">
              <h5 style="color:#3F51B5" >No se encontraron episodios</h5>
            </mat-card>
          </div>

        <mat-accordion>
        <div style="margin-top:1%" *ngFor="let episodio of episodios">

          <mat-expansion-panel class ="mat-elevation-z3">
            <mat-expansion-panel-header
              style="
                background: linear-gradient(
                  180deg,
                  rgba(255, 255, 255, 1) 95%,
                  rgba(63, 81, 181, 0.8) 5%
                );
              "
              
            >
              <mat-panel-title style="width:250px;margin-top:0.75% !important"> 
                <h5>{{ episodio.descripcion }} </h5> 
                <h5 class="espaciado">|</h5>
                <h5>{{ episodio.diagnostico }}</h5> 
              </mat-panel-title>
              <mat-panel-description style="margin-top:0.75% !important">
                <span>Inicio: </span> <b>{{ episodio.fechaInicio }} </b>
                <span class="espaciado">|</span> 
                <span> Profesional: </span> {{ episodio.nombreProfesional | toTitleCase }}
              </mat-panel-description>
              <!--Botón Editar-->
              <button 
                matTooltip="Editar episodio"
                (click)="irEditarEpisodio(episodio)" 
                color="primary"
                style="margin-right:1%;font-size:10px"
                class="float-right mat-elevation-z1"
                mat-mini-fab
                [ngStyle]="{transform: 'scale(0.6)'}"
              >
              <mat-icon >edit</mat-icon>
              </button>
            </mat-expansion-panel-header>

            <div class="row justify-content-center align-items-center" style="margin-left:5%;width:90%;margin-top:1%">
              <div class="col" >
                <span>
                  <h5>
                    Subepisodios ({{this.episodio.subepisodios.length}})
                  </h5>

                </span>
              </div>

              <div class="col" >
                <button mat-icon-button 
                        color="primary" 
                        (click)="imprimirTodoEpisodio(this.episodio.idEpisodio)"
                        *ngIf="this.episodio.subepisodios.length != 0">
                    <mat-icon>print</mat-icon> 
                    Imprimir todo
                </button>  
              </div>

              <div class="row">
                <button 
                    class="float-right"     
                    (click)="irCrearEpisodio(episodio)"
                    color="primary"
                    style=" border-radius: 8px;"
                    mat-raised-button
                  >
                    <mat-icon>library_add</mat-icon>
                    Crear Subepisodio
                </button>
                <button
                  id="boton_documentos_digitales"
                  mat-raised-button
                  color="primary"
                  (click)="irDocumentosDigitales(episodio)">
                  <mat-icon>storage</mat-icon>
                  Documentos digitales
                </button>
              </div>
              
            </div>
            
            <div class="contenedor-subepisodios" *ngFor="let subepisodio of episodio.subepisodios">
              <mat-card [tabindex]="0" style="margin-top:0%;max-height: 100px;" class="sub-episodio-card">
                <mat-card-header style="display: block !important;" >
             
                  <mat-card-title>
                   <h6> {{ subepisodio.descripcion | toTitleCase }} </h6>
                  </mat-card-title>
      
                  <mat-card-subtitle>
                    <div class="row justify-content-center align-items-center" >
                      <div class="col-md-10 col-lg-10 col-xl-10" >
                        <span>Inicio: </span><b> {{ subepisodio.fechaInicio }}</b> 
                        |
                        <span>Profesional:</span> {{ subepisodio.nombreProfesional | toTitleCase }}
                        <p>
                          <b style="color:black">{{ subepisodio.tipoDeEpisodio.descripcion }}</b>
                        
                          <button *ngIf="subepisodio.tipoDeEpisodio.idTipoEpisodio==3"
                            matTooltip="Ir a la Internacion" (click)="irAInternaciones(subepisodio)"
                            color="primary" style="font-size:20px" class="mat-elevation-z1"
                            mat-raised-button [ngStyle]="{transform: 'scale(0.6)'}"
                          >
                            Ir a la internación
                          </button>
                        </p>
                      </div>
                      <div class="col-md-1 col-lg-1 col-xl-1">
                        <!-- Botón detalles -->
                        <button 
                          matTooltip="Ver formularios"
                          (click)="verFormularios(subepisodio)"
                          color="primary"
                          style="font-size:20px"
                          class="float-right"
                          mat-raised-button
                          [ngStyle]="{transform: 'scale(0.6)'}"
                          
                        >
                          <mat-icon>visibility</mat-icon>
                          Ver Formularios
                        
                        </button>
                      </div>
                      <div class="col-md-1 col-lg-1 col-xl-1">
                          <!-- Botón editar -->
                          <button 
                            matTooltip="Editar subepisodio"
                            (click)="irEditarEpisodio(subepisodio)"
                            color="primary" 
                            style="font-size:10px"
                            class="float-right mat-elevation-z1"
                            mat-mini-fab
                            [ngStyle]="{transform: 'scale(0.6)'}"
                          >
                          <mat-icon>edit</mat-icon>
                          </button>
                      </div>
                      
                    </div>
                    
                    
                  </mat-card-subtitle>
 
                </mat-card-header>
              </mat-card>

            </div>

          </mat-expansion-panel>
        </div>
        </mat-accordion>

        </div>
      </div>
    </mat-card-content>

    </div>
  </mat-card>
</div>
<div
        class="d-flex justify-content-center"
        style="margin-top: 10%"
        *ngIf="cargandoRedireccion">
        <mat-spinner [diameter]="60"></mat-spinner>
      </div>
