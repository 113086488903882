import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppComponent } from '../app.component';
import { map } from 'rxjs/operators';



@Injectable({ providedIn: 'root' })
export class UrlResolverService {
  private baseUrl: string;

  constructor(private http: HttpClient) {
    this.baseUrl = AppComponent.baseUrl;
  }

  getHorusfullUrl(): Observable<any> {
    return this.http.get<any>(this.baseUrl + '/utils/horus-url');
  }

  getTiempoWC() {
    return this.http.get<any>(this.baseUrl + '/tiempo-web-checkin');
  }

  getParamAppVariableConfig(nombreAppVarConfig) {
    return this.http.get(this.baseUrl + '/core/parametros/seguro/variablesconfig/' + nombreAppVarConfig, { responseType: 'text' });
  }

}
