import * as moment from 'moment';
import { Turno } from './turno.model';

export class RespuestaTurno {
  ok: boolean;
  turnos: Turno[];
  tieneCancelaciones: boolean;
  ordenDesdeCancelaciones: number;
  ordenHastaCancelaciones: number;
  frecuencia: number;
  fechaTurnos: Date;
  servicioId: number;
  diasAnticipacion: number;
}
