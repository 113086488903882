import { Component, Inject, OnInit } from '@angular/core';
import { PacienteService } from '../_services/paciente.service';
import { AuthenticationService } from '../_services/authentication.service';
import { NavigationStart, Router } from '@angular/router';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogSolicitarEstudioComponent } from '../dialog-solicitar-estudio/dialog-solicitar-estudio.component';

import { AlertService } from '../_services/alert.service';
import { SucursalService } from '../_services/sucursal.service';
import { DialogEditarDatosPacienteComponent } from '../dialog-editar-datos-paciente/dialog-editar-datos-paciente.component';
import { PacienteBioComponent } from '../paciente-bio/paciente-bio.component';
import { DialogSolicitarBiopsiaComponent } from '../dialog-solicitar-biopsia/dialog-solicitar-biopsia.component';
import { DialogSolicitarInternacionComponent } from '../dialog-solicitar-internacion/dialog-solicitar-internacion.component';
import { FormulariosService } from '../_services/formularios.service';
import { EvolucionService } from '../_services/evolucion.service';
import { CharactersService } from '../_services/characters.service';
import { DialogAsignarConsentimientosComponent } from '../dialog-asignar-consentimientos/dialog-asignar-consentimientos.component';
@Component({
  selector: 'app-paciente',
  templateUrl: './paciente.component.html',
  styleUrls: ['./paciente.component.css'],
})
export class PacienteComponent implements OnInit {
  currentPatient: any;
  currentUser;
  patientData: any;
  etiquetasPaciente: any;

  constructor(
    private pacienteService: PacienteService,
    private authenticationService: AuthenticationService,
    private router: Router,
    private sucursalService: SucursalService,
    public dialog: MatDialog,
    private formularioService: FormulariosService,
    private evoService: EvolucionService,
    private characterService: CharactersService
  ) {
    this.router.events.subscribe((event: NavigationStart) => {
      if (event.navigationTrigger === 'popstate') {
        this.pacienteService.removeCurrentPatient();
        this.sucursalService.removeCurrentSucursal();
      }
    });
  }

  irHistoriaLab() {
    this.router.navigate(['/historial-laboratorio']);
  }

  irListarEpisodios() {
    this.currentPatient.coberturas = this.patientData.coberturas;
    this.currentPatient.edad = this.patientData.edad;
    this.currentPatient.sexo = this.patientData.sexo;
    this.currentPatient.fechaNacimiento = this.patientData.fechaNacimiento;
    this.pacienteService.setCurrentPatient(this.currentPatient);
    this.router.navigate(['/listar-episodios']);
  }

  openDialogSolicitarInternacion() {
    const dialogRef = this.dialog.open(DialogSolicitarInternacionComponent, {
      width: '500px',
      data: {
        nroDocumento: this.currentPatient.numeroDoc,
        tipoDocumento: this.currentPatient.tipoDoc,
        nombreApellido: this.currentPatient.str_paciente,
        rolMHE: this.currentUser.roles.find(elemento => elemento.codigo == 'MHE'),
      }
    });
  }

  openDialogSolicitudBiopsia() {
    const dialogRef = this.dialog.open(DialogSolicitarBiopsiaComponent, {
      width: '500px',
      height: '780px',
      data: {
        nroDocumento: this.currentPatient.numeroDoc,
        tipoDocumento: this.currentPatient.tipoDoc,
        rolMHE: this.currentUser.roles.find(elemento => elemento.codigo == 'MHE'),
      },
    });
  }

  openPacienteBio() {
    const dialogRef = this.dialog.open(PacienteBioComponent, {
      width: 'auto',
      // data: {
      //   nroDocumento: this.currentPatient.numeroDoc,
      //   tipoDocumento: this.currentPatient.tipoDoc,
      // },
    });
  }

  volver() {
    this.pacienteService.removeCurrentPatient();
    this.sucursalService.removeCurrentSucursal();
    this.evoService.unSetEvo();
    this.router.navigate(['/agenda']);
  }

  ngOnInit() {
    this.authenticationService.currentUser.subscribe((x) => {
      // console.log(x)
      this.currentUser = x;
    });
    this.currentPatient = this.pacienteService.getCurrentPatient(); 
    this.loadPatient();
  }

  loadPatient() {
    if (this.currentPatient) {
      this.pacienteService
        .datosPaciente(
          this.currentPatient.numeroDoc,
          this.currentPatient.tipoDoc
        )
        .subscribe(
          (data2) => {
            this.patientData = data2;
            this.patientData.antecedentes.forEach((ante)=>{
              ante.detalle = this.characterService.reconvertirCaracteresEspeciales(ante.detalle);

            })
            this.pacienteService
              .getEtiquetaPaciente(this.patientData)
              .subscribe(
                (etiquetasPaciente) => {
                  this.etiquetasPaciente =
                    etiquetasPaciente.paginador.elementos;
                  
                },
                (error) => {
                  console.log(error);
                }
              );
          },
          (error) => {
            console.log(error);
          }
        );
    }
  }

  openDialogAntecedentes() {
    const dialogRef = this.dialog.open(EditarAntecedentesDialog, {
      width: '500px',
      data: {
        
        antecedentesPaciente: this.patientData.antecedentes,
        nroDocumento: this.currentPatient.numeroDoc,
        tipoDocumento: this.currentPatient.tipoDoc,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.loadPatient();
    });
  }

  openDialogEtiquetas() {
    const dialogRef = this.dialog.open(EditarEtiquetasDialog, {
      width: '500px',
      data: {
        antecedentesPaciente: this.patientData.antecedentes,
        nroDocumento: this.currentPatient.numeroDoc,
        tipoDocumento: this.currentPatient.tipoDoc,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.loadPatient();
    });
  }

  borrarEtiqueta(etiqueta) {
    const body = etiqueta;
    body.estado = 'INA';
    this.pacienteService.setEtiquetaPaciente(body).subscribe(
      (data) => {
        this.loadPatient();
      },
      (error) => {
        console.log(error);
      }
    );
  }

  openDialogSolicitudEstudio(): void {
    const dialogRef = this.dialog.open(DialogSolicitarEstudioComponent, {
      panelClass: 'app-full-bleed-dialog',
      width: '1300px',
      height: '700px',
      data: {
        patientData: this.patientData,
      },
    });
  }
  openDialogEditarDatosPaciente(): void {
    const dialogRef = this.dialog
      .open(DialogEditarDatosPacienteComponent, {
        width: '600px',
        data: this.patientData,
      })
      .afterClosed()
      .subscribe((confirmado: boolean) => {
        if (confirmado) {
          // location.reload();
          const currentUrl = this.router.url;
          this.router.routeReuseStrategy.shouldReuseRoute = () => false;
          this.router.onSameUrlNavigation = 'reload';
          this.router.navigate([currentUrl]);
        }
      });
  }
  openDialogAgregarConsentimientos(): void {
    const dialogRef = this.dialog.open(DialogAsignarConsentimientosComponent,{
      width:'800px',
      data:this.patientData,
    }).afterClosed().subscribe((confirmado:boolean)=>{
        if(confirmado){
          const currentUrl = this.router.url;
          this.router.routeReuseStrategy.shouldReuseRoute = () => false;
          this.router.onSameUrlNavigation = 'reload';
          this.router.navigate([currentUrl]);
        }
    })
  }
}

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'dialog-editar-antecedentes',
  templateUrl: 'dialog-editar-antecedentes.html',
  styleUrls: ['./paciente.component.css'],
})
export class EditarAntecedentesDialog {
  antecedentesForm: UntypedFormGroup;
  maxDate: Date = new Date();
  submitted = false;
  loading = false;
  antecedentesCheck: any;
  antecedentesText: any;
  guardarCambios= true;
  antecedentesSeleccionados = new UntypedFormControl();

  constructor(
    private formBuilder: UntypedFormBuilder,
    private alertService: AlertService,
    private pacienteService: PacienteService,
    private authService: AuthenticationService,
    public dialogRef: MatDialogRef<EditarAntecedentesDialog>,
    private characterService:CharactersService,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  ngOnInit() {
    this.antecedentesSeleccionados.setValue(
      this.data.antecedentesPaciente.map((a) => a.codigoAntecedente)
    );
    this.loading = true;
    this.pacienteService.getAntecedentes().subscribe(
      (data) => {
      
        this.antecedentesCheck = data.paginador.elementos.filter(
          (a) => a.modoCarga === 'CHECK'
        );
        this.antecedentesText = data.paginador.elementos.filter(
          (a) => a.modoCarga === 'TEXTO'
        );
        this.buildForm();
        this.loading = false;
      },
      (error) => {
        console.log(error);
        this.loading = false;
      }
    );
  }

  get f() {
    return this.antecedentesForm.controls;
  }

  buildForm() {
    this.antecedentesForm = this.formBuilder.group({
      antecedentes: this.formBuilder.array([]),
    });
    this.agergarAntecedentesText();
  }

  private agergarAntecedentesText() {
    this.antecedentesText.forEach((element) => {
      
      const value = this.data.antecedentesPaciente.find((x) => {
        return x.codigoAntecedente === element.codigoAntecedente;
      });
      let fg = null;
      if (value !== undefined) {
        fg = this.formBuilder.group({
          descripcion: [element.descripcion],
          codigoAntecedente: [element.codigoAntecedente],
          detalle: [value.detalle],
        });
      } else {
        fg = this.formBuilder.group({
          descripcion: [element.descripcion],
          codigoAntecedente: [element.codigoAntecedente],
          detalle: [''],
        });
      }
      (this.antecedentesForm.get('antecedentes') as UntypedFormArray).push(fg);
    });
  }
  

  onSubmit() {
    this.guardarCambios=false;
    this.submitted = true;
    if (this.antecedentesForm.invalid) {
      return;
    }

    const antecedentes = [];
    this.antecedentesCheck.forEach((element) => {
      antecedentes.push({
        checked: this.antecedentesSeleccionados.value.includes(
          element.codigoAntecedente
        ),
        codigoAntecedente: element.codigoAntecedente,
      });
    });
    this.antecedentesForm.value.antecedentes.forEach((element) => {
      antecedentes.push({
        checked: element.detalle !== '' && element.detalle !== null,
        codigoAntecedente: element.codigoAntecedente,
        detalle: element.detalle,
      });
    });
    this.pacienteService
      .guardarAntecedentes(
        this.data.nroDocumento,
        this.data.tipoDocumento,
        antecedentes,
        this.authService.currentUserValue.usuario
      )
      .subscribe(
        (data) => {
          this.guardarCambios=true;
          this.dialogRef.close();
        },
        (error) => {
          console.log(error);
        }
      );
  }
}

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'dialog-editar-etiquetas',
  templateUrl: 'dialog-editar-etiquetas.html',
  styleUrls: ['./paciente.component.css'],
})
export class EditarEtiquetasDialog {
  submitted = false;
  loading = false;
  etiquetas;
  etiquetasFormControl = new UntypedFormControl();

  constructor(
    private formBuilder: UntypedFormBuilder,
    private alertService: AlertService,
    private pacienteService: PacienteService,
    private authService: AuthenticationService,
    public dialogRef: MatDialogRef<EditarAntecedentesDialog>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  ngOnInit() {
    this.loadEtiquetas();
  }

  loadEtiquetas() {
    this.loading = true;
    this.pacienteService.getEtiquetas().subscribe(
      (data) => {
        this.etiquetas = data.paginador.elementos;
        this.loading = false;
      },
      (error) => {
        console.log(error);
        this.loading = false;
      }
    );
  }

  onSubmit() {
    this.submitted = true;
    if (this.etiquetas.invalid) {
      return;
    }
    const body = this.etiquetasFormControl.value;
    body.tipoDocumento = this.data.tipoDocumento;
    body.numeroDocumento = this.data.nroDocumento;
    body.estado = 'ACT';
    this.pacienteService.setEtiquetaPaciente(body).subscribe(
      (data) => {
        this.dialogRef.close();
      },
      (error) => {
        console.log(error);
      }
    );
  }

  
}
