<div class="container-fluid">
    <div *ngIf="loading; else elseBlock">
        <h6>Se estan cargando los turnos, aguarde un momento</h6>
    </div>

    <ng-template #elseBlock>
        <!-- BUSCADOR DE TURNOS DISPONIBLES -->
        <div class="px-0 mx-0">
            <div class="container-fluid px-0 mx-0 mt-3">
                <form [formGroup]="filtroTurnosForm">
                    <div style="margin-left: 5%" class="row align-middle justify-content-center text-center">

                        <div id="selector" class="col-3 col-xs-3 col-sm-3 col-md-3 col-lg-3 col-xl-3 p-0 m-0" style="text-align: center">
                            <p>
                                <mat-form-field>
                                    <mat-label>Especialidades</mat-label>
                                    <mat-select formControlName="especialidad" style="width: 100%; margin-left: 15px">
                                        <mat-option>
                                            <ngx-mat-select-search placeholderLabel="Buscar especialidad" noEntriesFoundLabel="No se encontraron especialidades" [formControl]="bankMultiFilterCtrl">
                                            </ngx-mat-select-search>
                                        </mat-option>
                                        <mat-option *ngFor="let especialidad of this.especialidadesFiltradas" [value]="especialidad">
                                            {{ especialidad.nombre | toTitleCase }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </p>
                        </div>
                        <div id="selector " class="col-3 col-xs-3 col-sm-3 col-md-3 col-lg-3 col-xl-3 p-0 m-0 " style="text-align: center ">
                            <p>
                                <mat-form-field>
                                    <mat-label>Establecimientos</mat-label>
                                    <mat-select formControlName="establecimiento" style="width: 100%; margin-left: 15px">
                                        <mat-option>
                                            <ngx-mat-select-search placeholderLabel="Buscar establecimiento" noEntriesFoundLabel="No se encontraron establecimientos" [formControl]="bankMultiFilterCtrl">
                                            </ngx-mat-select-search>
                                        </mat-option>
                                        <mat-option *ngFor="let establecimiento of establecimientosFiltrados" [value]="establecimiento">
                                            {{ establecimiento.nombreCompleto | toTitleCase }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>

                            </p>
                        </div>

                        <div id="selector " class="col-3 col-xs-3 col-sm-3 col-md-3 col-lg-3 col-xl-3 p-0 m-0 " style="text-align: center ">
                            <p>
                                <mat-form-field>
                                    <mat-label>Profesionales</mat-label>
                                    <mat-select formControlName="profesional" style="width: 100%; margin-left: 15px">
                                        <mat-option>
                                            <ngx-mat-select-search placeholderLabel="Buscar profesional" noEntriesFoundLabel="No se encontraron profesionales" [formControl]="bankMultiFilterCtrl">
                                            </ngx-mat-select-search>
                                        </mat-option>
                                        <mat-option *ngFor="let profesional of profesionalesFiltrados" [value]="profesional">
                                            {{ profesional.apellido | toTitleCase }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </p>
                        </div>

                        <div class="col-3 col-xs-3 col-sm-3 col-md-3 col-lg-3 col-xl-3 p-0 m-0 " style="text-align: center ">
                            <div style="width: 250px; margin-left: 15px ">
                                <td class="align-middle ">
                                    <button mat-raised-button color="primary" (click)="onClickBuscar($event)" style="border-radius: 20px;">
                                                    <mat-icon >search</mat-icon>
                                                    Buscar
                                                </button>
                                </td>
                            </div>
                            <br>
                        </div>
                    </div>
                </form>
            </div>
        </div>

        <!-- TABLA DE TURNOS ENCONTRADOS -->
        <div *ngIf="!existenTurnos, else mostrarTurnos ">
            <h5>No se encontraron turnos disponibles para el criterio buscado</h5>
        </div>
        <ng-template #mostrarTurnos>
            <div class="container px-0 mx-auto ">
                <table mat-table [dataSource]="dataSourceItems" #mytable class="mat-elevation-z8">

                    <div style="text-align: center; margin-right: 1px">
                        <ng-container matColumnDef="profesional">
                            <th mat-header-cell *matHeaderCellDef>
                                <h6>Profesional</h6>
                            </th>
                            <td mat-cell *matCellDef="let profesional">
                                <p>
                                    <strong>{{ profesional.apellido | toTitleCase }} </strong> <br/>
                                    <span class="m-0 " *ngFor="let especialidad of profesional.especialidades ">                                                  
                                                        {{ especialidad.nombre | toTitleCase }} - 
                                    </span>
                                </p>
                            </td>
                        </ng-container>
                    </div>

                    <div style="text-align: center; margin-right: 1px">
                        <ng-container matColumnDef="diasdeatencion">
                            <th mat-header-cell *matHeaderCellDef>
                                <h6>Dias de atención</h6>
                            </th>
                            <td mat-cell *matCellDef="let diasdeatencion" class="action-link">
                                <br/>
                                <p class="m-0 " *ngFor=" let establecimiento of diasdeatencion.diasAtencion ">
                                    <strong *ngIf=FiltroNoSeleccionado()> {{ establecimiento.establecimiento | toTitleCase }}</strong>
                                    <span class="m-0 ">  
                                                  {{ establecimiento.nombreDia }} de
                                                  {{ establecimiento.str_horaInicioAtencion }} a
                                                  {{ establecimiento.str_horaFinAtencion }} 
                                                  <b>({{establecimiento.servicio}})</b>
                                                </span>
                                </p>
                                <br/>
                            </td>
                        </ng-container>
                    </div>

                    <div style="text-align: center; margin-right: 1px">
                        <ng-container matColumnDef="turnosDisponibles">
                            <th mat-header-cell *matHeaderCellDef>
                                <h6>turnos</h6>
                            </th>
                            <td mat-cell *matCellDef="let turnosDisponibles" class="action-link">
                                <button mat-stroked-button color="primary" (click)="mostrarDiasAtencion(turnosDisponibles)" style="border-radius: 20px;">
                                                            <mat-icon>assignment</mat-icon>
                                                            Ver turnos
                                                        </button>
                            </td>
                        </ng-container>
                    </div>
                    <tr mat-header-row *matHeaderRowDef="displayedColumnasItems"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumnasItems"></tr>

                </table>
            </div>
        </ng-template>
    </ng-template>



    <!-- FIN DE LA TABLA DE TURNOS DISPONIBLES -->

</div>