import { Component, OnInit, Inject } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { MAT_DATE_LOCALE, DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import {
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import { DialogMensajeComponent } from '../dialog-mensaje/dialog-mensaje.component';
import * as moment from 'moment';
import { AgendaService } from '../_services/agenda.service';
import { DialogConfirmacionComponent } from '../dialog-confirmacion/dialog-confirmacion.component';

@Component({
  selector: 'app-dialog-eliminar-agenda',
  templateUrl: './dialog-cancelar-turnos.component.html',
  styleUrls: ['./dialog-cancelar-turnos.component.css'],
  providers: [
    DatePipe,
    { provide: MAT_DATE_LOCALE, useValue: 'es_AR' },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],
})
export class DialogCancelarTurnosComponent implements OnInit {
  frecuencia: number;
  matricula: number;
  fecha: Date;
  agendaCancelar;
  sucursal;
  agendaCerrarForm: UntypedFormGroup;
  cargado = false;
  loading = false;
  horas = [];
  submitted = false;

  constructor(
    public dialogo: MatDialogRef<DialogCancelarTurnosComponent>,
    private datePipe: DatePipe,
    public dialog: MatDialog,
    private agendaService: AgendaService,
    private formBuilder: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public datos
  ) {}

  cerrarDialogo(): void {
    this.dialogo.close(false);
  }
  confirmado(): void {
    this.submitted = true;
    if (this.agendaCerrarForm.valid) {
      this.loading = true;
      this.agendaService
        .agendaTieneTurnos(this.agendaCerrarForm.value, this.horas.length)
        .subscribe(
          (respuestaTieneTurnos) => {
            this.loading = false;
            this.dialog
              .open(DialogConfirmacionComponent, {
                data:
                  `¿Seguro desea cancelar la agenda? ` +
                  respuestaTieneTurnos.mensaje,
              })
              .afterClosed()
              .subscribe(
                (confirmado: boolean) => {
                  if (confirmado) {
                    this.agendaService
                      .cancelarHorario(
                        this.agendaCerrarForm.value,
                        this.horas.length
                      )
                      .subscribe(
                        (respuestaCancelacion) => {
                          this.loading = false;
                          this.dialog
                            .open(DialogMensajeComponent, {
                              data: respuestaCancelacion,
                            })
                            .afterClosed()
                            .subscribe(() => {
                              if (respuestaCancelacion.ok) {
                                this.dialog.closeAll();
                                this.dialogo.close(true);
                                // window.location.reload();
                              }
                            });
                        },
                        (error) => {
                          this.dialog.open(DialogMensajeComponent, {
                            data: JSON.parse(
                              '{"mensaje":"Error al cancelar la agenda", "ok":false}'
                            ),
                          });
                          console.log('error' + error);
                        }
                      );
                  } else {
                    this.dialog.closeAll();
                    // const dialogRef = this.dialog.open(DialogMensajeComponent, {
                    //   width: '500px',
                    //   data: {
                    //     ok: false,
                    //     mensaje: 'Debe ingresar datos validos',
                    //   },
                    // });
                  }
                },
                (error) => {
                  console.log('error' + error);
                }
              );
          },
          (error) => {
            console.log('error' + error);
          }
        );
    }
  }
  private buildForm() {
    this.agendaCerrarForm = this.formBuilder.group({
      matricula: [this.matricula, Validators.required],
      fecha: [this.fecha, Validators.required],
      desdeHora: [this.agendaCancelar.str_horaInicio, Validators.required],
      desdeVigencia: [
        moment(this.agendaCancelar.desdeVigencia, 'DD/MM/YYYY').toDate(),
        Validators.required,
      ],
      ordenDesde: [, Validators.required],
      ordenHasta: [, Validators.required],
      motivo: [, Validators.required],
      sucursal: [this.sucursal, Validators.required],
    });
    this.cargado = true;
  }
  private cargarHoras() {
    // const openingHour = this.agendaCerrarForm.value.horaDesde.split(':', 2)[0];
    // const closingHour = this.agendaCerrarForm.value.horaHasta.split(':', 2)[0];
    // const intervals = [0, 15, 30, 45];
    // for (let hour = openingHour; hour < closingHour; hour++) {
    //   intervals.forEach((interval) => {
    //     this.horas.push(
    //       moment()
    //         .set({
    //           hours: hour,
    //           minutes: interval,
    //           seconds: 0,
    //         })
    //         .format('HH:mm')
    //     );
    //   });
    // }
    // this.horas.push(
    //   moment()
    //     .set({
    //       hours: this.agendaCerrarForm.value.horaHasta.split(':', 2)[0],
    //       minutes: this.agendaCerrarForm.value.horaHasta.split(':', 2)[1],
    //       seconds: 0,
    //     })
    //     .format('HH:mm')
    // );
    const openingHour = moment(this.agendaCancelar.str_horaInicio, 'HH:mm').add(
      -this.frecuencia,
      'minutes'
    );
    const closingHour = moment(this.agendaCancelar.str_horaFin, 'HH:mm');

    for (
      const hour = openingHour;
      hour <= closingHour;
      hour.add(this.frecuencia, 'minutes')
    ) {
      this.horas.push(hour.format('HH:mm'));
    }
    this.agendaCerrarForm.get('ordenDesde').setValue(1);
    this.agendaCerrarForm.get('ordenHasta').setValue(this.horas.length);
  }
  onChangeHoras() {
    const desde = this.agendaCerrarForm.get('ordenDesde').value;
    const hasta = this.agendaCerrarForm.get('ordenHasta').value;
    if (
      this.agendaCerrarForm.get('ordenDesde').value >=
      this.agendaCerrarForm.get('ordenHasta').value
    ) {
      this.agendaCerrarForm
        .get('ordenDesde')
        .setErrors({ horaDesdeInvalida: true });
      this.agendaCerrarForm
        .get('ordenHasta')
        .setErrors({ horaHastaInvalida: true });
    } else {
      this.agendaCerrarForm.get('ordenDesde').setErrors(null);
      this.agendaCerrarForm.get('ordenHasta').setErrors(null);
    }
  }
  ngOnInit() {
    this.dialogo.updateSize('400px', '400px');
    this.agendaCancelar = this.datos.agenda;
    this.frecuencia = this.datos.frecuencia;
    this.matricula = this.datos.matricula;
    this.fecha = this.datos.fecha;
    this.sucursal = this.datos.sucursalActual;
    this.buildForm();
    this.cargarHoras();
    // this.fechaCierre.setValue(      this.datePipe.transform(this.fechaSugerida, 'yyyy-MM-dd')    );
  }
}
