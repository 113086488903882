<div class="container-fluid" style="margin-top: 1%">
  <mat-card [tabindex]="0" class="example-card">
    <div class="container-fluid">
      <div class="row" id="firstRowBack" style="border: 0px solid red;">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <span style="float: right; margin-right: 3%;">
            <button mat-icon-button color="primary" (click)="volver()">
              <mat-icon aria-label="Example icon-button with a heart icon">keyboard_backspace</mat-icon> Volver
            </button>
          </span>
        </div>
      </div>
      <div style="display: flex;">
        <div class="lado-antecedentes">
          <mat-card-header *ngIf="this.patientData">
            <!-- <div mat-card-avatar>
              <mat-icon>person</mat-icon>
            </div> -->
            <mat-card-title class="patient-card-title" style="display: flex; flex-wrap: nowrap;"
              >
              <h5 style="white-space: nowrap; align-self: center; margin: 0;">{{ this.currentPatient.str_paciente | toTitleCase }}</h5>
              <button
                matTooltip="Editar datos del paciente"
                (click)="openDialogEditarDatosPaciente()"
                color="primary"
                mat-icon-button
 
                >
                <mat-icon>edit</mat-icon>
              </button>
              <button
                matTooltip="Solicitar orden"
                (click)="openDialogSolicitudEstudio()"
                color="primary"
                mat-icon-button
              >
                <mat-icon>receipt</mat-icon>
              </button>
              <button
                matTooltip="Solicitar Biopsia"
                (click)="openDialogSolicitudBiopsia()"
                color="primary"
                mat-icon-button
              >
                <mat-icon>add_circle_outline</mat-icon>
              </button>
              <button
                matTooltip="Listar Episodios"
                (click)="irListarEpisodios()"
                color="primary"
                mat-icon-button
              >
                <mat-icon>assignment_ind</mat-icon>
              </button>
              <button
                matTooltip="Historial de Laboratorio"
                (click)="irHistoriaLab()"
                color="primary"
                mat-icon-button
              >
                <mat-icon>folder_open</mat-icon>
              </button>
              <button
                (click)="openPacienteBio()"
                matTooltip="Bio"
                mat-icon-button
              >
                <img id="iconImg"
                  src="../../assets/images/iconos_paciente/icono_ver.png"
                />
              </button>
              <button
                matTooltip="Orden de Internaci&oacute;n"
                (click)="openDialogSolicitarInternacion()"
                color="primary"
                mat-icon-button
              >
                <mat-icon>local_hotel</mat-icon>
              </button>
              <button
              matTooltip="Asignaci&oacute;n de consentimientos para cirug&iacute;a"
              (click)="openDialogAgregarConsentimientos()"
              color="primary"
              mat-icon-button
            >
              <mat-icon>note_add</mat-icon>
            </button>
            <a matTooltip="Iniciar sesión - IOMA" href="https://sistemas.ioma.gba.gov.ar/PrestacionesDigitales/User/Login" target="_blank" class="button-link">
              <img class="imagen" src="./../../assets/images/iconos_paciente/login-ioma.png">
            </a>
            <a matTooltip="RCTA" href="https://app.rcta.me/login" target="_blank" class="button-link">
              <img class="imagen" src="./../../assets/images/iconos_paciente/rcta.png">
            </a>
            </mat-card-title>

            <mat-card-subtitle>
              <span>
                <span *ngIf="this.patientData.tipoDocumento == 9">
                  Temporal</span
                >
                <span *ngIf="this.patientData.tipoDocumento == 0"> DNI</span>
                : {{ this.currentPatient.numeroDoc }}
              </span>
              |
              <span *ngIf="this.patientData.coberturas.length > 0"
                >{{ this.patientData.coberturas[0].mutual | toTitleCase }} -
              </span>
              <span *ngIf="this.patientData.coberturas.length > 0">{{
                this.patientData.coberturas[0].nroCarnet
              }}</span>
            </mat-card-subtitle>

            <mat-card-subtitle>
              <span>Edad: {{ this.patientData.edad }} | </span>
              <span>{{ this.patientData.sexo | toTitleCase }}</span>
              |
              <span>Nacimiento: {{ this.patientData.fechaNacimiento }}</span>
            </mat-card-subtitle>
          </mat-card-header>
          <mat-chip-list aria-label="Etiquetas paciente">
            <mat-chip
              *ngFor="let e of etiquetasPaciente"
              color="accent"
              selected
              style="background-color: #fff; color: #ff4081"
              >{{ e.descripcion }}
              <button
                mat-icon-button
                style="width: 20px"
                (click)="borrarEtiqueta(e)"
                matTooltip="Borrar etiqueta"
              >
                <mat-icon style="font-size: 18px !important">close</mat-icon>
              </button></mat-chip
            >
            <button
              matTooltip="Agregar etiqueta"
              mat-icon-button
              (click)="openDialogEtiquetas()"
            >
              <mat-icon color="accent">add</mat-icon>
            </button>
          </mat-chip-list>
          <mat-chip-list *ngIf="patientData" aria-label="Antecedentes paciente">
            <mat-chip
              *ngFor="let a of this.patientData.antecedentes"
              style="background-color: #fff; color: #3f51b5"
              selected
              >{{ a.descripcion
              }}<span *ngIf="a.detalle">: {{ a.detalle }}</span></mat-chip
            >
            <button
              matTooltip="Modificar antecedentes"
              mat-icon-button
              (click)="openDialogAntecedentes()"
            >
              <mat-icon color="primary">add</mat-icon>
            </button>
          </mat-chip-list>
          <mat-divider style="width: 97%; left: unset;"></mat-divider>
          <!-- <mat-card-content>
            <mat-tab-group mat-align-tabs="center" style="font-size: 12px">
              <mat-tab label="EVOLUCIÓN"> -->
                <app-paciente-evolucion></app-paciente-evolucion>
              <!-- </mat-tab>
              <mat-tab label="BIO">
                <app-paciente-bio></app-paciente-bio>
              </mat-tab>
            </mat-tab-group>
          </mat-card-content> -->
        </div>
        <div class="lado-historial" style="margin-top: 3%;">
          <div class="row" id="rowBack" style="border: 0px solid red;">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <span style="float: right; margin-right: 5%;">
                <button mat-icon-button color="primary" (click)="volver()">
                  <mat-icon aria-label="Example icon-button with a heart icon">keyboard_backspace</mat-icon> Volver
                </button>
              </span>
            </div>
          </div>
          <app-paciente-historial></app-paciente-historial>
        </div>
      </div>
    </div>
    <br />
  </mat-card>
</div>
