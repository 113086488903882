<button
  mat-icon-button
  style="float: right; top: -24px; right: -24px"
  [mat-dialog-close]="false"
>
  <mat-icon class="close-icon" color="warn">close</mat-icon>
</button>
<h1 mat-dialog-title>Antecedentes</h1>
<div mat-dialog-content *ngIf="!loading">
  <mat-form-field>
    <mat-label>Antecedentes</mat-label>
    <mat-select [formControl]="antecedentesSeleccionados" multiple>
      <mat-option
        *ngFor="let a of antecedentesCheck"
        [value]="a.codigoAntecedente"
      >
        {{ a.descripcion | toTitleCase }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <form class="”form-group”" [formGroup]="antecedentesForm">
    <div >
      <div formArrayName="antecedentes"
        class="form-group"
        *ngFor="let a of this.antecedentesForm.get('antecedentes').controls; let i=index; trackBy:track"
      >
      <div [formGroupName]="i">
        <mat-label>{{a.value.descripcion}}</mat-label>
        <input
          class="form-control"
          type="text"
          formControlName="detalle"
        />
    </div>
      </div>
    </div>
  </form>

  <button mat-raised-button (click)="onSubmit()" color="primary" [disabled]="
  !guardarCambios" [hidden]="!guardarCambios">
   
    Guardar
  </button>  <mat-spinner *ngIf="!guardarCambios" [diameter]="30"></mat-spinner>
</div>
