import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppComponent } from '../app.component';
import { Observable, BehaviorSubject } from 'rxjs';

const httpOptions = {
    headers: new HttpHeaders({}),
  };

@Injectable({ providedIn: 'root' })
export class TemplatesService {
  private baseUrl: string;

  constructor(private http: HttpClient) {
    this.baseUrl = AppComponent.baseUrl;
  }

  getPlantillasPublicas(nroMatricula) {
    const datos = { 
      texto: '', 
      matriculaProfesionalActuante: nroMatricula
    };

    return this.http.post<any>(this.baseUrl + '/templatesDeDescripcion/publicos/seguro/buscar', datos);
  }

  getPlantillasPrivadas(nroMatricula) {
    return this.http.post<any>(this.baseUrl + '/templatesDeDescripcion/privados/seguro/buscarPorMatricula', nroMatricula);
  }

  getPlantillasPorPedido(pedido) {
    return this.http.post<any>(this.baseUrl + '/templatesDeDescripcion/publicos/seguro/buscarPorCodigoEstudio', pedido);
  }

}
