import { Component, OnInit, Inject } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Agenda } from '../dialog-datos-agenda/dialog-datos-agenda.component';
import { UntypedFormControl, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { MAT_DATE_LOCALE, DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import {
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import { DialogMensajeComponent } from '../dialog-mensaje/dialog-mensaje.component';
import { AdministracionAgendasService } from './../_services/administracion-agendas.service';

@Component({
  selector: 'app-dialog-eliminar-agenda',
  templateUrl: './dialog-eliminar-agenda.component.html',
  styleUrls: ['./dialog-eliminar-agenda.component.css'],
  providers: [
    DatePipe,
    { provide: MAT_DATE_LOCALE, useValue: 'es_AR' },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],
})
export class DialogEliminarAgendaComponent implements OnInit {
  fechaCierre = new UntypedFormControl('', Validators.required);
  matricula: number;
  sucursal: number;
  agendaEliminar: Agenda;
  fechaSugerida = null;
  loading = false;
  constructor(
    public dialogo: MatDialogRef<DialogEliminarAgendaComponent>,
    private datePipe: DatePipe,
    public dialog: MatDialog,
    private administradorService: AdministracionAgendasService,
    @Inject(MAT_DIALOG_DATA) public datos
  ) {}

  cerrarDialogo(): void {
    this.dialogo.close(false);
  }
  confirmado(): void {
    if (this.fechaCierre.value) {
      this.loading = true;
      this.administradorService
        .borrarAgendaProfesional(
          this.matricula,
          this.agendaEliminar.horaInicio,
          this.agendaEliminar.vigenciaInicio,
          this.agendaEliminar.numeroDia,
          this.fechaCierre.value
        )
        .subscribe(
          (respuestaAgenda) => {
            this.loading = false;
            if (respuestaAgenda.ok) {
              respuestaAgenda.mensaje = 'La agenda se cerro correctamente';
            }
            const dialogRef = this.dialog.open(DialogMensajeComponent, {
              width: '500px',
              data: respuestaAgenda,
            });
            dialogRef.afterClosed().subscribe(() => {
              if (respuestaAgenda.ok) {
                this.dialogo.close(respuestaAgenda.ok);
              }
            });
          },
          (error) => {
            console.log('error' + error);
          }
        );
    } else {
      const dialogRef = this.dialog.open(DialogMensajeComponent, {
        width: '500px',
        data: { ok: false, mensaje: 'debe ingresar la fecha de cierre' },
      });
    }
  }

  ngOnInit() {
    this.dialogo.updateSize('300px');
    this.agendaEliminar = this.datos.agenda;
    this.sucursal = this.datos.sucursal;
    this.matricula = this.datos.matricula;
    this.getPrimerDiaSinTurnosAsignados();
    //this.fechaCierre.setValue(      this.datePipe.transform(this.fechaSugerida, 'yyyy-MM-dd')    );
  }
  getPrimerDiaSinTurnosAsignados() {
    this.administradorService
      .getPrimerDiaSinTurnosAsignados(
        this.matricula,
        this.agendaEliminar.horaInicio,
        this.agendaEliminar.vigenciaInicio,
        this.agendaEliminar.numeroDia
      )
      .subscribe(
        (data) => {
          if (data) {
            const fecha = new Date(data.fecha);
            this.fechaSugerida = this.datePipe.transform(
              data.fecha,
              'yyyy-MM-dd'
            );
            this.fechaCierre.setValue(this.fechaSugerida);
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }
}
