import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { DialogConfirmacionComponent } from '../dialog-confirmacion/dialog-confirmacion.component';
import { DialogMensajeComponent, Respuesta } from '../dialog-mensaje/dialog-mensaje.component';
import { AuthenticationService } from '../_services/authentication.service';
import { EpisodiosPacienteService } from '../_services/episodiosPaciente.service';
import { EstudioService } from '../_services/estudios.service';
import { PacienteService } from '../_services/paciente.service';

@Component({
  selector: 'app-cargar-documento-digital',
  templateUrl: './cargar-documento-digital.component.html',
  styleUrls: ['./cargar-documento-digital.component.css'],
  providers: [
    {provide: MAT_DATE_LOCALE, useValue: 'es-ES'},
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
  ],
})
export class CargarDocumentoDigitalComponent implements OnInit {
  form: UntypedFormGroup;
  submitted: boolean;
  output: String;
  patient: any;
  matricula: any;
  currentUser: any;  
  
  file: File;
  fileName: string = '';

  constructor(
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    public dialog: MatDialog,       
    private pacienteService: PacienteService,
    private episodiosPacienteService: EpisodiosPacienteService,    
    private estudioService: EstudioService,
    private authService: AuthenticationService
  ) { 
    this.patient = pacienteService.getCurrentPatient(); // obtengo los datos del paciente seleccionado
    this.matricula = sessionStorage.getItem('matriculaAux');//recupero la matricula del profesional desde agenda    
  }

  ngOnInit() {
    this.currentUser = this.authService.currentUserValue;
    this.initForm();  
  }

  initForm() {
    this.form = this.formBuilder.group({
      description:['', [Validators.required, Validators.pattern(/^[a-zA-Z0-9 .,]*$/)]],
      date: ['', [Validators.required]],
      file: ['', [Validators.required]]
    });
  }

  onFileSelected(event) {    
    this.file = event.target.files[0];    
    if (this.file) {
        this.fileName = this.file.name;        
    }  
  }

  saveData(){    
    this.submitted = true;    
    if (this.form.dirty) {
      if (this.form.valid){
      this.output = JSON.stringify(this.buildingJson());      
      
      this.estudioService.guardarRegistroHCEPacientes(this.output, this.file).subscribe((res) => {
              
              this.openDialog(res)
                    
          },
          (error) => {
            console.log('error' + error);
          }
        );
      }
    }
    else
    {
      this.dialog.open(DialogMensajeComponent, {
        width: '500px',
        data: {
          ok: false,
          mensaje: 'Por favor, complete los campos',
        }
      })
    }
  } 

  get getForm() {
    return this.form.controls;
  }

  
  buildingJson(){ 
    let date:Date = new Date();

    function padTo2Digits(num) {
      return num.toString().padStart(2, '0');
    }

    function ultimoCampoReferencia(date:Date) {
      return [
        padTo2Digits(date.getDate()),
        padTo2Digits(date.getMonth() + 1),
        date.getFullYear(),
        padTo2Digits(date.getHours()),
        padTo2Digits(date.getMinutes()),
        padTo2Digits(date.getSeconds())
      ].join('');
    }

    return {     
      tipoRegistro: {tipoRegistroId: 48},  
      tipoDocumento: parseInt(this.patient.tipoDoc), 
      numeroDocumento: parseInt(this.patient.numeroDoc),
      descripcion: this.form.value.description,
      fechaRegistro: moment(this.form.value.date).format("DD/MM/YYYY"),
      matriculaAutor: this.matricula,
      tipoEpisodio: 'documentos_episodios',
      UsuarioModifico: this.currentUser.usuario,
      referenciaDocumento: this.patient.tipoDoc + '_' + this.patient.numeroDoc+ '_' + ultimoCampoReferencia(date),
      idEpisodio: this.getCurrentEpisodio().idEpisodio,
    }
  }

  getCurrentEpisodio(){
    return (this.episodiosPacienteService.getCurrentEpisodio());
  }

  getErrorMessageDescription() {
    return this.form.controls.description.hasError('pattern') ? 'Ingrese solo letras, numeros, puntos y comas' :        
        this.form.controls.description.hasError('required') ? 'La descripcion es obligatoria' : '';
  }

  getErrorMessageDate() {
    return this.form.controls.date.hasError('required') ? 'La fecha es obligatoria': '';
  }

  getErrorMessageFile() {
    return this.form.controls.file.hasError('required') ? 'Debe adjuntarse un archivo .PDF': '';
  }

  openDialog(res: Respuesta): void {
    this.dialog.open(DialogMensajeComponent, {
      width: '500px',
      data: res,
    }).
    afterClosed().subscribe(() => {
       if (res.ok) {  
        this.router.navigate(['/documentos-digitales']);  
       }
     });
  }

  back(){
    this.dialog.open(DialogConfirmacionComponent, {
      data: `¿Seguro desea cancelar la carga del documento?`,
    })
    .afterClosed()
    .subscribe((confirmado: boolean) => {
      if (confirmado) {
        this.router.navigate(['/documentos-digitales']);        
      }
    });
  }

  volver(){
    this.router.navigate(['/documentos-digitales']);
  }

}
