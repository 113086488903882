<div class="container-fluid center-block  " style="margin-top: 1%">
    <div class="container-fluid">

      <div class="row" id="rowBack">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <span style="float: right; margin-right: 8%">
            <button mat-icon-button color="primary" (click)="volver()">
              <mat-icon>keyboard_backspace</mat-icon> Volver
            </button>
          </span>
        </div>
      </div>

      <div class="row justify-content-md-center d-flex justify-content-center">
        <div style="margin-top: 10%" *ngIf="loading">
          <mat-spinner [diameter]="60"></mat-spinner>
        </div>
      </div>
      <div  *ngIf="!loading && formularioResp" class="divForm" >
       
            <mat-card-title >{{formularioResp.nombre | titlecase }}</mat-card-title>
            
            <div class="container-fluid">
              <div id="formulario" style= "  text-align: center; width: 80%;">
                <mat-accordion>

                  <div *ngIf="formularioResp.listaSecciones !== undefined && formularioResp.listaSecciones !== null 
                    && formularioResp.listaSecciones.length > 0 && formulario !== null"
                    style="margin-left: 2%;margin-right: 2%">

                    <div style="margin-top:2%" *ngFor="let seccion of formularioResp.listaSecciones">

                      <mat-expansion-panel class ="mat-elevation-z3">
                          <mat-expansion-panel-header
                            style="
                              background: linear-gradient(
                                180deg,
                                rgba(255, 255, 255, 1) 95%,
                                rgba(63, 81, 181, 0.8) 5%
                              );
                            "
                          >
                            <mat-panel-title> {{ seccion.nombre }}  </mat-panel-title><!--{{ seccion.nombre | titlecase }}  -->
                          </mat-expansion-panel-header>
                          <br />

                          <form [formGroup]="getFormSeccion(seccion.nombre)">
                      
                            <div style="margin-left: 3%;" *ngFor="let campo of seccion.listaCampos">
            
                              <mat-form-field appearance="outline" style="width: 100%;" 
                                    [color]="primary">
                                  <mat-label>{{ campo.nombre }}</mat-label>
                                  <textarea
                                    class="editor"
                                    matInput
                                    type="text"
                                    formControlName="{{campo.nombre}}"
                                    placeholder="{{campo.nombre}}"
                                    cdkTextareaAutosize
                                    #autosize="cdkTextareaAutosize"
                                    cdkAutosizeMaxRows="15"
                                  ></textarea>
                                  <mat-error *ngIf="getFormSeccion(seccion.nombre).controls[campo.nombre].invalid 
                                              && submitted">{{getErrorMessage(seccion.nombre,campo.nombre)}}</mat-error>
                                  <mat-error *ngIf="getFormSeccion(seccion.nombre).controls[campo.nombre].invalid 
                                    && getFormSeccion(seccion.nombre).controls[campo.nombre].errors.pattern">
                                    El campo no puede contener los siguientes caracteres especiales: ", &, <, >
                                  </mat-error>
                              </mat-form-field>
                            
                            </div>
                          </form>
                      </mat-expansion-panel>
                    </div>
                  </div>
                </mat-accordion>

                <div class="botonSubmit" >
                  <button
                    mat-raised-button
                    (click)="onSubmit()"
                    color="primary"

                    [disabled]="loadingSubmit"
                    [class.spinnerButton]="loadingSubmit">
                    <span class="spinner-border spinner-border-sm" role="status" *ngIf="loadingSubmit" aria-hidden="true"></span>
                    Guardar
                  </button>
                  
                </div>
              </div>
            </div>
       
      </div>
    </div>
</div>
