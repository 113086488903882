import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AdministracionAgendasService } from './../_services/administracion-agendas.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DialogConfirmacionComponent } from './../dialog-confirmacion/dialog-confirmacion.component';
import { Sucursal } from '../administracion-agendas/administracion-agendas.component';
import {
  Respuesta,
  DialogMensajeComponent,
} from './../dialog-mensaje/dialog-mensaje.component';
import { EmbedUrlsService } from '../_services/embedUrls.service';

@Component({
  selector: 'app-modificar-establecimiento',
  templateUrl: './modificar-establecimiento.component.html',
  styleUrls: ['./modificar-establecimiento.component.css'],
})
export class ModificarEstablecimientoComponent implements OnInit {
  loading = false;
  establecimientoForm: UntypedFormGroup;
  establecimientoModificar: Sucursal;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private administradorService: AdministracionAgendasService,
    public dialog: MatDialog,
    private router: Router,
    private embedUrlService: EmbedUrlsService
  ) {}

  ngOnInit() {
    this.embedUrlService.embedUrls.subscribe((funciones) => {
      if (
        funciones.find((funcion) => {
          return funcion.nombreMenu=== 'Establecimientos';
        })
      ) {
      } else {
        this.router.navigate(['/agenda']);
      }
    });
    this.establecimientoModificar = history.state.establecimientoModificar;
    this.buildForm();
  }

  private buildForm() {
    this.establecimientoForm = this.formBuilder.group({
      id: [this.establecimientoModificar.id, Validators.required],
      nombre: [this.establecimientoModificar.nombre, Validators.required],
      estado: [true, Validators.required],
      /* posiblemente despues lo usemos van hardcodeados */

      domicilio: [this.establecimientoModificar.domicilio, Validators.required],
      localidad: [this.establecimientoModificar.localidad, Validators.required],
      turnosOtraSucursal: [null],
      quirofanoOtraSucursal: [null],
    });
  }
  public get respuesta(): Respuesta {
    return this.respuesta;
  }
  public cancelar() {
    this.dialog
      .open(DialogConfirmacionComponent, {
        data: `¿Seguro desea cancelar la modificación del establecimiento?`,
      })
      .afterClosed()
      .subscribe((confirmado: boolean) => {
        if (confirmado) {
          this.router.navigate(['/administracionEstablecimientos']);
        }
      });
  }

  openDialog(respuesta: Respuesta): void {
    const dialogRef = this.dialog.open(DialogMensajeComponent, {
      width: '500px',
      data: respuesta,
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (respuesta.ok) {
        this.router.navigate(['/administracionEstablecimientos']);
      }
    });
  }
  onSubmit() {
    this.establecimientoForm.value.estado
      ? (this.establecimientoForm.value.estado = 'ACT')
      : (this.establecimientoForm.value.estado = 'INA');
    if (
      this.establecimientoModificar.estado ===
      this.establecimientoForm.value.estado
    ) {
      this.establecimientoForm.value.estado = null;
    }

    if (this.establecimientoForm.valid) {
      this.loading = true;

      this.administradorService
        .editEstablecimiento(this.establecimientoForm.value)
        .subscribe(
          (res) => {
            this.loading = false;
            this.openDialog(res);
          },
          (error) => {
            console.log('error' + error);
          }
        );
    }
  }
}
