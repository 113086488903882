import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppComponent } from '../app.component';
import { EpisodioHistorial } from '../_models/episodioHistorial.model';
import { EpisodioHistorialInfoGuardiaAdapter, EpisodioHistorialInfoEstudioAdapter } from '../_models/episodioHistorialInfo.model';
import { UrlResolverService } from '../_services/urlResolver.service';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json; charset=utf-8',
  }),
};

@Injectable({ providedIn: 'root' })
export class EstudioService {
  baseUrl: string;
  userData;
  private clinicalHistorySubject: BehaviorSubject<any>;
  public clinicalHistory: Observable<any>;
  public horusRestfullUrl: string;
  private sucursal;
  private currentPatientSubject: BehaviorSubject<any>;
  public currentPatient: Observable<any>;

  constructor(
    private http: HttpClient,
    private episodioHistorialInfoGuardiaAdapter: EpisodioHistorialInfoGuardiaAdapter,
    private episodioHistorialInfoEstudioAdapter: EpisodioHistorialInfoEstudioAdapter,
  ) {
    
    this.baseUrl = AppComponent.baseUrl;
    this.currentPatientSubject = new BehaviorSubject<any>(
      JSON.parse(sessionStorage.getItem('currentPatient'))
    );
    this.currentPatient = this.currentPatientSubject.asObservable();
  }

  getHistorialLaboratorio(idHistoriaClinica) {
    return this.http.get<any>(this.baseUrl + '/laboratorio/seguro/getResultadosHistoriaClinica/'+idHistoriaClinica+'/'+ new Date().getTime());
  }

  getPedidosGenericoSinFechas(pedido) {
    return this.http.post<any>(this.baseUrl + '/historiaClinica/pedidos/seguro/getPedidosGenericoSinFechas', pedido);
  }

  getRndInteger(min, max) {
    return Math.floor(Math.random() * (max - min)) + min;
  }
 

  getEpisodioGuardia(episodio: EpisodioHistorial) {
    return this.http
      .post<any>(
        this.baseUrl + '/episodiosguardia/seguro/datosepisodio',
        episodio.idEpisodio,
        httpOptions
      )
      .pipe(
        map((data: any) => this.episodioHistorialInfoGuardiaAdapter.adapt(data))
      );
  }

  getPedido(episodio: EpisodioHistorial) {
    return this.http
      .get<any>(
        this.baseUrl +
          '/historiaClinica/pedidos/seguro/findById/' +
          episodio.idEpisodio,
        httpOptions
      )
      .pipe(
        map((data: any) => this.episodioHistorialInfoEstudioAdapter.adapt(data))
      );
  }

  registrarPedido(pedido) {
    // console.log(pedido.nroCarnet,pedido.numero)
    return this.http.get(this.baseUrl + '/historiaClinica/pedidos/registrarNuevoPedido/' + pedido.numero)
  }

  setEstadoPedidoGenerico(pedido) {
    return this.http.post<any>(this.baseUrl + '/historiaClinica/pedidos/seguro/setEstadoPedido', pedido)
  }

  // OBTENGO EL PEDIDO POR NUM PEDIDO DE POSTGRES
  getPedidoPorNumero(numPedido) {
    return this.http.get(this.baseUrl + '/historiaClinica/pedidos/getPedidoPorNumero/' + numPedido)
  }

  informarConfirmar(pedido) {
    return this.http.post<any>(this.baseUrl + '/historiaClinica/pedidos/seguro/informarYConfirmar', pedido)
  }

  imprimirHCDigital(idInternacion) {
    return (
      this.baseUrl + '/internacion/epicrisis/getHCDigital/' + idInternacion
    );
  }

  imprimirDocumentoDigital(referenciaRegistro){
    let timestamp = Date.now();
    //console.log('/hcdigital/imprimir/pdf/48/' + referenciaRegistro + '/' + timestamp)
    return this.baseUrl + '/hcdigital/imprimir/pdf/48/' + referenciaRegistro + '/' + timestamp;
  }
  
  imprimirEstudio(id) {
    return (
      this.baseUrl +
      '/historiaClinica/pedidos/imprimir/pdf/' +
      id +
      '/' +
      id +
      '/' +
      this.getRndInteger(1000000000000, 99999999999999)
    );
  }

  imprimirEstudioNuevo(id) {
    return (
      this.baseUrl +
      '/historiaClinica/pedidos/imprimirInformeComplementario/pdf/' +
      id +
      '/' +
      id +
      '/' +
      this.getRndInteger(1000000000000, 99999999999999)
    );
  }

  getRegistroHcePdf(episodio) {    
    return this.baseUrl + '/hcdigital/imprimir/pdf/' + episodio.idTipoEpisodio + '/' + episodio.referenciaRegistro + '/' +  this.getRndInteger(1000000000000, 99999999999999)
  }

  imprimirRP(pedido) {
    return this.baseUrl + '/hcdigital/imprimir/pdf/35/' + pedido.numero + '/' +  this.getRndInteger(1000000000000, 99999999999999);
  }  

  listarServiciosEfectores(sector): any {
    return this.http.get<any>(this.baseUrl + '/core/areas/servicios/seguro/listarServiciosPorSectorTurno/' + sector);
  }

  listarEstudiosDeServicioEfector(datosFiltro) {
    return this.http.post<any>(this.baseUrl + '/historiaClinica/pedidos/seguro/buscarEstudiosPrestacionesDeServicio', datosFiltro);
  }

  guardarPedidoGenerico(pedido) {
   // console.log(pedido)
    return this.http.post<any>(
      this.baseUrl + '/historiaClinica/pedidos/seguro/guardarPedidoGenerico',
      pedido
    );
  }

  pedidosPaciente(nroDniPaciente, tipoDniPaciente, tipoPedi) {
    return this.http.post<any>(
      this.baseUrl + '/historiaClinica/pedidos/seguro/getPedidosPaciente',
      { nroDniPaciente, tipoDniPaciente, tipoPedido: tipoPedi }
    );
  }

  imprimirOrdenPedido(pedido, usuario) {
    return (
      this.baseUrl +
      '/historiaClinica/pedidos/imprimir_orden_pedido/pdf/' +
      pedido.numero +
      '/' +
      usuario +
      '/' +
      this.getRndInteger(1000000000000, 99999999999999)
    );
  }

  concatenarOrdenBiopsia(numeroPedido,usuario) {
    
    const numRandom = new Date();
    return (this.baseUrl + '/historiaClinica/pedidosBiopsia/imprimir_pedidobiopsiaconcatenado_numero/'+ numeroPedido +'/'+ usuario);
  }

  getFinanciadores(tipoDni, nroDni) {
    return this.http.get<any>(
      this.baseUrl + '/financiadores/listar/ACT/' + tipoDni + '/' + nroDni
    );
  }

  getUltimoEstudioPaciente(nroDniPaciente, tipoDniPaciente) {
    return this.http.post<any>(
      this.baseUrl + '/historiaClinica/pedidos/seguro/getUtlimoPedidoPaciente',
      { nroDocumento: nroDniPaciente, tipoDocumento: tipoDniPaciente }
    );
  }

  chequeoEstadoImagenesAngras(numPedido) {
    return this.http.get<any>(
      this.baseUrl +
        '/historiaClinica/pedidos/restricted/chequeoEstadoImagenes/' +
        numPedido
    );
  }

  guardarPedidoBiopsia(pedido) {
    return this.http.post<any>(
      this.baseUrl + '/historiaClinica/pedidosBiopsia/seguro/guardarPedidoBiopsia', pedido
    );
  }

  imprimirPedidoBiopsia(numeroPedido) {
    const numRandom = new Date();
    return (this.baseUrl + '/historiaClinica/pedidosBiopsia/imprimir_pedidobiopsia_numero/'+ numeroPedido + '/' + numRandom.getTime());
  }

  getUrlImgAurora(estudio) {
    return this.http.post<any>(this.baseUrl + '/historiaClinica/pedidos/getUrlImagenAurora', estudio);
  }

  getInfoComplPDF(URL) {
    return this.http.get<any>(URL, { responseType: 'blob' as 'json'});
  }

  guardarRegistroHCEPacientes(registroHCE, file){
    let params = new FormData();
    
    params.append("registrohce", registroHCE);
    params.append("informeComplementario", file);
    
    return this.http.post<any>(this.baseUrl + '/hcdigital/guardarRegistroHCE', params);
  }

  listarDocumentosDigitales(idEpisodio){    
    let body = {
      episodioId: idEpisodio,
      tipoEpisodio: null,
      //tipoRegistro: {tipoRegistroId: 48},
      tipoDocumento: 48,
      matriculaAutor: -1,
      // fechaDesde: null,
      // fechaHasta: null,
      //tipoDocumento: 5,
    }
    return this.http.post<any>(this.baseUrl + '/hcdigital/seguro/getRegistrosPorFiltro', body);
  }

  bajaRegistroHCEPacientes(estudio, usuario){   
    return this.http.post<any>(this.baseUrl + '/hcdigital/bajaRegistroHCE', 
    {
      idRegistro: estudio.idEpisodio, 
      referenciaDocumento: estudio.referenciaRegistro,
      UsuarioModifico: usuario,   
      tipoRegistro: {tipoRegistroId: 44}   
    });
  }

  bajaRegistroHCEtrasplante(idRegistro, referenciaDocumento, usuario){
    return this.http.post<any>(this.baseUrl + '/hcdigital/bajaRegistroHCE',
    {
      idRegistro: idRegistro,
      referenciaDocumento: referenciaDocumento,
      UsuarioModifico: usuario,
      tipoRegistro: {tipoRegistroId: 48}
    });
  }

  localStorageSetEstudio(estudio){
    localStorage.setItem('estudio', JSON.stringify(estudio));
  }

  localStorageGetEstudio(){
    return JSON.parse(localStorage.getItem('estudio'));
  }

  localStorageDeleteEstudio(){
    localStorage.removeItem('estudio')
  }
  buscarEstudiosDeServicio(est){
    
    return this.http.post<any>(this.baseUrl + '/historiaClinica/pedidos/seguro/buscarEstudiosDeServicio',{
      servicio: est.servicio,
      estudio:est.estudio
     
    });
    
  }
  
}