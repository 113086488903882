<div class="container-fluid center-block" style="margin-top: 1%">
  <mat-card [tabindex]="0" class="card">
    <div class="container-fluid">
      <div class="row" id="rowBack">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <span style="float: right; margin-right: 8%">
            <button mat-icon-button color="primary" (click)="volver()">
              <mat-icon>keyboard_backspace</mat-icon> Volver
            </button>
          </span>
        </div>
      </div>
      <div class="row justify-content-md-center d-flex justify-content-center">
        <div class="
        col-xs-12 col-sm-12 col-md-12 col-lg-8 col-xl-6
          align-self-center align-middle">

          <div class="container-fluid">
            <div *ngIf="currentEpisodio && !editar">
              
                <mat-card-title>
                  {{ currentEpisodio.descripcion | toTitleCase }}
                </mat-card-title>

                <mat-card-subtitle>
                  <span>Fecha de Inicio:</span> <b>{{ currentEpisodio.fechaInicio }}</b>
                  |
                  <span>Profesional:</span> {{ currentEpisodio.nombreProfesional | toTitleCase }}
                </mat-card-subtitle>
              
                <mat-card-subtitle *ngIf="currentEpisodio.tipoDeEpisodio.descripcion">
                  <span>Tipo:</span><b> {{ currentEpisodio.tipoDeEpisodio.descripcion | toTitleCase }}</b>
                </mat-card-subtitle>

                <mat-divider  inset></mat-divider>
                <br>
              

            </div>
            <mat-card-header >
              <div *ngIf="!editar;then tituloNuevo else tituloEditar"></div>
              <ng-template #tituloNuevo>
                <mat-card-title style="font-size: 25px;margin-left: -6%;">
                  Nuevo episodio</mat-card-title>
              </ng-template>
              <ng-template #tituloEditar>
                <mat-card-title style="font-size: 25px;margin-left: -6%;">
                  Editar episodio</mat-card-title>
              </ng-template>
        
            </mat-card-header>
            <form [formGroup]="episodioForm">
              <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
                  <mat-form-field appearance="outline" style="width: 100%;">
                  <mat-label>Paciente *</mat-label>
                    <input
                      matInput
                      type="text"
                      formControlName="paciente"
                      placeholder="Paciente *"
                    />
                  </mat-form-field>
                </div>
          
                <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                  <mat-form-field appearance="outline" style="width: 100%;">
                  <mat-label>DNI *</mat-label>
                    <input
                      matInput
                      type="text"
                      formControlName="dni"
                      placeholder="DNI *"
                    />
                  </mat-form-field>
                </div>
              </div>

              <mat-form-field appearance="outline" >
                <mat-label>Descripción *</mat-label>
                <textarea
                  class="editor"
                  matInput
                  type="text"
                  formControlName="descripcion"
                  placeholder="Ingrese una descripción"
                  cdkTextareaAutosize
                  #autosize="cdkTextareaAutosize"
                  cdkAutosizeMaxRows="5"
                ></textarea>
                <mat-error *ngIf="episodioForm.controls['descripcion'].invalid 
                          && submitted">{{getErrorMessage('descripcion')}}</mat-error>
              </mat-form-field>

              <mat-form-field appearance="outline" >
              <mat-label>Diagnóstico *</mat-label>
              <textarea
                class="editor"
                matInput
                type="text"
                formControlName="diagnostico"
                placeholder="Ingrese un diagnóstico"
                cdkTextareaAutosize
                #autosize="cdkTextareaAutosize"
                cdkAutosizeMaxRows="5"
              ></textarea>
              <mat-error *ngIf="episodioForm.controls['diagnostico'].invalid 
                && submitted">{{getErrorMessage('diagnostico')}}</mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" *ngIf="this.currentEpisodio && !this.editar">
              <mat-label>Seleccionar tipo de episodio</mat-label> 
            <!--  <mat-label>Seleccionar formulario asociado</mat-label>-->
              <mat-select  formControlName="tipoEpisodio" style="width:97%;margin-left:1.5%">
                  <mat-option>
                      <ngx-mat-select-search placeholderLabel="Buscar" 
                                            noEntriesFoundLabel="No se encontraron coincidencias" 
                                            [formControl]="filterCtrl">
                      </ngx-mat-select-search>
                  </mat-option>
                  <mat-option *ngFor="let tipo of this.tiposEpisodioFiltrados" [value]="tipo">
                      {{ tipo.descripcion | toTitleCase }}
                  </mat-option>

              </mat-select>
            </mat-form-field>

            <mat-form-field appearance="outline" *ngIf="this.mostrarItemsHistorial">
               <mat-label>Seleccionar ítem del historial</mat-label>
               <mat-select  formControlName="itemHistorialClinico" style="width:97%;margin-left:1.5%">
                   <mat-option>
                       <ngx-mat-select-search placeholderLabel="Buscar" 
                                             noEntriesFoundLabel="No se encontraron coincidencias" 
                                             [formControl]="filterHistorialCtrl">
                       </ngx-mat-select-search>
                   </mat-option>
                   <mat-option *ngFor="let item of this.itemsHistorialFiltrados" [value]="item">
                       Nro Carpeta: {{ item.idEpisodio }}
                   </mat-option>
 
               </mat-select>
             </mat-form-field>

            <mat-form-field appearance="outline" >
              <mat-label>Fecha de finalización</mat-label>
              <input matInput
                     formControlName="fechaFin" 
                     placeholder="Seleccione una fecha" 
                     [matDatepicker]="picker"
                     [min]="fechaTomorrow"
                     />
              <mat-datepicker-toggle matSuffix [for]="picker" matTooltip="Seleccionar fecha"></mat-datepicker-toggle>
              <mat-datepicker #picker disabled="false"></mat-datepicker>
            </mat-form-field>

            <div class="botonSubmit" >
              <button
                *ngIf="editar"
                type="submit"
                [disabled]="false"
                mat-raised-button
                (click)="abrirDialogConfirmacionEliminar()"
                color="warn"
                style="margin-right: 4%;"
              >
                <mat-icon [ngStyle]="{transform: 'scale(0.9)'}">delete</mat-icon>
                Eliminar episodio
              </button>
              <button
                type="submit"
                mat-raised-button
                (click)="onSubmit()"
                color="primary"

                [disabled]="loadingSubmit"
                [class.spinnerButton]="loadingSubmit"
              >
                Guardar episodio
              </button>
            </div>
            </form>
          </div>

        </div>
      </div>
    </div>
  </mat-card>
</div>