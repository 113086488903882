import { Component, OnInit, ViewChild } from '@angular/core';
import { PacienteService } from '../_services/paciente.service';
import { AuthenticationService } from '../_services/authentication.service';
import { EpisodiosPacienteService } from '../_services/episodiosPaciente.service';
import { Router, NavigationStart , ActivatedRoute} from '@angular/router';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { DialogPdfComponent } from '../dialog-pdf/dialog-pdf.component';
import { FormulariosService } from '../_services/formularios.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FormularioGenerico } from '../_models/formularioGenerico.interface';
import { DialogConfirmacionComponent } from '../dialog-confirmacion/dialog-confirmacion.component';
import { Location } from '@angular/common';

@Component({
  selector: 'app-ver-formularios-episodio',
  templateUrl: './ver-formularios-episodio.component.html',
  styleUrls: ['./ver-formularios-episodio.component.css']
})
export class  VerFormulariosEpisodioComponent implements OnInit {
  loading = true;
  sinFormularios;
  currentPatient: any;
  currentEpisodio: any;
  dataSourceItems: any;
  displayedColumnasItems: string[] = ['nombre', 'fechaHoraRegistro', 'action'];
  formularios: any[];
  formularioSeleccionado: FormularioGenerico;

  /* Variables para los mat-select */

  filterCtrl: UntypedFormControl = new UntypedFormControl();
  formulariosFiltrados: any[];
  protected onDestroyProperty = new Subject<void>();

  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    private pacienteService: PacienteService,
    private authenticationService: AuthenticationService,
    private formularioService: FormulariosService,
    private router: Router,
    private episodiosPacienteService: EpisodiosPacienteService,
    public dialog: MatDialog,
    private location: Location) { }

  async ngOnInit() {
    this.currentPatient =  this.pacienteService.getCurrentPatient();
    this.currentEpisodio = this.episodiosPacienteService.getCurrentEpisodio();
    this.cargarFormulariosDeEpisodio();
    this.cargarFormularios(this.currentEpisodio.tipoDeEpisodio.idTipoEpisodio);
    this.listenFilter();
    this.eventListenerBack();
    this.formularioService.removeCurrentFormularioCabecera();
  }

  listenFilter() {
    this.filterCtrl.valueChanges
      .pipe(takeUntil(this.onDestroyProperty))
      .subscribe(() => {
        this.filtroFormularios();
      });
  }

  eventListenerBack() {
    this.router.events.subscribe((event: NavigationStart) => {
      if (event.navigationTrigger === 'popstate') {
        this.episodiosPacienteService.removeCurrentEpisodio();
        // console.log('entro al evento');
      }
    });
  }

  protected filtroFormularios() {
    if (!this.formularios) {
      return;
    }
    let search = this.filterCtrl.value;
    if (!search) {
      this.formulariosFiltrados = this.formularios;
      return;
    } else {
      search = search.toLowerCase();
    }
    // filtro los formularios
    this.formulariosFiltrados =
      this.formularios.filter(
        (formulario) => formulario.nombre.toLowerCase().indexOf(search) > -1
      );
  }

  irCrearEpisodio() {
    this.router.navigate(['/crear-episodio']);
  }

  volver() {
    this.episodiosPacienteService.removeCurrentEpisodio();
    //this.router.navigate(['/listar-episodios']);
    const urlback = localStorage.getItem('urlback');
    this.router.navigate([urlback]);
  }

  cargarFormulariosDeEpisodio() {
   // console.log(this.currentEpisodio);
    this.episodiosPacienteService.verFormulariosEpisodio(this.currentEpisodio.idEpisodio)
        .subscribe(
          (respuesta) => {
           // console.log(respuesta);
            if (respuesta.ok) {
              if (respuesta.elementos.length !== 0) {
                this.dataSourceItems = new MatTableDataSource(respuesta.elementos);
                this.dataSourceItems.paginator = this.paginator;
              } else {
                this.sinFormularios = true;
              }
              this.loading = false;
            }
          },
          (error) => {
            console.log(error);
          }
        );
  }

  imprimirFormulario(formularioCabecera) {
   // console.log(formularioCabecera);
    const dialogRef = this.dialog.open(DialogPdfComponent, {
      width: '1300px',
      height: '700px',
      data: {
        url: this.formularioService.imprimirFormulario(
          formularioCabecera.idFormularioCabecera,
          this.authenticationService.currentUserValue.usuario
        ),
      },
    });
  }

  editarFormulario(formulario) {
    this.formularioService.setCurrentFormularioCabecera(formulario.cabecera);
    this.irCompletarFormulario(formulario.idFormulario);
  }

  borrarFormulario(formulario) {
    const cabecera = {
      idFormularioCabecera: formulario.cabecera.idFormularioCabecera,
      estado: 'INA',
      loginEstado: this.authenticationService.currentUserValue.usuario,
    };
    this.formularioService.guardarFormularioValores([], cabecera).subscribe(
        (data) => {
          // console.log(data);
          this.cargarFormulariosDeEpisodio();
        },
        (error) => {
          console.log(error);
        }
    );
  }

  imprimirEpisodio(idEpisodio) {
  // console.log(formularioCabecera);
    const dialogRef = this.dialog.open(DialogPdfComponent, {
      width: '1300px',
      height: '700px',
      data: {
        url: this.formularioService.imprimirFormulariosEpisodio(
          idEpisodio,
          this.authenticationService.currentUserValue.usuario
        ),
      },
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSourceItems.filter = filterValue.trim().toLowerCase();
  }

  cargarFormularios(idTipoEpisodio) {
    // console.log('idTipoEpisodio:', idTipoEpisodio);
    this.formularioService.getFormulariosPorTipoEpisodio(idTipoEpisodio).subscribe(
      (respuesta) => {
        // console.log(respuesta);
        if (respuesta.ok) {
          this.formularios = respuesta.formularios;
          this.formulariosFiltrados = respuesta.formularios;
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  irCompletarFormulario(idFormulario) {
    // console.log(this.formularioSeleccionado);
    this.router.navigate(['/escribir-formulario', idFormulario]);
  }

  abrirDialogConfirmacionBorrar(formulario) {
    this.dialog.open( DialogConfirmacionComponent, {
      data: `¿Está seguro que desea borrar el formulario? `,
    })
    .afterClosed()
      .subscribe(
        (confirmado: boolean) => {
          if (confirmado) {
            this.borrarFormulario(formulario);
          } else {
            this.dialog.closeAll();
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }
}
