import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DialogConfirmacionComponent } from '../dialog-confirmacion/dialog-confirmacion.component';
import { DialogMensajeComponent } from '../dialog-mensaje/dialog-mensaje.component';
import { DialogPdfComponent } from '../dialog-pdf/dialog-pdf.component';
import { AuthenticationService } from '../_services/authentication.service';
import { ConsentimientoService } from '../_services/consentimientos.service';
import { PacienteService } from '../_services/paciente.service';


@Component({
  selector: 'app-dialog-asignar-consentimientos',
  templateUrl: './dialog-asignar-consentimientos.component.html',
  styleUrls: ['./dialog-asignar-consentimientos.component.css']
})
export class DialogAsignarConsentimientosComponent implements OnInit {
  listaConsentimientos:any[] = [];
  listaFavoritos: any[] = [];
  listaNueva:any[] = [];
  hayFavoritos = false;
  consentimientoSeleccionado: any;
  bankMultiFilterCtrl: UntypedFormControl = new UntypedFormControl();
  public consentimientosFiltrados: ReplaySubject<any> = new ReplaySubject<any>(1);
  protected onDestroy = new Subject<void>();
  //tabla
  columnsToDisplay = ['nombre', 'fechaAsignacion', 'acciones'];
  dataSourceItems;
  hayConsentimientos = false;
  currentPatient;
  currentUser;
  listadoConsentimientos:any[] =[];
  agregando = false;
  matricula;
  
  constructor(
   private consentimientosService: ConsentimientoService,
   private formBuilder: UntypedFormBuilder,
   private pacienteService: PacienteService,
   private authService: AuthenticationService,
   public dialog: MatDialog
  ) {
    this.currentPatient = this.pacienteService.getCurrentPatient();
    this.currentUser = this.authService.currentUserValue;
    this.matricula =  this.authService.currentUserValue.roles.find(
      (rol) => rol.codigo === 'MHE'
    ).valorTipoID;
   }

  async ngOnInit() {
  //await this.buildConsentimientosForm();
   await this.listarConsentimientos();
   this.bankMultiFilterCtrl.valueChanges
   .pipe(takeUntil(this.onDestroy))
   .subscribe(() => {
     this.filterBanksMulti();
  });
  await this.obtenerConsentimientos();
  }


//  async buildConsentimientosForm(){
//   this.consentimientosForm = this.formBuilder.group({
//     nombre:[''],
//     tipoId:[null]

//   })
//  }
 async listarConsentimientos(){
    this.consentimientosService.getConsentimientosProfesional(this.matricula).subscribe(
      (data2)=>{
        if(data2.ok){
       
        this.listaFavoritos = data2.elementos;
        }
      
      }, error => {
        console.log(error)
      })
  
      
    this.consentimientosService.getConsentimientos().subscribe(
      (data)=> {
      if (data) {
        this.listaConsentimientos = data;
        this.listaConsentimientos.sort((a,b)=>{
          if(a.nombre < b.nombre) { return -1; }
          if(a.nombre > b.nombre) { return 1; }
        });
        
        if (this.listaFavoritos != undefined) {
          if(this.listaFavoritos.length > 0){
            let elem;
            this.listaFavoritos.forEach((e)=>{
            
              elem =  this.listaConsentimientos.find((fav)=>
              
                fav.modeloId === e.modeloId
              )
              let index = this.listaConsentimientos.indexOf(elem);
              this.listaConsentimientos.splice(index,1);
              
              this.listaConsentimientos.unshift(elem);
            })
              
            }
        }
        
        this.consentimientosFiltrados.next(this.listaConsentimientos);
        
      }
     
     },
     (error)=> {
      console.log(error);
     }
    );  
     
  }
  protected filterBanksMulti() {
    // get the search keyword
    let search = this.bankMultiFilterCtrl.value;
    if (!search) {
      this.consentimientosFiltrados.next(this.listaConsentimientos.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filtro plantillas
    this.consentimientosFiltrados.next(
      this.listaConsentimientos.filter(
        (consentimiento) => consentimiento.nombre.toLowerCase().indexOf(search) > -1
      )
    );
  }
  agregarConsentimiento(consentimiento){
  
    let paciente= this.currentPatient;
    this.agregando = true;
      consentimiento.forEach(element => {
        element.tipoDoc = paciente.tipoDoc;
        element.nroDocumento = paciente.numeroDoc;
        element.matricula = this.matricula;
        element.idConsentimiento = -1;
      });
      console.log(consentimiento);
    if(this.listaFavoritos.length > 0){
      
      this.listaFavoritos.forEach((e)=>{
        consentimiento.forEach((c)=> {
        if(e.modeloId == c.modeloId){
          this.hayFavoritos = true;
       }
      });
    });
    }
    if(!this.hayFavoritos){
      this.consentimientosService.agregarConsentimientoProfesional(consentimiento,this.matricula).toPromise().then(
        (data2)=>{
          if(data2.ok){
            console.log('todo ok');
          }
        }
      )
      }
    this.consentimientosService.agregarConsentimiento(consentimiento).toPromise().then(
      (data)=>{
        if(data.ok)
        {
          this.dialog.open(DialogMensajeComponent,{
            width: '500px',
            data:{
              ok: true,
              mensaje: "Consentimiento cargado correctamente"
            }}).afterClosed().subscribe(()=>{
              this.agregando= false;
              this.consentimientoSeleccionado = [];
              this.obtenerConsentimientos();
              this.listarConsentimientos();
          });
        
          

        }
      }
    )
    }
  
  async obtenerConsentimientos(){
    
    let nroDoc= this.currentPatient.numeroDoc;
    let tipoDoc = this.currentPatient.tipoDoc;
  
    this.consentimientosService.obtenerConsentimientos(tipoDoc,nroDoc,this.matricula).subscribe(
      (data) =>
      {
        
        this.hayConsentimientos=true;
        this.listadoConsentimientos = data.elementos;
        this.dataSourceItems =new MatTableDataSource(this.listadoConsentimientos);
        
      }
    )
  }
  eliminarConsentimiento(c){
  
    this.dialog.open(DialogConfirmacionComponent, {
      data: '¿Seguro desea eliminar el consentimiento?',
    }).afterClosed().subscribe((confirmado: boolean) => {
      if (confirmado) {
        c.estado = 'INA';
        c.nroDocumento = this.currentPatient.numeroDoc;
        c.tipoDoc = this.currentPatient.tipoDoc;
        this.consentimientosService.cambiarEstado(c).subscribe((respuesta) => {
        
          if (respuesta.ok) {
            this.dialog.open(DialogMensajeComponent,{
              width: '500px',
              data:{
                ok: true,
                mensaje: "Consentimiento eliminado correctamente"
              }}).afterClosed().subscribe(()=>{
                
                this.obtenerConsentimientos();
            });
           
          }
        })
      }
    })
  }
  imprimirConsentimiento(consentimiento){
 
    const dialogRef = this.dialog.open(DialogPdfComponent, {
      width: '1300px',
      height: '700px',
      data: {
        url: this.consentimientosService.imprimirConsentimiento(
          consentimiento,
        ),
      },
    });
  }
}