import { Component, OnInit } from "@angular/core";
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl } from "@angular/forms";
import { Location } from '@angular/common';
import { takeUntil } from 'rxjs/operators';
import { TurnosService } from '../_services/turnos.service';
import * as moment from "moment";
import { Subject } from "rxjs";
import {
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import { MAT_DATE_LOCALE, DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatStepper } from '@angular/material/stepper';
import { MatTableDataSource } from '@angular/material/table';
import { toTitleCasePipe } from "../_pipes/toTitleCase.pipe";



@Component({
    selector: 'app-turnos-anulados',
    templateUrl: './turnos-anulados.component.html',
    styleUrls: ['./turnos-anulados.component.css'],
    providers: [
      toTitleCasePipe,
      { provide: MAT_DATE_LOCALE, useValue: 'es_AR' , },
      {
        provide: DateAdapter,
        useClass: MomentDateAdapter,
        deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
      },
      { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    ],
})

export class TurnosAnuladosComponent implements OnInit {    
  
    filtroBusquedaForm: UntypedFormGroup;
    
    dataSourceItems: any;
    displayedColumnasItems: string[] = ['paciente', 'profesional', 'hora', 'orden', 'telefono', 'usuarioAnulo', 'fechaAnulacion']; // poner las columnas que estan en la tarjeta
    loading = true;
    sinTurnos: any;

    bankMultiFilterCtrl: UntypedFormControl = new UntypedFormControl();
    profesionalesFiltrados: any[];
    profesionales: any[];
    profesionalSeleccionado: any = {nroMatricula: null};
    fechaTurno: Date;

    protected _onDestroy = new Subject<void>(); 
    constructor(
        private formBuilder: UntypedFormBuilder,
        private turnosService: TurnosService,
        public location: Location,
    ) {
        
    }
    
  
    ngOnInit() {
        if (sessionStorage.getItem("filtro")) sessionStorage.removeItem("filtro");
        this.buildFiltroBusquedaForm();
        this.cargarProfesionales();
        this.buscarTurnosAnulados();
    }

    private buildFiltroBusquedaForm() {
      this.bankMultiFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterBanksMultiProfesionales();
      });
      this.filtroBusquedaForm = this.formBuilder.group({
          fechaTurno: [new Date()],
          matricula: [{value:{apellido:'Todos', nroMatricula: null}}],
      });
    
    }

    applyFilter(event: Event) {
      const filterValue = (event.target as HTMLInputElement).value;
      sessionStorage.setItem("filtro", filterValue);
      this.dataSourceItems.filter = filterValue.trim().toLowerCase();
    }

    async cargarProfesionales() {
      const filtro = {
        matricula: 'null',
        servicio:'null',
        especialidad: 'null',
        condicion: 'null',
        estado: 'ON',
      }

      await this.turnosService.getProfesionales(filtro).toPromise().then( 
          (data) => {     
              if (data.ok ) {       
              this.profesionales = data.paginador.elementos;  
              this.profesionalesFiltrados=data.paginador.elementos;  
            }
            this.profesionales.sort(function (a, b) {
              if (a.apellido > b.apellido) {
                return 1;
              }
              if (a.apellido < b.apellido) {
                return -1;
              }
              return 0;
            })
            this.profesionales.splice(0,0,{apellido: 'Todos', nroMatricula: null});
            this.profesionalSeleccionado= this.profesionales[0];  
            this.filtroBusquedaForm.get('matricula').setValue(this.profesionales[0]); 
          }
          ); 
    }

    protected filterBanksMultiProfesionales() {
      let search = this.bankMultiFilterCtrl.value;
      if (!search) {
        this.profesionalesFiltrados=this.profesionales;
        return;
      } else {
        search = search.toLowerCase();
      }
          this.profesionalesFiltrados=
          this.profesionales.filter(
            (profesional) => profesional.apellido.toLowerCase().indexOf(search) > -1
          );
    }

    OnDestroy() {
      this._onDestroy.next();
      this._onDestroy.complete();
    }
    
    async buscarTurnosAnulados() {
      this.loading = true;
      const filtroBusqueda = {
          fechaTurno: moment(this.filtroBusquedaForm.get('fechaTurno').value).format('DD/MM/YYYY'),
          profesional: {
            matricula: this.filtroBusquedaForm.get('matricula').value.nroMatricula }
      };
      
      await this.turnosService.getTurnosAnulados(filtroBusqueda).toPromise().then( 
        (respuesta) => {
          if (respuesta.ok) { 
              this.sinTurnos = false; 
              this.dataSourceItems = new MatTableDataSource(respuesta.elementos);
              // si existe el elemento
              if (sessionStorage.getItem("filtro"))
                this.dataSourceItems.filter = sessionStorage.getItem("filtro");
            }
          else {
            this.sinTurnos = true;
          }
          this.loading = false;
        },
        (error) => {
          console.log(error);
        }
      );
    }


}