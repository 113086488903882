import { Component, Inject, OnInit } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DialogConfirmacionComponent } from '../dialog-confirmacion/dialog-confirmacion.component';
import { AdministracionAgendasService } from './../_services/administracion-agendas.service';
import {
  DialogMensajeComponent,
  Respuesta,
} from '../dialog-mensaje/dialog-mensaje.component';
import { Sucursal } from '../administracion-agendas/administracion-agendas.component';
import { AuthenticationService } from '../_services/authentication.service';

@Component({
  selector: 'app-dialog-agregar-consultorio',
  templateUrl: './dialog-agregar-consultorio.component.html',
  styleUrls: ['./dialog-agregar-consultorio.component.css'],
})
export class DialogAgregarConsultorioComponent implements OnInit {
  consultorioForm: UntypedFormGroup;
  loading = false;
  submitted = false;
  private listaSucursales: Sucursal[];
  private matricula: number;

  constructor(
    private authService: AuthenticationService,
    public dialogo: MatDialogRef<DialogAgregarConsultorioComponent>,
    public dialog: MatDialog,
    private router: Router,
    private administradorService: AdministracionAgendasService,
    private form: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public datos
  ) {
    this.matricula = this.authService.currentUserValue.roles.find(
      (rol) => rol.codigo === 'MHE'
    ).valorTipoID;
  }

  ngOnInit() {
    this.buildForm();
    this.cargarSucursales(this.matricula);
    try {
      this.consultorioForm
        .get('sucursalId')
        .setValue(this.datos.sucursalSeleccionada);
    } catch {
      this.consultorioForm.get('sucursalId').setValue(null);
    }
  }
  private buildForm() {
    this.consultorioForm = this.form.group({
      sucursalId: [, Validators.required],
      descripcion: [, Validators.required],
      ubicacion: ['EXT'],
      observacion: [null],
      servidorTv: [null],
    });
  }
  openDialog(respuesta: Respuesta): void {
    const dialogRef = this.dialog.open(DialogMensajeComponent, {
      width: '500px',
      data: respuesta,
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (respuesta.ok) {
        this.dialog.closeAll();
        //window.location.reload();
      }
    });
  }
  public cancelar() {
    this.dialog
      .open(DialogConfirmacionComponent, {
        data: `¿Seguro desea cancelar la creación del consultorio?`,
      })
      .afterClosed()
      .subscribe((confirmado: boolean) => {
        if (confirmado) {
          this.dialog.closeAll();
        }
      });
  }
  cargarSucursales(matricula) {
    this.administradorService.getSucursalesProfesional(matricula).subscribe(
      (data) => {
        if (data) {
          this.listaSucursales = data.elementos;
          this.listaSucursales = this.listaSucursales.filter(
            (sucursal) => sucursal.id != 1
          );
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  public get sucursales(): Sucursal[] {
    return this.listaSucursales;
  }
  onSubmit() {
    this.submitted = true;
    if (this.consultorioForm.valid) {
      this.loading = true;
      this.administradorService
        .setConsultorio(this.consultorioForm.value)
        .subscribe(
          (res) => {
            this.loading = false;
            if (res.ok) {
              this.dialogo.close(this.consultorioForm.value);
            }
            this.openDialog(res);
          },
          (error) => {
            console.log('error' + error);
          }
        );
    }
  }
}
