<mat-spinner [hidden]="!cargando" style="margin: auto;"></mat-spinner>

<div class="container-fluid" [hidden]="cargando" style="border: 0px solid red;">
  <h1 mat-dialog-title>Generar Orden de Internaci&oacute;n</h1>
  <form [formGroup]="solicitarInternacionForm" style="border: 0px solid blue;">
    <div class="row" style="border: 0px solid yellow;">
      <mat-form-field class="full-width">
        <mat-label>Paciente</mat-label>
        <input matInput type="text" formControlName="paciente"/>
      </mat-form-field>
    </div>
    <div class="row" style="border: 0px solid yellow;">
        <mat-form-field class="full-width">
          <mat-label>Financiador - Nro. de Carnet</mat-label>
          <mat-select formControlName="financiadorCarnet" required>
            <mat-option *ngFor="let mut of listaMutuales" [value]="mut">{{ mut.nombreFinanciador }} - {{ mut.nroCarnet }}</mat-option>
          </mat-select>
        </mat-form-field>
    </div>
    <div class="row" style="border: 0px solid yellow;">
      <mat-form-field class="full-width">
        <mat-label>M&eacute;dico de Cabecera</mat-label>
        <input type="text" placeholder="Buscar profesional" matInput formControlName="matriculaEfector" [matAutocomplete]="auto" required>
        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
          <mat-option *ngFor="let p of listaProfesionales | async" [value]="p">
            {{ p.apellido }} ({{ p.nroMatricula }})
          </mat-option>
        </mat-autocomplete>
        <mat-error *ngIf="solicitarInternacionForm.controls['matriculaEfector'].invalid">{{getErrorMessage('matriculaEfector')}}</mat-error>
      </mat-form-field>
    </div>
    <div class="row" style="border: 0px solid yellow;">
      <div class="col" style="padding-left: 0px;">
        <mat-form-field class="full-width">
          <mat-label>Pabell&oacute;n</mat-label>
          <mat-select formControlName="pabellon" required>
            <mat-option *ngFor="let pab of listaPabellones" [value]="pab" (click)="seleccionarPabellon(pab.pabellon)">
              {{ pab.denominacion }}</mat-option>
          </mat-select>
          <!-- <mat-error *ngIf="solicitarInternacionForm.controls['financiador'].invalid">{{getErrorMessage('financiador')}}</mat-error> -->
        </mat-form-field>
      </div>
      <div class="col" style="padding-right: 0px;">
        <mat-form-field class="full-width">
          <mat-label>Pieza - Cama - Estado</mat-label>
          <mat-select formControlName="cama" required>
            <mat-option *ngFor="let cama of listaCamas" [value]="cama">{{ cama.pieza }} - {{ cama.cama }} - {{ cama.estadoActual }}</mat-option>
          </mat-select>
          <mat-error *ngIf="solicitarInternacionForm.controls['cama'].invalid">{{getErrorMessage('cama')}}</mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row" style="border: 0px solid yellow;">
      <mat-form-field class="full-width">
        <mat-label>Diagn&oacute;stico</mat-label>
        <textarea matInput rows="2" formControlName="diagnostico" placeholder="Ingrese un diagn&oacute;stico" required></textarea>
        <mat-error *ngIf="solicitarInternacionForm.controls['diagnostico'].invalid">{{getErrorMessage('diagnostico')}}</mat-error>
      </mat-form-field>
    </div>

    <div class="row">
      <alert [hidden]="!alerta" style="text-align: center; margin-top: 1%"></alert>
    </div>
  
    <div class="row" style="border: 0px solid blue; margin-top: 1%;">
      <button mat-raised-button color="warn" [mat-dialog-close]="false" style="margin-right: 1%;" (click)="cerrar()">Cerrar</button>
      <button mat-raised-button color="primary" [hidden]="hayImpresion" [class.spinner]="loadButton" [disabled]="loadButton" (click)="solicitarInternacion()">Guardar</button>
      <button mat-raised-button [hidden]="!hayImpresion" (click)="imprimirOrdenInternacion()">Imprimir</button>
    </div>
  </form>
</div>