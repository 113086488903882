<div class="container-fluid" style="border: 0px solid red;">
  <mat-progress-bar mode="indeterminate" *ngIf="cargando" style="margin-top: 1%;"></mat-progress-bar>
  <mat-card *ngIf="!cargando && !pedido">

    <div class="row" style="border: 0px solid red">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <span style="float: right; margin-right: 5%;">
          <button mat-icon-button color="primary" (click)="volver()">
            <mat-icon aria-label="Example icon-button with a heart icon">keyboard_backspace</mat-icon> Volver
          </button>
        </span>
      </div>
    </div>

    <mat-card-header>
      <mat-card-title>No se pudo obtener el pedido.</mat-card-title>
    </mat-card-header>
  </mat-card>
  <mat-card [tabindex]="0" class="example-card" *ngIf="!cargando && pedido">
    <div class="row" style="border: 0px solid red">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <span style="float: right; margin-right: 5%;">
          <button mat-icon-button color="primary" (click)="volver()">
            <mat-icon aria-label="Example icon-button with a heart icon">keyboard_backspace</mat-icon> Volver
          </button>
        </span>
      </div>
    </div>
    <mat-card-header style="border: 0px solid red;">
      <mat-card-title>Escribir Informe</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <form [formGroup]="escribirInformeForm">
        <div class="row" style="padding-top: 1%;">
          <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
            <mat-form-field>
              <input
                matInput
                type="text"
                formControlName="paciente"
                placeholder="Paciente"
              />
            </mat-form-field>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
            <mat-form-field>
              <input
                matInput
                type="text"
                formControlName="dni"
                placeholder="DNI"
              />
            </mat-form-field>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
            <mat-form-field>
              <input
                matInput
                type="text"
                formControlName="fecha"
                placeholder="Fecha"
              />
            </mat-form-field>
          </div>
        </div>
        <div class="row" *ngIf="!cargando" style="padding-top: 1%;">
          <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
            <mat-form-field>
              <input
                matInput
                type="text"
                formControlName="solicitante"
                placeholder="Indicado por:"
              />
            </mat-form-field>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
            <mat-form-field>
              <input
                matInput
                type="text"
                formControlName="servicio"
                placeholder="Servicio"
              />
            </mat-form-field>
          </div>
        </div>

        <mat-divider [inset]="true"></mat-divider>

        <div class="row" style="padding-top: 1%;">
          <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8" style="border: 0px solid red;">
            <div class="row" style="padding-top: 1%;">
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <mat-form-field>
                  <mat-label>Plantillas</mat-label>
                  <mat-select [(ngModel)]="plantillaSeleccionada" [ngModelOptions]="{ standalone: true }">
                    <mat-option>
                      <ngx-mat-select-search
                        placeholderLabel="Buscar plantilla"
                        noEntriesFoundLabel="No se encontraron plantillas"
                        [formControl]="bankMultiFilterCtrl"
                      ></ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let pp of this.plantillasTodas | async" [value]="pp" (click)="insertarPlantilla(pp)">
                      {{ pp.titulo | toTitleCase }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div class="row" style="padding-top: 1%;">
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <ckeditor
                  formControlName="informe"
                  [editor]="editor"
                  [config]="{
                    toolbar: [
                      'heading',
                      'bold',
                      'italic',
                      'numberedlist',
                      'bulletedlist',
                      'link',
                      'blockquote',
                      'undo'
                    ]
                  }"
                ></ckeditor>
              </div>
            </div>
            <div class="row" style="margin-top: 1%;">
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <button mat-raised-button (click)="limpiarInforme()" color="warn">Limpiar</button>
              </div>
            </div>
          </div>

          <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" style="border: 0px solid red;">
            <mat-list>
              <h3 mat-subheader style="font-size: 18px;">Practicas</h3>
              <mat-list-item *ngFor="let practica of practicasDelPedido"> {{practica.nombreEstudio}}
                <mat-divider></mat-divider>
              </mat-list-item>
            </mat-list>
          </div>

        </div>
      </form>
  </mat-card-content>
  <mat-card-actions>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
        <alert [hidden]="!alerta" style="text-align: center; margin-top: 1%"></alert>
      </div>
    </div>
    <button mat-raised-button color="primary" [class.spinner]="loadButton" [disabled]="loadButton" (click)="guardarConfirmarImprimir()">
      Guardar y confirmar</button>
      <a mat-raised-button *ngIf="urlPdf" href="{{ urlPdf }}" target="_blank">Imprimir</a>
    <!-- <button mat-button (click)="confirmar()">Confirmar</button> -->
  </mat-card-actions>
  </mat-card>
</div>
