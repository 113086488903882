import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { User } from "../_models/user.model";

@Injectable({ providedIn: "root" })
export class EvolucionService {
  private currentEvoSubject: BehaviorSubject<User>;

  public currentEvo: Observable<User>;

  constructor() {
    this.currentEvoSubject = new BehaviorSubject(null);
    this.currentEvo = this.currentEvoSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentEvoSubject.value;
  }

  setEvo(evolucion) {
    this.currentEvoSubject.next(evolucion);
  }

  unSetEvo() {
    this.currentEvoSubject.next(null);
  }
}
