<div class="container-fluid">
  <mat-progress-bar mode="indeterminate" style="margin-top: 1%;" [hidden]="!cargando"></mat-progress-bar>

  <div class="row" style="justify-content: center; margin-top: 1%;">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 col-xxl-8">
      <div class="row" [hidden]="cargando" style="border: 0px solid red;">
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-6 col-xxl-6">
          <mat-form-field appearance="standard">
            <mat-label>Buscar</mat-label>
            <input matInput (keyup)="applyFilter($event)" #input>
          </mat-form-field>
        </div>
        
        <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxl-3" style="border: 0px solid blue; display: flex;">
          <!-- <button mat-raised-button color="primary" (click)="crearFormulario()" style="margin: auto;">
            <mat-icon>add</mat-icon> Nuevo Formulario</button> -->
        </div>
        <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxl-3" style="border: 0px solid blue; display: flex;">
          <button mat-raised-button color="primary" matTooltip="Nuevo Formulario" (click)="crearFormulario()" style="margin: auto;">
            <mat-icon>add</mat-icon></button>
        </div>
      </div>  
  
      <div class="mat-elevation-z8" [hidden]="cargando" style="margin-top: 1%;">
        <table mat-table [dataSource]="dataSource" matSort>
      
          <ng-container matColumnDef="idFormulario">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> idFormulario </th>
            <td mat-cell *matCellDef="let row"> {{row.idFormulario}} </td>
          </ng-container>
      
          <ng-container matColumnDef="nombre">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> nombre </th>
            <td mat-cell *matCellDef="let row"> {{row.nombre}} </td>
          </ng-container>
      
          <ng-container matColumnDef="estado">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> estado </th>
            <td mat-cell *matCellDef="let row"> {{row.estado}} </td>
          </ng-container>
      
          <ng-container matColumnDef="acciones">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> acciones </th>
            <td mat-cell *matCellDef="let row">
              <mat-icon matTooltip="Eliminar" style="color: red; cursor: pointer;" (click)="eliminarFormulario(row)"
                *ngIf="row.estado == 'ACT'">delete_outline</mat-icon>
              <mat-icon matTooltip="Editar" style="color: darkblue; cursor: pointer;" (click)="editarFormulario(row)"
                *ngIf="row.estado == 'ACT'">edit</mat-icon>
              <mat-icon matTooltip="Activar" style="color: green; cursor: pointer;" (click)="activarFormulario(row)"
                *ngIf="row.estado == 'INA'">check_circle</mat-icon>
            </td>
          </ng-container>
      
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;" disabled></tr>
      
          <!-- Row shown when there is no matching data. -->
          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
          </tr>
        </table>
      
        <mat-paginator [pageSizeOptions]="[10, 25, 100]" aria-label="Select page of users"></mat-paginator>
      </div>
    </div>
  </div>
</div>