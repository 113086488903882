import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AdministracionAgendasService } from './../_services/administracion-agendas.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DialogConfirmacionComponent } from './../dialog-confirmacion/dialog-confirmacion.component';
import {
  Respuesta,
  DialogMensajeComponent,
} from './../dialog-mensaje/dialog-mensaje.component';
import { AuthenticationService } from '../_services/authentication.service';
import { SeguidorPaginasService } from '../_services/seguidorPaginas.service';
import { EmbedUrlsService } from '../_services/embedUrls.service';

@Component({
  selector: 'app-agregar-establecimiento',
  templateUrl: './agregar-establecimiento.component.html',
  styleUrls: ['./agregar-establecimiento.component.css'],
})
export class AgregarEstablecimientoComponent implements OnInit {
  loading = false;
  establecimientoForm: UntypedFormGroup;
  submitted = false;
  private matricula: number;
  constructor(
    private authService: AuthenticationService,
    private formBuilder: UntypedFormBuilder,
    private administradorService: AdministracionAgendasService,
    public dialog: MatDialog,
    private router: Router,
    private seguidorPaginas: SeguidorPaginasService,
    private embedUrlService: EmbedUrlsService
  ) {}

  ngOnInit() {
    this.embedUrlService.embedUrls.subscribe((funciones) => {
      if (
        funciones.find((funcion) => {
          return funcion.nombreMenu === 'Establecimientos';
        })
      ) {
      } else {
        this.router.navigate(['/agenda']);
      }
    });
    this.matricula = this.authService.currentUserValue.roles.find(
      (rol) => rol.codigo === 'MHE'
    ).valorTipoID;
    this.buildForm();
  }

  private buildForm() {
    this.establecimientoForm = this.formBuilder.group({
      nombre: [, Validators.required],
      /* posiblemente despues lo usemos van hardcodeados */
      turnosOtraSucursal: ['N', Validators.required],
      quirofanoOtraSucursal: ['N', Validators.required],
      descripcion: [null],
      ubicacion: ['EXT'],
      domicilio: [, Validators.required],
      localidad: [, Validators.required],
      observacion: [null],
      servidorTv: [null],
    });
  }
  public get respuesta(): Respuesta {
    return this.respuesta;
  }
  public cancelar() {
    this.dialog
      .open(DialogConfirmacionComponent, {
        data: `¿Seguro desea cancelar la carga del establecimiento?`,
      })
      .afterClosed()
      .subscribe((confirmado: boolean) => {
        if (confirmado) {
          //this.router.navigate(['/administracionEstablecimientos']);
          this.volver();
        }
      });
  }

  openDialog(respuesta: Respuesta): void {
    const dialogRef = this.dialog.open(DialogMensajeComponent, {
      width: '500px',
      data: respuesta,
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (respuesta.ok) {
        this.volver();
        //this.router.navigate(['/administracionEstablecimientos']);
      }
    });
  }
  onSubmit() {
    this.submitted = true;
    if (this.establecimientoForm.valid) {
      this.loading = true;

      this.administradorService
        .setEstablecimiento(this.establecimientoForm.value, this.matricula)
        .subscribe(
          (res) => {
            this.loading = false;
            this.openDialog(res);
          },
          (error) => {
            console.log('error' + error);
          }
        );
    }
  }
  volver() {
    if (this.seguidorPaginas.getPreviousUrl()) {
      this.router.navigateByUrl(this.seguidorPaginas.getPreviousUrl());
    } else {
      this.router.navigate(['/administracionEstablecimientos']);
    }
  }
}
