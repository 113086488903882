import { Component, OnInit, Inject, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DataTablaItems {
  numeroItem: number;
  descripcion: string;
}

@Component({
  selector: 'app-dialog-ingresar-item',
  templateUrl: './dialog-ingresar-item.component.html',
  styleUrls: ['./dialog-ingresar-item.component.css'],
})
export class DialogIngresarItemComponent implements OnInit {
  accion: string;
  local_data: any;
  constructor(
    public dialogRef: MatDialogRef<DialogIngresarItemComponent>,
    // @Optional() se usa para prevenir un error, si no se pasa data
    @Optional() @Inject(MAT_DIALOG_DATA) public data: DataTablaItems
  ) {
    dialogRef.disableClose = true;
    this.local_data = { ...data };
    this.accion = this.local_data.accion;
  }

  ngOnInit() {
    if (!this.local_data.tipoRecetaFarmacia) {
      this.dialogRef.updateSize('40%', '35%');
    } else {
      this.dialogRef.updateSize('40%', '55%');
    }
  }

  accionar() {
    this.dialogRef.close({ data: this.local_data });
  }

  cancelar() {
    this.dialogRef.close({ evento: 'Cancelar' });
  }
}
