import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppComponent } from '../app.component';
import { BehaviorSubject, Observable } from 'rxjs';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json; charset=utf-8',
  }),
};

@Injectable({
  providedIn: 'root'
})
export class EpisodiosPacienteService {
  baseUrl: string;

  private currentEpisodioSubject: BehaviorSubject<any>;

  public currentEpisodio: Observable<any>;

  constructor(
    private http: HttpClient
  ) {
    this.baseUrl = AppComponent.baseUrl;
    this.currentEpisodioSubject = new BehaviorSubject<any>(
      JSON.parse(sessionStorage.getItem('currentEpisodio'))
    );
    this.currentEpisodio = this.currentEpisodioSubject.asObservable();
  }

  setCurrentEpisodio(episodio) {
    sessionStorage.setItem('currentEpisodio', JSON.stringify(episodio));
    this.currentEpisodioSubject.next(episodio);
  }

  removeCurrentEpisodio() {
    sessionStorage.removeItem('currentEpisodio');
    this.currentEpisodioSubject.next(null);
  }

  getCurrentEpisodio() {
    return this.currentEpisodioSubject.value;
  }

  setEpisodio(episodio) {
    return this.http.post<any>(
      this.baseUrl + '/episodioPaciente/setEpisodio',
      episodio,
      httpOptions);
  }

  listarEpisodiosPaciente(numeroDoc, tipoDoc) {
    return this.http.get<any>(
      this.baseUrl + '/episodioPaciente/seguro/listarEpisodiosPaciente/' +
      tipoDoc +
      '/' +
      numeroDoc);
  }

  verFormulariosEpisodio(idEpisodio) {
    return this.http.get<any>(
      this.baseUrl + '/episodioPaciente/seguro/getFormulariosEpisodio/' + idEpisodio);
  }

  getTiposEpisodio() {
    return this.http.get<any>(
      this.baseUrl + '/episodioPaciente/getTiposEpisodio');
  }

  getCarpetasDePaciente(tipoDoc, numDoc) {
    // En este método se llama a las carpetas para episodios. Antes se usaba el url getCarpetasDePaciente
    return this.http.get<any>(
      this.baseUrl + '/episodioPaciente/seguro/getCarpetasParaEpisodios/' + tipoDoc + '/' + numDoc);
  }

}
