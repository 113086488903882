import { Component, OnInit } from '@angular/core';
import { EstudioService } from '../_services/estudios.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TemplatesService } from '../_services/templates.service';
import { AuthenticationService } from '../_services/authentication.service';
import { ReplaySubject } from 'rxjs/internal/ReplaySubject';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { AlertService } from '../_services/alert.service';
import { UrlResolverService } from '../_services/urlResolver.service';

@Component({
  selector: 'app-escribir-informe',
  templateUrl: './escribir-informe.component.html',
  styleUrls: ['./escribir-informe.component.css']
})
export class EscribirInformeComponent implements OnInit {

  public editor = ClassicEditor;
  cargando: boolean = true;
  escribirInformeForm: UntypedFormGroup;
  pedido: any;
  bankMultiFilterCtrl: UntypedFormControl = new UntypedFormControl();
  plantillaSeleccionada: any;
  public plantillasTodas: ReplaySubject<any> = new ReplaySubject<any>(1);
  plantillas: any[] = [];
  protected onDestroy = new Subject<void>();
  practicasDelPedido: any[] = [];
  loadButton: boolean = false;
  alerta: boolean = false;
  urlPdf: string;
  urlHorusRestfull: string;

  constructor(
    private urlService: UrlResolverService,
    private alertService: AlertService,
    private authService: AuthenticationService,
    private templatesService: TemplatesService,
    private estudioService: EstudioService,
    private route: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    private router: Router,
  ) { }

  ngOnInit() {

    this.bankMultiFilterCtrl.valueChanges.pipe(takeUntil(this.onDestroy)).subscribe(() => {
      this.filterBanksMulti();
    });
    this.getHorusURL();
    this.iniciarPedidoForm();
    this.obtenerPedido();
  }

  protected filterBanksMulti() {
    // get the search keyword
    let search = this.bankMultiFilterCtrl.value;

    if (!search) {
      this.plantillasTodas.next(this.plantillas.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filtro plantillas
    this.plantillasTodas.next(this.plantillas.filter((plantilla) => plantilla.titulo.toLowerCase().indexOf(search) > -1));
  }

  OnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  insertarPlantilla(plantilla) {
    // console.log(this.escribirInformeForm.get('informe').value) por si concatenamos
    this.escribirInformeForm.get('informe').setValue(plantilla.texto);
  }

  limpiarInforme() {
    this.escribirInformeForm.get('informe').setValue('');
    this.plantillaSeleccionada = '';
  }

  async obtenerPlantillas() {
    await this.templatesService.getPlantillasPublicas(
      this.authService.currentUserValue.roles.find((rol) => rol.codigo === 'MHE').valorTipoID
    ).toPromise().then((respPublicas) => {
      // console.log(respPublicas);
      if (respPublicas.ok && respPublicas.paginador.elementos.length > 0) {
        this.plantillas = respPublicas.paginador.elementos;
      }
    }, (error) => {
      console.log(error);
    })

    await this.templatesService.getPlantillasPrivadas(
      this.authService.currentUserValue.roles.find((rol) => rol.codigo === 'MHE').valorTipoID
    ).toPromise().then((respPrivadas) => {
      // console.log(respPrivadas);
      if (respPrivadas.ok && respPrivadas.paginador.elementos.length > 0) {
        respPrivadas.paginador.elementos.forEach(p => {
          p.titulo = p.titulo + ' (Privada)';
        });
        this.plantillas = this.plantillas.concat(respPrivadas.paginador.elementos);
      }
    }, (error) => {
      console.log(error);
    })

    await this.templatesService.getPlantillasPorPedido(this.pedido).toPromise().then((respPorPedido) => {
      // console.log(respPorPedido);
      if (respPorPedido.ok && respPorPedido.paginador.elementos.length > 0) {
        respPorPedido.paginador.elementos.forEach(p => {
          p.titulo = p.titulo + ' (Practica)';
        });
        this.plantillas = this.plantillas.concat(respPorPedido.paginador.elementos);
      }
    }, (error) => {
      console.log(error);
    })

    this.plantillasTodas.next(this.plantillas);
  }

  async obtenerPedido() {
    await this.estudioService.getPedidoPorNumero(this.route.snapshot.params['numeroPedido']).toPromise().then((respRegPedido) => {
      // console.log(respRegPedido);
      this.pedido = respRegPedido;
      this.practicasDelPedido = this.pedido.estudiosPaciente;
      this.escribirInformeForm.get('paciente').setValue(this.pedido.paciente);
      this.escribirInformeForm.get('dni').setValue(this.pedido.nroDniPaciente);
      this.escribirInformeForm.get('fecha').setValue(this.pedido.fecha);
      this.escribirInformeForm.get('servicio').setValue(this.pedido.nombreServicio);
      this.escribirInformeForm.get('solicitante').setValue(this.pedido.solicitanteRaw);
      this.obtenerPlantillas();
      this.cargando = false;
    }, error => {
      console.log("No se pudo obtener el pedido.");
      this.cargando = false;
    })
  }

  iniciarPedidoForm() {
    this.escribirInformeForm = this.formBuilder.group({
      // paciente: [ { value: null, disabled: true},  Validators.required ],
      paciente: [ null, Validators.required ],
      dni: [ null, Validators.required ],
      fecha: [ null, Validators.required],
      servicio: [ null, Validators.required],
      solicitante: [ null, Validators.required],
      informe: [ '', Validators.required],
    });
  }

  async guardarConfirmarImprimir() {
    this.alerta = false;
    this.loadButton = true;
    this.pedido.texto = this.escribirInformeForm.get('informe').value;

    await this.estudioService.informarConfirmar(this.pedido).toPromise().then((resp) => {
      // console.log(resp);
      if (resp.ok) {
        this.urlPdf = window.location.protocol +'//'+ this.urlHorusRestfull +'/horus_restfull/api/historiaClinica/pedidos/imprimir/pdf/'+this.pedido.id+'/'+this.pedido.id+'/'+new Date().getTime();
        this.alertService.success(resp.mensaje)
      } else {
        this.alertService.error(resp.mensaje)
      }

      this.alerta = true;
      this.loadButton = false;
    }, (error) => {
      console.log(error);
      this.alertService.error('No se pudo confirmar el pedido.')
      this.alerta = true;
      this.loadButton = false;
    })
  }

  async getHorusURL() {
    await this.urlService.getHorusfullUrl().toPromise().then(resp => {
      // console.log(resp)
      this.urlHorusRestfull = resp.paginador.elementos[0].valor;
    }, error => {
      console.log(error)
    })
  }

  volver() {
    this.router.navigate(['/paciente']);
  }

}