import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SucursalService } from '../_services/sucursal.service';
import { TurnosService } from '../_services/turnos.service';

@Component({
  selector: 'app-dias-atencion',
  templateUrl: './dias-atencion.component.html',
  styleUrls: ['./dias-atencion.component.css']
})
export class DiasAtencionComponent implements OnInit {
 
  private nroMatricula: any;
  private nroSucursal: any;
  private profesional: any;
  private horarios: any[];
  private establecimientos: any[];
  loading = true;
  loadingProfesional = true;

  dataTablaItems: any[] = [];
  dataSourceItems = this.dataTablaItems;
  displayedColumnasItems: string[] = ['dia', 'acciones'];


  constructor( 
    private turnosService: TurnosService,
    private router: Router,
    private sucursalService: SucursalService 
    ) { }

  ngOnInit() {
    this.nroMatricula = sessionStorage.getItem('matriculaProfesional');
    this.nroSucursal = sessionStorage.getItem('establecimiento');
    sessionStorage.removeItem('matriculaProfesional');
    sessionStorage.removeItem('establecimiento');
    this.cargarProfesional();
    this.cargarEstablecimientos();
    this.cargarDiasAtencion();
  }


  async cargarProfesional() {
    await this.turnosService.getProfesional(this.nroMatricula).toPromise().then(
        (data) => {               
            if (data.ok ) {    
            this.profesional = data.paginador.elementos[0];     
            this.loadingProfesional = false;                                               
          }
        }
      ); 
  }
  async cargarEstablecimientos() {
 /*     Función necesaria para obtener el código del establecimiento seleccionado de la Franja en oferta
        sin tener que implementar un SP que busque establecimientos por nombre.
 */  
    await this.sucursalService.getEstablecimientos().toPromise().then(
        (data) => {               
            if (data.ok ) {      
            this.establecimientos = data.data;                                                   
          }
        }
       ); 
  } 

  async cargarDiasAtencion() {
    await this.turnosService.getDiasPorMatySuc(this.nroMatricula,this.nroSucursal).toPromise().then(
        (data) => {             
            if (data) {
              data = JSON.parse(data);
              /* Controla que la vista no se rompa al actualizar el sitio*/
              try{
                this.horarios= data.ps[0].diasAtencion; 
              }catch(Error) {
                this.router.navigate(['/busquedaTurnos']);               
              }
              /* Ordena por Fecha los días de atención*/
               let arreglodeTurnos = [];
               this.horarios.forEach((x) => {
                 if (
                   !arreglodeTurnos.hasOwnProperty(
                     x.establecimiento + ' - ' + x.servicio
                   )
                 ) {
                   arreglodeTurnos[x.establecimiento + ' - ' + x.servicio] = [];
                 }
                 arreglodeTurnos[x.establecimiento + ' - ' + x.servicio].push(x);
               });
               this.horarios = arreglodeTurnos;
               Object.values(this.horarios).forEach(
                 (establecimiento: any) => {
                   establecimiento.sort(
                     (a, b) =>
                       <any>new Date(a.diaPrimerTurnoLibre).getTime() -
                       <any>new Date(b.diaPrimerTurnoLibre).getTime()
                   );
                 }
               );
              this.loading = false;                                        
          }
        }    
    )};

   
    onVerAgenda(dia: any) {
      /*Llamar a filtrar*/
      /* Se recupera el establecimiento antes de cambiar de vista */
      this.establecimientos=
            this.establecimientos.filter(
              (establecimiento) => establecimiento.nombreCompleto ==
              dia.establecimiento
            );
     this.MostrarAgenda(dia);
  }

  onVolver(){
    this.router.navigate(['/busquedaTurnos']);
  }


    MostrarAgenda(dia: any){
      let idConsultorio=this.establecimientos[0].codigo;
      let nombreConsultorio=this.establecimientos[0].nombreCompleto;
      sessionStorage.setItem('matriculaProfesional', JSON.parse( this.nroMatricula));
      sessionStorage.setItem('filtrado', JSON.stringify( true));      
      sessionStorage.setItem('establecimientoCodigo', JSON.parse(idConsultorio));
      sessionStorage.setItem('establecimientoNombre', JSON.stringify(nombreConsultorio));
      sessionStorage.setItem('nombreCompletoProf', JSON.stringify(this.profesional.apellido));
      sessionStorage.setItem('horaInicio', JSON.stringify(dia.str_horaInicioAtencion));
      sessionStorage.setItem('fecha', JSON.stringify( dia.diaPrimerTurnoLibre));
      this.router.navigate(['/agenda']);
    
    }
}

