import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppComponent } from '../app.component';
import { DatePipe } from '@angular/common';
import { Agenda } from '../dialog-datos-agenda/dialog-datos-agenda.component';
import { FormGroup } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json; charset=utf-8',
  }),
};

@Injectable({ providedIn: 'root' })
export class AdministracionAgendasService {
  baseUrl: string;

  private currentConsultorioSubject: BehaviorSubject<any>;
  public currentConsultorio: Observable<any>;

  constructor(private http: HttpClient) {
    this.baseUrl = AppComponent.baseUrl + '/administracionAgendas/seguro';
    this.currentConsultorioSubject = new BehaviorSubject<any>(
      JSON.parse(sessionStorage.getItem('currentPatient'))
    );
    this.currentConsultorio = this.currentConsultorioSubject.asObservable();
  }

  getEspecialidades() {
    return this.http.post<any>(
      this.baseUrl + '/getEspecialidades',
      httpOptions
    );
  }
  getAgendasProfesional(matricula, sucursal) {
    const datos: string = JSON.stringify({
      matricula,
      sucursal,
    });
    return this.http.post<any>(
      this.baseUrl + '/getAgendasProfesional',
      datos,
      httpOptions
    );
  }

  getSucursalesProfesional(matricula) {
    const datos: string = JSON.stringify({
      matricula,
    });
    return this.http.post<any>(
      this.baseUrl + '/getSucursalesProfesional',
      datos,
      httpOptions
    );
  }
  getServiciosProfesional(matricula) {
    const datos: string = JSON.stringify({
      matricula,
    });
    return this.http.post<any>(
      this.baseUrl + '/getServiciosProfesional',
      datos,
      httpOptions
    );
  }
  getConsultorios() {
    return this.http.get<any>(this.baseUrl + '/getConsultorios', httpOptions);
  }
  getConsultoriosSucursal(idSucursal: number) {
    const datos: string = JSON.stringify({
      idSucursal,
    });
    return this.http.post<any>(
      this.baseUrl + '/getConsultoriosSucursal',
      datos,
      httpOptions
    );
  }
  setAgendaProfesional(matricula, agendaNueva) {
    const datos: string = JSON.stringify({
      matricula,
      cantTurnos: Math.trunc(agendaNueva.get('cantidadTurnos').value),
      cons: agendaNueva.get('consultorio').value,
      horaInicio: new DatePipe('en-US').transform(
        '01/01/1900 ' + agendaNueva.get('desdeHora').value,
        'MMM d, y h:mm:ss a'
      ),
      vigenciaInicio: new DatePipe('en-US').transform(
        agendaNueva.get('desdeVigencia').value,
        'MMM d, y 0:00:00 a'
      ),
      diasAntic: agendaNueva.get('diasAnticipacion').value,
      diasAtencion: agendaNueva.get('diasAtencion').value,
      minTurno: Math.trunc(agendaNueva.get('duracionTurno').value),
      horaFin: new DatePipe('en-US').transform(
        '01/01/1900 ' + agendaNueva.get('hastaHora').value,
        'MMM d, y h:mm:ss a'
      ),
      vigenciaFin: new DatePipe('en-US').transform(
        agendaNueva.get('hastaVigencia').value,
        'MMM d, y 0:00:00 a'
      ),
      servicio: agendaNueva.get('servicio').value,
      establecimiento: agendaNueva.get('establecimiento').value,
      codEspecialidad: agendaNueva.get('codEspecialidad').value,
      habilitadoTurnosWeb: agendaNueva.get('habilitadoTurnosWeb').value,
    });
    return this.http.post<any>(
      this.baseUrl + '/agregarAgenda',
      datos,
      httpOptions
    );
  }
  editAgendaProfesional(matricula, agendaEditar) {
    const datos: string = JSON.stringify({
      matricula,
      cantTurnos: Math.trunc(agendaEditar.get('cantidadTurnos').value),
      cons: agendaEditar.get('consultorio').value,
      // horaInicio: '01/01/1900 ' + agendaNueva.get('desdeHora').value,
      horaInicio: new DatePipe('en-US').transform(
        agendaEditar.get('desdeHora').value,
        'MMM d, y h:mm:ss a'
      ),
      /* vigenciaInicio: new DatePipe('en-US').transform(
        agendaNueva.get('desdeVigencia').value,
        'dd/MM/yyyy HH:mm'
      ), */
      vigenciaInicio: new DatePipe('en-US').transform(
        agendaEditar.get('desdeVigencia').value,
        'MMM d, y 0:00:00 a'
      ),
      diasAntic: agendaEditar.get('diasAnticipacion').value,
      numeroDia: agendaEditar.get('diasAtencion').value,
      minTurno: Math.trunc(agendaEditar.get('duracionTurno').value),
      // horaFin: '01/01/1900 ' + agendaNueva.get('hastaHora').value,
      horaFin:
        agendaEditar.get('hastaHora').value != null
          ? new DatePipe('en-US').transform(
              '01/01/1900 ' + agendaEditar.get('hastaHora').value,
              'MMM d, y h:mm:ss a'
            )
          : null,
      /* vigenciaFin: new DatePipe('en-US').transform(
        agendaNueva.get('hastaVigencia').value,
        'dd/MM/yyyy HH:mm'
      ), */
      vigenciaFin:
        agendaEditar.get('hastaVigencia').value != null
          ? new DatePipe('en-US').transform(
              agendaEditar.get('hastaVigencia').value,
              'MMM d, y 0:00:00 a'
            )
          : null,
      servicio: agendaEditar.get('servicio').value,
      establecimiento: agendaEditar.get('establecimiento').value,
      codEspecialidad: agendaEditar.get('codEspecialidad').value,
      habilitadoTurnosWeb: agendaEditar.get('habilitadoTurnosWeb').value,
    });
    return this.http.post<any>(
      this.baseUrl + '/modificarAgenda',
      datos,
      httpOptions
    );
  }
  borrarAgendaProfesional(
    matricula: number,
    horaInicio: Date,
    vigenciaInicio: Date,
    numeroDia: number,
    vigenciaFin: Date
  ) {
    const datos: string = JSON.stringify({
      matricula,
      horaInicio: new DatePipe('en-US').transform(
        horaInicio,
        'MMM d, y h:mm:ss a'
      ),
      vigenciaInicio: new DatePipe('en-US').transform(
        vigenciaInicio,
        'MMM d, y 0:00:00 a'
      ),
      numeroDia,
      vigenciaFin: new DatePipe('en-US').transform(
        vigenciaFin,
        'MMM d, y 0:00:00 a'
      ),
    });
    return this.http.post<any>(
      this.baseUrl + '/modificarAgenda',
      datos,
      httpOptions
    );
  }

  setEstablecimiento(establecimiento, matricula) {
    const datos: string = JSON.stringify({
      matricula,
      nombre: establecimiento.nombre,
      localidad: establecimiento.localidad,
      domicilio: establecimiento.domicilio,
      turnosOtraSucursal: establecimiento.turnosOtraSucursal,
      quirofanoOtraSucursal: establecimiento.quirofanoOtraSucursal,
      descripcion: establecimiento.descripcion,
      ubicacion: establecimiento.ubicacion,
      observacion: establecimiento.observacion,
      servidorTv: establecimiento.servidorTv,
    });
    return this.http.post<any>(
      this.baseUrl + '/agregarSucursalProfesional',
      datos,
      httpOptions
    );
  }
  editEstablecimiento(establecimiento) {
    return this.http.post<any>(
      this.baseUrl + '/modificarSucursalProfesional',
      establecimiento,
      httpOptions
    );
  }
  setConsultorio(consultorio) {
    return this.http.post<any>(
      this.baseUrl + '/agregarConsultorio',
      consultorio,
      httpOptions
    );
  }
  getPrimerDiaSinTurnosAsignados(
    matricula: number,
    horaInicio: Date,
    vigenciaInicio: Date,
    numeroDia: number
  ) {
    const datos: string = JSON.stringify({
      matricula,
      horaInicio: new DatePipe('en-US').transform(
        horaInicio,
        'MMM d, y h:mm:ss a'
      ),
      vigenciaInicio: new DatePipe('en-US').transform(
        vigenciaInicio,
        'MMM d, y h:mm:ss a'
      ),
      numeroDia,
    });
    return this.http.post<any>(
      this.baseUrl + '/getPrimerDiaSinTurnosAsignados',
      datos,
      httpOptions
    );
  }
  getDatosProfesional(matricula: number) {
    return this.http.get<any>(
      AppComponent.baseUrl +
        '/profesionales/seguro/recuperarDatosProfesional/' +
        matricula,
      httpOptions
    );
  }
  setCurrentConsultorio(consultorio) {
    sessionStorage.setItem('currentConsultorio', JSON.stringify(consultorio));
    this.currentConsultorioSubject.next(consultorio);
  }
  removeCurrentConsultorio() {
    sessionStorage.removeItem('currentConsultorio');
    this.currentConsultorioSubject.next(null);
  }

  getCurrentConsultorio() {
    return this.currentConsultorioSubject.value;
  }
}
