import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthenticationService } from '../_services/authentication.service';
import { AdministracionAgendasService } from './../_services/administracion-agendas.service';
import {
  DialogMensajeComponent,
  Respuesta,
} from './../dialog-mensaje/dialog-mensaje.component';
import { Sucursal } from '../administracion-agendas/administracion-agendas.component';
import { DialogAgregarConsultorioComponent } from '../dialog-agregar-consultorio/dialog-agregar-consultorio.component';
import { EmbedUrlsService } from '../_services/embedUrls.service';

@Component({
  selector: 'app-administracion-establecimientos',
  templateUrl: './administracion-establecimientos.component.html',
  styleUrls: ['./administracion-establecimientos.component.css'],
})
export class AdministracionEstablecimientosComponent implements OnInit {
  private matricula: number;
  private listaSucursales: Sucursal[];
  loading = false;
  constructor(
    private authService: AuthenticationService,
    private administradorService: AdministracionAgendasService,
    public dialog: MatDialog,
    private router: Router,
    private embedUrlService: EmbedUrlsService
  ) {}

  ngOnInit() {
    this.embedUrlService.embedUrls.subscribe((funciones) => {
      if (
        funciones.find((funcion) => {
          return funcion.nombreMenu === 'Establecimientos';
        })
      ) {
      } else {
        this.router.navigate(['/agenda']);
      }
    });
    this.matricula = this.authService.currentUserValue.roles.find(
      (rol) => rol.codigo === 'MHE'
    ).valorTipoID;

    this.cargarSucursales(this.matricula);
  }

  cargarSucursales(matricula) {
    this.administradorService.getSucursalesProfesional(matricula).subscribe(
      (data) => {
        if (data) {
          this.listaSucursales = data.elementos;
          this.listaSucursales = this.listaSucursales.filter(
            (sucursal) => sucursal.id != 1
          );
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  public get sucursales(): Sucursal[] {
    return this.listaSucursales;
  }

  openDialog(respuesta: Respuesta): void {
    if (respuesta.ok) {
      respuesta.mensaje = 'El establecimiento se inactivo correctamente';
    }
    const dialogRef = this.dialog.open(DialogMensajeComponent, {
      width: '500px',
      data: respuesta,
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (respuesta.ok) {
        /* window.location.reload(); */
        this.cargarSucursales(this.matricula);
      }
    });
  }

  borrarEstablecimiento(establecimiento: Sucursal): void {
    if (establecimiento) {
      establecimiento.estado = 'INA';
      this.loading = true;
      this.administradorService.editEstablecimiento(establecimiento).subscribe(
        (res) => {
          this.loading = false;
          this.openDialog(res);
          // this.router.navigate(['/inicio']); // mejorar esto, manda directo al inicio
        },
        (error) => {
          console.log('error' + error);
        }
      );
    }
  }
  agregarNuevoEstablecimiento() {
    this.router.navigate(['/agregarEstablecimiento']);
  }
  agregarNuevoConsultorio() {
    const dialogRef = this.dialog.open(DialogAgregarConsultorioComponent, {
      width: '400px',
    });
  }
  modificarEstablecimiento(establecimiento: Sucursal) {
    this.router.navigate(['/modificarEstablecimiento'], {
      state: {
        establecimientoModificar: establecimiento,
      },
    });
  }
}
