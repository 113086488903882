<h1 mat-dialog-title>Cierre de agenda</h1>
<!-- <p class="m-0 p-0">Indique la fecha</p> -->
<!--   <p>¿Cuando desea cerrar la agenda?</p> -->
<mat-card-subtitle class="mb-2" *ngIf="fechaCierre">
  Puede cerrar su agenda a partir del
  {{ this.fechaSugerida | date: 'dd/MM/yyyy' }}, anteriormente tiene turnos
  dados</mat-card-subtitle
>
<mat-form-field class="d-flex justify-content-center">
  <mat-label>Fecha de cierre</mat-label>
  <input
    matInput
    [matDatepicker]="picker2"
    [formControl]="fechaCierre"
    [min]="agendaEliminar.vigenciaInicio | date: 'yyyy-MM-dd'"
    required
  />
  <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
  <mat-datepicker #picker2></mat-datepicker>
</mat-form-field>
<div mat-dialog-actions class="d-flex justify-content-center">
  <button mat-raised-button color="primary" (click)="cerrarDialogo()">
    cancelar
  </button>
  <button mat-raised-button color="warn" (click)="confirmado()">
    confirmar
  </button>
  <mat-spinner class="d-inline" diameter="30" *ngIf="loading"></mat-spinner>
</div>
