import { Injectable } from "@angular/core";
import { MatSidenav } from "@angular/material/sidenav";

@Injectable({
  providedIn: "root",
})

export class NavBarServiceService {
  private sideNav: MatSidenav;

  setDrawer(sidenav: MatSidenav) {
    this.sideNav = sidenav;
  }

  toggle() {
    this.sideNav.toggle();
  }
  
  close() {
    this.sideNav.close();
  }
}