import { Component, OnInit} from '@angular/core';
import { AuthenticationService } from '../_services/authentication.service';
import { PacienteService } from '../_services/paciente.service';
import { EpisodiosPacienteService } from '../_services/episodiosPaciente.service';
import { Router } from '@angular/router';
import { UrlResolverService } from '../_services/urlResolver.service';
import { Episodio } from '../_models/episodio.interface';
import { FormulariosService } from '../_services/formularios.service';
import { MatDialog } from '@angular/material/dialog';
import { DialogPdfComponent } from '../dialog-pdf/dialog-pdf.component';
import { DialogMensajeComponent } from '../dialog-mensaje/dialog-mensaje.component';
import { InternacionService } from '../_services/internacion.service';
import { ActivatedRoute, NavigationStart } from '@angular/router';
import { UserService } from '../_services/user.service';
import { AdministracionAgendasComponent } from '../administracion-agendas/administracion-agendas.component';
import { AdministracionAgendasService } from '../_services/administracion-agendas.service';

@Component({
  selector: 'app-listar-episodios',
  templateUrl: './listar-episodios.component.html',
  styleUrls: ['./listar-episodios.component.css']
})
export class ListarEpisodiosComponent implements OnInit {
  episodios;
  loading = true;
  sinEpisodios;
  currentPatient: any;
  horusUrl;
  cargandoRedireccion = true;
  errorPaciente = false;
  consultorioActual;
  profesionalSeleccionado;
  matricula;
  fechaSeleccionada;
  horaIni;

  constructor(
    private authenticationService: AuthenticationService,
    private pacienteService: PacienteService,
    private router: Router,
    private episodiosPacienteService: EpisodiosPacienteService,
    private urlResolver: UrlResolverService,
    private formularioService: FormulariosService,
    public dialog: MatDialog,
    private userService: UserService,
    private route: ActivatedRoute,
    private administradorService: AdministracionAgendasService,
    public internacionService: InternacionService) {}

  ngOnInit() {
    if (this.route.snapshot.params['dniPaciente'] != undefined) {
      const currentUser = this.authenticationService.currentUserValue;
      if (!currentUser) {
        localStorage.setItem('dniPaciente', this.route.snapshot.params['dniPaciente']);
        this.router.navigate(['/login'], { queryParams: { returnUrl: '/listar_episodios' }});
      } else {
        this.setearPaciente();
      }
    } else {
      this.cargandoRedireccion = false;
      this.currentPatient = this.pacienteService.getCurrentPatient();
      this.cargarEpisodios();
      this.recuperarUrlHorus();
    } 
  }

  async setearPaciente() {
      if (localStorage.getItem('dniPaciente') != undefined) {
        let aux = atob(localStorage.getItem('dniPaciente'));
        let dniPaciente = aux.substring(0, 8);
        let tipoDocumento = aux[8];
        this.pacienteService.buscarPaciente(-1, -1, dniPaciente).subscribe(
        (data) => {
          let pacientes = data.paginador.elementos;
          pacientes = pacientes.filter(paciente => paciente.tipoDocumento === tipoDocumento);
          this.loadPaciente(pacientes[0]);
          this.pacienteService.setCurrentPatient(this.currentPatient);
          if (!this.errorPaciente)
            localStorage.removeItem('dniPaciente');
          this.cargarMatricula();
          this.cargarEpisodios();
          this.recuperarUrlHorus();
        },
        (error) => {
          this.errorPaciente = true;
          console.log(error);
        }
      );
      }
      else {
        this.currentPatient = this.pacienteService.getCurrentPatient();
        this.cargarMatricula();
        this.cargandoRedireccion = false;
        this.cargarEpisodios();
        this.recuperarUrlHorus();
      }
  }

  cargarMatricula() {
    sessionStorage.setItem('matriculaAux', String(this.authenticationService.currentUserValue.roles.find(
        (rol) => rol.codigo === 'MHE'
      ).valorTipoID));
  }

  perfilSecretaria(): any {
    return this.userService.perfilSecretaria;
  }

  async loadPaciente(paciente) {
    this.currentPatient = {
      numeroDoc: paciente.nroDocumento,
      tipoDoc: paciente.tipoDocumento,
      apellido: paciente.apellido,
      str_paciente: paciente.apellido
    }
    await this.pacienteService
        .datosPaciente(
          this.currentPatient.numeroDoc,
          this.currentPatient.tipoDoc
        )
        .subscribe(
          (data2) => {
            let patientData = data2;
            this.currentPatient.coberturas = patientData.coberturas;
            this.currentPatient.edad = patientData.edad;
            this.currentPatient.sexo = patientData.sexo;
            this.currentPatient.fechaNacimiento = patientData.fechaNacimiento;
            this.pacienteService.setCurrentPatient(this.currentPatient)
            this.cargandoRedireccion = false;
          },
          (error) => {
            this.errorPaciente = true;
            this.cargandoRedireccion = false;
            console.log(error);
          });
  }

  recuperarUrlHorus(){
    this.urlResolver.getHorusfullUrl().subscribe((x) => {
      this.horusUrl = x.paginador.elementos[0].valor;
    });
  }

  irCrearEpisodio(episodio) {
    this.episodiosPacienteService.setCurrentEpisodio(episodio);
    this.router.navigate(['/crear-episodio']);
  }
  
  irEditarEpisodio(episodio) {
    episodio.editar = true;
    this.episodiosPacienteService.setCurrentEpisodio(episodio);
    this.router.navigate(['/crear-episodio']);
  }
  
  irDocumentosDigitales(episodio){
    this.episodiosPacienteService.setCurrentEpisodio(episodio);
    //console.log(episodio)
    this.router.navigate(['/documentos-digitales']);
  }

  volver() {
    this.router.navigate(['/paciente']);
  }

  cargarEpisodios() {
    this.episodiosPacienteService.listarEpisodiosPaciente(this.currentPatient.numeroDoc,
                                                          this.currentPatient.tipoDoc)
        .subscribe(
          (respuesta) => {
            // console.log(respuesta);
            if (respuesta.ok) {
              if (respuesta.elementos.length !== 0) {
                this.episodios = respuesta.elementos;
              } else {
                this.sinEpisodios = true;
              }
              this.loading = false;
            }
          },
          (error) => {
            console.log(error);
          }
        );
  }

  verFormularios(episodio:Episodio) {
    this.episodiosPacienteService.setCurrentEpisodio(episodio);
    localStorage.setItem('urlback', '/listar-episodios');
    this.router.navigate(['/ver-formularios-episodio']);
  }

  irAlHistorial(idHistorial) {
    window.open(
      window.location.protocol +
        '//' +
        this.horusUrl +
        '/he_fe/#/login?site=internacion/ver/' +
        idHistorial,
      '_blank'
    );
  }

  //esta funcion no se llama más desde el html
  verDetalle(episodio:Episodio){
    //console.log('verDetalle -> episodio', episodio);
    if(episodio.idHistorial !== 0){
      this.irAlHistorial(episodio.idHistorial);
    } else {
      this.verFormularios(episodio);
    }

  }

  async imprimirTodoEpisodio(idEpisodio) {
      const dialogRef = this.dialog.open(DialogPdfComponent, {
      width: '1300px',
      height: '700px',
      data: {
        url: this.formularioService.imprimirEpisodio(idEpisodio, this.authenticationService.currentUserValue.usuario),
      },
     });
  }

  irAInternaciones(subepisodio) {
    if (subepisodio.carpeta.fechaEgreso) {
      // si tiene fecha de egreso debe redirigir como el boton ver detalles
      this.irAlHistorial(subepisodio.idHistorial);
    }
    else {
      // si es una carpeta activa redirige a SM Internaciones Trasplantes
      window.open(
        window.location.protocol+'//'+this.horusUrl+'/he_fe/#/login?site=internacion/camas_xservicio/43', 
        '_blank'
      );
    }
  }

}
