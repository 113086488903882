<div class="container pagina" style="margin-top: 1%" *ngIf="mostrar">
  <div class="row justify-content-md-center" style="text-align: center">
    <div
      class="
        col col-sm-12 col-md-12 col-lg-12
        align-self-center align-middle
        text-center
      "
    >
      <div class="row justify-content-center">
        <mat-card class="py-1 my-1 w-100" style="text-align: center">
          <mat-card-title class="py-0 my-0"
            >Agregar agenda -
            {{ this.profesional | toTitleCase }}</mat-card-title
          >
          <form
            class="ui form"
            [formGroup]="agendaForm"
            enctype="multipart/form-data"
          >
            <mat-card-content>
              <div class="form-row align-items-center">
                <div
                  class="
                    form-group
                    col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4
                  "
                >
                  <mat-form-field>
                    <mat-label>Dias de atención</mat-label>
                    <mat-select
                      formControlName="diasAtencion"
                      multiple
                      required
                    >
                      <mat-option *ngFor="let dia of dias" [value]="dia.valor">
                        {{ dia.nombre | toTitleCase }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <div
                    class="alert alert-danger p-1 m-1 align-middle"
                    *ngIf="
                      agendaForm.get('diasAtencion').hasError('required') &&
                      (agendaForm.get('diasAtencion').touched || submitted)
                    "
                  >
                    <p class="p-0 m-0">Debe seleccionar al menos un dia</p>
                  </div>
                </div>
                <div
                  class="
                    form-group
                    col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4
                  "
                >
                  <mat-form-field>
                    <input
                      matInput
                      type="time"
                      formControlName="desdeHora"
                      placeholder="Hora inicio atención"
                      (change)="setHoraInicioAtencion()"
                      required
                    />
                  </mat-form-field>
                  <div
                    class="alert alert-danger p-1 m-1 align-middle"
                    *ngIf="
                      agendaForm.get('desdeHora').hasError('required') &&
                      (agendaForm.get('desdeHora').touched || submitted)
                    "
                  >
                    <p class="p-0 m-0">Debe seleccionar la hora de inicio</p>
                  </div>
                  <div
                    class="alert alert-danger p-1 m-1 align-middle"
                    *ngIf="!horaInicioValida"
                  >
                    <p class="p-0 m-0">
                      La hora de inicio debe ser inferior a la de fin
                    </p>
                  </div>
                </div>
                <div
                  class="
                    form-group
                    col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4
                  "
                >
                  <mat-form-field>
                    <input
                      type="time"
                      formControlName="hastaHora"
                      matInput
                      placeholder="Hora fin atención"
                      (change)="setHoraFinAtencion()"
                      required
                    />
                  </mat-form-field>

                  <div
                    class="alert alert-danger p-1 m-1 align-middle"
                    *ngIf="
                      agendaForm.get('hastaHora').hasError('required') &&
                      (agendaForm.get('hastaHora').touched || submitted)
                    "
                  >
                    <p class="p-0 m-0">Debe seleccionar la hora de fin</p>
                  </div>
                  <div
                    class="alert alert-danger p-1 m-1 align-middle"
                    *ngIf="!horaFinValida"
                  >
                    <p class="p-0 m-0">
                      La hora de fin debe ser superior a la de inicio
                    </p>
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div
                  class="
                    form-group
                    col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4
                  "
                >
                  <mat-form-field>
                    <input
                      matInput
                      type="number"
                      formControlName="duracionTurno"
                      placeholder="Duración de turnos"
                      (change)="setDuracion()"
                      min="0"
                      required
                    />
                    <!-- 
                      *ngIf="
                        this.agendaForm.get('hastaHora') &&
                        this.agendaForm.get('desdeHora') &&
                        agendaForm.get('cantidadTurnos')
                      "
                     -->
                  </mat-form-field>
                  <div
                    class="alert alert-danger p-1 m-1 align-middle"
                    *ngIf="
                      agendaForm.get('duracionTurno').hasError('required') &&
                      (agendaForm.get('duracionTurno').touched || submitted)
                    "
                  >
                    <p class="p-0 m-0">Debe ingresar la duración de turnos</p>
                  </div>
                  <div
                    class="alert alert-warning p-1 m-1 align-middle"
                    *ngIf="
                      agendaForm.get('duracionTurno').value &&
                      agendaForm.get('duracionTurno').value % 1 !== 0
                    "
                  >
                    <p class="p-0 m-0">Va a tener tiempo libre entre turnos</p>
                  </div>
                </div>
                <div
                  class="
                    form-group
                    col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4
                  "
                >
                  <mat-form-field>
                    <mat-label>Fecha inicio atención</mat-label>
                    <input
                      matInput
                      [matDatepicker]="desdeVigencia"
                      formControlName="desdeVigencia"
                      required
                    />
                    <mat-datepicker-toggle
                      matSuffix
                      [for]="desdeVigencia"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #desdeVigencia></mat-datepicker>
                  </mat-form-field>

                  <div
                    class="alert alert-danger p-1 m-1 align-middle"
                    *ngIf="
                      agendaForm.get('desdeVigencia').hasError('required') &&
                      (agendaForm.get('desdeVigencia').touched || submitted)
                    "
                  >
                    <p class="p-0 m-0">Debe seleccionar la fecha de inicio</p>
                  </div>
                </div>
                <div
                  class="
                    form-group
                    col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4
                  "
                >
                  <mat-form-field>
                    <mat-label>Fecha fin atención</mat-label>
                    <input
                      matInput
                      [matDatepicker]="picker2"
                      formControlName="hastaVigencia"
                      [min]="agendaForm.get('desdeVigencia').value"
                    />
                    <mat-datepicker-toggle
                      matSuffix
                      [for]="picker2"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #picker2></mat-datepicker>
                  </mat-form-field>
                </div>
              </div>
              <div class="form-row">
                <div
                  class="
                    form-group
                    col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4
                  "
                >
                  <mat-form-field>
                    <mat-label>Establecimiento / Lugar </mat-label>
                    <mat-select
                      formControlName="establecimiento"
                      (selectionChange)="cargarConsultorios($event.value, null)"
                      required
                    >
                      <!-- <mat-option
                        value=""
                        routerLink="/agregarEstablecimiento"
                        class="font-weight-bold"
                      >
                        <b>Agregar establecimiento</b>
                      </mat-option> -->
                      <mat-option
                        *ngFor="let sucur of sucursales"
                        [value]="sucur.id"
                        [disabled]="sucur.id == 1"
                      >
                        {{ sucur.nombre | toTitleCase }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <div
                    class="alert alert-danger p-1 m-1"
                    *ngIf="
                      agendaForm.get('establecimiento').hasError('required') &&
                      (agendaForm.get('establecimiento').touched || submitted)
                    "
                  >
                    <p class="p-0 m-0">Debe seleccionar una sucursal</p>
                  </div>
                </div>

                <div
                  class="
                    form-group
                    col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4
                  "
                  *ngIf="respuestaConsultorio !== undefined"
                >
                  <mat-form-field>
                    <mat-label>Consultorio</mat-label>
                    <mat-select
                      formControlName="consultorio"
                      (selectionChange)="seleccionoConsultorio($event.value)"
                      required
                    >
                      <!-- <mat-option value="" class="font-weight-bold">
                        <b>Agregar consultorio</b>
                      </mat-option> -->
                      <span *ngIf="respuestaConsultorio.ok">
                        <mat-option
                          *ngFor="
                            let consu of respuestaConsultorio.consultorios
                          "
                          [value]="consu.id"
                        >
                          {{ consu.descripcion | toTitleCase }}
                        </mat-option>
                      </span>
                    </mat-select>
                  </mat-form-field>
                  <div
                    class="alert alert-danger p-1 m-1"
                    *ngIf="
                      agendaForm.get('consultorio').hasError('required') &&
                      (agendaForm.get('consultorio').touched || submitted) &&
                      respuestaConsultorio.ok
                    "
                  >
                    <p class="p-0 m-0">Debe seleccionar un consultorio</p>
                  </div>

                  <div
                    class="alert alert-danger p-1 m-1"
                    *ngIf="!respuestaConsultorio.ok"
                  >
                    <p class="p-0 m-0">
                      La sucursal no posee consultorios debe crear uno con la
                      opcion agregar consultorio
                    </p>
                  </div>
                </div>
                <div
                  class="
                    form-group
                    col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4
                  "
                >
                  <mat-form-field>
                    <mat-label>Especialidad</mat-label>
                    <mat-select formControlName="codEspecialidad" required>
                      <mat-option
                        *ngFor="let espe of especialidades"
                        [value]="espe.codigo"
                      >
                        {{ espe.nombre | toTitleCase }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <mat-spinner
                    class="d-inline"
                    diameter="15"
                    *ngIf="cargando"
                  ></mat-spinner>
                  <div
                    class="alert alert-danger p-1 m-1"
                    *ngIf="
                      agendaForm.get('codEspecialidad').hasError('required') &&
                      (agendaForm.get('codEspecialidad').touched || submitted)
                    "
                  >
                    <p class="p-0 m-0">Debe seleccionar una especialidad</p>
                  </div>
                </div>
              </div>
              <button
                type="button"
                class="button"
                mat-stroked-button
                (click)="mostrarMas = !mostrarMas"
              >
                <mat-icon>visibility</mat-icon> más opciones
              </button>
              <div *ngIf="mostrarMas">
                <div class="form-row">
                  <div
                    class="
                      form-group
                      col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4
                    "
                  >
                    <mat-form-field>
                      <input
                        matInput
                        type="number"
                        formControlName="cantidadTurnos"
                        placeholder="Cantidad de turnos"
                        (change)="setCantidadTurnos()"
                        min="1"
                        required
                      />
                      <!-- 
                      *ngIf="
                        this.agendaForm.get('hastaHora') &&
                        this.agendaForm.get('desdeHora') &&
                        agendaForm.get('duracionTurno')
                      "
                      
                     -->
                    </mat-form-field>
                    <div
                      class="alert alert-danger p-1 m-1 align-middle"
                      *ngIf="
                        agendaForm.get('cantidadTurnos').hasError('required') &&
                        (agendaForm.get('cantidadTurnos').touched || submitted)
                      "
                    >
                      <p class="p-0 m-0">Debe ingresar la cantidad de turnos</p>
                    </div>
                    <div
                      class="alert alert-warning p-1 m-1 align-middle"
                      *ngIf="
                        agendaForm.get('cantidadTurnos').value &&
                        agendaForm.get('cantidadTurnos').value % 1 !== 0
                      "
                    >
                      <p class="p-0 m-0">
                        Va a tener tiempo libre entre turnos
                      </p>
                    </div>
                  </div>

                  <div
                    class="
                      form-group
                      col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4
                    "
                  >
                    <mat-form-field>
                      <input
                        matInput
                        type="number"
                        formControlName="diasAnticipacion"
                        placeholder="Dias de anticipacion de turnos"
                        min="0"
                        required
                      />
                    </mat-form-field>
                    <div
                      class="alert alert-danger p-1 m-1 align-middle"
                      *ngIf="
                        agendaForm
                          .get('diasAnticipacion')
                          .hasError('required') &&
                        (agendaForm.get('diasAnticipacion').touched ||
                          submitted)
                      "
                    >
                      <p class="p-0 m-0">Debe ingresar la duración de turnos</p>
                    </div>
                  </div>
                  <div
                    class="
                      form-group
                      col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4
                    "
                  >
                    <mat-form-field>
                      <mat-label>Servicio</mat-label>
                      <mat-select formControlName="servicio" required>
                        <mat-option value="1"> Consultorio </mat-option>
                      </mat-select>
                    </mat-form-field>
                    <div
                      class="alert alert-danger p-1 m-1"
                      *ngIf="
                        agendaForm.get('servicio').hasError('required') &&
                        (agendaForm.get('servicio').touched || submitted)
                      "
                    >
                      <p class="p-0 m-0">Debe seleccionar un servicio</p>
                    </div>
                  </div>
                </div>
                <div
                  class="
                    row
                    justify-content-md-center
                    d-flex
                    justify-content-center
                  "
                >
                  <div
                    class="
                      form-group
                      col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6
                    "
                  >
                    <label for="habilitadoTurnosWeb"
                      >Publicar en sitio de pacientes
                    </label>
                    <input
                      style="width: 1.5em; height: 1.5em; display: initial"
                      type="radio"
                      formControlName="habilitadoTurnosWeb"
                      class="form-control align-middle ml-1"
                      [ngClass]="{
                        'is-invalid':
                          submitted &&
                          agendaForm.get('habilitadoTurnosWeb').hasError()
                      }"
                      value="N"
                    />
                    <span class="align-middle p-1">No</span>
                    <input
                      style="width: 1.5em; height: 1.5em; display: initial"
                      type="radio"
                      formControlName="habilitadoTurnosWeb"
                      class="form-control align-middle"
                      [ngClass]="{
                        'is-invalid':
                          submitted &&
                          agendaForm.get('habilitadoTurnosWeb').hasError()
                      }"
                      value="S"
                    />
                    <span class="align-middle p-1">Si</span>

                    <div
                      *ngIf="
                        submitted &&
                        agendaForm.get('habilitadoTurnosWeb').hasError()
                      "
                      class="invalid-feedback"
                    >
                      <div
                        *ngIf="
                          agendaForm
                            .get('habilitadoTurnosWeb')
                            .hasError('required')
                        "
                      >
                        Este campo es requerido
                      </div>
                    </div>
                  </div>
                </div>
                <!-- 
                  servicio desde la base
                  <div
                  class="form-group col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4"
                >
                  <mat-form-field>
                    <mat-label>Servicio</mat-label>
                    <mat-select formControlName="servicio" required>
                      <mat-option
                        *ngFor="let servi of respuestaServicios?.servicios"
                        [value]="servi.id"
                      >
                        {{ servi.n | toTitleCase }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <div
                    class="alert alert-danger p-1 m-1"
                    *ngIf="
                      agendaForm.get('servicio').hasError('required') &&
                      agendaForm.get('servicio').touched
                    "
                  >
                    <p class="p-0 m-0">Debe seleccionar un servicio</p>
                  </div>
                </div> -->
              </div>
            </mat-card-content>
            <mat-card-actions class="p-0 m-0">
              <div>
                <button
                  mat-raised-button
                  color="warn"
                  type="button"
                  class="btn btn-primary btn"
                  style="margin: 2px"
                  (click)="cancelar()"
                >
                  Cancelar
                </button>
                <button
                  mat-raised-button
                  color="primary"
                  class="btn btn-primary btn"
                  style="margin: 2px"
                  type="submit"
                  (click)="onSubmit()"
                >
                  Confirmar
                </button>
              </div>
            </mat-card-actions>
          </form>
        </mat-card>
      </div>
    </div>
  </div>
</div>

<!-- <mat-spinner
                diameter="22"
                [hidden]="!loading"
                style="display: inline-block; margin-left: 5%; margin-top: 1%"
              ></mat-spinner> -->
