<div *ngIf="cargado">
  <form
    class="ui form"
    [formGroup]="agendaCerrarForm"
    enctype="multipart/form-data"
  >
    <h1 mat-dialog-title>Cancelación de turnos</h1>
    <mat-card-subtitle class="mb-2">
      Puede cancelar la totalidad de turnos o una franja
      horaria</mat-card-subtitle
    >
    <div class="p-1">
      <mat-form-field class="d-flex justify-content-center">
        <mat-label>Desde hora</mat-label>
        <mat-select
          formControlName="ordenDesde"
          (selectionChange)="onChangeHoras()"
        >
          <mat-option
            *ngFor="let iteracion of this.horas; let i = index"
            [value]="i + 1"
          >
            {{ iteracion }}
          </mat-option>
        </mat-select>
        <mat-error
          *ngIf="
            agendaCerrarForm.get('ordenDesde').hasError('required') &&
            (agendaCerrarForm.get('ordenDesde').touched || submitted)
          "
          >Debe seleccionar una hora desde</mat-error
        >
        <mat-error
          *ngIf="
            agendaCerrarForm.get('ordenDesde').hasError('horaDesdeInvalida') &&
            (agendaCerrarForm.get('ordenDesde').touched || submitted)
          "
          >La hora de inicio debe ser inferior a la de fin</mat-error
        >
      </mat-form-field>
    </div>
    <div class="p-1">
      <mat-form-field class="d-flex justify-content-center">
        <mat-label>Hasta hora</mat-label>

        <mat-select
          formControlName="ordenHasta"
          (selectionChange)="onChangeHoras()"
        >
          <mat-option
            *ngFor="let iteracion of this.horas; let i = index"
            [value]="i + 1"
          >
            {{ iteracion }}
          </mat-option>
        </mat-select>

        <mat-error
          *ngIf="
            agendaCerrarForm.get('ordenHasta').hasError('required') &&
            (agendaCerrarForm.get('ordenHasta').touched || submitted)
          "
          >Debe seleccionar una hora hasta</mat-error
        >
        <mat-error
          *ngIf="
            agendaCerrarForm.get('ordenHasta').hasError('horaHastaInvalida') &&
            (agendaCerrarForm.get('ordenHasta').touched || submitted)
          "
          >La hora de fin debe ser superior a la de inicio</mat-error
        >
      </mat-form-field>
    </div>
    <div class="p-1">
      <mat-form-field class="d-flex justify-content-center">
        <mat-label>Motivo de la cancelación</mat-label>

        <input matInput type="text" formControlName="motivo" required />

        <mat-error
          *ngIf="
            agendaCerrarForm.get('motivo').hasError('required') &&
            (agendaCerrarForm.get('motivo').touched || submitted)
          "
          >Debe ingresar un motivo</mat-error
        >
      </mat-form-field>
    </div>
    <div mat-dialog-actions class="d-flex justify-content-center">
      <button mat-raised-button color="primary" (click)="cerrarDialogo()">
        cancelar
      </button>
      <button mat-raised-button color="warn" (click)="confirmado()">
        confirmar
      </button>
      <mat-spinner class="d-inline" diameter="30" *ngIf="loading"></mat-spinner>
    </div>
  </form>
</div>
