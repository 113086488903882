
<button mat-icon-button style="float: right;top:-24px;right:-24px;" [mat-dialog-close]="false">
    <mat-icon class="close-icon" color="warn">close</mat-icon>
</button>

<h1 mat-dialog-title style="padding-left: 0%;padding-right: 3.5%;">Seleccionar Cobertura</h1>

<div class="d-flex justify-content-center" style="margin-top:10%;" *ngIf="loadingCoberturas">
    <mat-spinner [diameter]="30"></mat-spinner>
</div>

<div style="text-align: center; margin-top: 3%;" [hidden]="loadingCirugias" *ngIf="errorNoMutuales">
    <mat-card class="sin-elementos-card" style="width: 85%;">
      <h5 style="color:#3F51B5">Ocurrió un error al recuperar las mutuales del paciente</h5>
    </mat-card>
  </div>

<div mat-dialog-content *ngIf="!errorNoMutuales">

    <div class="row"  *ngIf="!loadingCoberturas">
        <form [formGroup]="selectCoberturaForm" style="width: 280px; margin-left: 15px;" *ngIf="!loadingCoberturas">
            <mat-form-field appearance="outline" class="filtros" id="instrumentistas" style="max-width: 280px;">
                <mat-label>Cobertura</mat-label>
                <mat-select  formControlName="cobertura">
                    <mat-option *ngFor="let cobertura of this.paciente.coberturas" [value]="cobertura.mutual">
                        {{ cobertura.nombreMutual | toTitleCase }} | {{ cobertura.nroCarnet }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </form>
        <mat-action-list style="width: 26%;text-align: right;margin-top: 5px;margin-right: 6px;" *ngIf="!loadingCoberturas">
            <button mat-icon-button (click)="openConfirmarTurno()" color="primary"> 
                    <mat-icon style="font-size:25px;">insert_invitation</mat-icon>
            </button>
        </mat-action-list>
    </div>
    
    <div class="row" style="margin-bottom: 15px;">
        <button mat-raised-button class="agregar-button"
                  matTooltip="Agregar cobertura"
                  (click)="mostrarMutuales()"
                  style="margin-left: 15px;"
                  *ngIf="!crearCobertura && !loadingCoberturas">  <!--  TAMBIEN PONERLE EL LOADING A ESTOS BOTONES  -->
                  <mat-icon>add</mat-icon> 
        </button>

        <button [disabled]="this.particular" mat-raised-button class="modificar-button"
                  matTooltip="Editar cobertura"
                  (click)="mostrarEditarCobertura()"
                  *ngIf="!loadingCoberturas"
                  style="margin-left: 15px;">
                  <mat-icon>edit</mat-icon>
        </button>

        <button [disabled]="this.particular" mat-raised-button class="eliminar-button"
                  matTooltip="Eliminar cobertura"
                  (click)="dialogConfirmacionEliminarCobertura()"
                  *ngIf="!loadingCoberturas"
                  style="margin-left: 15px;">
                  <mat-icon>delete</mat-icon>  
        </button>
    </div>
    
    <div *ngIf="crearCobertura">

        <mat-divider class="divisor" style="margin-top: 5%; margin-bottom: 10px;"></mat-divider> 

        <span style="margin-bottom: 10px;">
            <h6>
              Agregar cobertura
            </h6>
        </span>

        <div class="d-flex justify-content-center" style="margin-top:10%; margin-bottom: 10px;" *ngIf="loadingMutuales">
            <mat-spinner [diameter]="30"></mat-spinner>
        </div>

        <div *ngIf="errorMutuales">
            <div style="text-align: center; margin-top: 5%; margin-bottom: 2%;" >
              <mat-card class="sin-elementos-card">
                <h5 style="color:#d30225">Ocurrió un error al recuperar las Mutuales.</h5>
              </mat-card>
            </div>
        
          </div>
        
        <div *ngIf="!errorMutuales">
            <form [formGroup]="crearCoberturaForm" *ngIf="!loadingMutuales" style="width: 350px; margin-left: 15px;">
                <div class="row">
                        <mat-form-field appearance="outline" class="filtros" id="instrumentistas" style="max-width: 280px;">
                            <mat-label>Mutual</mat-label>
                            <mat-select  formControlName="mutual">
                                <mat-option *ngFor="let mutual of this.mutuales" [value]="mutual.codigo">
                                    {{ mutual.nombre | toTitleCase }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <div class="row">
                            <mat-form-field style="width: 190px; margin-left: 17px; margin-right: 15px;">
                                <input matInput placeholder="Número de afiliado" 
                                  formControlName="nroAfiliado" required maxlength="20">
                            </mat-form-field>
    
                            <button [disabled]="this.camposVacios || this.cargandoCobertura" (click)="agregarCobertura()" class="agregar-button" mat-raised-button style="width: 38%;height: 33px;top: 13px;">
                                Añadir cobertura
                            </button>
                        </div>
                        

                </div>

            </form>

        </div>

    </div>

    <div *ngIf="modificarCobertura">

        <mat-divider class="divisor" style="margin-top: 5%; margin-bottom: 10px;"></mat-divider> 

        <span style="margin-bottom: 10px;">
            <h6>
              Modificar cobertura
            </h6>
        </span>

        <div class="d-flex justify-content-center" style="margin-top:10%; margin-bottom: 10px;" *ngIf="loadingEditandoCobertura">
            <mat-spinner [diameter]="30"></mat-spinner>
        </div>

        <form [formGroup]="modificarCoberturaForm" *ngIf="!loadingEditandoCobertura" style="width: 350px; margin-left: 15px;">
            <div class="row">
                <mat-form-field appearance="outline" class="filtros">
                    <mat-label>Mutual</mat-label>
                        <mat-select [(value)]="this.mutualAEditar.mutual" [placeholder]="this.mutualAEditar.nombreMutual"  formControlName="mutual">
                            <mat-option [value]="this.mutualAEditar.mutual">
                                {{ mutualAEditar.nombreMutual | toTitleCase }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <div class="row">
                        <mat-form-field style="width: 190px; margin-left: 17px; margin-right: 15px;">
                            <mat-label>Número de afiliado</mat-label>
                            <input matInput placeholder="Número de afiliado" 
                                formControlName="nroAfiliado" required maxlength="20">
                        </mat-form-field>
    
                        <button [disabled]="this.campoEditarVacio || this.modificarCoberturaForm.pristine" (click)="dialogConfirmacionEditarCobertura()" class="agregar-button" mat-raised-button style="width: 41%;height: 33px;top: 13px;">
                            Modificar cobertura
                        </button>
                    </div>
            </div>

        </form>

    </div>

</div>

