import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../_services/authentication.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { AlertService } from '../_services/alert.service';
import { MatDialog } from '@angular/material/dialog';
import { DialogRecuperarContrasenaComponent } from '../dialog-recuperar-contrasena/dialog-recuperar-contrasena.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})

export class LoginComponent implements OnInit {

  submitted = false;
  loading = false;
  loginForm: UntypedFormGroup;
  showPass: boolean = false;
  mayusActivado;
  press = 0;
  intervalId;
  time = new Date();
  
  constructor(
    private formBuilder: UntypedFormBuilder,
    private alertService: AlertService,
    private router: Router,
    private authenticationService: AuthenticationService,
    private dialog: MatDialog, 
  ) {
    if (this.authenticationService.currentUserValue) {
      this.router.navigate(['/agenda']);
    }
  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
    });

   window.addEventListener('keydown', this.mayusActivo);
  }

  recuperarClave() {
    this.dialog.open(DialogRecuperarContrasenaComponent, {
      width: '500px',
      hasBackdrop: true
    })
  }

  get f() {
    return this.loginForm.controls;
  }

  password() {
      this.showPass = !this.showPass;
  }
  mayusActivo(event:KeyboardEvent){

    if(event.getModifierState('CapsLock'))
    {
      document.getElementById('mayus').style.display = 'block';
          
    }
    else{
      document.getElementById('mayus').style.display = 'none';
    }
   }

 
  onSubmit() {
    
    this.submitted = true;
    this.alertService.clear();
    if (this.loginForm.invalid) {
      return;
    }
    this.loading = true;
    this.authenticationService
      .login(this.f.username.value, this.f.password.value)
      .pipe(first())
      .subscribe(
        (data) => {
          if (data.ok) {
            sessionStorage.setItem('cerroPopup', 'false');
            if (localStorage.getItem('dniPaciente') != undefined) 
              this.router.navigate(['/listar-episodios'+'/'+localStorage.getItem('dniPaciente')]);
            else
              this.router.navigate(['/agenda']);
          } else {
            this.alertService.error('Usuario y contraseña incorrectos');
            this.loading = false;
          }
        },
        (error) => {
          console.log(error);

          this.loading = false;
          this.alertService.error(
            'Se produjo un error, por favor intente mas tarde'
          );
        }
      );
  }

  convertirEnMinusculas(){
    this.f.username.setValue(this.f.username.value.toLowerCase());
  }
}
