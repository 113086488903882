<mat-spinner [hidden]="!cargando" style="margin: auto;">
</mat-spinner>

<div class="container" [hidden]="cargando">
    <div class="row">
        <h1 mat-dialog-title>Datos del Paciente</h1><br>

        <div class="container">
            <p><span style="font-weight: 700;">Apellidos: </span>{{ datosPaciente.apellido }}</p>
            <p><span style="font-weight: 700;">Nombres: </span>{{ datosPaciente.nombre }}</p>
            <p><span style="font-weight: 700;">DNI: </span>{{ datosPaciente.nroDocumento }}</p>
            <p><span style="font-weight: 700;">Fecha de Nacimiento: </span>{{ datosPaciente.fechaNacimiento }}</p>
            <p><span style="font-weight: 700;">Edad: </span>{{ datosPaciente.edad }}</p>
            <p><span style="font-weight: 700;">Tel&eacute;fono: </span>{{ datosPaciente.telefono }}</p>
            <p><span style="font-weight: 700;">Direcci&oacute;n: </span>{{ datosPaciente.domicilio }}</p>
            <p><span style="font-weight: 700;">Localidad: </span>{{ datosPaciente.localidad }}</p>
            <p><span style="font-weight: 700;">Mail: </span>{{ datosPaciente.email }}</p>
        </div>

    </div>
    <div class="row" style="border: 0px solid red;">
        <h1 mat-dialog-title>Información del turno</h1><br>

        <div class="container">
            <p><span style="font-weight: 700;">Mutual: </span>{{ data.nombreMutual }}</p>
            <p><span style="font-weight: 700;">Nro. de Afiliado: </span>{{ data.nroAfiliado }}</p>
            <p><span style="font-weight: 700;">Nro. de Bono: </span>
                <span *ngIf="data.numeroBono">{{ data.numeroBono }}</span>
                <span *ngIf="!data.numeroBono">No disponible</span>
            </p>
            <p><span style="font-weight: 700;">Importe: </span>
                <span *ngIf="reciboAphe.importe">$ {{ reciboAphe.importe }}</span>
                <span *ngIf="!reciboAphe.importe">No disponible</span>
            </p>
            <p><span style="font-weight: 700;">Recibo APHE: </span>
                <a *ngIf="existeRecAphe" href="{{ urlPdf }}" target="_blank">Ver</a>
                <span *ngIf="!existeRecAphe">No disponible</span>
            </p>
        </div>
    </div>

    <div class="row" style="border: 0px solid blue; float: right;">
        <button mat-raised-button class="mb-2" color="warn" [mat-dialog-close]="false">Cerrar</button>
    </div>
</div>