import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppComponent } from '../app.component';

@Injectable({
  providedIn: 'root'
})

export class InternacionService {
  
  baseUrl: string;

  constructor(
    private http: HttpClient
  ) {
    this.baseUrl = AppComponent.baseUrl;
  }

  obtenerPabellones() {
    return this.http.get<any>(this.baseUrl + '/internacion/pabellones')
  }

  obtenerCamasPorPabellon(idPabellon){
    return this.http.get<any>(this.baseUrl + '/camas/getCamasPorPabellon/'+ idPabellon)
  }
}
