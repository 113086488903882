import { Component, OnInit, Inject } from "@angular/core";
import { PacienteService } from "../_services/paciente.service";
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: "app-paciente-bio",
  templateUrl: "./paciente-bio.component.html",
  styleUrls: ["./paciente-bio.component.css"],
})
export class PacienteBioComponent implements OnInit {
  currentPatient: any;
  patientData: any;

  constructor(
    private pacienteService: PacienteService,
    public dialogRef: MatDialogRef<PacienteBioComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {

  }

  ngOnInit() {
    this.currentPatient = this.pacienteService.getCurrentPatient(); 
    this.loadPatient();
    
  }

  loadPatient(){
    if (this.currentPatient) {
      this.pacienteService
        .datosPaciente(
          this.currentPatient.numeroDoc,
          this.currentPatient.tipoDoc
        )
        .subscribe(
          (data) => {
            this.patientData = data;
          },
          (error) => {
            console.log(error);
          }
        );
    }
  }
}
