<h1 mat-dialog-title class="text-center mb-1">Agregar consultorio</h1>
<div mat-dialog-content>
  <form
    class="ui form"
    [formGroup]="consultorioForm"
    enctype="multipart/form-data"
  >
    <div>
      <mat-form-field>
        <!-- <mat-label>Sucursal</mat-label> -->
        <mat-select formControlName="sucursalId">
          <mat-option *ngFor="let sucursal of sucursales" [value]="sucursal.id">
            {{ sucursal.nombre }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <div
        class="alert alert-danger p-1 m-1"
        *ngIf="
          consultorioForm.get('sucursalId').hasError('required') &&
          (consultorioForm.get('sucursalId').touched || submitted)
        "
      >
        <p class="m-0 p-0">Debe seleccionar sucursal</p>
      </div>
      <mat-form-field>
        <input
          matInput
          type="text"
          formControlName="descripcion"
          placeholder="Nombre del consultorio"
          required
        />
      </mat-form-field>
      <div
        class="alert alert-danger p-1 m-1"
        *ngIf="
          consultorioForm.get('descripcion').hasError('required') &&
          (consultorioForm.get('descripcion').touched || submitted)
        "
      >
        <p class="m-0 p-0">Debe ingresar el nombre de consultorio</p>
      </div>
    </div>

    <div mat-dialog-actions class="justify-content-center mb-1">
      <button mat-raised-button color="primary" (click)="cancelar()">
        cancelar
      </button>
      <button mat-raised-button color="warn" (click)="onSubmit()">
        confirmar
      </button>
    </div>
    <mat-spinner
      diameter="22"
      [hidden]="!loading"
      style="display: inline-block; margin-left: 5%; margin-top: 1%"
    ></mat-spinner>
  </form>
</div>
