import { Component, Inject, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';
import * as moment from 'moment';
import { DialogConfirmacionComponent } from '../dialog-confirmacion/dialog-confirmacion.component';
import { DialogMensajeComponent } from '../dialog-mensaje/dialog-mensaje.component';
import { PacienteService } from '../_services/paciente.service';

function isValidMaxDate(c: UntypedFormControl) {
  if (new Date(c.value).getTime() > new Date().getTime()) {
    return { maxDate: true };
  } else {
    return;
  }
}

function isValidMinDate(c: UntypedFormControl) {
  if (
    new Date(c.value).getTime() < new Date(1900, 1, 1, 0, 0, 0, 0).getTime()
  ) {
    return { minDate: true };
  } else {
    return;
  }
}
@Component({
  selector: 'app-dialog-editar-datos-paciente',
  templateUrl: './dialog-editar-datos-paciente.component.html',
  styleUrls: ['./dialog-editar-datos-paciente.component.css'],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'es_AR' },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],
})
export class DialogEditarDatosPacienteComponent implements OnInit {
  editarPacienteForm: UntypedFormGroup;
  submitted = false;
  loading = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private form: UntypedFormBuilder,
    public dialog: MatDialog,
    public dialogo: MatDialogRef<DialogEditarDatosPacienteComponent>,
    private pacienteService: PacienteService
  ) {}

  ngOnInit() {
    this.buildFormEditarPaciente();
  }
  buildFormEditarPaciente() {
    this.editarPacienteForm = this.form.group({
      numeroDocumento: [this.data.nroDocumento, Validators.required],
      tipoDocumento: [this.data.tipoDocumento, Validators.required],
      mail: [
        this.data.email,
        [
          Validators.required,
          Validators.email,
          Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'),
        ],
      ],
      telefono: [
        this.data.telefono,
        [
          Validators.required,
          Validators.maxLength(20),
          Validators.minLength(7),
          Validators.pattern('[0-9 ]*'),
        ],
      ],
      fechaNacimiento: [
        moment(this.data.fechaNacimiento, 'DD/MM/YYYY').toDate(),
        [Validators.required, isValidMaxDate, isValidMinDate],
      ],
      sexo: [this.data.sexo, Validators.required],
      domicilio: [
        this.data.domicilio,
        [Validators.required, Validators.pattern('[a-zA-Z0-9 ]*')],
      ],
      localidad: [
        this.data.localidad,
        [Validators.required, Validators.pattern('[a-zA-Z0-9 ]*')],
      ],
    });
  }

  get f() {
    return this.editarPacienteForm.controls;
  }
  cerrarDialogo(): void {
    this.dialogo.close(false);
  }
  onSubmit() {
    this.submitted = true;
    if (this.editarPacienteForm.valid) {
      if (this.editarPacienteForm.dirty) {
        this.loading = true;
        this.pacienteService
          .editPaciente(this.editarPacienteForm.value)
          .subscribe(
            (res) => {
              this.loading = false;
              const dialogRef = this.dialog.open(DialogMensajeComponent, {
                width: '500px',
                data: res,
              });
              dialogRef.afterClosed().subscribe(() => {
                if (res.ok) {
                  this.dialogo.close(res.ok);
                  // this.dialog.closeAll();
                }
              });
            },
            (error) => {
              console.log('error' + error);
            }
          );
      } else {
        const dialogRef = this.dialog.open(DialogMensajeComponent, {
          width: '500px',
          data: {
            ok: false,
            mensaje: 'Debe modificar algun dato para confirmar.',
          },
        });
      }
    }
  }
  public cancelar() {
    this.dialog
      .open(DialogConfirmacionComponent, {
        data: `¿Seguro desea cancelar la modificación del paciente?`,
      })
      .afterClosed()
      .subscribe((confirmado: boolean) => {
        if (confirmado) {
          this.dialogo.close(false);
          // this.dialog.closeAll();
        }
      });
  }
}
