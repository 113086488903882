<div class="container-fluid" style="margin-top: 1%;">
    <mat-card [tabindex]="0">
        <div class="container-fluid">
            <h3>Turnos anulados</h3>
            <div 
                class="d-flex justify-content-center"
                style="margin-top: 10%;"
                *ngIf="loading">
                <mat-spinner [diameter]="60"></mat-spinner>
            </div>

            <div [hidden]="loading" class="row" style="width: 70%;">
                <div class="col"> 
                    <form [formGroup]="filtroBusquedaForm" >
                        <div class="row" >
                            <div class="col-xs-8 col-sm-8 col-md-4 col-lg-3 col-xl-3">
                                <mat-form-field style="width: 100%">
                                    <mat-label>Turnos anulados correspondientes al día:</mat-label>
                                    <input matInput formControlName="fechaTurno"  [matDatepicker]="picker">
                                    <mat-datepicker-toggle matSuffix [for]="picker" matTooltip="Seleccionar fecha"></mat-datepicker-toggle>
                                    <mat-datepicker #picker ></mat-datepicker>
                                </mat-form-field>
                            </div>

                            <div class="col-xs-8 col-sm-8 col-md-4 col-lg-3 col-xl-3" style="text-align: center ">
                                <p>
                                    <mat-form-field>
                                        <mat-label>Profesionales</mat-label>
                                        <mat-select formControlName="matricula" style="width: 100%; margin-left: 15px">
                                            <mat-option>
                                                <ngx-mat-select-search placeholderLabel="Buscar profesional" noEntriesFoundLabel="No se encontraron profesionales" [formControl]="bankMultiFilterCtrl"> <!---->
                                                </ngx-mat-select-search>
                                            </mat-option>
                                            <mat-option *ngFor="let profesional of profesionalesFiltrados" [value]="profesional">
                                                {{ profesional.apellido | toTitleCase }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </p>
                            </div>

                            <div class="col-xs-8 col-sm-8 col-md-4 col-lg-3 col-xl-2">
                                <button mat-raised-button 
                                    color="primary" 
                                    (click)="buscarTurnosAnulados()" 
                                    style="border-radius: 20px;">
                                    <mat-icon>search</mat-icon>
                                    Buscar turnos
                                </button>
                            </div>
                        </div> 
                    </form>
                </div>  
            </div> 


            <mat-card-content>
                <div  class="row" style="margin-top: 1%;">
                    <div [hidden]="loading" class="col" style="overflow: auto;">
                    
                        <div style="text-align: center; margin-top: 5%;  " *ngIf="sinTurnos">
                            <mat-card class="sin-elementos-card">
                            <h5 style="color:#3F51B5">No se encontraron turnos anulados</h5>
                            </mat-card>
                        </div>
            
                        <mat-form-field *ngIf="!sinTurnos">
                            <mat-label>Buscar</mat-label>
                            <input matInput (keyup)="applyFilter($event)" autocomplete="off" />
                        </mat-form-field>
                        <span style="margin-left:1%;"></span>

                        <div class="contenedor-tabla">
                            <table
                                mat-table
                                [dataSource]="dataSourceItems"
                                class="mat-elevation-z8"
                                *ngIf="!sinTurnos"
                                style="width: 100%;"
                            >
                    
                                <!-- Columna Paciente  -->
                                <div style="text-align: center; margin-right: 1px">
                                    <ng-container matColumnDef="paciente">
                                        <th mat-header-cell *matHeaderCellDef>Paciente</th>
                                        <td mat-cell *matCellDef="let turno">
                                            {{ turno.paciente }} 
                                        </td>
                                    </ng-container>
                                </div>  
                    
                                <!-- Columna Profesional -->
                                <div style="text-align: center; margin-right: 1px">
                                    <ng-container matColumnDef="profesional">
                                        <th mat-header-cell *matHeaderCellDef>Profesional</th>
                                        <td mat-cell *matCellDef="let turno">
                                            {{ turno.profesional.nombreCompleto }} 
                                        </td>
                                    </ng-container>
                                </div> 
                        
                                <!-- Columna Hora -->
                                <div style="text-align: center; margin-right: 1px">
                                    <ng-container matColumnDef="hora">
                                        <th mat-header-cell *matHeaderCellDef>Horario</th>
                                        <td mat-cell *matCellDef="let turno">
                                            {{ turno.desdeHora }} 
                                        </td>
                                    </ng-container>
                                </div> 
                    
                                <!-- Columna Orden  -->
                                <div style="text-align: center; margin-right: 1px">
                                    <ng-container matColumnDef="orden">
                                        <th mat-header-cell *matHeaderCellDef>Orden</th>
                                        <td mat-cell *matCellDef="let turno">
                                            {{ turno.orden }} 
                                        </td>
                                    </ng-container>
                                </div> 
                    
                                <!-- Columna Telefono -->
                                <div style="text-align: center; margin-right: 1px">
                                    <ng-container matColumnDef="telefono">
                                        <th mat-header-cell *matHeaderCellDef>Telefono</th>
                                        <td mat-cell *matCellDef="let turno">
                                            {{ turno.telefono }} 
                                        </td>
                                    </ng-container>
                                </div> 
                        
                                <!-- Columna Usuario que elimino el turno -->
                                <div style="text-align: center; margin-right: 1px">
                                    <ng-container matColumnDef="usuarioAnulo">
                                        <th mat-header-cell *matHeaderCellDef>Usuario que anuló</th>
                                        <td mat-cell *matCellDef="let turno">
                                            {{ turno.usroElimino }} 
                                        </td>
                                    </ng-container>
                                </div> 
                        
                                <!-- Columna Fecha anulacion -->
                                <div style="text-align: center; margin-right: 1px">
                                    <ng-container matColumnDef="fechaAnulacion">
                                        <th mat-header-cell *matHeaderCellDef>Fecha de anulación</th>
                                        <td mat-cell *matCellDef="let turno">
                                            {{ turno.fechaAnulp }} 
                                        </td>
                                    </ng-container>
                                </div> 
                                <tr mat-header-row *matHeaderRowDef="displayedColumnasItems; sticky: true" ></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedColumnasItems"></tr>

                            </table>
                        </div>

                    </div>
                </div>
                
            </mat-card-content>
    
        </div>
    </mat-card>   
</div>