import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { User } from '../_models/user.model';
import { AuthenticationService } from '../_services/authentication.service';
import { PacienteService } from '../_services/paciente.service';
import { EmbedUrlsService } from '../_services/embedUrls.service';
import { SucursalService } from '../_services/sucursal.service';
import { NavBarServiceService } from '../_services/nav-bar-service.service'
import { MatDialog } from '@angular/material/dialog';
import { DialogCambiarContrasenaComponent } from '../dialog-cambiar-contrasena/dialog-cambiar-contrasena.component';
import { DialogCambiarCorreoComponent } from '../dialog-cambiar-correo/dialog-cambiar-correo.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})

export class HeaderComponent implements OnInit {
  //CREO EL OBSERVABLE QUE VA A CONSUMIR LOS CAMBIOS DE LOS ROLES
  navLinks: any[];
  activeLinkIndex = -1;
  currentUser: User;
  currentPatient;
  success;

  // userData: any;
  // userDataSubject: BehaviorSubject<any>;
  horusUrl: any;
  constructor(
    private pacienteService: PacienteService,
    private router: Router,
    private authenticationService: AuthenticationService,
    private sucursalService: SucursalService,
    private urlsService: EmbedUrlsService,
    private navBarService: NavBarServiceService,
    public dialog: MatDialog,
  ) {
    this.authenticationService.currentUser.subscribe((x) => {
      // console.log(x)
      this.currentUser = x;
      // if (this.currentUser != null) {
      //   let datos: any[] = [];
      //   this.authenticationService.currentUserValue.roles.forEach((rol) => {
      //     rol.perfiles.forEach((perfil) => {
      //       perfil.funciones.forEach((funcion) => {
      //         datos.push({
      //           label: funcion.nombreMenu,
      //           link: './' + funcion.url,
      //           index: funcion.id,
      //         });
      //       });
      //     });
      //   });

      //   datos = datos.filter(
      //     (v, i) => datos.findIndex((item) => item.link == v.link) === i
      //   );

      //   this.navLinks = datos.sort(function (a, b) {
      //     if (a.index > b.index) {
      //       return 1;
      //     }
      //     if (a.index < b.index) {
      //       return -1;
      //     }
      //     // a must be equal to b
      //     return 0;
      //   });
      //   this.urlsService.setUrls(this.navLinks);
      // }
    });

    this.pacienteService.currentPatient.subscribe((x) => {
      this.currentPatient = x;
    });

    // this.navLinks = [
    //   {
    //     label: 'Turnos',
    //     link: './agenda',
    //     index: 0,
    //   },
    //   {
    //     label: 'Agendas',
    //     link: './administracionAgendas',
    //     index: 0,
    //   },
    //   {
    //     label: 'Establecimientos',
    //     link: './administracionEstablecimientos',
    //     index: 0,
    //   },
    // ];
  }

  ngOnInit(): void {
    //ME SUBSCRIBO A LOS CAMBIOS EN EL OBSERVABLE
    // this.estadoLogueo$ = this.authenticationService.getEstadoLogueo$();
    // this.estadoLogueo$.subscribe(
    //   (estadoLogueo) => {
    //     this.estadoLogueo = estadoLogueo;
    //   },
    //   (error) => {}
    // );
    // if (this.currentUser != null) {
    //   this.router.events.subscribe((res) => {
    //     this.activeLinkIndex = this.navLinks.indexOf(
    //       this.navLinks.find((tab) => tab.link === '.' + this.router.url)
    //     );
    //   });
    // }
  }

  cambiarContrasena() {
    this.dialog.open(DialogCambiarContrasenaComponent, {
      width: '500px',
      data: this.currentUser,
      hasBackdrop: true,
    });
  }

  cambiarCorreo() {
    this.dialog.open(DialogCambiarCorreoComponent, {
      width: '500px',
      data: this.currentUser,
    })
  }

  logout() {
    this.currentPatient = null;
   
    this.authenticationService.logout();
    this.router.navigate(['/login']);
    
  }

  volver() {
    if (this.router.url.includes('/escribir-informe')) {
      this.router.navigate(['/paciente']);
    } else {
      this.pacienteService.removeCurrentPatient();
      this.sucursalService.removeCurrentSucursal();
      this.router.navigate(['/agenda']);
    }
  }

  toggleNavBar() {
    this.navBarService.toggle();
  }
  closeNavBar() {
    this.navBarService.close();
  }
  toggleSuccess() {
    this.success == !this.success;
  }
  
}
