import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatTable } from '@angular/material/table';
import { AuthenticationService } from '../_services/authentication.service';
import { EstudioService } from '../_services/estudios.service';
import { PacienteService } from '../_services/paciente.service';
import { map, startWith, take, takeUntil } from 'rxjs/operators';
import { Observable, Subject, of, throwError } from 'rxjs';
import { ReplaySubject } from 'rxjs/internal/ReplaySubject';
import { animate, state, style, transition, trigger } from '@angular/animations';
import * as moment from 'moment';
import { DialogPdfComponent } from '../dialog-pdf/dialog-pdf.component';
import { AlertService } from '../_services/alert.service';
import { DialogConfirmacionComponent } from '../dialog-confirmacion/dialog-confirmacion.component';
import { Router } from '@angular/router';
import { DialogIngresarItemComponent } from '../dialog-ingresar-item/dialog-ingresar-item.component';
import { SucursalService } from '../_services/sucursal.service';
import { EstadisticasService } from '../_services/estadisticas.service';
import { AgendaService } from '../_services/agenda.service';
import { TurnosService } from '../_services/turnos.service';

@Component({
  selector: 'app-dialog-solicitar-estudio',
  templateUrl: './dialog-solicitar-estudio.component.html',
  styleUrls: ['./dialog-solicitar-estudio.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})

export class DialogSolicitarEstudioComponent implements OnInit {
  solicitudEstudiosForm: UntypedFormGroup;
  farmaciaComercialForm: UntypedFormGroup;
  serviciosEfectores: any;
  estudiosServicioEfector: any[] = [];
  coberturasPaciente: any;
  financiadores: any;
  bankMultiFilterCtrl: UntypedFormControl = new UntypedFormControl();
  options: string[] = [];
  filteredOptions: Observable<string[]>;
  financiadoresPaciente: any;
  dataTablaItems: any[] = [];
  dataSourceItems = this.dataTablaItems;
  displayedColumnasItems: string[] = ['descripcion', 'action'];
  tipoRecetaGenerica = false;
  tipoRecetaFarmacia = false;
  @ViewChild(MatTable, { static: true }) table: MatTable<any>;
  correoPaciente = null; //
  enviarMail = false; // para que no se mande más la orden
  descMedicamento = '';
  unidadesMedicamento: any[] = [];
  dataSourceRecetasFarmacia;
  coberturaTurno: any;
  estadistica: any = {};

  protected _onDestroy = new Subject<void>();

  loadingPedidos = false;
  dataSource;
  dataSourceEstudios;
  dataSourceRecetas;
  columnsToDisplay = [
    { name: 'fecha', jsonName: 'fechast' },
    { name: 'profesional', jsonName: 'nombreProfesional' },
    { name: 'servicio', jsonName: 'nombreServicio' },
    { name: 'estado', jsonName: 'estado' },
    { name: 'Nro. pedido', jsonName: 'numero' },
    { name: 'acciones', jsonName: 'acciones' },
  ];
  expandedElement: any | null;
  columnsToDisplay2 = [
    'fecha',
    'profesional',
    'servicio',
    'estado',
    'Nro. pedido',
    'acciones',
  ];
  detailedInfo;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  public estudiosServicioEfectorFiltrados: ReplaySubject<any> =
    new ReplaySubject<any>(1);
  listaProfesionales: any[] = [];
  datosPaciente: any;
  public profSolicitanteFiltrado: ReplaySubject<any> =
    new ReplaySubject<any>(1);
  filterProfCtrl: UntypedFormControl = new UntypedFormControl();
  loadingSubmit;
  mutualSeleccionada: any = {};


  constructor(
    private formBuilder: UntypedFormBuilder,
    private estudiosService: EstudioService,
    private authService: AuthenticationService,
    private pacienteService: PacienteService,
    private estadisticaService: EstadisticasService,
    public dialog: MatDialog,
    public dialogInsertar: MatDialog,
    private alertService: AlertService,
    private router: Router,
    private sucursalService: SucursalService,
    public dialogo: MatDialogRef<DialogConfirmacionComponent>,
    private turnosService: TurnosService,
    @Inject(MAT_DIALOG_DATA) public data
  ) { }

  ngOnInit() {
    // console.log(this.data)
    this.estadistica.usuario = this.authService.currentUserValue.usuario;

    for (let i = 0; i < 100; i++) {
      this.unidadesMedicamento[i] = i + 1;
    }

    if (this.servicioRequierePedido()) {
      this.obtenerProfesionales();
    } else {
      this.cargarPedidos();
    }

    this.initAndListenForms();
    this.listarServicios();
    this.getMutualesPaciente();
  }

  getMutualesPaciente() {
    this.pacienteService.mutualesDePaciente(this.data.patientData.nroDocumento, this.data.patientData.tipoDocumento).subscribe((data) => {
      this.coberturasPaciente = data.paginador.elementos;
      // console.log(this.coberturasPaciente);

      this.cargarFinanciadores();      
    }, (error) => {
      console.log(error);
    });
  }

  listarServicios() {
    // El sector_turno 23 trae estudios DxI, Farmacia Comercial y Otro
    this.estudiosService.listarServiciosEfectores(23).subscribe((data) => {
      // console.log(data.paginador.elementos);
      this.serviciosEfectores = data.paginador.elementos;
      if (this.servicioRequierePedido()) {
        this.solicitudEstudiosForm.controls['servicioEfector'].setValue(this.serviciosEfectores.find(s => Number.parseInt(s.codigo) == Number.parseInt(this.data.servicio.idServicio)));
        this.solicitudEstudiosForm.controls['servicioEfector'].disable();
      }
    }, (error) => {
      console.log(error);
    });
  }

  initAndListenForms() {
    this.bankMultiFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterBanksMulti();
      });

    this.filterProfCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filtrarProfSolicitante();
      });

    this.solicitudEstudiosForm = this.formBuilder.group({
      paciente: this.servicioRequierePedido() ? 
        [ { value: this.data.patientData.apellido, disabled: true }, Validators.required ] : 
        [ { value: this.data.patientData.apellido , disabled: true }, Validators.required ],
      dni: [ { value: this.data.patientData.nroDocumento, disabled: true }, Validators.required ],
      diagnostico: [ null, Validators.required ],
      financiador: this.servicioRequierePedido() ? [ null ] : [ null, Validators.required ],
      nroCarnet: this.servicioRequierePedido() ? [ null ] : [ null, Validators.required ],
      servicioEfector: [ null, Validators.required],
      estudios: [[]],
      profesionalSolicitante: this.servicioRequierePedido() ? [ null, Validators.required ] : [ null ]
    });

    this.farmaciaComercialForm = this.formBuilder.group({
      unidadMedicamento: [{ value: 1, disabled: false }, Validators.required],
      tratamiento: [null],
    });

    this.solicitudEstudiosForm.get('servicioEfector').valueChanges.subscribe((servicio) => {
      // console.log(servicio)
      if (this.solicitudEstudiosForm.get('servicioEfector').value.nombre !== 'OTRO') {
        this.tipoRecetaGenerica = false;
        this.solicitudEstudiosForm.controls.estudios.enable();

        if (this.solicitudEstudiosForm.get('servicioEfector').value.nombre !=='FARMACIA COMERCIAL') {
          this.tipoRecetaFarmacia = false;
          const datosFiltroEstudios = {
            servicio: servicio.codigo,
            estudio: '-1', // trae estudios de DxI
          };
          this.solicitudEstudiosForm.controls.estudios.enable();
          this.solicitudEstudiosForm.get('estudios').setValue([]);
          this.estudiosService.listarEstudiosDeServicioEfector(datosFiltroEstudios).subscribe((data) => {
            this.estudiosServicioEfector = data.paginador.elementos;
            this.estudiosServicioEfectorFiltrados.next(this.estudiosServicioEfector);
          });
        } else {
          this.tipoRecetaFarmacia = true;
          this.solicitudEstudiosForm.get('estudios').setValue([]);
          this.estudiosServicioEfector = [];
          this.estudiosServicioEfectorFiltrados.next(this.estudiosServicioEfector);
        }
      } else {
        this.estudiosServicioEfector = [];
        this.solicitudEstudiosForm.get('estudios').setValue(['Otro']);
        this.tipoRecetaFarmacia = false;
        this.tipoRecetaGenerica = true;
        this.solicitudEstudiosForm.controls.estudios.disable();
      }
    });

    /*this.solicitudEstudiosForm.get('financiador').valueChanges.subscribe((value) => {
      this.options = [];
      console.log(value);
      this.coberturasPaciente.forEach((cobertura) => {
        if (cobertura.nombreFinanciador === value.nombre) {
          // console.log(cobertura)
          this.options.push(cobertura.nroCarnet);
        }
      });
      this.solicitudEstudiosForm.controls['nroCarnet'].setValue(this.options[0]);
      this.filteredOptions = of(this.options);
    });*/
    
    this.filteredOptions = this.solicitudEstudiosForm.get('nroCarnet').valueChanges.pipe(startWith(''), map((value) => this._filter(value)));
  }

  async obtenerProfesionales() {
    let jsonFiltros = {
      matricula: 'null',
      servicio:'null',
      especialidad: 'null',
      condicion: 'null',
      estado: 'ON',
    }

    await this.turnosService.getProfesionales(jsonFiltros).toPromise().then(rp => {
      this.listaProfesionales = rp.paginador.elementos.sort((a, b) => 0 - (a.apellido > b.apellido ? -1 : 1));
      this.listaProfesionales.unshift({apellido: 'Medico Externo', nroMatricula: 0})
      this.profSolicitanteFiltrado.next(this.listaProfesionales);
      // console.log(rp.paginador.elementos)
    }).catch(error => {
      console.log(error)
    }).finally(() => { })
  }

  servicioRequierePedido() {
    return this.data.servicio ? true : false;
  }

  OnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  get s() {
    return this.solicitudEstudiosForm.controls;
  }

  cargarFinanciadores() {
    this.estudiosService.getFinanciadores(this.data.patientData.tipoDocumento, this.data.patientData.nroDocumento).subscribe((data) => {
      this.financiadores = data.paginador.elementos;
      // console.log(this.financiadores)
      //console.log(this.financiadores)
      /*Filtra financiadores con la cobertura del paciente*/
      this.financiadoresPaciente = this.financiadores.filter((financiador) => {
        let guardar = false;
        this.coberturasPaciente.forEach((cobertura) => {
          if (!guardar && financiador.financiadorId === parseInt(cobertura.Financiador, 10)) {
            guardar = true;
          }
        });

        return guardar;
      });
      // console.log(this.financiadoresPaciente)
      if (this.servicioRequierePedido()) {
        this.solicitudEstudiosForm.controls['financiador'].setValue(this.financiadoresPaciente.find(f => f.financiadorId == this.data.patientData.idFinanciador));
        this.solicitudEstudiosForm.controls['financiador'].disable();
        this.solicitudEstudiosForm.controls['nroCarnet'].setValue(this.data.patientData.nroCarnet);
        this.solicitudEstudiosForm.controls['nroCarnet'].disable();
      } else {
        this.solicitudEstudiosForm.controls['financiador'].setValue(this.financiadoresPaciente.find(f => f.financiadorId == this.coberturasPaciente[0].Financiador));
        this.solicitudEstudiosForm.controls['nroCarnet'].setValue(this.coberturasPaciente[0].nroCarnet);
      }
      
    }, (error) => {
      console.log(error);
    });
  }

  cargarPedidos() {
    this.loadingPedidos = true;

    this.estudiosService
      .pedidosPaciente(
        this.data.patientData.nroDocumento,
        this.data.patientData.tipoDocumento,
        10
      )
      .subscribe(
        (data2) => {
          if (data2.ok) {
            this.dataSourceRecetasFarmacia = data2.pedidos;
          } else {
            this.dataSourceRecetasFarmacia = {};
          }

          this.estudiosService
            .pedidosPaciente(
              this.data.patientData.nroDocumento,
              this.data.patientData.tipoDocumento,
              9
            )
            .subscribe(
              (data1) => {
                if (data1.ok) {
                  this.dataSourceRecetas = data1.pedidos;
                } else {
                  this.dataSourceRecetas = {};
                }
                this.loadingPedidos = false;

                this.estudiosService
                  .pedidosPaciente(
                    this.data.patientData.nroDocumento,
                    this.data.patientData.tipoDocumento,
                    1
                  )
                  .subscribe(
                    (data) => {
                      if (data.ok) {
                        this.dataSourceEstudios = data.pedidos;
                      } else {
                        this.dataSourceEstudios = {};
                      }

                      if (data2.ok) {
                        this.dataSource = this.dataSourceRecetasFarmacia;
                        if (data1.ok) {
                          this.dataSource = this.dataSource.concat(this.dataSourceRecetas);
                        }
                        if (data.ok) {
                          this.dataSource = this.dataSource.concat(this.dataSourceEstudios);
                        }
                      } else {
                        if (data1.ok) {
                          this.dataSource = this.dataSourceRecetas;
                          if (data.ok) {
                            this.dataSource = this.dataSource.concat(this.dataSourceEstudios);
                          }
                        } else {
                          if (data.ok) {
                            this.dataSource = this.dataSourceEstudios;
                          } else {
                            this.dataSource = {};
                          }
                        }
                      }

                      if (data2.ok || data1.ok || data.ok) {
                       /* this.dataSource = this.dataSource.concat(
                          this.dataSourceEstudios
                        );
                        this.dataSource = this.dataSource.concat(
                          this.dataSourceRecetas
                        );
                        this.dataSource = this.dataSource.concat(
                          this.dataSourceRecetasFarmacia
                        );*/

                        this.dataSource = this.dataSource.sort((a, b) =>
                          moment(b.fechaHoraPedida, 'DD/MM/YYYY HH:mm').diff(
                            moment(a.fechaHoraPedida, 'DD/MM/YYYY HH:mm')
                          )
                        );
                        this.dataSource = new MatTableDataSource(
                          this.dataSource
                        );
                        this.nombrarEstado();
                        this.dataSource.paginator = this.paginator;
                      } else {
                        this.dataSource = new MatTableDataSource(
                          this.dataSource
                        );
                        this.dataSource.data = {};

                      }
                      this.loadingPedidos = false;
                    },
                    (error) => {
                      console.log(error);
                      this.loadingPedidos = false;
                    }
                  );
              },
              (error) => {
                console.log(error);
                this.loadingPedidos = false;
              }
            );
        },
        (error) => {
          console.log(error);
          this.loadingPedidos = false;
        }
      );
  }

  onSubmit() {
    if (this.solicitudEstudiosForm.invalid || this.dataSourceItems.length === 0) {
      // console.log(this.solicitudEstudiosForm)
      return;
    }

    this.loadingSubmit = true;
    const items = [];
    this.dataSourceItems.forEach((each, index) =>
      items.push({
        numeroItem: index + 1,
        nombreEstudio: each.descripcion,
        codigoEstudio: each.codigoEstudio,
        motivo: each.motivo,
        nomenclador: each.nomenclador,
        cantidad: each.cantidad,
      })
    );

    const payload = {
      categoria: 'AMBULATORIO',
      codigoServicio: this.solicitudEstudiosForm.get('servicioEfector').value.codigo,
      diagnostico: this.solicitudEstudiosForm.get('diagnostico').value,
      edad: this.data.patientData.edad,
      matriculaProfesionalSolicitante: this.servicioRequierePedido() ? 
        this.solicitudEstudiosForm.get('profesionalSolicitante').value.nroMatricula : this.authService.currentUserValue.roles.find((rol) => rol.codigo === 'MHE').valorTipoID,
      nroDniPaciente: this.data.patientData.nroDocumento,
      paciente: this.solicitudEstudiosForm.get('paciente').value,
      tipoDniPaciente: this.data.patientData.tipoDocumento,
      emailPaciente: null,
      urgente: false,
      idFinanciador: this.solicitudEstudiosForm.get('financiador').value ? this.solicitudEstudiosForm.get('financiador').value.financiadorId : 65, 
      matriculaProfesionalActuante: this.servicioRequierePedido() ? this.data.servicio.matriculaEfector : null,
      nroCarnet: this.solicitudEstudiosForm.get('nroCarnet').value,
      sectorInforme: this.solicitudEstudiosForm.get('servicioEfector').value.sectorInforme,
      estudiosPaciente: items,
      codigoServicioSol: 1,
      tipoPedido: 1,
      cantDiasInternacion: 0,
      sucursal: this.sucursalService.getCurrentSucursal(),
      admisionAdministrativa: 'PDT',
      esOrdenDeInternacion: false
    };
    
    items.forEach(item => {
      if (item.codigoEstudio == '0002832') {          // SI ES EL ESTUDIO ES LA VIDEOCOLPO (GINECOLOGIA) SE SETEA LA MISMA MATRICULA AL EFECTOR Y SOLICITANTE
        payload.matriculaProfesionalActuante = this.authService.currentUserValue.roles.find(
          (rol) => rol.codigo === 'MHE'
        ).valorTipoID
      }
    })

    if (this.tipoRecetaGenerica) {
      payload.sectorInforme = 0;
      payload.codigoServicioSol = 0;
      payload.tipoPedido = 9;
    } else if (this.tipoRecetaFarmacia) {
      payload.sectorInforme = 0;
      payload.tipoPedido = 10;
      payload.sectorInforme = 0;
      payload.codigoServicioSol = 0;
    }

    this.estudiosService.guardarPedidoGenerico(payload).toPromise().then((data) => {
      // console.log(data)
      if (data.ok) {

        if (!this.servicioRequierePedido()) {
          this.cargarPedidos();
          this.imprimirOrdenPedido({ numero: data.numero });
          this.dialogo.close();
        } else {
          this.alertService.success("El pedido se guardo correctamente");
          setTimeout(() => {
            this.dialogo.close({
              ok: true,
              numeroPedido: data.numero,
            });
          }, 2000);
        }
        
        this.dataSourceItems = [];
        this.solicitudEstudiosForm.patchValue(
          {
            diagnostico: null,
            // financiador: this.coberturasPaciente[0].nombreFinanciador,
            // nroCarnet: this.coberturasPaciente[0].nroCarnet,
            servicioEfector: null,
            estudios: [],
          }, { 
            emitEvent: false 
          }
        );

        this.farmaciaComercialForm.patchValue(
          {
            unidadMedicamento: 1,
            tratamiento: null,
          }, { 
            emitEvent: false
          }
        );

        this.options = [];
        this.filteredOptions = of(this.options);
        this.guardarEstadistica();
      } else {
        this.alertService.error(data.mensaje);
        this.loadingSubmit = false;
      }

    }).catch((error) => {
      console.log(error);
      this.alertService.error('Se produjo un error al cargar el pedido');
      this.loadingSubmit = false;
    }).finally(() => { });

    this.tipoRecetaGenerica = false;
    this.tipoRecetaFarmacia = false;
    this.solicitudEstudiosForm.controls.estudios.enable();
    this.descMedicamento = '';
  }

  guardarEstadistica() {

    this.estadistica.description = 'El usuario '+ this.authService.currentUserValue.usuario + ' realizo la solicitud de una orden.';
    this.estadistica.funcion = 'FX_SolicitarOrden';

    this.estadisticaService.guardarEstadistica(this.estadistica).subscribe(resp => {
      // console.log(resp)
    }, error => {
      console.log(error)
    });
  }

  protected filterBanksMulti() {
    if (!this.estudiosServicioEfector) {
      return;
    }
    // get the search keyword
    let search = this.bankMultiFilterCtrl.value;
    if (!search) {
      this.estudiosServicioEfectorFiltrados.next(
        this.estudiosServicioEfector.slice()
      );
      return;
    } else {
      search = search.toLowerCase();
    }
    // filtro estudios
    this.estudiosServicioEfectorFiltrados.next(
      this.estudiosServicioEfector.filter(
        (estudio) => estudio.estudio.toLowerCase().indexOf(search) > -1
      )
    );
  }

  protected filtrarProfSolicitante() {
    if (!this.listaProfesionales) {
      return;
    }
    // get the search keyword
    let search = this.filterProfCtrl.value;
    if (!search) {
      this.profSolicitanteFiltrado.next(
        this.listaProfesionales.slice()
      );
      return;
    } else {
      search = search.toLowerCase();
    }
    // filtro estudios
    this.profSolicitanteFiltrado.next(
      this.listaProfesionales.filter((prof) => prof.apellido.toLowerCase().indexOf(search) > -1)
    );
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  imprimirOrdenPedido(pedido) {
    const dialogRef = this.dialog.open(DialogPdfComponent, {
      width: '1300px',
      height: '700px',
      data: {
        url: this.estudiosService.imprimirOrdenPedido(
          pedido,
          this.authService.currentUserValue.usuario
        ),
      },
    });
  }

  async regPedidoEscribirInforme(pedido) {
    // console.log(pedido);
    this.loadingPedidos = true;

    pedido.fechaHoraProg = new Date().toLocaleDateString() +' '+ new Date().toLocaleTimeString();
    pedido.estado = 'ENA';

    await this.estudiosService.setEstadoPedidoGenerico(pedido).toPromise().then(respSetEstado => {
      // console.log(respSetEstado);
      if (respSetEstado.ok) {
        // console.log(respSetEstado);
        this.estudiosService.registrarPedido(pedido).toPromise().then((respRegPedido) => {
          // console.log(respRegPedido);
        }, (error) => {
          // console.log(error);
        })
      }
    }, error => {
      console.log(error)
    })

    await this.router.navigate(['/escribir-informe', pedido.numero]);
    this.dialog.closeAll();
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.options.filter((option) =>
      option.toLowerCase().includes(filterValue)
    );
  }
  public borrarItem(elemento) {
    this.dialog
      .open(DialogConfirmacionComponent, {
        data: `¿Está seguro que desea borrar el item de la orden?`,
      })
      .afterClosed()
      .subscribe((confirmado: boolean) => {
        if (confirmado) {
          this.dataSourceItems = this.dataSourceItems.filter((value, key) => {
            return value.numeroItem !== elemento.numeroItem;
          });
          if (this.dataSourceItems.length === 0) {
            this.solicitudEstudiosForm.controls.servicioEfector.enable();
          }
        }
      });
  }
  public agregarItemGenerico(accion, elemento) {
    elemento.accion = accion;
    this.dialogInsertar
      .open(DialogIngresarItemComponent, {
        data: elemento,
      })
      .afterClosed()
      .subscribe((resultado) => {
        if (resultado.evento !== 'Cancelar') {
          const d = new Date();
          this.dataSourceItems.push({
            numeroItem: d.getTime(),
            descripcion: resultado.data.descripcion,
            codigoEstudio: null,
            motivo: null,
            nomenclador: null,
            cantidad: null,
          });
          this.solicitudEstudiosForm.controls.servicioEfector.disable();
        }
        this.table.renderRows();
      });
  }

  public editarItem(accion, elemento) {
    elemento.accion = accion;
    this.dialogInsertar
      .open(DialogIngresarItemComponent, {
        data: elemento,
      })
      .afterClosed()
      .subscribe((resultado) => {
        if (resultado.evento !== 'Cancelar') {
          this.dataSourceItems = this.dataSourceItems.filter((value, key) => {
            if (value.numeroItem === resultado.data.numeroItem) {
              if (!this.tipoRecetaFarmacia) {
                value.descripcion = resultado.data.descripcion;
              } else {
                value.cantidad = resultado.data.cantidad;
                value.tratamiento = resultado.data.tratamiento;
                value.motivo = (resultado.data.tratamiento) ?
                ('Unidades: ' +
                resultado.data.cantidad +
                '.' +
                ' Tratamiento: ' +
                resultado.data.tratamiento +
                '.') : ('Unidades: ' +
                resultado.data.cantidad +
                '.');
              }
            }
            return true;
          });
        }
      });
  }

  public agregarItem() {
    const estudioSelec = this.solicitudEstudiosForm.get('estudios').value;
    const d = new Date();
    if (!this.tipoRecetaFarmacia) {
      this.dataSourceItems.push({
        numeroItem: d.getTime(),
        descripcion: estudioSelec.estudio, // nombre del estudio o medicamento
        codigoEstudio: estudioSelec.codigo, // codigo estudio o troquel
        motivo: null,
        nomenclador: null,
        cantidad: null,
      });
    } else {
      if (!this.farmaciaComercialForm.invalid) {
        this.dataSourceItems.push({
          numeroItem: d.getTime(),
          descripcion: estudioSelec.estudio, // nombre del estudio o medicamento
          codigoEstudio: estudioSelec.codigo, // codigo estudio o troquel
          cantidad: this.farmaciaComercialForm.get('unidadMedicamento').value,
          tratamiento: this.farmaciaComercialForm.get('tratamiento').value,
          motivo: (this.farmaciaComercialForm.get('tratamiento').value) ?
          ('Unidades: ' +
          this.farmaciaComercialForm.get('unidadMedicamento').value +
          '.' +
          ' Tratamiento: ' +
          this.farmaciaComercialForm.get('tratamiento').value +
          '.') : ('Unidades: ' +
          this.farmaciaComercialForm.get('unidadMedicamento').value +
          '.'),
          nomenclador: 14,
          tipoRecetaFarmacia: this.tipoRecetaFarmacia,
        });
        this.farmaciaComercialForm.get('tratamiento').setValue('');
        this.solicitudEstudiosForm.get('estudios').setValue([]);
        this.farmaciaComercialForm.get('unidadMedicamento').setValue(1);
        this.descMedicamento = '';
        this.estudiosServicioEfector = [];
        this.estudiosServicioEfectorFiltrados.next(
          this.estudiosServicioEfector
        );
      }
    }
    this.table.renderRows();
    if (this.dataSourceItems.length !== 0) {
      this.solicitudEstudiosForm.controls.servicioEfector.disable();
    } else {
      this.solicitudEstudiosForm.controls.servicioEfector.enable();
    }
  }

  public buscarMedicamentos() {
    if (
      this.solicitudEstudiosForm.get('servicioEfector').value.nombre ===
      'FARMACIA COMERCIAL'
    ) {
      const datosFiltroEstudios = {
        servicio:
          this.solicitudEstudiosForm.get('servicioEfector').value.codigo,
        estudio: this.descMedicamento,
      };
      this.solicitudEstudiosForm.get('estudios').setValue([]);
      this.estudiosService
        .listarEstudiosDeServicioEfector(datosFiltroEstudios)
        .subscribe((data) => {
          this.estudiosServicioEfector = data.paginador.elementos;
          this.estudiosServicioEfectorFiltrados.next(
            this.estudiosServicioEfector
          );
        });
    }
  }

  nombrarEstado() {
    this.dataSource.data.forEach((element) => {
      switch (element.estado) {
        case 'SOL':
          element.estado = 'Solicitado';
          break;
        case 'PRO':
          element.estado = 'Programado';
          break;
        case 'ANU':
          element.estado = 'Anulado';
          break;
        case 'CTG':
          element.estado = 'En Contingencia';
          break;
        case 'ENA':
          element.estado = 'En Atención';
          break;
        case 'INF':
          element.estado = 'Informado';
          break;
        case 'CAN':
          element.estado = 'Cancelado';
          break;
        default:
        //  console.log('No se pudo cambiar el nombre del estado');
      }
    });
  }
}
