import { Component, OnInit, ViewChild } from '@angular/core';
import { NavBarServiceService } from './_services/nav-bar-service.service';
import { MatSidenav } from '@angular/material/sidenav';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  title = 'sitio-medico';

  @ViewChild(MatSidenav, { static: true }) sideNav: MatSidenav;
  constructor(
    private navBarService: NavBarServiceService
  ) {

  }

  ngOnInit() {
    this.navBarService.setDrawer(this.sideNav);
  }

  public static get baseUrl(): string {
    // return "http://172.20.35.240/horus_restfull/api/";
    return (
      window.location.protocol +
      '//' +
      window.location.hostname +
      '/horus_restfull/api'
    );
  }
}
