import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import * as moment from 'moment';
import { EvolucionPaciente } from '../_models/evolucionPaciente.model';
import { AuthenticationService } from '../_services/authentication.service';
import { PacienteService } from '../_services/paciente.service';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { EvolucionService } from '../_services/evolucion.service';
import { TemplatesService } from '../_services/templates.service';
import { ReplaySubject } from 'rxjs/internal/ReplaySubject';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { EpisodiosPacienteService } from '../_services/episodiosPaciente.service';
import { CampoFormulario } from '../_models/campoFormulario.interface';
import { FormulariosService } from '../_services/formularios.service';

@Component({
  selector: 'app-paciente-evolucion',
  templateUrl: './paciente-evolucion.component.html',
  styleUrls: ['./paciente-evolucion.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})

export class PacienteEvolucionComponent implements OnInit {
  
  public editor = ClassicEditor;

  currentPatient: any;
  loading = false;
  loadingForm = false;
  dataSource;
  columnsToDisplay = [
    { name: 'fecha', jsonName: 'fecha' },
    { name: 'profesional', jsonName: 'nombreMedico' },
    { name: 'acciones', jsonName: 'acciones' },
  ];
  expandedElement: EvolucionPaciente | null;
  columnsToDisplay2 = ['fecha', 'profesional', 'acciones'];

  plantillaSeleccionada: any;
  bankMultiFilterCtrl: UntypedFormControl = new UntypedFormControl();
  plantillas: any[] = [];
  public plantillasFiltradas: ReplaySubject<any> = new ReplaySubject<any>(1);
  protected onDestroy = new Subject<void>();

  @ViewChild(MatPaginator) paginator: MatPaginator;
  evolucionForm: UntypedFormGroup;
  plantillasPriv: any;

  tieneEpiActivo: boolean = false;
  epiActivo: any; // el episodio activo con subepisodios de hc del receptor/dador
  subepiActivo: any[] = []; // el subepisodio elegido donde se va a guardar la evolucion 
  guardadaEvolucion: boolean = false;

  constructor(
    private pacienteService: PacienteService,
    private authService: AuthenticationService,
    private evoService: EvolucionService,
    private templatesService: TemplatesService,
    private formBuilder: UntypedFormBuilder,
    public dialog: MatDialog,
    private episodiosService: EpisodiosPacienteService,
    private formularioService: FormulariosService,
  ) {}

  ngOnInit() {
    this.evolucionForm = this.formBuilder.group({
      id: [null],
      version: [null],
      evolucion: ['', Validators.required],
      privada: [false, Validators.required],
      publicarEnTrasplante: [false]
    });

    this.evoService.currentEvo.subscribe((evo) => {
      if (evo) {
        this.editarEvolucion(evo);
      }
    });

    this.bankMultiFilterCtrl.valueChanges
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => {
        this.filterBanksMulti();
    });

    this.pacienteService.currentPatient.subscribe(
      (data) => {
        this.currentPatient = data;
      },
      (error) => {
        console.log(error);
      }
    );

    this.templatesService
      .getPlantillasPublicas(
        this.authService.currentUserValue.roles.find(
          (rol) => rol.codigo === 'MHE'
        ).valorTipoID
      )
      .subscribe((data) => {
      //  console.log(data);
        if (data.ok) {
          this.plantillas = data.paginador.elementos;
        }
        this.templatesService
          .getPlantillasPrivadas(
              this.authService.currentUserValue.roles.find(
                (rol) => rol.codigo === 'MHE'
                ).valorTipoID
          )
          .subscribe((data1) => {
            // console.log(data1);
            if (data1.ok) {
              this.plantillasPriv = data1.paginador.elementos;
              this.plantillasPriv.forEach(elem => {
                elem.privada = true;
              });
              if (data.ok) {
                this.plantillas = this.plantillas.concat(this.plantillasPriv);
              } else {
                this.plantillas = this.plantillasPriv;
              }
            }
            this.plantillasFiltradas.next(this.plantillas);
          });
   });


   this.chequearPublicarEnTrasplante();

  }

  async onSubmitForm() {
    
    this.loadingForm = true;
    if (this.evolucionForm.invalid) {
      return;
    }
    
    const estadoPublicarTrasplante = this.evolucionForm.controls.publicarEnTrasplante.value;
    let resp = null;
    if (this.evolucionForm.controls.publicarEnTrasplante.value[0] && this.subepiActivo.length > 1) {
      resp = await this.openDialogSeleccionarSubepisodio();
    }
    
    if ((! this.evolucionForm.controls.publicarEnTrasplante.value[0]) || 
        (this.evolucionForm.controls.publicarEnTrasplante.value[0] && resp) || 
        (this.evolucionForm.controls.publicarEnTrasplante.value[0] && this.subepiActivo.length == 1)) {

      this.pacienteService
        .guardarEvolucion(
          this.evolucionForm.value.evolucion,
          moment().format('DD/MM/YYYY HH:mm'),
          this.authService.currentUserValue.roles.find(
            (rol) => rol.codigo === 'MHE'
          ).valorTipoID,
          this.authService.currentUserValue.nombreCompleto,
          this.currentPatient.numeroDoc,
          this.evolucionForm.value.privada,
          { id: 2, descripcion: 'consultorio particular', borrado: false },
          this.currentPatient.tipoDoc,
          this.evolucionForm.value.id,
          this.evolucionForm.value.version
        )
        .subscribe(
          (data) => {            
            this.evolucionForm.reset();
            this.evolucionForm.get('privada').setValue(false);
            this.evolucionForm.get('publicarEnTrasplante').setValue(estadoPublicarTrasplante);
            this.pacienteService.recargarHistorial();
            this.plantillaSeleccionada = {};
            this.evoService.unSetEvo();
          },
          (error) => {
            console.log(error);
            this.evoService.unSetEvo();
          }
        );
        // Si tiene publicarEnTrasplante activo, lo mando como un formulario
        if (this.evolucionForm.controls.publicarEnTrasplante.value[0]) {
          const cabeceraFormulario = {
            idFormulario: 45, // hardcodeo. es el id de formulario para evoluciones (tanto dador vivo como receptor)
            tipoDocumento: this.currentPatient.tipoDoc,
            nroDocumento: this.currentPatient.numeroDoc,
            loginRegistro: this.authService.currentUserValue.usuario,
            idEpisodio: resp.idEpisodio,
            idFormularioCabecera: null,
            estado: 'ACT',
            loginEstado: this.authService.currentUserValue.usuario,
            loginUltModificacion: this.authService.currentUserValue.usuario
          };
          var aux = this.obtenerTextoSinHTML(this.evolucionForm.value.evolucion);
          const campo: CampoFormulario[] = [{
            idCampo: 556,
            idSeccion: 252,
            nombre: "Evolucion",
            estado: 'ACT',
            valor: aux,
          }]
          this.formularioService.guardarFormularioValores(campo, cabeceraFormulario).subscribe(
            (data) => {
            this.guardadaEvolucion = true;
            this.evolucionForm.controls.publicarEnTrasplante.setValue(true);
            },
            (error) => {
              console.log(error);
            }
          );
        }
      
    }
    
  }

  obtenerTextoSinHTML(html) {
    var textoLimpio = html.replace(/<p>&nbsp;<\/p>/g, '<br>')
                          .replace(/<\/p>(?=<p>)/g, '<br>')
                          .replace(/&nbsp;/g, '')
                          .replace(/"/g, "'")
                          .replace(/<(?!br\s*\/?)[^>]+>/g, ''); // Elimina cualquier otra etiqueta HTML menos la etiqueta <br>

    return textoLimpio;
  }

  editarEvolucion(evolucion) {
    this.evolucionForm.setValue({
      id: evolucion.id,
      version: evolucion.version,
      evolucion: evolucion.anotacion,
      privada: evolucion.privada,
      publicarEnTrasplante: [false]
    });
    this.plantillaSeleccionada = {};
    this.chequearPublicarEnTrasplante();
  }

  resetForm() {
    this.evolucionForm.reset();
    this.evolucionForm.value.privada = false;
    this.plantillaSeleccionada = {};
    this.evoService.unSetEvo();
  }

  evolucionEnTiempo?(evolucion: EvolucionPaciente) {
    return moment(evolucion.fecha, 'DD/MM/YYYY').date() >= moment().date();
  }

  protected filterBanksMulti() {
    // get the search keyword
    let search = this.bankMultiFilterCtrl.value;
    if (!search) {
      this.plantillasFiltradas.next(this.plantillas.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filtro plantillas
    this.plantillasFiltradas.next(
      this.plantillas.filter(
        (plantilla) => plantilla.titulo.toLowerCase().indexOf(search) > -1
      )
    );
  }

  insertarPlantilla(plantilla) {
    // this.plantillaSeleccionada = plantilla.titulo;
    this.evolucionForm.get('evolucion').setValue(plantilla.texto);
  }

  OnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  chequearPublicarEnTrasplante() {
    // traigo los episodios para publicar la evo en episodio de trasplante
    this.episodiosService.listarEpisodiosPaciente(this.currentPatient.numeroDoc, this.currentPatient.tipoDoc)
    .subscribe((respuesta) => {
      if (respuesta.ok) {
        if (respuesta.elementos.length !== 0) {
          for (let episodios of respuesta.elementos) {
            if (episodios.fechaFin) {
              var fechaFin = episodios.fechaFin.split("/");
              fechaFin = new Date(fechaFin[2], fechaFin[1], fechaFin[0]);
            }
            if (!episodios.fechaFin || fechaFin > new Date()) {
              for (let subepisodio of episodios.subepisodios) {
                if (subepisodio.tipoDeEpisodio.idTipoEpisodio==1 || subepisodio.tipoDeEpisodio.idTipoEpisodio==2) {
                  // Tiene un subepisodio de hc de dador o receptor
                  this.tieneEpiActivo = true;
                  this.epiActivo = episodios;
                  this.subepiActivo.push(subepisodio);
                  // Activo la opcion en el formulario 
                  this.evolucionForm.controls.publicarEnTrasplante.setValue(true);
                }
              }
            }
          } 
        } else {
          this.tieneEpiActivo = false
        }
        this.loading = false;
        }
      }, (error) => {
        console.log(error);
      }
    );
  }

  async openDialogSeleccionarSubepisodio() {
    let datos = null;
    const dialog = this.dialog.open(DialogSeleccionarSubepisodio, {
      width: '600px',
      data: {subepisodios: this.subepiActivo}
    });
    await dialog.afterClosed().toPromise().then(result => {
      if (result.opcion) datos = result.opcion;
    });
    return datos;
  }


}


@Component({
  selector: 'dialog-seleccionar-subepisodio',
  templateUrl: 'dialog-seleccionar-subepisodio.html',

})
export class DialogSeleccionarSubepisodio {

  opcion: any;
  result: any = null;

  constructor(
    public dialogRef: MatDialogRef<DialogSeleccionarSubepisodio>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.opcion = '1';
  }

  cancelar(): void {
    this.dialogRef.close({opcion: null});
  }

  guardar(): void {
    if (this.data.subepisodios[0].tipoDeEpisodio.idTipoEpisodio==this.opcion) {
      this.result = this.data.subepisodios[0];
    }
    else {
      this.result = this.data.subepisodios[1];
    }
    this.dialogRef.close({opcion: this.result});
  }

}