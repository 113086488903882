<div class="container pagina" *ngIf="cargado" style="margin-top: 1%">
    <div *ngIf="
      (this.soySecretaria &&
        this.respuestaProfesionales.profesionales.length != 0) ||
        !soySecretaria;
      else elseBlockTemplate
    ">
        <div *ngIf="
        this.sucursales !== null && this.cargoProfesionales;
        else elseBlockTemplate
      ">
            <div class="row justify-content-md-center d-flex justify-content-center">
                <div class="
            col-xs-6 col-sm-6 col-md-5 col-lg-4 col-xl-4
            align-self-center align-middle
            text-center
          ">
                    <mat-form-field>
                        <!-- <mat-label>Sucursal</mat-label> -->
                        <mat-select (selectionChange)="seleccionoSucursal($event.value)" [ngModel]="sucursalActual">
                            <mat-option value="" class="font-weight-bold">
                                <b>Todas</b>
                            </mat-option>
                            <mat-option *ngFor="let sucursal of sucursales" [value]="sucursal.id">
                                {{ sucursal.nombre }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="
            col-xs-6 col-sm-6 col-md-5 col-lg-4 col-xl-4
            align-self-center align-middle
            text-center
          " *ngIf="this.soySecretaria">
                    <mat-form-field>
                        <mat-select (selectionChange)="seleccionoProfesional($event.value)" [ngModel]="profesionalSeleccionado">
                            <mat-option *ngFor="let profesional of respuestaProfesionales.profesionales" [value]="profesional.matricula">
                                {{ profesional.nombreCompleto }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="container">
                <div class="d-flex justify-content-center" style="margin-top: 10%" *ngIf="loading">
                    <mat-spinner [diameter]="60"></mat-spinner>
                </div>
                <div class="row justify-content-center">
                    <div style="padding: 0" class="col-xs-12 col-sm-12 col-md-12 col-lg-8 col-xl-6" *ngIf="!loading">
                        <div class="d-flex justify-content-center" style="margin-top: 15%" *ngIf="agendas == null; else elseBlockTemplate">
                            <h6>Ocurrió un problema al cargar las agendas del usuario</h6>
                        </div>
                        <ng-template #elseBlockTemplate>
                            <div class="d-flex justify-content-center" style="margin-top: 15%" *ngIf="!agendas.ok">
                                <h6>El profesional no posee agendas</h6>
                            </div>

                            <mat-list *ngIf="agendas.ok">
                                <div mat-subheader>Agendas</div>
                                <mat-list-item *ngFor="let agenda of agendas.horarios">
                                    <span matListAvatar style="width: 50px; height: 50px">
                    <div class="text-center" style="font-size: 11px">
                      {{ agenda.nombreDia | toTitleCase }}
                      {{ agenda.horaInicio | date: 'HH:mm' }}
                      {{ agenda.horaFin | date: 'HH:mm' }}
                    </div>
                  </span>
                                    <div class="mat-list-text">
                                        <div mat-line *ngIf="sucursalActual === ''; else elseBlock">
                                            {{ agenda.nombreEstablecimiento }} - {{ agenda.nomCons }}
                                        </div>
                                        <ng-template #elseBlock>
                                            <div mat-line>
                                                {{ agenda.nomCons }}
                                            </div>
                                        </ng-template>
                                        <div mat-line>
                                            {{ agenda.vigenciaInicio | date: 'dd/MM/yyyy' }} -
                                            <span *ngIf="
                          (agenda.vigenciaFin | date: 'dd/MM/yyyy') !==
                            '01/01/1900';
                          else sinFinVigenciaTemplate
                        ">
                        {{ agenda.vigenciaFin | date: 'dd/MM/yyyy' }}
                      </span>
                                            <ng-template #sinFinVigenciaTemplate>
                                                <!-- Actualidad -->
                                            </ng-template>
                                        </div>
                                    </div>
                                    <mat-action-list class="align-middle p-0 m-0">
                                        <button class="menu mx-1" mat-mini-fab color="primary" (click)="openDialog(agenda)">
                      <mat-icon>visibility</mat-icon>
                    </button>

                                        <button class="menu mx-1" mat-mini-fab [disabled]="
                        agenda.nombreEstablecimiento === 'Hospital Español' ||
                        (soySecretaria &&
                          agenda.establecimiento !== sucursalSecretaria)
                      " color="accent" (click)="modificarAgenda(agenda)">
                      <mat-icon>create</mat-icon>
                    </button>
                                        <button class="menu mx-1" mat-mini-fab color="warn" [disabled]="
                        (agenda.vigenciaFin | date: 'dd/MM/yyyy') !==
                          '01/01/1900' ||
                        agenda.nombreEstablecimiento === 'Hospital Español' ||
                        (soySecretaria &&
                          agenda.establecimiento !== sucursalSecretaria)
                      " (click)="borrarAgenda(agenda)">
                      <!--  mat-icon-button -->
                      <mat-icon>event</mat-icon>
                    </button>
                                    </mat-action-list>
                                </mat-list-item>
                            </mat-list>
                        </ng-template>
                    </div>
                </div>
                <div class="text-center mt-3">
                    <button type="button" class="btn btn-outline-primary align-middle" (click)="agregarNuevaAgenda()">
            <span class="material-icons align-middle">add</span>
            <span class="align-middle">Agregar agenda</span>
          </button>
                </div>
            </div>
        </div>
        <ng-template #elseBlockTemplate>
            <div class="d-flex justify-content-center">
                <h6 class="text-center my-5">
                    El profesional no posee establecimientos, debe crear uno para utilizar el sistema
                </h6>
            </div>
            <div class="text-center m-1">
                <button type="button" class="btn btn-outline-primary align-middle m-2" (click)="agregarNuevoEstablecimiento()">
          <span class="material-icons align-middle">add</span>
          <span class="align-middle">Agregar establecimiento</span>
        </button>
            </div>
        </ng-template>
    </div>
    <ng-template #elseBlockTemplate>
        <div class="d-flex justify-content-center">
            <h6 class="text-center my-5">
                El establecimiento del cual es secretaria no posee profesionales
            </h6>
        </div>
    </ng-template>
</div>

<!-- 

<mat-list>
          <div mat-subheader>Agendas</div>
          <mat-list-item *ngFor="let agenda of respuesta.horarios">
            <span matListAvatar>
              <mat-icon>calendar_today</mat-icon>
              <div style="font-size: 11px">
               {{ agenda.horaInicio | date }} a {{ agenda.horaFin | date }}
              </div>
            </span>
            <h5 style="padding-top: 20px" matLine>
              {{ agenda.numeroDia }} {{ agenda.nombreDia }}
            </h5>
            <p matLine>
              <span style="padding: 0" mat-subheader
                >Clickeame para sacar turno</span
              >
            </p>
          </mat-list-item>
        </mat-list>

 -->