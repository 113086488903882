import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EpisodiosPacienteService } from '../_services/episodiosPaciente.service';
import { EstudioService } from '../_services/estudios.service';

import {MatSort} from '@angular/material/sort';

import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { DialogPdfComponent } from '../dialog-pdf/dialog-pdf.component';

import { DialogConfirmacionComponent } from '../dialog-confirmacion/dialog-confirmacion.component';
import { DialogMensajeComponent } from '../dialog-mensaje/dialog-mensaje.component';
import { AuthenticationService } from '../_services/authentication.service';

export class DocumentoDigital{
  idRegistro: String;
  nombre: String;
  fecha: String;
  referenciaDocumento: String;
}

@Component({
  selector: 'app-documentos-digitales',
  templateUrl: './documentos-digitales.component.html',
  styleUrls: ['./documentos-digitales.component.css']
})
export class DocumentosDigitalesComponent implements OnInit, AfterViewInit{
  sinDocumentos: boolean = true;
  documentos_digitales: any[] = [];
  loading;
  datasrc_doc_digitales: MatTableDataSource<DocumentoDigital>;
  displayedColumns: string[] = ['nombre', 'fecha', 'acciones'];
  datos: DocumentoDigital[] = [];
  currentPatient: any;
  currentUser: any;
  episodio: any;


  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
     
  constructor(
    private router: Router,    
    private route: ActivatedRoute,
    private estudiosService: EstudioService,    
    private episodiosPacienteService: EpisodiosPacienteService,
    
    private authService: AuthenticationService,
    public dialog: MatDialog,

  ) { 
    this.datasrc_doc_digitales = new MatTableDataSource<DocumentoDigital>(this.documentos_digitales);
  }

  async ngOnInit() {
    await this.listarDocumentosDigitales(); 
    this.inicializarDataSource();
    this.currentUser = this.authService.currentUserValue;
    //console.log(this.currentUser);  
  }

  async listarDocumentosDigitales() {    
    this.episodio = this.episodiosPacienteService.getCurrentEpisodio();
    //console.log(this.episodio)
    //this.estudiosService.listarDocumentosDigitales(episodio.idEpisodio, episodio.matriculaProfesional)
    await this.estudiosService.listarDocumentosDigitales(this.episodio.idEpisodio)
        .toPromise().then(
          (respuesta) => {
            //console.log(respuesta);
            if(respuesta.listaRegistros.length !== 0) {
              respuesta.listaRegistros.forEach(registro => {
                //console.log(registro)
                let aux:DocumentoDigital = new DocumentoDigital();
                aux.idRegistro = registro.idRegistro;
                aux.nombre = registro.descripcion;
                aux.fecha = registro.fechaRegistro;
                aux.referenciaDocumento = registro.referenciaDocumento;
                this.documentos_digitales.push(aux);                
                //console.log(aux)
              });
              //console.log(this.documentos_digitales);          
              this.sinDocumentos = false;
            } else {
              this.sinDocumentos = true;
            }
            this.loading = false;
          },
          (error) => {
            console.log(error);
          }
        );
  }
  
  inicializarDataSource() {    
    this.datasrc_doc_digitales.paginator = this.paginator;
    this.datasrc_doc_digitales.sort = this.sort;
  }

  ngAfterViewInit() {
    this.inicializarDataSource();
  }
  
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.datasrc_doc_digitales.filter = filterValue.trim().toLowerCase();

    if (this.datasrc_doc_digitales.paginator) {
      this.datasrc_doc_digitales.paginator.firstPage();
    }
  }
 
  volver() {
    this.router.navigate(['/listar-episodios']);
  }

  cargarDocumento(){
    this.router.navigate(['/cargar-documento-digital']);
  }

  openDialog(referenciaDocumento): void {
    this.dialog.open(DialogPdfComponent, {
      width: '1300px',
      height: '700px',
      data: {
        url: this.estudiosService.imprimirDocumentoDigital(referenciaDocumento),
      },
    });
  }

  borrarDocumento(idRegistro, referenciaDocumento) {
    //console.log(idRegistro);
    //console.log(referenciaDocumento)
    let usuario = this.currentUser.usuario;

    this.dialog.open(DialogConfirmacionComponent, {
      data: `¿Seguro desea borrar el documento?` }
    ).afterClosed().subscribe((confirmado: boolean) => {
      if (confirmado) {
        this.estudiosService.bajaRegistroHCEtrasplante(idRegistro, referenciaDocumento, usuario).subscribe((respuesta) => {
          if (respuesta.ok) {
            this.openRespuesta(respuesta);
          }
        })
      }
    });
  }

  openRespuesta(respuesta) {
    this.dialog.open(DialogMensajeComponent, {
      width: '500px',
      data: {ok:respuesta.ok, mensaje:respuesta.mensaje}
    }).afterClosed().subscribe(()=> {  
        //location.reload();
        this.recargar();        
    });
  }

  recargar(){
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigate(['./'], {
          relativeTo: this.route
        })
  }

}
