import { EventEmitter, Injectable, Output } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from '../_models/user.model';
import { Router } from '@angular/router';
import { AppComponent } from '../app.component';

const httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8',
    }),
  };
  @Injectable({ providedIn: 'root' })
  export class ConsentimientoService {
    baseUrl: string;
  
    private currentPatientSubject: BehaviorSubject<any>;
  
    public currentPatient: Observable<any>;

    constructor(
        private http: HttpClient,
        private router: Router
      ) {
        this.baseUrl = AppComponent.baseUrl;
        this.currentPatientSubject = new BehaviorSubject<any>(
          JSON.parse(sessionStorage.getItem('currentPatient'))
        );
        this.currentPatient = this.currentPatientSubject.asObservable();
      }
    
      getConsentimientos(){
        const body = {
          servicio:null,
          estado: 'ACT',
          tipo:3
        }
        return this.http.post<any>(
          this.baseUrl + '/hcdigital/seguro/consentimientos',
          body
        );
      }
      getConsentimientosProfesional(matricula){
        const body = {
          matricula:matricula
        }
        return this.http.post<any>(
          this.baseUrl + '/hcdigital/seguro/getConsentimientosProfesional',
          body
        );
      }
      agregarConsentimiento(consentimiento){
 
        return this.http.post<any>(
            this.baseUrl +'/hcdigital/seguro/agregar-consentimiento',
            consentimiento,
            httpOptions
          );
      }
      agregarConsentimientoProfesional(consentimiento,matricula){
        
        return this.http.post<any>(
            this.baseUrl +'/hcdigital/seguro/agregar-consentimientoFavorito',
            consentimiento,
            httpOptions
          );
      }
      obtenerConsentimientos(tipoDoc,nroDoc,matricula){
        const body = {
            tipoDoc: tipoDoc,
            nroDocumento: nroDoc,
            matricula:matricula
        }
        return this.http.post<any>(
            this.baseUrl + '/hcdigital/seguro/getConsentimientosPaciente',body
          );
      }
      
      cambiarEstado(consentimiento){
        return this.http.post<any>(
          this.baseUrl + '/hcdigital/seguro/setEstadoConsentimientoPaciente',consentimiento      
         );

      }
      imprimirConsentimiento(consentimiento){
       
        let timestamp = Date.now();

        return  (this.baseUrl + '/hcdigital/imprimir-consentimiento/pdf/'+ consentimiento.urlCarpeta +  '/' + consentimiento.nombreArchivo+  '/' + timestamp);    
         
      }        

    }