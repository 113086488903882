import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EstudioService } from '../_services/estudios.service';

@Component({
  selector: 'app-dialog-pdf',
  templateUrl: './dialog-pdf.component.html',
  styleUrls: ['./dialog-pdf.component.css'],
})
export class DialogPdfComponent implements OnInit {

  selectedUrl;
  cargando;

  constructor(
    public dialogRef: MatDialogRef<DialogPdfComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.cargando = true;
    this.selectedUrl = this.data.url;
  }

  setearEnFalse() {
    this.cargando = false;
  }

   cerrar() {
    this.dialogRef.close(true);
  }
}
