import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthenticationService } from '../_services/authentication.service';
import { AdministracionAgendasService } from './../_services/administracion-agendas.service';
import { DialogEliminarAgendaComponent } from '../dialog-eliminar-agenda/dialog-eliminar-agenda.component';
import {
  DialogDatosAgendaComponent,
  RespuestaAgenda,
  Agenda,
} from './../dialog-datos-agenda/dialog-datos-agenda.component';
import { DatePipe } from '@angular/common';
import { DialogAgregarConsultorioComponent } from '../dialog-agregar-consultorio/dialog-agregar-consultorio.component';
import { EmbedUrlsService } from '../_services/embedUrls.service';
import { AgendaService } from '../_services/agenda.service';
import { UserService } from '../_services/user.service';
import { RespuestaProfesionales } from '../agenda/agenda.component';

export interface Sucursal {
  id: number;
  nombre: string;
  estado: string;
  domicilio: string;
  localidad: string;
}

@Component({
  selector: 'app-administracion-agendas',
  templateUrl: './administracion-agendas.component.html',
  styleUrls: ['./administracion-agendas.component.css'],
})
export class AdministracionAgendasComponent implements OnInit {
  private matricula: number;
  private sucursalSeleccionada;
  sucursalSecretaria;
  private respuesta: RespuestaAgenda;
  private listaSucursales: Sucursal[];
  loading = false;
  soySecretaria: boolean;
  respuestaProfesionales: RespuestaProfesionales;
  profesionalSeleccionado: number;
  cargoProfesionales = false;
  cargado = false;

  constructor(
    private authService: AuthenticationService,
    private administradorService: AdministracionAgendasService,
    public dialog: MatDialog,
    private router: Router,
    private embedUrlService: EmbedUrlsService,
    private agendaService: AgendaService,
    private userService: UserService
  ) {}

  ngOnInit() {
    this.embedUrlService.embedUrls.subscribe((funciones) => {
      if (
        funciones.find((funcion) => {
          return funcion.nombreMenu === 'Agendas';
        })
      ) {
      } else {
        this.router.navigate(['/agenda']);
      }
    });

    let aux = this.perfilSecretaria();
    
    if (aux) {      
      this.listaSucursales = [];
      aux.forEach((perfil) => {
        this.listaSucursales.push({
          id: parseInt(perfil.codigoSucursal),
          nombre: perfil.nombreSucursal,
          estado: '',
          domicilio: '',
          localidad: '',
        });
      });
      this.sucursalSeleccionada = this.listaSucursales[0].id;
      this.sucursalSecretaria = this.sucursalSeleccionada;
      this.soySecretaria = true;
      this.cargarProfesionales(this.sucursalSeleccionada);
    } else {
      this.matricula = this.authService.currentUserValue.roles.find(
        (rol) => rol.codigo === 'MHE'
      ).valorTipoID;
      
      this.cargarSucursales(this.matricula);
    }
  }
  public soySecretariaEstado(): boolean {
    return this.soySecretaria;
  }
  perfilSecretaria(): any {
    return this.userService.perfilSecretaria;
  }
  seleccionoProfesional(seleccionado) {
    this.profesionalSeleccionado = seleccionado;
    this.matricula = seleccionado;
    this.cargarAgendas(this.matricula, this.sucursalSeleccionada);
  }
  cargarProfesionales(sucursal) {
    this.agendaService.getProfesionalesSucursal(sucursal).subscribe(
      (data) => {        
        if (data) {
          data.profesionales = data.profesionales.sort(function (a, b) {
            if (a.nombreCompleto > b.nombreCompleto) {
              return 1;
            }
            if (a.nombreCompleto < b.nombreCompleto) {
              return -1;
            }
            return 0;
          });
          this.respuestaProfesionales = data;
          if (this.respuestaProfesionales.profesionales.length != 0) {
            this.matricula =
              this.respuestaProfesionales.profesionales[0].matricula;
            this.profesionalSeleccionado = this.matricula;
            this.cargarAgendas(this.matricula, this.sucursalSeleccionada);
          } else {
            this.matricula = null;
            this.profesionalSeleccionado = null;
          }
          this.cargado = true;
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  cargarAgendas(matricula, sucursal) {
    this.loading = true;
    this.administradorService
      .getAgendasProfesional(matricula, sucursal)
      .subscribe(
        (data) => {
          if (data) {
            this.respuesta = data;
            this.loading = false;
            this.cargoProfesionales = true;
          }
        },
        (error) => {
          console.log(error);
          this.loading = false;
        }
      );
  }
  cargarSucursales(matricula) {
    this.administradorService.getSucursalesProfesional(matricula).subscribe(
      (data) => {
        if (data) {          
          this.listaSucursales = data.elementos;
          if (this.listaSucursales.length !== 0) {
            this.sucursalSeleccionada = '';
            this.cargarAgendas(this.matricula, this.sucursalSeleccionada);
          } else {
            this.listaSucursales = null;
          }
          this.cargado = true;
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  public get agendas(): RespuestaAgenda {
    return this.respuesta;
  }
  public get sucursales(): Sucursal[] {
    return this.listaSucursales;
  }
  public get sucursalActual(): any {
    return this.sucursalSeleccionada;
  }
  nombreSucursal(numeroSucursal): string {
    let nombre;
    try {
      nombre = this.listaSucursales.find(
        (obj) => obj.id === numeroSucursal
      ).nombre;
    } catch (error) {
      nombre = '';
    }
    return nombre;
  }
  getSucursalbyId(numeroSucursal): Sucursal {
    return this.listaSucursales.find((obj) => obj.id === numeroSucursal);
  }
  seleccionoSucursal(seleccionada) {
    this.sucursalSeleccionada = seleccionada;
    this.cargarAgendas(this.matricula, this.sucursalSeleccionada);
  }

  openDialog(agenda: Agenda): void {
    const dialogRef = this.dialog.open(DialogDatosAgendaComponent, {
      width: '500px',
      data: agenda,
    });
  }
  borrarAgenda(agenda: Agenda): void {
    if (
      new DatePipe('en-US').transform(agenda.vigenciaFin, 'dd/MM/yyyy') ===
      '01/01/1900'
    ) {
      this.dialog
        .open(DialogEliminarAgendaComponent, {
          data: {
            agenda,
            matricula: this.matricula,
            sucursal: agenda.establecimiento,
          },
        })
        .afterClosed()
        .subscribe((confirmado: boolean) => {
          if (confirmado) {
            this.cargarAgendas(this.matricula, this.sucursalSeleccionada);
          }
        });
    }
  }
  agregarNuevaAgenda() {
    this.router.navigate(['/agregarAgenda'], {
      state: {
        sucursalActual:
          this.sucursalActual !== 1 && this.sucursalActual !== ''
            ? this.sucursalActual
            : this.listaSucursales.length > 1
            ? this.listaSucursales[1].id
            : null,
        matricula: this.matricula,
        profesional: this.soySecretaria
          ? this.respuestaProfesionales.profesionales.find(
              (prof) => prof.matricula === this.profesionalSeleccionado
            ).nombreCompleto
          : this.authService.currentUserValue.nombreCompleto,
      },
    });
  }
  modificarAgenda(agenda: Agenda) {
    this.router.navigate(['/modificarAgenda'], {
      state: {
        agendaModificar: agenda,
        sucursalActual: agenda.nombreEstablecimiento,
        matricula: this.matricula,
        profesional: this.soySecretaria
          ? this.respuestaProfesionales.profesionales.find(
              (prof) => prof.matricula === this.profesionalSeleccionado
            ).nombreCompleto
          : this.authService.currentUserValue.nombreCompleto,
      },
    });
  }

  agregarNuevoEstablecimiento() {
    this.router.navigate(['/agregarEstablecimiento']);
  }
  agregarNuevoConsultorio() {
    const dialogRef = this.dialog.open(DialogAgregarConsultorioComponent, {
      width: '400px',
    });
  }
}
