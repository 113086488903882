import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormBuilder, FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { EstudioService } from '../_services/estudios.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService } from '../_services/alert.service';
import { MatDialog } from '@angular/material/dialog';
import { DialogPdfComponent } from '../dialog-pdf/dialog-pdf.component';
import { Observable, Subject, of, throwError } from 'rxjs';
import { map, startWith, take, takeUntil } from 'rxjs/operators';
import { PacienteService } from '../_services/paciente.service';
import { AuthenticationService } from '../_services/authentication.service';

@Component({
  selector: 'app-dialog-solicitar-biopsia',
  templateUrl: './dialog-solicitar-biopsia.component.html',
  styleUrls: ['./dialog-solicitar-biopsia.component.css']
})
export class DialogSolicitarBiopsiaComponent implements OnInit {

  cargando: boolean = true;
  solicitarBiopsiaForm: UntypedFormGroup;
  loadButton: boolean = false;
  pedido: any = {};
  alerta: boolean = false;
  hayImpresion: boolean = false;
  nroPedidoNuevo: any;
  listaEstudios=[];
  coberturasPaciente: any;
  financiadores: any;
  financiadoresPaciente: any;
  coberturaTurno: any;
  options: string[] = [];
  currentUser;
  filteredOptions: Observable<string[]>;
  // estudios= [
  //   {descripcion:'Biopsia',codigo:'000268'},
  //   {descripcion:'Papanicolau',codigo:'000269'},


  // ];

  constructor(
    private authService: AuthenticationService,
    private formBuilder: UntypedFormBuilder,
    private estudiosService: EstudioService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private alertService: AlertService,
    public dialog: MatDialog,
    private pacienteService: PacienteService
  ) {
    this.currentUser =this.authService.currentUserValue.usuario;
  }

  ngOnInit() {
    // console.log(this.data);
    this.iniciarPedidoForm();
    this.obtenerEstudiosBiopsia();
    this.pacienteService
      .mutualesDePaciente(
        this.data.nroDocumento,
        this.data.tipoDocumento
      )
      .subscribe(
        (data) => {
          this.coberturasPaciente = data.paginador.elementos;
          this.obtenerFinanciadores();
         // console.log(this.coberturasPaciente);

          this.coberturaTurno = {
            financiadorId: parseInt(this.coberturasPaciente[0].Financiador, 10),
            nombre: this.coberturasPaciente[0].nombreFinanciador,
            nroCarnet: this.coberturasPaciente[0].nroCarnet,
            estado: 'ACT',
            fechaHoraEstado: ' ',
            usuarioEstado: ' ',
          };

          this.solicitarBiopsiaForm
            .get('financiador')
            .setValue(this.coberturaTurno.nombre);
          this.solicitarBiopsiaForm
            .get('nroCarnet')
            .setValue(this.coberturaTurno.nroCarnet);
        },
        (error) => {
          console.log(error);
        }
      );
      this.solicitarBiopsiaForm
      .get('financiador')
      .valueChanges.subscribe((value) => {
        this.options = [];
        this.coberturasPaciente.forEach((cobertura) => {
          if (cobertura.nombreFinanciador === value) {
            this.options.push(cobertura.nroCarnet);
          }
        });
        this.filteredOptions = of(this.options);
      });
    this.filteredOptions = this.solicitarBiopsiaForm
      .get('nroCarnet')
      .valueChanges.pipe(
        startWith(''),
        map((value) => this._filter(value))
      );
  }

  iniciarPedidoForm() {
    this.solicitarBiopsiaForm = this.formBuilder.group({
      // paciente: [ { value: null, disabled: true},  Validators.required ],
      estudio:[null,Validators.required],
      materialEnviado: [ null ],
      ubicacionLesionRB: [ null ],
      ubicacionLesionTexto: [ null ],
      biopsiaAnteriorRB: [ null ],
      biopsiaAnteriorTexto: [ null ],
      datosInteres: [ null ],
      recibirCorreo: [ 'true' ],
      financiador: [null, Validators.required],
      nroCarnet: [null, Validators.required],
    });

    this.cargando = false;
  }
 //carga del listado de financiadores
  obtenerFinanciadores(){
    this.solicitarBiopsiaForm
    .get('financiador')
    .valueChanges.subscribe((value) => {
      
      this.options = [];
      this.coberturasPaciente.forEach((cobertura) => {
        if (cobertura.nombreFinanciador === value) {
          this.options.push(cobertura.nroCarnet);
        }
      });
      this.filteredOptions = of(this.options);
    });
    
  this.filteredOptions = this.solicitarBiopsiaForm
    .get('nroCarnet')
    .valueChanges.pipe(
      startWith(''),
      map((value) => this._filter(value))
    );
    
      this.estudiosService
        .getFinanciadores(
          this.data.tipoDocumento,
          this.data.nroDocumento
        )
        .subscribe(
          (data) => {
            this.financiadores = data.paginador.elementos;
            
            /*Filtra financiadores con la cobertura del paciente*/
            this.financiadoresPaciente = this.financiadores.filter(
              (financiador) => {
                let guardar = false;
                this.coberturasPaciente.forEach((cobertura) => {
                  if (
                    !guardar &&
                    financiador.financiadorId ===
                      parseInt(cobertura.Financiador, 10)
                  ) {
                    guardar = true;
                    // console.log(financiador)
                  }
                });
                
                return guardar;
              }
            );
            // console.log(this.financiadoresPaciente);
            
          },
          (error) => {
            console.log(error);
          }
        );


  }
  getErrorMessage(Campo) {
    return this.solicitarBiopsiaForm.get(Campo).hasError('required') ? 'Este campo es obligatorio' : '';
  }
  async guardarPedidoBiopsia() {
    this.loadButton = true;
    
    const pedido = {
      diagnostico: null,
      nroCarpeta: 0,
      nroDniPaciente: this.data.nroDocumento,
      tipoDniPaciente: this.data.tipoDocumento,			
      itemsPedido: [{
        numeroItem: 1,
  			codigoEstudio: this.solicitarBiopsiaForm.get('estudio').value.codigo,
  			nombreEstudio: this.solicitarBiopsiaForm.get('estudio').value.estudio,
  			descripcion: null,
  			nomenclador: 9,
  			practica: {
          descripcion: this.solicitarBiopsiaForm.get('estudio').value.estudio,
          codigo: this.solicitarBiopsiaForm.get('estudio').value.codigo
        },
  			cantSolicitada: 1,
  			materialEnviado: this.solicitarBiopsiaForm.get('materialEnviado').value,
  			ubicacionLesionDetalle: this.solicitarBiopsiaForm.get('ubicacionLesionTexto').value,					
  			ubicacionLesion: this.solicitarBiopsiaForm.get('ubicacionLesionRB').value,			
  			huboBiopsiaAnt: this.solicitarBiopsiaForm.get('biopsiaAnteriorRB').value,			
  			resultadoBiopsiaAnt: this.solicitarBiopsiaForm.get('biopsiaAnteriorTexto').value,
  			datos: this.solicitarBiopsiaForm.get('datosInteres').value,
  			numeroPedidoExtracion: null,		
  			numeroParte: null,
        
      }],
      urgente: false,
      matriculaSolicitante: this.data.rolMHE.valorTipoID,
      servicioSolicitante: null,
      sectorInforme: null,
      notificarSolicitante: (this.solicitarBiopsiaForm.get('recibirCorreo').value) ? 'SI' : 'NO',
      numero: null,
      financiador:{ 
        nombre:this.solicitarBiopsiaForm.get('financiador').value,
        financiadorId:null,
      } ,
      nroCarnet:this.solicitarBiopsiaForm.get('nroCarnet').value
      
    };
    //recupero el id del financiador segun el financiador que se selecciono y me lo guardo en el pedido
    pedido.financiador.financiadorId = this.financiadoresPaciente.filter((financiador) => {
     if (
       financiador.nombre ===
       this.solicitarBiopsiaForm.get('financiador').value
     ) {
       return true;
     }
   })[0].financiadorId
    

    await this.estudiosService.guardarPedidoBiopsia(pedido).toPromise().then(resp => {
      // console.log(pedido);
      if (resp.ok) {
        // console.log(resp.value)
        this.hayImpresion = true;
        this.nroPedidoNuevo = resp.numero;
        this.alertService.success(resp.mensaje)
        this.estudiosService.registrarPedido(pedido).toPromise().then((respRegPedido) => {
          // console.log(respRegPedido)
        }, (error) => {
          console.log(error);
        });
      } else {
        this.alertService.error(resp.mensaje)
      }
      this.alerta = true;
      this.loadButton = false;
    }, error => {
      console.log(error);
    })
  }

  imprimirPedidoBiopsia() {
    const dialogRef = this.dialog.open(DialogPdfComponent, {
      width: '1300px',
      height: '700px',
      data: {
        url: this.estudiosService.imprimirPedidoBiopsia(this.nroPedidoNuevo),
      },
    });
    // console.log(this.nroPedidoNuevo)
  }


  imprimirPdfConcatenado() {
   
    const dialogRef = this.dialog.open(DialogPdfComponent, {
      width: '1300px',
      height: '700px',
      data: {
        url: this.estudiosService.concatenarOrdenBiopsia(this.nroPedidoNuevo,this.currentUser),
      },
    });
    // console.log(this.nroPedidoNuevo)
  }
  //esta funcion se trae los estudios relacionados con el servicio de ANATOMIA PATOLOGICA (Esto es para la solicitud de una biopsia)
  obtenerEstudiosBiopsia(){
   let estudio = {
    servicio: 51,
    estudio: null

   }
   this.estudiosService.buscarEstudiosDeServicio(estudio).subscribe((respuesta)=>
   {
    if(respuesta.ok){
      this.listaEstudios = respuesta.paginador.elementos;
    }
    // console.log(this.listaEstudios)
   }
   );
  
  }
  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.options.filter((option) =>
      option.toLowerCase().includes(filterValue)
    );
  }

}