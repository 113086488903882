<div class="container-fluid" style="border: 0px solid blue;">
    <div *ngIf="!mailActualizado && !loadButton">
        <h1 mat-dialog-title class="text-center mb-1">Campa&ntilde;a de actualizaci&oacute;n de correo</h1>
        <p>Por Favor, ingres&aacute; un email* al cual te podamos enviar el enlace para recuperar la contrase&ntilde;a, en caso de ser requerido.</p>
        <p>*El email ingresado no debe ser de extensi&oacute;n: @hospitalespanol.org.ar</p>
    </div>
    <div *ngIf="mailActualizado && !loadButton">
        <h1 mat-dialog-title class="text-center mb-1">Modificar correo</h1>
        <p>*El email ingresado no debe ser de extensi&oacute;n: @hospitalespanol.org.ar</p>
        <hr>
    </div>

    <mat-spinner [hidden]="!loadButton" style="margin: auto;"></mat-spinner>

    <form [formGroup]="cambiarCorreoForm" [hidden]="loadButton" style="border: 0px solid blue;" *ngIf="!loadButton">

        <div class="row" style="border: 0px solid yellow; margin-left: 0;">
            <mat-form-field class="full-width">
              <mat-label>Email Actual</mat-label>
              <input matInput formControlName="emailActual" required/>
              <!-- <mat-error *ngIf="cambiarCorreoForm.get('emailNuevo').invalid">{{getErrorMessage('emailNuevo')}}</mat-error> -->
            </mat-form-field>
        </div>

        <div class="row" style="border: 0px solid yellow; margin-left: 0;">
            <mat-form-field class="full-width">
              <mat-label>Email Nuevo</mat-label>
              <input matInput formControlName="emailNuevo" required/>
              <mat-error *ngIf="cambiarCorreoForm.get('emailNuevo').invalid">{{getErrorMessage('emailNuevo')}}</mat-error>
            </mat-form-field>
        </div>

        <div class="row" style="border: 0px solid yellow; margin-top: 1%; margin-left: 0;">
            <mat-form-field class="full-width">
              <mat-label>Confirmar Email</mat-label>
              <input matInput formControlName="confirmarEmail" required/>
              <mat-error *ngIf="cambiarCorreoForm.get('confirmarEmail').invalid">{{getErrorMessage('confirmarEmail')}}</mat-error>
            </mat-form-field>
        </div>

        <div class="row" style="border: 0px solid blue; margin-top: 1%; margin-left: 0;">
            <button mat-raised-button color="primary" (click)="cambiarCorreo()" style="margin-right: 1%;" [disabled]="guardandoMail">Actualizar</button>
            <button mat-raised-button color="warn" (click)="cerrarPopup()" [mat-dialog-close]="false" [disabled]="guardandoMail">{{ mailActualizado ? 'Cerrar' : 'Mas Tarde'}}</button>
        </div>

    </form>

</div>