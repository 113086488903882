import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnInit, Pipe, ViewChild } from '@angular/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import * as moment from 'moment';
import { EpisodioHistorial } from '../_models/episodioHistorial.model';
import { EpisodioHistorialInfo } from '../_models/episodioHistorialInfo.model';
import { AuthenticationService } from '../_services/authentication.service';
import { EstudioService } from '../_services/estudios.service';
import { PacienteService } from '../_services/paciente.service';
import { UrlResolverService } from '../_services/urlResolver.service';
import { DialogPdfComponent } from '../dialog-pdf/dialog-pdf.component';
import { MatDialog } from '@angular/material/dialog';
import { DialogConfirmacionComponent } from '../dialog-confirmacion/dialog-confirmacion.component';
import { EvolucionService } from '../_services/evolucion.service';
import { Router } from '@angular/router';
import { DialogMensajeComponent } from '../dialog-mensaje/dialog-mensaje.component';
import { UntypedFormControl } from '@angular/forms';
import {MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import { DomSanitizer } from '@angular/platform-browser';
import { FormulariosService } from '../_services/formularios.service';
import { EpisodiosPacienteService } from '../_services/episodiosPaciente.service';

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD-MM-YYYY',
  },
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

@Component({
  selector: 'app-paciente-historial',
  templateUrl: './paciente-historial.component.html',
  styleUrls: ['./paciente-historial.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
  providers: [
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ]
})

// @Pipe({
//   name: 'safe'
// })

export class PacienteHistorialComponent implements OnInit {
  currentPatient: any;
  currentUser: any;
  loading = false;
  dataSource;

  columnsToDisplay2 = [
    { name: 'fecha', jsonName: 'fechast' },
    { name: 'episodio', jsonName: 'tipoEpisodio' },
    { name: 'profesional', jsonName: 'nombreProfesional' },
    { name: 'acciones' , jsonName: 'acciones'}
  ];

  expandedElement: EpisodioHistorial | null;
  columnsToDisplay = ['fecha', 'episodio', 'profesional', 'acciones'];
  detailedInfo;
  matricula;
  roles:any;
  rolMHE=false;
  url:any;
  click = 0;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  horusUrl: any;
  loadingDetailedInfo = false;
  todosLosElementos: any [] = [];
  fechaDesde: string;
  fechaHasta: string;
  dateDesde = new UntypedFormControl(moment().subtract(6, 'months'));
  dateHasta = new UntypedFormControl(moment());
  maxDate = moment();
  cambioFechas: boolean = false;

  episodioVisualizado = null;

  constructor(
    private pacienteService: PacienteService,
    private authService: AuthenticationService,
    private estudioService: EstudioService,
    private urlResolver: UrlResolverService,
    private evoService: EvolucionService,
    public dialog: MatDialog,
    private route: Router,
    private sanitizer:DomSanitizer,
    private formularioService: FormulariosService,
    private episodiosPacienteService: EpisodiosPacienteService,
    private router: Router,
  ) {
    this.currentUser = this.authService.currentUserValue;
    //console.log(this.currentUser.roles)
    this.matricula = sessionStorage.getItem('matriculaAux');          //recupero la matricula del profesional desde agenda
  }

  ngOnInit() {
    this.urlResolver.getHorusfullUrl().subscribe((x) => {
      this.horusUrl = x.paginador.elementos[0].valor;
    });  
   
    this.currentPatient = this.pacienteService.getCurrentPatient(); 

    this.pacienteService.recargarHistorialEmitter.subscribe((x) => {
      this.cargarHistorial();
    });

    this.obtenerRoles();
    this.cargarHistorial();
  }

  async getHistorial(fechaDesde: string, fechaHasta: string) {
    await this.pacienteService.historialPaciente(this.currentPatient.numeroDoc, this.currentPatient.tipoDoc, this.matricula,
      fechaDesde, fechaHasta).toPromise().then((data) => {
        // console.log(data);
        data = data.sort((a, b) => b.fecha.diff(a.fecha));
        this.dataSource = new MatTableDataSource(data);
        this.dataSource.paginator = this.paginator;
        this.dataSource.data.forEach(element => {
          // console.log(element)
          if (element.tipoEpisodio === 'Estudio') {
            this.getInfoDeEstudios(element);
          }
        });
        this.cambioFechas = false;
        this.loading = false;
    }, (error) => {
      console.log(error);
      this.loading = false;
    });
  }

  async cargarHistorial() {
    this.loading = true;
    this.fechaDesde = moment(this.dateDesde.value).startOf('day').format("YYYY-MM-DD HH:mm:ss");
    this.fechaHasta = moment(this.dateHasta.value).endOf('day').format("YYYY-MM-DD HH:mm:ss");

    if (this.currentPatient) {
      await this.getHistorial(this.fechaDesde, this.fechaHasta);
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  obtenerRoles() {
    this.roles = this.currentUser.roles;
    this.roles.forEach((rol) => {
      if(rol.codigo === 'MHE')
        this.rolMHE = true;
    });
  }

  getInfoDeEstudios(element) {
    this.estudioService.getPedido(element).toPromise().then(async (data: EpisodioHistorialInfo) => {
      // console.log(element)
      // console.log(data)
      
      element.estudiosPaciente = data.estudiosPaciente;
      await element.estudiosPaciente.sort((a,b) => 0 - (a.numeroItem > b.numeroItem ? -1 : 1));
      if (element.sectorInforme === 1) {                             // SI ES DxI BUSCO LAS IMAGENES
       // console.log(element);
        element.estudiosPaciente.forEach(est => {
          this.getImgAurora(est, data);
        });
  
        await this.estudioService.chequeoEstadoImagenesAngras(element.estudiosPaciente[0].pedido.numero).toPromise().then(respCheckImg => {
          // console.log(respCheckImg)
          for (let index = 0; index < element.estudiosPaciente.length; index++) {
            element.estudiosPaciente[index].estadoImagen = respCheckImg.paginador.elementos[index];
          }
        }, error => {
          console.log(error);
        })
      }
    }, (error) => {
      console.log(error);
    });
  }

  async getImgAurora(estudio, pedido) {
    // console.log(estudio)
    // console.log(pedido)

    estudio.pedido = {
      numero: pedido.numero
    }

    await this.estudioService.getUrlImgAurora(estudio).toPromise().then(resp => {
      // console.log(resp)
      estudio.estadoImgAurora = resp.error;
      estudio.urlAurora = resp.url;
    }, error => {
      console.log(error)
    })
  }

  irAInternacion(episodio: EpisodioHistorial) {
    window.open(
      window.location.protocol +
      '//' +
      this.horusUrl +
      '/he_fe/#/login?site=internacion/ver/' +
      episodio.idEpisodio,
      '_blank'
    );
  }

  openDialog(episodio: EpisodioHistorial): void {
    this.dialog.open(DialogPdfComponent, {
      width: '1300px',
      height: '700px',
      data: {
        url: this.estudioService.imprimirHCDigital(episodio.idEpisodio),
      },
    });
  }

  openDialogEstudio(episodio) {
   
    episodio.cargandoEstudio=true;
   
    this.episodioVisualizado = episodio.idEpisodio;
    this.url = 
    (episodio.idTipoEpisodio === 43 || episodio.idTipoEpisodio === 44 ) ? this.estudioService.getRegistroHcePdf(episodio) : 
    (episodio.especialidadAtencion.indexOf('HEM') === -1
      ? this.estudioService.imprimirEstudio(episodio.idEpisodio)
      : this.estudioService.imprimirEstudioNuevo(episodio.idEpisodio));
      this.sanitizer.bypassSecurityTrustResourceUrl(this.url);

   this.loadIframe(this.url,episodio);
    
  }
  archivoCargado(episodio){
   // console.log('entro al onload');
    episodio.cargandoEstudio=false;
  }
  loadIframe(url,episodio): void {
    const iframe = document.getElementById(episodio.idEpisodio) as HTMLIFrameElement;

   // console.log(iframe);
    iframe.onload = () => {
      //console.log('El iframe ha sido cargado');
      this.archivoCargado(episodio);

    };
    iframe.src = url;
  }
  
  



  evolucionEnTiempo?(episodio: EpisodioHistorial) {
    return moment().diff(episodio.fecha, 'hours') < 24;
  }

  borrarEvolucion(episodio: EpisodioHistorial) {
    this.dialog.open(DialogConfirmacionComponent, {
      width: '500px',
      data: 'Desea borrar la evolución?',
    }).afterClosed().subscribe((result) => {
      if (result) {
        this.pacienteService.borrarEvolucion(episodio.evolucion).subscribe((data) => {
          this.cargarHistorial();
          // this.pacienteService.recargarHistorial();
          }, (error) => {
            console.log(error);
        });
      }
    });
  }

  editarEvolucion(episodio: EpisodioHistorial) {
    this.evoService.setEvo(episodio.evolucion);
  }

  agregarPDF(paciente) {
    this.pacienteService.setCurrentPatient(paciente)  
    this.route.navigate(['/estudios-externos']);
  }

  borrarDocumento(estudio) {
    this.dialog.open(DialogConfirmacionComponent, {
      data: `¿Seguro desea borrar el documento?`,
    }).afterClosed().subscribe((confirmado: boolean) => {
      if (confirmado) {
        this.estudioService.bajaRegistroHCEPacientes(estudio, this.currentPatient.usuario).subscribe((respuesta) => {
          if (respuesta.ok) {
            this.openRespuesta(respuesta);
          }
        })
      }
    })
  }

  openRespuesta(respuesta) {
    this.dialog.open(DialogMensajeComponent, {
      width: '500px',
      data: {ok:respuesta.ok, mensaje:respuesta.mensaje}
    }).afterClosed().subscribe(()=> {
        location.reload()     
    })
  }

  cambioFechaDesde(event: MatDatepickerInputEvent<Date>) {
    // this.cambioFechas = true;
    this.fechaDesde = moment(event.value).startOf('day').format("YYYY-MM-DD HH:mm:ss");
  }

  cambioFechaHasta(event: MatDatepickerInputEvent<Date>) {
    // this.cambioFechas = true;
    this.fechaHasta = moment(event.value).endOf('day').format("YYYY-MM-DD HH:mm:ss");
  }

  async filtrarPorFechas() {
    this.loading = true;
    await this.getHistorial(this.fechaDesde, this.fechaHasta);
  }

  abrirEnNuevaVentana(episodio: EpisodioHistorial) {
  if (episodio.hcdigital) {
    this.url = this.estudioService.imprimirHCDigital(episodio.idEpisodio);
  }
  else {
    this.url = 
        (episodio.idTipoEpisodio === 43 || episodio.idTipoEpisodio === 44 ) ? this.estudioService.getRegistroHcePdf(episodio) : 
        (episodio.especialidadAtencion.indexOf('HEM') === -1
          ? this.estudioService.imprimirEstudio(episodio.idEpisodio)
          : this.estudioService.imprimirEstudioNuevo(episodio.idEpisodio)); }
  window.open(this.url, '_blank');

  }

  imprimirTodoTrasplante(idEpisodio: any) {
    const dialogRef = this.dialog.open(DialogPdfComponent, {
      width: '1300px',
      height: '700px',
      data: {
        url: this.formularioService.imprimirEpisodio(idEpisodio, this.authService.currentUserValue.usuario),
      },
     });
  }


  irAInternaciones(subepisodio) {
    if (subepisodio.carpeta.fechaEgreso) {
      // si tiene fecha de egreso debe redirigir como el boton ver detalles
      window.open(
        window.location.protocol +
          '//' +
          this.horusUrl +
          '/he_fe/#/login?site=internacion/ver/' +
          subepisodio.idHistorial,
        '_blank'
      );
    }
    else {
      // si es una carpeta activa redirige a SM Internaciones Trasplantes
      window.open(
        window.location.protocol+'//'+this.horusUrl+'/he_fe/#/login?site=internacion/camas_xservicio/43', 
        '_blank'
      );
    }
  }

  verFormularios(subEpisodio) {
    this.pacienteService.setCurrentPatient(this.currentPatient);
    this.episodiosPacienteService.setCurrentEpisodio(subEpisodio);
    localStorage.setItem('urlback', '/paciente');
    this.router.navigate(['/ver-formularios-episodio']);
  }

}