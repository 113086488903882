<div class="container-fluid" style="margin-top: 1%">
  <mat-card [tabindex]="0" class="card-principal">
    <div class="container-fluid">
      <div class="row">
        <div class="col">
          <h3>
            Formularios
          </h3>
        </div>
        <div class="col">
          <button mat-icon-button 
                  class="float-right"
                  color="primary" 
                  (click)="volver()"
                  style="margin-right: 8%">
            <mat-icon>keyboard_backspace</mat-icon> Volver
          </button>   
        </div>
      </div>

      <div
        class="d-flex justify-content-center"
        style="margin-top: 10%"
        *ngIf="loading">
        <mat-spinner [diameter]="60"></mat-spinner>
      </div>
      
      <div [hidden]="loading" class="row">
        <!-- <div class="col">
          <mat-divider></mat-divider>
          <mat-card-header style="margin-left: -16px;margin-top:1.5%">
           
            <mat-card-title>
              {{ currentPatient.str_paciente | toTitleCase }}
            </mat-card-title>

            <mat-card-subtitle>
              <span>
                <span *ngIf="this.currentPatient.tipoDoc == 9">
                  Temporal</span
                >
                <span *ngIf="this.currentPatient.tipoDoc == 0"> DNI:</span>
                 {{ currentPatient.numeroDoc }}
              </span>
              |
              <span *ngIf="currentPatient.coberturas.length > 0"
                >{{ currentPatient.coberturas[0].mutual | toTitleCase }} -
              </span>
              <span *ngIf="currentPatient.coberturas.length > 0">{{
                currentPatient.coberturas[0].nroCarnet
              }}</span>
            </mat-card-subtitle>

            <mat-card-subtitle>
              <span>Edad: {{ currentPatient.edad }} | </span>
              <span>{{ currentPatient.sexo | toTitleCase }}</span>
              |
              <span>Nacimiento: {{ currentPatient.fechaNacimiento }}</span>
            </mat-card-subtitle>

          </mat-card-header>
          
        </div> -->
      </div>

      <div [hidden]="loading" class="row">
        <div class="col">
          <mat-divider></mat-divider>
          <mat-card-header style="margin-left: -16px;margin-top:1.5%" *ngIf="currentEpisodio">
           
            <mat-card-title>
              {{ currentEpisodio.descripcion | toTitleCase }}
            </mat-card-title>

            <mat-card-subtitle>
              <span>Inicio:</span> <b> {{ currentEpisodio.fechaInicio }}</b>
              |
              <span>Paciente:</span><b> {{ currentPatient.str_paciente | toTitleCase}}</b> 
            </mat-card-subtitle>
          
            <mat-card-subtitle *ngIf="currentEpisodio.tipoDeEpisodio.descripcion">
              <b style="color:black"> {{ currentEpisodio.tipoDeEpisodio.descripcion | toTitleCase }}</b>
            </mat-card-subtitle> 

          </mat-card-header>
          
        </div>
        <div class="col">
          <div class="row" style="padding-top: 5%;padding-left: 12%;">
             <div class="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7 ">
              <mat-form-field style="width: 100%;">
                <mat-label>Seleccionar formulario</mat-label>
                <mat-select  [(value)]="formularioSeleccionado" class="float-right" >
                    <mat-option>
                        <ngx-mat-select-search placeholderLabel="Buscar formulario" 
                                              noEntriesFoundLabel="No se encontraron formularios" 
                                              [formControl]="filterCtrl">
                        </ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let formulario of this.formulariosFiltrados" [value]="formulario">
                        {{ formulario.nombre | toTitleCase }}
                    </mat-option>

                </mat-select>
              </mat-form-field>
             </div>
             <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 ">
              <button mat-raised-button  [disabled]="!formularioSeleccionado" 
                                          color="primary" 
                                          (click)="irCompletarFormulario(this.formularioSeleccionado.idFormulario)" 
                                          style="border-radius: 8px; margin-top:8%;margin-right: 8%;"
                                          class="float-right">
                <mat-icon>description</mat-icon>
                Crear formulario
              </button>
          
              </div>
          </div>
        </div> 
      </div>

      <mat-card-content>
        
      <div  class="row" style="margin-top: 1%;">
        <div [hidden]="loading" class="col">
          
          <div style="text-align: center; margin-top: 5%;  " *ngIf="sinFormularios">
            <mat-card class="sin-formulario-card">
              <h5 style="color:#3F51B5" >No se encontraron formularios</h5>
            </mat-card>
          </div>
          
          <div *ngIf="!sinFormularios" class="row justify-content-center align-items-center" >
            <div class="col">
              <mat-form-field >
                <mat-label>Buscar</mat-label>
                <input matInput (keyup)="applyFilter($event)" autocomplete="off" />
              </mat-form-field>
            </div>
            <div class="col">
              <button mat-icon-button 
                      class="float-right"
                      color="primary" 
                      (click)="imprimirEpisodio(currentEpisodio.idEpisodio)"
                      style="margin-right: 16%">
                  <mat-icon>print</mat-icon> 
                  Imprimir todo
              </button>  
              
            </div>

          </div>
          

         

          <table
            mat-table
            [dataSource]="dataSourceItems"
            #tablaEpisodios
            class="mat-elevation-z8"
            *ngIf="!sinFormularios"
          >
            <div style="text-align: center; margin-right: 1px">
              <ng-container matColumnDef="nombre">
                <th mat-header-cell *matHeaderCellDef>Nombre</th>
                
                <td mat-cell *matCellDef="let formulario">
                  {{ formulario.nombre }} 
                </td>
               
              </ng-container>
            </div>

            <div style="text-align: center; margin-right: 1px">
              <ng-container matColumnDef="fechaHoraRegistro">
                <th mat-header-cell *matHeaderCellDef>Fecha</th>
                
                <td mat-cell *matCellDef="let formulario">
                  {{ formulario.cabecera.fechaHoraRegistro }} 
                </td>
               
              </ng-container>
            </div>

            <div style="text-align: center; margin-right: 1px">
              <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef>Acciones</th>
                <td mat-cell *matCellDef="let formulario" class="action-link">
                  <button
                    matTooltip="Imprimir"
                    (click)="imprimirFormulario(formulario.cabecera)"
                    color="primary"
                    mat-icon-button
                  >
                    <mat-icon>picture_as_pdf</mat-icon>
                  </button>

                  <button
                    matTooltip="Editar"
                    (click)="editarFormulario(formulario)"
                    color="primary"
                    mat-icon-button
                  >
                    <mat-icon>edit</mat-icon>
                  
                  </button>

                  <button
                    matTooltip="Borrar"
                    (click)="abrirDialogConfirmacionBorrar(formulario)"
                    color="warn"
                    mat-icon-button
                  >
                    <mat-icon>delete</mat-icon>   
                  </button>

                </td>
              </ng-container>
            </div>

            <tr mat-header-row *matHeaderRowDef="displayedColumnasItems"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumnasItems"></tr>
          </table>
          <mat-paginator *ngIf="!sinFormularios" [pageSizeOptions]="[5]"></mat-paginator>

        </div>
      </div>
    </mat-card-content>

    </div>
  </mat-card>
</div>

