import { Component, OnInit, NgZone, ViewChild } from '@angular/core';
import { FormulariosService } from '../_services/formularios.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import { FormularioGenerico } from '../_models/formularioGenerico.interface';
import { CampoFormulario } from '../_models/campoFormulario.interface';
import { CabeceraFormulario } from '../_models/cabeceraFormulario.interface';
import { PacienteService } from '../_services/paciente.service';
import { AuthenticationService } from '../_services/authentication.service';
import { EpisodiosPacienteService } from '../_services/episodiosPaciente.service';
import { ActivatedRoute, Router, NavigationStart } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { DialogPdfComponent } from '../dialog-pdf/dialog-pdf.component';
import { DialogMensajeComponent } from '../dialog-mensaje/dialog-mensaje.component';
import { DialogConfirmacionComponent } from '../dialog-confirmacion/dialog-confirmacion.component';
import {CdkTextareaAutosize} from '@angular/cdk/text-field';
import {take} from 'rxjs/operators';
import { Episodio } from '../_models/episodio.interface';

@Component({
  selector: 'app-escribir-formulario',
  templateUrl: './escribir-formulario.component.html',
  styleUrls: ['./escribir-formulario.component.css']
})
export class EscribirFormularioComponent implements OnInit {

  formularioResp: FormularioGenerico;
  loading: boolean;

  formulario: UntypedFormGroup[] = [];
  arrayCampos: CampoFormulario[] = [];
  formCampos: UntypedFormGroup;
  campoValor: CampoFormulario;
  cabeceraFormulario: CabeceraFormulario;
  paciente: any;
  submitted: boolean;
  loadingSubmit = false;

  formValido: boolean;
  currentEpisodio: Episodio;
  currentFormularioCabecera: CabeceraFormulario;
  formularioValores: any;

  constructor(
    private formularioService: FormulariosService,
    private formBuilder: UntypedFormBuilder,
    private pacienteService: PacienteService,
    private authService: AuthenticationService,
    private episodioPacienteService: EpisodiosPacienteService,
    public dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private ngZone: NgZone) { }

    @ViewChild('autosize') autosize: CdkTextareaAutosize;

    triggerResize() {
      // Wait for changes to be applied, then trigger textarea resize.
      this.ngZone.onStable.pipe(take(1)).subscribe(() => this.autosize.resizeToFitContent(true));
    }

  async ngOnInit() {
    this.loading = true;
    this.currentFormularioCabecera = this.formularioService.getCurrentFormularioCabecera();
    // console.log(this.currentFormularioCabecera);
    if (this.currentFormularioCabecera) {
      await this.cargarValores(this.currentFormularioCabecera.idFormularioCabecera);
    }
    this.currentEpisodio = this.episodioPacienteService.getCurrentEpisodio();
    this.getFormularioGenerico(this.route.snapshot.params['idFormulario']);
    this.eventListenerBack();
  }

  eventListenerBack() {
    this.router.events.subscribe((event: NavigationStart) => {
      if (event.navigationTrigger === 'popstate') {
        this.formularioService.removeCurrentFormularioCabecera();
        // console.log('entro al evento');
      }
    });
  }

  private async cargarValores(idFormularioCabecera) {
    await this.formularioService.getFormularioValores(idFormularioCabecera).toPromise().then(
      (data) => {
       // console.log('cargarValores -> data', data);
        this.formularioValores = data.formulario;
      },
      (error) => {
        console.log(error);
      }
    );
  }

  private async getFormularioGenerico(idFormularioGenerico) {
    await this.formularioService.getFormulario(idFormularioGenerico).toPromise().then(
      (data) => {
       // console.log(data);
        if (data.ok) {
          this.formularioResp =  data.formulario as FormularioGenerico;
          this.generarFormulario();
        } else {
          this.loading  = false;
          this.abrirDialogMensaje(data);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  private generarFormulario() {
    // console.log(this.formularioResp);
    let auxCompletado = '';
    this.formularioResp.listaSecciones.forEach((seccion, indexSeccion) => {
      this.formCampos = this.formBuilder.group({});
      seccion.listaCampos.forEach( (campo, indexCampo) => {
      // acá seria if (tipo = string and  campo.informacionCampo.length > 0)
        if (this.currentFormularioCabecera) {
          auxCompletado = this.formularioValores.listaSecciones[indexSeccion].listaCampos[indexCampo]
                          .valor.replace(/<br\s*[\/]?>/gi, '\n');
        } else {
          auxCompletado = '';
        }
        if ( auxCompletado === '' && campo.listaOpciones.length > 0) {
          auxCompletado = campo.listaOpciones[0].informacionCampo;
        }

        this.formCampos.addControl(campo.nombre, this.formBuilder.control(
        auxCompletado,
        campo.obligatorio === 'SI' ? [Validators.required, Validators.pattern("[^<>&\"]*")] : [Validators.pattern("[^<>&\"]*")]));

      });
     /* seccion.listaCampos.forEach( (campo) => {
        this.formCampos.addControl(campo.nombre, this.formBuilder.control('', [])); 
      });*/

      this.formulario[seccion.nombre] = this.formCampos;
    });
    this.loading  = false;

    setTimeout(() => {
      const collectElementos = document.getElementsByClassName('editor') as HTMLCollectionOf<HTMLElement>;
      Array.from(collectElementos).forEach((elemento) => {
        elemento.style.height = elemento.scrollHeight + 'px';
      });
     }, 10);

   // console.log(this.formulario);
  }

  getFormSeccion(nomSeccion): UntypedFormGroup {
    return this.formulario[nomSeccion] as UntypedFormGroup;
  }

  onSubmit() {
    this.submitted = true;
    this.loadingSubmit = true;    

    this.formValido = true;
   // console.log(this.formulario);

    /*
    Reviso campo a campo para ver si alguno es invalid y
    marco los campos que no fueron tocados, para que genere el error de vacio
    */
    this.formularioResp.listaSecciones.forEach((seccion) => {
      this.formCampos = this.getFormSeccion(seccion.nombre);
      seccion.listaCampos.forEach( (campo) => {
        if (this.formCampos.controls[campo.nombre].untouched) {
          this.formCampos.controls[campo.nombre].markAsTouched();
        }
        if ( this.formCampos.controls[campo.nombre].invalid) {
         this.formValido = false;
        }

      });
    });


    if (!this.formValido) {
     // console.log('formulario invalido');
      this.abrirDialogMensaje({ok: false, mensaje: 'Formulario inválido'});
     // this.submitted = false;
      this.loadingSubmit=false;
      return;
    }
    /*
    Genera el arreglo de campos con valores
    */
    this.formularioResp.listaSecciones.forEach((seccion, indexSeccion) => {
      this.formCampos = this.getFormSeccion(seccion.nombre);

      seccion.listaCampos.forEach( (campo, indexCampo) => {
      // console.log(campo.nombre);
        let auxValorCampo;
        if (this.formCampos.controls[campo.nombre].pristine) {
          if (this.currentFormularioCabecera) {
             /*Guarda el valor del formulario original*/
            auxValorCampo = this.formularioValores.listaSecciones[indexSeccion].
                          listaCampos[indexCampo].valor;
          } else {
            /*Guarda vacio*/
            auxValorCampo = '';
          }
        } else {
           /*Guarda el valor modificado*/
          auxValorCampo = this.formCampos.get(campo.nombre).value.replace(/(?:\r\n|\r|\n)/g, '<br>');
        }

        this.campoValor = {
          idFormularioValores: null,
          idCampo: campo.idCampo,
          nombre: campo.nombre,
          valor:  auxValorCampo
        };
        /*Si está editando, guarda solo los campos que se modificaron*/
        if (this.currentFormularioCabecera) {
          if ( this.campoValor.valor !== this.formularioValores.listaSecciones[indexSeccion].
              listaCampos[indexCampo].valor ) {
                this.campoValor.idFormularioValores = this.formularioValores.listaSecciones[indexSeccion].
                                                      listaCampos[indexCampo].idFormularioValores;
                this.arrayCampos.push(this.campoValor);
          }
        } else {
          this.arrayCampos.push(this.campoValor);
        }
      });
    });

    this.paciente = this.pacienteService.getCurrentPatient();

    /*
    Genera la cabeceraFormulario
    */
    this.cabeceraFormulario = {
      idFormulario: this.formularioResp.idFormulario,
      tipoDocumento: this.paciente.tipoDoc,
      nroDocumento: this.paciente.numeroDoc,
      loginRegistro: this.authService.currentUserValue.usuario,
      idEpisodio: this.currentEpisodio.idEpisodio,
      idFormularioCabecera: this.currentFormularioCabecera ?
                  this.currentFormularioCabecera.idFormularioCabecera : null,
      estado: this.currentFormularioCabecera ? null : 'ACT',
      loginEstado: this.authService.currentUserValue.usuario,
      loginUltModificacion: this.authService.currentUserValue.usuario
    };

    /*console.log(this.arrayCampos);
    console.log(this.cabeceraFormulario);*/

    this.formularioService.guardarFormularioValores(
      this.arrayCampos, this.cabeceraFormulario).subscribe(
      (data) => {
       // console.log(data);
       this.loadingSubmit = false;
        if (data.ok) {
          //this.imprimirFormulario(data.idFormularioCabecera);
          this.submitted = false;
          const dialog = this.abrirDialogMensaje(data);
          dialog.afterClosed().subscribe(x => {
            this.router.navigate(['/ver-formularios-episodio']);
          })
          
        } else {
          this.abrirDialogMensaje(data);
        }
      },
      (error) => {
        console.log(error);
      }
    );

  }

  imprimirFormulario(idFormularioCabecera) {
    this.dialog.open(DialogPdfComponent, {
      width: '1300px',
      height: '700px',
      data: {
        url: this.formularioService.imprimirFormulario(
          idFormularioCabecera,
          this.authService.currentUserValue.usuario
        ),
      },
    }).afterClosed()
    .subscribe(
      (confirmado: boolean) => {
        if (confirmado) {
          this.dialog.closeAll();
          this.router.navigate(['/ver-formularios-episodio']);
         // window.location.reload();
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  abrirDialogMensaje(resp) {
    const d = this.dialog.open(DialogMensajeComponent, {
      width: '500px',
      height: '100px',
      data: resp,
    });
    return d;
  }

  getErrorMessage(nomSeccion, nomCampo) {
   // console.log('intenta con nomSeccion:');
    return this.formulario[nomSeccion].get(nomCampo)
    .hasError('required') ? 'Este campo es obligatorio' : '';
  }

  volver() {
    this.abrirDialogConfirmacionVolver();
  }

  abrirDialogConfirmacionVolver() {
    this.dialog.open( DialogConfirmacionComponent, {
      data: `¿Está seguro que desea volver? `,
    })
    .afterClosed()
      .subscribe(
        (confirmado: boolean) => {
          if (confirmado) {
            this.formularioService.removeCurrentFormularioCabecera();
            this.router.navigate(['/ver-formularios-episodio']);
          } else {
            this.dialog.closeAll();
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }
}
