<button
  mat-icon-button
  style="float: right; top: -24px; right: -24px"
 
  (click)="cerrar()"
>
  <mat-icon class="close-icon" color="warn">close</mat-icon>
</button>
<h1 mat-dialog-title style="padding-left: 0%; padding-right: 3.5%">Pdf</h1>

<div>
  <div
    class="d-flex justify-content-center"
    style="margin-top: 10%"
    *ngIf="this.cargando"
  >
    <mat-spinner [diameter]="30"></mat-spinner>
  </div>
  <iframe
    style="border: none"
    width="100%"
    height="500"
    type="application/pdf"
    [src]="selectedUrl | safeUrl"
    (load)="setearEnFalse()"
  ></iframe>
</div>
