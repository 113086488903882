<div class="text-left" >
  <h1 mat-dialog-title>{{accion}} item</h1>          
      <mat-form-field style="width:100% !important">
        <input
          [disabled]="local_data.tipoRecetaFarmacia"
          matInput
          type="text"
          placeholder="{{accion}} descripción"
          [(ngModel)]="local_data.descripcion"
        />
      </mat-form-field>
     <div *ngIf= "local_data.tipoRecetaFarmacia">
      <mat-form-field style="width:25%">
        <input
            matInput
            type="number"
            min="1" max="100"
            placeholder="{{accion}} unidades"
            [(ngModel)]="local_data.cantidad"
            />
      </mat-form-field>
      <mat-form-field style="width:100% !important">
        <input
            matInput
            type="text"
            placeholder="{{accion}} tratamiento"
            [(ngModel)]="local_data.tratamiento"
          />
      </mat-form-field>
      </div>
  <div mat-dialog-actions class="d-flex justify-content-center">
    <button mat-button (click)="cancelar()" mat-raised-button color="warn">Cancelar</button>

    <button [hidden]="local_data.tipoRecetaFarmacia" 
        [disabled]="!local_data.descripcion" 
        mat-button (click)="accionar()" mat-raised-button color="primary">{{accion}}</button>

    <button [hidden]="!local_data.tipoRecetaFarmacia"
            [disabled]="!(local_data.cantidad)" 
            mat-button (click)="accionar()" mat-raised-button color="primary" >{{accion}}</button>
  </div>
</div>
