<button
  mat-icon-button
  style="float: right; top: -24px; right: -24px"
  [mat-dialog-close]="false"
>
  <mat-icon class="close-icon" color="warn">close</mat-icon>
</button>
<h1 mat-dialog-title>Datos de la agenda</h1>
<div mat-dialog-content>
  <h6>
    Dia: <span class="text-muted">{{ data.nombreDia | toTitleCase }}</span>
  </h6>
  <h6>
    Horario:
    <span class="text-muted"
      >{{ data.horaInicio | date: 'HH:mm' }} a
      {{ data.horaFin | date: 'HH:mm' }}</span
    >
  </h6>
  <h6>
    Servicio:
    <span class="text-muted">{{ data.nomServicio | toTitleCase }}</span>
  </h6>
  <h6>
    Consultorio:
    <span class="text-muted">{{ data.nomCons | toTitleCase }}</span>
  </h6>
  <h6>
    Vigencia:
    <span class="text-muted"
      >{{ data.vigenciaInicio | date: 'dd/MM/yyyy' }}
    </span>
    <span
      class="text-muted"
      *ngIf="
        (data.vigenciaFin | date: 'dd/MM/yyyy') !== '01/01/1900';
        else sinFinVigenciaTemplate
      "
    >
      a {{ data.vigenciaFin | date: 'dd/MM/yyyy' }}</span
    >
    <ng-template #sinFinVigenciaTemplate>
      <span class="text-muted"> <!-- la actualidad --></span></ng-template
    >
  </h6>

  <h6>
    Cantidad de turnos: <span class="text-muted">{{ data.cantTurnos }}</span>
  </h6>
  <h6>
    Tiempo de turnos: <span class="text-muted">{{ data.minTurno }}</span>
  </h6>
  <h6>
    Dias de anticipación: <span class="text-muted">{{ data.diasAntic }}</span>
  </h6>
</div>

<!-- 

     <h5>
    Vigencia inicio: {{data.vigenciaInicio }} a Vigencia fin:{{data.vigenciaFin
    }}
  </h5>
  <h5>Horario: {{data.horaInicio }} a Vigencia fin:{{data.horaFin }}</h5>
  <p>
    Cantidad de turnos: {{data.cantTurnos }} - Tiempo de turnos: {{data.minTurno
    }}{{data.minTurno }} - Dias de anticipación: {{data.diasAntic }}
  </p>
  <p>
    Servicio: {{data.servicio }} - Descripción de servicio: {{data.nomServicio
    }}
  </p>
  <p>
    Consultorio: {{data.cons }} - Descripción de consultorio: {{data.nomCons }}
  </p>
  <p>Dia: {{data.numeroDia }} - Nombre del dia: {{data.nombreDia }}</p>

 -->
