import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { throwToolbarMixedModesError } from '@angular/material/toolbar';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { DialogMensajeComponent, Respuesta } from '../dialog-mensaje/dialog-mensaje.component';
import { EstudioService } from '../_services/estudios.service';
import { AuthenticationService } from '../_services/authentication.service';

import { UserService } from '../_services/user.service';
import { DialogConfirmacionComponent } from '../dialog-confirmacion/dialog-confirmacion.component';
import { PacienteService } from '../_services/paciente.service';
import { TurnosService } from '../_services/turnos.service';

@Component({
  selector: 'app-estudios-externos',
  templateUrl: './estudios-externos.component.html',
  styleUrls: ['./estudios-externos.component.css'],
  providers: [
    {provide: MAT_DATE_LOCALE, useValue: 'es-ES'},
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
  ],
})
export class EstudiosExternosComponent implements OnInit {

  patient: any;
  currentUser: any;
  profesional:any;
  fileName = '';
  form: UntypedFormGroup;
  maxDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
  loading = false;
  submitted: boolean;
  file: File;
  output: String;
  matricula; 
  userModifico;
  constructor( 
    private userService: UserService,
    private estudioService: EstudioService,
    private formBuilder: UntypedFormBuilder,
    public dialog: MatDialog,
    private router: Router,
    private authService: AuthenticationService,
    private pacienteService: PacienteService) { 

      this.patient = pacienteService.getCurrentPatient(); //obtengo los datos del paciente seleccionado
      this.matricula = sessionStorage.getItem('matriculaAux');//recupero la matricula del profesional desde agenda
      
  }

   ngOnInit() {
    // console.log(this.matricula)
    this.currentUser = this.authService.currentUserValue;   
    this.initForm();    
  }


  initForm() {
    this.form = this.formBuilder.group({
      description:['', [Validators.required, Validators.pattern(/^[a-zA-Z0-9 .,]*$/)]],
      date: ['', [Validators.required]],
      file: ['', [Validators.required]]
    });
  }


  onFileSelected(event) {    
    this.file = event.target.files[0];    
    if (this.file) {
        this.fileName = this.file.name;        
    }
  
  }
  
  saveData(){    
    this.submitted = true;    
    if (this.form.dirty) {
      if (this.form.valid){
      this.output = JSON.stringify(this.buildingJson());      
      
      this.estudioService.guardarRegistroHCEPacientes(this.output, this.file).subscribe((res) => {
              
              this.openDialog(res)
                    
          },
          (error) => {
            console.log('error' + error);
          }
        );
      }
    }
    else
    {
      this.dialog.open(DialogMensajeComponent, {
        width: '500px',
        data: {
          ok: false,
          mensaje: 'Por favor, complete los campos',
        }
      })
    }
  } 

  get getForm() {
    return this.form.controls;
  }

  
  buildingJson(){
    
    return {
      tipoRegistro: {tipoRegistroId: 44},
      tipoDocumento: parseInt(this.patient.tipoDoc), 
      numeroDocumento: parseInt(this.patient.numeroDoc),
      descripcion: this.form.value.description,
      fechaRegistro: moment(this.form.value.date).format("DD/MM/YYYY"),
      matriculaAutor: this.matricula,
      tipoEpisodio: 'Registros de medicos',
      UsuarioModifico: this.currentUser.usuario,
      referenciaDocumento: this.patient.tipoDoc + '_' + this.patient.numeroDoc+ '_' + Math.floor(1000 + Math.random() * 9000)
      // estado: 'ACT',
      // login_estado: this.patient.usuario,
      // fecha_hora_estado: new Date()
    }
  }

  getErrorMessageDescription() {
    return this.form.controls.description.hasError('pattern') ? 'Ingrese solo letras, numeros, puntos y comas' :        
        this.form.controls.description.hasError('required') ? 'La descripcion es obligatoria' : '';
  }

  getErrorMessageDate() {
    return this.form.controls.date.hasError('required') ? 'La fecha es obligatoria': '';
  }

  getErrorMessageFile() {
    return this.form.controls.file.hasError('required') ? 'Debe adjuntarse un archivo .PDF': '';
  }

  openDialog(res: Respuesta): void {
    this.dialog.open(DialogMensajeComponent, {
      width: '500px',
      data: res,
    }).
    afterClosed().subscribe(() => {
       if (res.ok) {        
        this.router.navigate(['/paciente']);  
       }
     });
  }

  back(){
    this.dialog.open(DialogConfirmacionComponent, {
      data: `¿Seguro desea cancelar la carga del documento?`,
    })
    .afterClosed()
    .subscribe((confirmado: boolean) => {
      if (confirmado) {
        this.router.navigate(['/paciente']);        
      }
    });
  }
  volver() {

    this.router.navigate(['/paciente']);
  }
}
