<button mat-icon-button style="float: right; top: -12px; right: -12px" [mat-dialog-close]="false">
  <mat-icon class="close-icon" color="warn">close</mat-icon>
</button>

<h1 mat-dialog-title>Solicitar orden</h1>

<div class="container-fluid">
  <form [formGroup]="solicitudEstudiosForm">
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
        <mat-form-field>
          <input
            matInput
            type="text"
            formControlName="paciente"
            placeholder="Paciente *"
          />
        </mat-form-field>
      </div>

      <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
        <mat-form-field>
          <input
            matInput
            type="text"
            formControlName="dni"
            placeholder="DNI *"
          />
        </mat-form-field>
      </div>

      <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
        <mat-form-field>
          <mat-label>Financiador</mat-label>
          <mat-select formControlName="financiador">
            <mat-option
              *ngFor="let f of financiadoresPaciente"
              [value]="f"
            >
              {{ f.nombre }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
        <mat-form-field>
          <mat-label>Nro carnet</mat-label>
          <input
            type="text"
            placeholder="Elija o escriba uno"
            aria-label="Number"
            matInput
            formControlName="nroCarnet"
            [matAutocomplete]="auto"
          />
          <mat-autocomplete #auto="matAutocomplete">
            <mat-option
              *ngFor="let option of filteredOptions | async"
              [value]="option"
            >
              {{ option }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <mat-form-field>
          <input
            matInput
            type="text"
            formControlName="diagnostico"
            placeholder="Diagnóstico"
          />
        </mat-form-field>
      </div>
      
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" [hidden]="!servicioRequierePedido()">
        <mat-form-field>
          <mat-label>Medico Solicitante</mat-label>
          <mat-select formControlName="profesionalSolicitante">
            <mat-option>
              <ngx-mat-select-search placeholderLabel="Buscar" noEntriesFoundLabel="No se encontraron coincidencias" [formControl]="filterProfCtrl"></ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let solicitante of this.profSolicitanteFiltrado | async" [value]="solicitante">
              {{ solicitante.apellido | toTitleCase }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
        <mat-form-field>
          <mat-label>Servicio efector</mat-label>
          <mat-select
            formControlName="servicioEfector"
          >
            <mat-option
              *ngFor="let servicio of this.serviciosEfectores"
              [value]="servicio"
            >
              {{ servicio.nombre | toTitleCase }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
        <div [hidden]="!tipoRecetaFarmacia" class="text-right">
          <div class="row">
            <div class="col-5">
              <mat-form-field>
                <mat-label>Buscar por descripción</mat-label>
                <input
                  matInput
                  [(ngModel)]="descMedicamento"
                  [ngModelOptions]="{ standalone: true }"
                />
              </mat-form-field>
            </div>
            <div>
              <button mat-icon-button (click)="buscarMedicamentos()">
                <mat-icon>search</mat-icon>
              </button>
            </div>
          </div>

          <div class="container" style="padding-left: 0px !important;">
           <form [formGroup]="farmaciaComercialForm">
             <div class="row">
              <div class="col-2">
                <mat-form-field>
                  <mat-label>Unidades *</mat-label>
                  <mat-select formControlName="unidadMedicamento">
                    <mat-option
                      *ngFor="let uni of unidadesMedicamento"
                      [value]="uni"
                    >
                      {{ uni }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div class="col-xs-12 col-sm-12 col-md-10 col-lg-10 col-xl-10">
                  <mat-form-field>
                    <input
                      matInput
                      type="text"
                      formControlName="tratamiento"
                      placeholder="Tratamiento"
                    />
                  </mat-form-field>
              </div>
             </div>
            </form>
          </div>
        </div>

        <mat-form-field style="width: 100%">
          <mat-label>Estudios / Medicamentos *</mat-label>
          <mat-select
            formControlName="estudios"
          >
            <mat-option>
              <ngx-mat-select-search
                placeholderLabel="Buscar estudio"
                noEntriesFoundLabel="No se encontraron estudios"
                [formControl]="bankMultiFilterCtrl"
              ></ngx-mat-select-search>
            </mat-option>
            <mat-option
              *ngFor="
                let estudio of this.estudiosServicioEfectorFiltrados | async
              "
              [value]="estudio"
            >
              {{ estudio.estudio | toTitleCase }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div class="container col-xs-12 col-sm-12 col-md-12">
      <div [hidden]="!tipoRecetaGenerica" class="text-right">
        <button
          mat-button
          (click)="agregarItemGenerico('Agregar', {})"
          mat-raised-button
          color="primary"
        >
          Agregar item
        </button>
      </div>

      <div [hidden]="tipoRecetaGenerica" class="text-right">
        <button
          [disabled]="
            !solicitudEstudiosForm.get('estudios').value.estudio ||
            (tipoRecetaFarmacia && this.dataSourceItems.length == 3)
          "
          mat-button
          (click)="agregarItem()"
          mat-raised-button
          color="primary"
        >
          Agregar item
        </button>
      </div>
      <br />
      <table
        mat-table
        [dataSource]="dataSourceItems"
        #mytable
        class="mat-elevation-z8"
      >
        <div style="text-align: center; margin-right: 1px">
          <ng-container matColumnDef="descripcion">
            <th mat-header-cell *matHeaderCellDef>Descripción del item</th>
            <span *ngIf="!tipoRecetaFarmacia; else tipoFarmacia">
              <td mat-cell *matCellDef="let element">
                {{ element.descripcion }}
              </td>
            </span>
            <ng-template #tipoFarmacia>
              <td mat-cell *matCellDef="let element">
                {{ element.descripcion }} - {{ element.motivo }}
              </td>
            </ng-template>
          </ng-container>
        </div>

        <div style="text-align: center; margin-right: 1px">
          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef>Acciones</th>
            <td mat-cell *matCellDef="let element" class="action-link">
              <button
                [hidden]="!(tipoRecetaGenerica || tipoRecetaFarmacia)"
                matTooltip="Editar item"
                (click)="editarItem('Editar', element)"
                color="primary"
                mat-icon-button
              >
                <mat-icon>edit</mat-icon>
              </button>
              <button
                matTooltip="Borrar item"
                (click)="borrarItem(element)"
                color="warn"
                mat-icon-button
              >
                <mat-icon>delete</mat-icon>
              </button>
            </td>
          </ng-container>
        </div>

        <tr mat-header-row *matHeaderRowDef="displayedColumnasItems"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumnasItems"></tr>
      </table>
    </div>
    <br/>
  </form>
<!--
  <div class="text-left">
    <mat-checkbox [disabled]="!correoPaciente" [(ngModel)]="enviarMail"
      >Enviar a:
    </mat-checkbox>
    <mat-form-field style="width: 40% !important ; margin-left: 2%">
      <input
        matInput
        type="text"
        [(ngModel)]="correoPaciente"
        placeholder="Correo"
      />
    </mat-form-field>
  </div>
--->
  <div class="text-right">
    <button
      [disabled]="this.dataSourceItems.length == 0 || this.loadingSubmit"
      mat-raised-button
      (click)="onSubmit()"
      color="primary"
      [class.spinnerButton]="loadingSubmit"
    >
      {{ servicioRequierePedido() ? 'Guardar' : 'Guardar e imprimir' }}
    </button>
  </div>

  <alert style="text-align: center; margin-top: 1%"></alert>

  <!-- <alert style="text-align: center; margin-top: 1%"></alert> -->

  <div
    class="d-flex justify-content-center"
    style="margin-top: 15%"
    *ngIf="loadingPedidos"
  >
    <mat-spinner [diameter]="60"></mat-spinner>
  </div>
  <div [hidden]="loadingPedidos || servicioRequierePedido()">
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
        <mat-form-field>
          <mat-label>Buscar</mat-label>
          <input
            matInput
            (keyup)="applyFilter($event)"
            autocomplete="off"
            #input
          />
        </mat-form-field>
      </div>
    </div>
    <table
      mat-table
      [dataSource]="dataSource"
      multiTemplateDataRows
      class="mat-elevation-z8"
    >
      <ng-container matColumnDef="fecha">
        <th mat-header-cell *matHeaderCellDef>fecha</th>
        <td mat-cell *matCellDef="let element">
          {{ element.fechaHoraPedida }}
        </td>
      </ng-container>
      <ng-container matColumnDef="profesional">
        <th mat-header-cell *matHeaderCellDef>profesional</th>
        <td mat-cell *matCellDef="let element">
          {{ element.solicitante | toTitleCase }}
        </td>
      </ng-container>
      <ng-container matColumnDef="servicio">
        <th mat-header-cell *matHeaderCellDef>servicio</th>
        <td mat-cell *matCellDef="let element">
          {{ element.nombreServicio | toTitleCase }}
        </td>
      </ng-container>
      <ng-container matColumnDef="estado">
        <th mat-header-cell *matHeaderCellDef>estado</th>
        <td mat-cell *matCellDef="let element">
          {{ element.estado | toTitleCase }}
        </td>
      </ng-container>
      <ng-container matColumnDef="Nro. pedido">
        <th class="cellphone-non-viewable" mat-header-cell *matHeaderCellDef>
          Nro. pedido
        </th>
        <td class="cellphone-non-viewable" mat-cell *matCellDef="let element">
          {{ element.numero }}
        </td>
      </ng-container>
      <ng-container matColumnDef="acciones">
        <th mat-header-cell *matHeaderCellDef>acciones</th>
        <td mat-cell *matCellDef="let element">
          <button mat-icon-button matTooltip="Ver Oden" (click)="imprimirOrdenPedido(element)">
            <mat-icon color="primary">picture_as_pdf</mat-icon>
          </button>
          <button mat-icon-button matTooltip="Escribir Informe" *ngIf="element.sectorInforme == 10" (click)="regPedidoEscribirInforme(element)">
            <mat-icon color="primary">mode_edit</mat-icon>
          </button>
        </td>
      </ng-container>

      <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
      <ng-container matColumnDef="expandedDetail">
        <td
          mat-cell
          *matCellDef="let element"
          [attr.colspan]="columnsToDisplay.length"
        >
          <div
            class="example-element-detail"
            [@detailExpand]="
              element == expandedElement ? 'expanded' : 'collapsed'
            "
          >
            <!--<div class="example-element-description">
              <div *ngFor="let e of element.estudiosPaciente">
                <span>{{e.nombreEstudio}}</span> - <span>{{e.codigoEstudio}}</span>
              </div>
            </div>-->
            <div class="example-element-description">
              <div *ngFor="let e of element.estudiosPaciente">
                <span>{{ e.descripcion }}</span>
              </div>
            </div>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columnsToDisplay2"></tr>
      <tr mat-row *matRowDef="let element; columns: columnsToDisplay2"></tr>
      <!--  <tr
        mat-row
        *matRowDef="let element; columns: columnsToDisplay2"
        class="example-element-row"
        [class.example-expanded-row]="expandedElement === element"
        (click)="expandedElement = expandedElement === element ? null : element"
      ></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: ['expandedDetail']"
        class="example-detail-row"
      ></tr>-->
    </table>

    <mat-paginator [pageSizeOptions]="[4]"></mat-paginator>
  </div>
</div>
