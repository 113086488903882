import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'base64Decode'
})
export class Base64DecodePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) { }
  transform(datos: string) {
      return this.sanitizer.bypassSecurityTrustHtml(atob(datos));
    }
  }



