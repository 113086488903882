import { Component, OnInit, Inject } from '@angular/core';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface Agenda {
  establecimiento: number;
  nombreEstablecimiento: string;
  numeroDia: number;
  nombreDia: string;
  horaInicio: Date;
  horaFin: Date;
  vigenciaInicio: Date;
  vigenciaFin: Date;
  cantTurnos: number;
  minTurno: Date;
  diasAntic: number;
  servicio: number;
  nomServicio: string;
  cons: number;
  nomCons: string;
  codEspecialidad: number;
  habilitadoTurnosWeb: string;
}
export interface RespuestaAgenda {
  ok: boolean;
  mensaje: string;
  horarios: Agenda[];
}
@Component({
  selector: 'app-dialog-datos-agenda',
  templateUrl: './dialog-datos-agenda.component.html',
  styleUrls: ['./dialog-datos-agenda.component.css'],
})
export class DialogDatosAgendaComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<DialogDatosAgendaComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Agenda
  ) {}

  ngOnInit() {}
}
