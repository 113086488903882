import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthenticationService } from '../_services/authentication.service';
import { AdministracionAgendasService } from './../_services/administracion-agendas.service';
import { Agenda } from '../dialog-datos-agenda/dialog-datos-agenda.component';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { toMinutesPipe } from '../_pipes/toMinutes.pipe';
import { MAT_DATE_LOCALE, DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import {
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import { DatePipe, registerLocaleData } from '@angular/common';
import {
  Consultorio,
  Dia,
  Especialidad,
  RespuestaConsultorio,
  RespuestaServicio,
} from './../agregar-agenda/agregar-agenda.component';
import {
  Respuesta,
  DialogMensajeComponent,
} from './../dialog-mensaje/dialog-mensaje.component';
import { ActivatedRoute } from '@angular/router';
import { DialogConfirmacionComponent } from '../dialog-confirmacion/dialog-confirmacion.component';
import { DialogAgregarConsultorioComponent } from '../dialog-agregar-consultorio/dialog-agregar-consultorio.component';
import { EmbedUrlsService } from '../_services/embedUrls.service';
/* import localeEsAs from '@angular/common/locales/es-AR'; */
@Component({
  selector: 'app-modificar-agenda',
  templateUrl: './modificar-agenda.component.html',
  styleUrls: ['./modificar-agenda.component.css'],
  providers: [
    toMinutesPipe,
    DatePipe,
    { provide: MAT_DATE_LOCALE, useValue: 'es_AR' },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],
})
export class ModificarAgendaComponent implements OnInit {
  private matricula: number;
  private respuestaEspecialidades: Especialidad[];
  private respuestaConsultorios: RespuestaConsultorio;
  listaDias: Dia[];
  private respuestaServiciosProfesional: RespuestaServicio;
  noEsValida = true;
  loading = false;
  agendaForm: UntypedFormGroup;
  agendaModificar: Agenda;
  sucursalActual: string;
  mostrarMas = false;
  cargando = false;
  profesional: string;
  mostrar = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private authService: AuthenticationService,
    private administradorService: AdministracionAgendasService,
    public dialog: MatDialog,
    private router: Router,
    private toMinutes: toMinutesPipe,
    private activatedRoute: ActivatedRoute,
    private datePipe: DatePipe,
    private embedUrlService: EmbedUrlsService
  ) {}

  ngOnInit() {
    this.embedUrlService.embedUrls.subscribe((funciones) => {
      if (
        history.state.matricula &&
        funciones.find((funcion) => {
          return funcion.nombreMenu === 'Agendas';
        })
      ) {
        this.profesional = history.state.profesional;
        this.matricula = history.state.matricula;
        this.agendaModificar = history.state.agendaModificar;
        this.mostrar = true;
        this.cargarConsultorios(
          this.agendaModificar.establecimiento,
          this.agendaModificar.cons
        );
        this.obtenerDatosProfesional(this.matricula);
        this.sucursalActual = history.state.sucursalActual;
        this.cargarServicios(this.matricula);

        this.cargarEspecialidades();
        this.listaDias = [
          {
            nombre: 'domingo',
            valor: 1,
          },
          {
            nombre: 'lunes',
            valor: 2,
          },
          {
            nombre: 'martes',
            valor: 3,
          },
          {
            nombre: 'miercoles',
            valor: 4,
          },
          {
            nombre: 'jueves',
            valor: 5,
          },
          {
            nombre: 'viernes',
            valor: 6,
          },
          {
            nombre: 'sabado',
            valor: 7,
          },
        ];
        this.buildForm();
      } else {
        this.router.navigate(['/administracionAgendas']);
      }
    });
  }
  obtenerDatosProfesional(matricula) {
    this.cargando = true;
    this.administradorService.getDatosProfesional(matricula).subscribe(
      (data) => {
        if (data) {
          try {
            this.agendaForm
              .get('codEspecialidad')
              .setValue(data.paginador.elementos[0].especialidades[0].codigo);
          } catch (error) {
            //no tengo especialidad, queda en null
          } finally {
            this.cargando = false;
          }
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  cargarConsultorios(idSucursal, consultorioSeleccionado) {
    this.administradorService.getConsultoriosSucursal(idSucursal).subscribe(
      (data) => {
        this.respuestaConsultorios = data;
        if (consultorioSeleccionado) {
          this.agendaForm
            .get('consultorio')
            .setValue(
              this.respuestaConsultorio.consultorios.find(
                (obj) => obj.id === consultorioSeleccionado
              ).id
            );
        } else {
          if (this.respuestaConsultorio.ok) {
            this.agendaForm
              .get('consultorio')
              .setValue(this.respuestaConsultorio.consultorios[0].id);
          }
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  cargarEspecialidades() {
    this.administradorService.getEspecialidades().subscribe(
      (data) => {
        if (data) {
          this.respuestaEspecialidades = data;
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  cargarServicios(matricula) {
    this.administradorService.getServiciosProfesional(matricula).subscribe(
      (data) => {
        if (data.ok) {
          this.respuestaServiciosProfesional = data;
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  private buildForm() {
    this.agendaForm = this.formBuilder.group({
      establecimiento: [this.agendaModificar.establecimiento],
      consultorio: [this.agendaModificar.cons, Validators.required],
      diasAtencion: [this.agendaModificar.numeroDia],
      desdeHora: [this.agendaModificar.horaInicio],
      hastaHora: [
        this.datePipe.transform(this.agendaModificar.horaFin, 'HH:mm'),
      ],
      desdeVigencia: [this.agendaModificar.vigenciaInicio],
      hastaVigencia: [
        this.datePipe.transform(
          this.agendaModificar.vigenciaFin,
          'dd/MM/yyyy'
        ) !== '01/01/1900'
          ? this.datePipe.transform(
              this.agendaModificar.vigenciaFin,
              'yyyy-MM-dd'
            )
          : null,
      ],
      cantidadTurnos: [this.agendaModificar.cantTurnos, [Validators.min(1)]],
      duracionTurno: [
        this.agendaModificar.minTurno,
        [Validators.required, Validators.min(1)],
      ],
      diasAnticipacion: [this.agendaModificar.diasAntic, Validators.required],
      servicio: [this.agendaModificar.servicio.toString(), Validators.required],
      codEspecialidad: [
        this.agendaModificar.codEspecialidad,
        Validators.required,
      ],

      habilitadoTurnosWeb: [
        this.agendaModificar.habilitadoTurnosWeb,
        Validators.required,
      ],
    });
  }
  public get respuestaConsultorio(): RespuestaConsultorio {
    return this.respuestaConsultorios;
  }
  public get dias(): Dia[] {
    return this.listaDias;
  }

  public get respuestaServicios(): RespuestaServicio {
    return this.respuestaServiciosProfesional;
  }
  public get especialidades(): Especialidad[] {
    return this.respuestaEspecialidades;
  }
  public cancelar() {
    this.dialog
      .open(DialogConfirmacionComponent, {
        data: `¿Seguro desea cancelar la modificación de la agenda?`,
      })
      .afterClosed()
      .subscribe((confirmado: boolean) => {
        if (confirmado) {
          this.router.navigate(['/administracionAgendas']);
        }
      });
  }
  openDialog(respuestaAgenda: Respuesta): void {
    const dialogRef = this.dialog.open(DialogMensajeComponent, {
      width: '500px',
      data: respuestaAgenda,
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (respuestaAgenda.ok) {
        this.router.navigate(['/administracionAgendas']);
      }
    });
  }
  onSubmit() {
    if (this.agendaForm.valid) {
      this.loading = true;
      this.administradorService
        .editAgendaProfesional(this.matricula, this.agendaForm)
        .subscribe(
          (res) => {
            this.loading = false;
            this.openDialog(res);
          },
          (error) => {
            console.log('error' + error);
          }
        );
    }
  }
  validarHora() {
    if (this.agendaForm.get('hastaHora').value) {
      if (
        this.toMinutes.transform(this.agendaForm.get('hastaHora').value) <
        this.toMinutes.transform(
          this.datePipe.transform(
            this.agendaForm.get('desdeHora').value,
            'HH:mm'
          )
        )
      ) {
        this.noEsValida = false;
      } else {
        this.noEsValida = true;
        this.setDuracion();
      }
    }
  }
  setCantidadTurnos() {
    this.agendaForm
      .get('duracionTurno')
      .setValue(
        (
          (this.toMinutes.transform(this.agendaForm.get('hastaHora').value) -
            this.toMinutes.transform(
              this.datePipe.transform(
                this.agendaForm.get('desdeHora').value,
                'HH:mm'
              )
            )) /
          this.agendaForm.get('cantidadTurnos').value
        ).toFixed(1)
      );
  }
  setDuracion() {
    this.agendaForm
      .get('cantidadTurnos')
      .setValue(
        (
          (this.toMinutes.transform(this.agendaForm.get('hastaHora').value) -
            this.toMinutes.transform(
              this.datePipe.transform(
                this.agendaForm.get('desdeHora').value,
                'HH:mm'
              )
            )) /
          this.agendaForm.get('duracionTurno').value
        ).toFixed(1)
      );
  }
  agregarNuevoConsultorio() {
    const dialogRef = this.dialog.open(DialogAgregarConsultorioComponent, {
      width: '400px',
      data: {
        sucursalSeleccionada: this.agendaForm.get('establecimiento').value,
      },
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.cargarConsultorios(
          this.agendaModificar.establecimiento,
          res.descripcion
        );
      }
    });
  }
  seleccionoConsultorio(evento) {
    if (evento === '') {
      this.agendaForm.get('consultorio').setValue(null);
      this.agregarNuevoConsultorio();
    }
  }
}
