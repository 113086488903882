import { EventEmitter, Injectable, Output, Directive } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { AppComponent } from '../app.component';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json; charset=utf-8',
  }),
};

@Directive()
@Injectable({ providedIn: 'root' })
export class SucursalService {
  baseUrl: string;

  private currentSucursalSubject: BehaviorSubject<any>;

  public currentSucursal: Observable<any>;

  @Output() recargarHistorialEmitter: EventEmitter<boolean> =
    new EventEmitter();

  constructor(private http: HttpClient, private router: Router) {
    this.baseUrl = AppComponent.baseUrl;
    this.currentSucursalSubject = new BehaviorSubject<any>(
      JSON.parse(sessionStorage.getItem('currentSucursal'))
    );
    this.currentSucursal = this.currentSucursalSubject.asObservable();
  }

  setCurrentSucursal(sucursal) {
    sessionStorage.setItem('currentSucursal', JSON.stringify(sucursal));
    this.currentSucursalSubject.next(sucursal);
  }



  getEstablecimientos() {
    return this.http.get<any>(this.baseUrl + '/core/areas/sucursales/seguro/getEstablecimientos');
  }


  removeCurrentSucursal() {
    sessionStorage.removeItem('currentSucursal');
    this.currentSucursalSubject.next(null);
  }

  getCurrentSucursal() {
    return this.currentSucursalSubject.value;
  }
}
