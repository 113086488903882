<div *ngIf="currentUser">
  <mat-toolbar>
    <span>
      <!-- <button
        *ngIf="currentPatient"
        mat-button
        style="
          min-width: 40px;
          padding: 0;
          margin-left: -5%;
          margin-top: 2%;
          width: 15px !important;
        "
        (click)="volver()"
      >
        <mat-icon>keyboard_backspace</mat-icon>
      </button> -->
      <button mat-icon-button (click)="toggleNavBar()">
        <mat-icon>menu</mat-icon>
      </button>
      <img
        style="width: 37px; height: 32px; margin-right: 3%"
        src="../../assets/images/isologo_español.png"
      />
      <span>consultorios</span>
    </span>
    <span class="example-spacer"></span>
    <span style="font-size: 15px; font-weight: 400">{{
      this.currentUser.nombreCompleto
    }}</span>
    <button class="menu" mat-icon-button [matMenuTriggerFor]="belowMenu" (click)="closeNavBar()">
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #belowMenu="matMenu" yPosition="below">
      <button mat-menu-item (click)="cambiarCorreo()">Actualizar correo</button>
      <button mat-menu-item (click)="cambiarContrasena()">Actualizar Contraseña</button>
      <button mat-menu-item (click)="logout()">Cerrar sesión</button>
    </mat-menu>
  </mat-toolbar>
  <!-- <nav *ngIf="!currentPatient" mat-tab-nav-bar mat-align-tabs="center">
    <div
      mat-tab-link
      *ngFor="let link of navLinks"
      [routerLink]="link.link"
      routerLinkActive
      #rla="routerLinkActive"
      [active]="rla.isActive"
    >
      {{ link.label }}
    </div>
  </nav> -->
</div>
