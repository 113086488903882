<button mat-icon-button style="float: right;top:-24px;right:-24px;" [mat-dialog-close]="false">
    <mat-icon class="close-icon" color="warn">close</mat-icon>
</button>
<h1 mat-dialog-title>Crear paciente</h1>
<div mat-dialog-content *ngIf="!this.loading">
    <form [formGroup]="registerForm">
        <div class="row">
            <div class="col">
                <mat-form-field>
                    <input matInput type="text" formControlName="firstName" [ngClass]="{ 'is-invalid': submitted && f.firstName.errors }" placeholder="Nombre">
                    <mat-error *ngIf="submitted && registerForm.controls['firstName'].errors?.required">Este campo es requerido</mat-error>
                    <mat-error *ngIf="submitted && registerForm.controls['firstName'].errors?.pattern">Solo letras son válidas</mat-error>
                </mat-form-field>

            </div>
            <div class="col">
                <mat-form-field>
                    <input matInput type="text" formControlName="lastName" [ngClass]="{ 'is-invalid': submitted && f.lastName.errors }" placeholder="Apellido">
                    <mat-error *ngIf="submitted && registerForm.controls['lastName'].errors?.required">Este campo es requerido</mat-error>
                    <mat-error *ngIf="submitted && registerForm.controls['lastName'].errors?.pattern">Solo letras son válidas</mat-error>
                </mat-form-field>
            </div>

        </div>
        <div class="row">
            <div class="col">
                <mat-form-field>
                    <mat-label>Sexo</mat-label>
                    <mat-select formControlName="gender">
                        <mat-option *ngFor="let gender of genders" [value]="gender.value">
                            {{gender.descripcion}}
                        </mat-option>

                    </mat-select>
                    <mat-error *ngIf="submitted && registerForm.controls['gender'].errors?.required">Este campo es requerido</mat-error>
                </mat-form-field>
            </div>
            <div class="col">
                <mat-form-field>
                    <mat-label>Fecha de nacimiento</mat-label>
                    <input matInput formControlName="date" [max]="maxDate" [matDatepicker]="picker" placeholder="dd/mm/aaaa">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                    <mat-error *ngIf="submitted && registerForm.controls['date'].errors?.required">Este campo es requerido</mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <mat-form-field>
                    <input matInput type="mail" formControlName="mail" [ngClass]="{ 'is-invalid': submitted && f.mail.errors }" placeholder="Email">
                        <mat-error *ngIf="submitted && registerForm.controls['mail'].errors?.required">Este campo es requerido</mat-error>
                </mat-form-field>
                <div *ngIf="submitted && registerForm.controls['mail'].errors?.pattern" style="font-size: 12px; margin: 0; padding: 0;">
                    <mat-error>El email ingresado está incompleto o posee caracteres inválidos</mat-error>
                </div>
            </div>
            <div class="col">
                <mat-form-field>
                    <input matInput type="number" minlength="7" maxlength="16" formControlName="tel" [ngClass]="{ 'is-invalid': submitted && f.tel.errors }" placeholder="Teléfono">
                        <mat-error *ngIf="submitted && registerForm.controls['tel'].errors?.required">Este campo es requerido</mat-error>
                        <mat-error *ngIf="submitted && (registerForm.controls['tel'].errors?.pattern || registerForm.controls['tel'].errors?.max || registerForm.controls['tel'].errors?.min)">
                            El número de teléfono debe tener entre 7 y 16 números</mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <mat-form-field>
                    <input matInput type="text" formControlName="location" [ngClass]="{ 'is-invalid': submitted && f.location.errors }" placeholder="Domicilio">
                        <mat-error *ngIf="submitted && registerForm.controls['location'].errors?.required">Este campo es requerido</mat-error>
                        <mat-error *ngIf="submitted && registerForm.controls['location'].errors?.pattern">Solo puede ingresar letras y números</mat-error>
                        <mat-error *ngIf="submitted && registerForm.controls['location'].errors?.maxlength">El domicilio no puede superar los 60 caracteres</mat-error>
                    </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <mat-form-field>
                    <input matInput type="text" formControlName="city" [ngClass]="{ 'is-invalid': submitted && f.city.errors }" placeholder="Ciudad">
                        <mat-error *ngIf="submitted && registerForm.controls['city'].errors?.required">Este campo es requerido</mat-error>
                        <mat-error *ngIf="submitted && registerForm.controls['city'].errors?.pattern">Solo puede ingresar letras y números</mat-error>
                </mat-form-field>
            </div>
            <div class="col">
                <mat-form-field>
                    <input matInput type="text" formControlName="cod" [ngClass]="{ 'is-invalid': submitted && f.location.errors }" placeholder="Código postal">
                        <mat-error *ngIf="submitted && registerForm.controls['cod'].errors?.required">Este campo es requerido</mat-error>
                        <mat-error *ngIf="submitted && registerForm.controls['cod'].errors?.pattern">Solo puede ingresar números</mat-error>
                </mat-form-field>
            </div>
        </div>

        <div class="row" *ngIf="data.registrarPaciente">
            <div class="col">
                <mat-form-field>
                    <mat-label>Tipo de Documento</mat-label>
                    <mat-select formControlName="tipoDocumento">
                        <mat-option *ngFor="let tipo of tiposDocumentos" [value]="tipo.codTipo">
                            {{tipo.desc}}
                        </mat-option>

                    </mat-select>
                    <mat-error *ngIf="submitted && registerForm.controls['tipoDocumento'].errors?.pattern">Solo puede ingresar números</mat-error>
                </mat-form-field>
            </div>
            <div class="col">
                <mat-form-field>
                    <input matInput type="text" formControlName="nroDocumento" [ngClass]="{ 'is-invalid': submitted && f.location.errors }" placeholder="Número de documento">
                    <div *ngIf="submitted && f.cod.errors" class="invalid-feedback">
                    </div>
                </mat-form-field>
            </div>
        </div>

        <div class="d-flex justify-content-md-center">
            <button mat-raised-button color="primary" [disabled]="loading" (click)="onSubmit(turno)" type="submit">Crear paciente
            </button>
            <mat-spinner [diameter]="30" *ngIf="loading"></mat-spinner>
        </div>
        <alert style="font-size: 12px"></alert>
    </form>
</div>