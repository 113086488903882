import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { DialogSolicitarInternacionComponent } from '../dialog-solicitar-internacion/dialog-solicitar-internacion.component';
import { AuthenticationService } from '../_services/authentication.service';
import { PacienteService } from '../_services/paciente.service';
import * as moment from 'moment';

@Component({
  selector: 'app-dialog-atencion-agenda',
  templateUrl: './dialog-atencion-agenda.component.html',
  styleUrls: ['./dialog-atencion-agenda.component.css']
})

export class DialogAtencionAgendaComponent implements OnInit {
  
  paciente: any = {};
  currentUser;
  cargando: Boolean = true;
  existeOrden: boolean;

  constructor(
    public dialog: MatDialogRef<DialogAtencionAgendaComponent>,
    public dialogo: MatDialog,
    public authenticationService: AuthenticationService,
    public pacienteService: PacienteService
  ) { }

  async ngOnInit() {
    this.authenticationService.currentUser.subscribe((x) => {
      // console.log(x)
      this.currentUser = x;
    });
    await this.getCurretnPatient();
    this.cargando = false;
  }

  async getCurretnPatient() {
    this.paciente = {
      nombre: sessionStorage.getItem('currentPatientName'),
      dni: sessionStorage.getItem('currentPatientDNI'),
      tipoDni: sessionStorage.getItem('currentPatientTipoDNI')
    }
  }

  cancelar() {
    this.dialog.close();
  }

  realizarAccion(accion: String) {
    this.dialog.close(accion);
  }

  async buscarOrdenInternacion() {
    this.cargando = true;
    await this.pacienteService.getRegistrosHCEPaciente(
      sessionStorage.getItem('currentPatientDNI'),
      sessionStorage.getItem('currentPatientTipoDNI'),
      1,
      moment().format("YYYY-DD-MM"),
      moment().add(1, 'day').format("YYYY-DD-MM")).toPromise().then(resp => {
      // console.log(resp)
      if (resp.ok && resp.paginador.elementos.length > 0) {
        this.existeOrden = true;
        this.cargando = false;
      } else {
        this.existeOrden = false;
        this.openDialogSolicitarInternacion();
        this.cargando = false;
      }
    }, error => {
      console.log(error)
      this.existeOrden = false;
      this.openDialogSolicitarInternacion();
      this.cargando = false;
    })
  }

  openDialogSolicitarInternacion() {
    const dialogRef = this.dialogo.open(DialogSolicitarInternacionComponent, {
      disableClose: true,
      width: '500px',
      data: {
        nroDocumento: sessionStorage.getItem('currentPatientDNI'),
        tipoDocumento: sessionStorage.getItem('currentPatientTipoDNI'),
        nombreApellido: sessionStorage.getItem('currentPatientName'),
        rolMHE: this.currentUser.roles.find(elemento => elemento.codigo == 'MHE'),
      }
    }).afterClosed().subscribe((accion: Boolean) => {
      if (accion) {
        this.realizarAccion('INT');
      } else {
        // this.cancelar();
      }
    });
  }

}
