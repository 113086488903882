<div class="row" id="firstRowBack" style="border: 0px solid red;">
  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
    <span style="float: right; margin-right: 3%;">
      <button mat-icon-button color="primary" (click)="volver()">
        <mat-icon aria-label="Example icon-button with a heart icon">keyboard_backspace</mat-icon> Volver
      </button>
    </span>
  </div>
</div>

<div class="container mt-3">
<div class="text-center">
    <h2>Agregar documento digital</h2>
    <form class="form margin-form" [formGroup]="form" (ngSubmit)="saveData()" novalidate>

        <div class="form-group d-flex flex-column col-xl-5 m-auto col-lg-5 col-md-6">
            <!-- Description field -->
            <mat-form-field appearance="outline">
                <mat-label>Descripción del estudio</mat-label>
                <input matInput formControlName="description" type="text">
                <mat-error *ngIf="getForm.description.invalid && submitted">{{getErrorMessageDescription()}}</mat-error>
                <mat-hint>Se permiten solo letras, numeros, puntos y comas.</mat-hint>
            </mat-form-field>

            <!-- Date field -->
            <mat-form-field class="mt-3" appearance="outline">
                <mat-label>Fecha de realización</mat-label>
                <input matInput formControlName="date" [matDatepicker]="medicalStudyDate">
                <mat-error *ngIf="getForm.date.invalid && submitted">{{getErrorMessageDate()}}</mat-error>
                <mat-datepicker-toggle matSuffix [for]="medicalStudyDate"></mat-datepicker-toggle>
                <mat-datepicker #medicalStudyDate></mat-datepicker>
            </mat-form-field>

            <!-- File upload field -->
            <input type="file" class="file-input" formControlName="file" (change)="onFileSelected($event)" #fileUpload accept="application/pdf">
            <div class="file-upload">
                {{ fileName || "No se ha seleccionado ningun archivo."}}
                <button mat-mini-fab color="primary" type="button" class="upload-btn" (click)="fileUpload.click()">
                    <mat-icon>file_upload</mat-icon>
                </button>
                <mat-error *ngIf="getForm.file.invalid && submitted">{{getErrorMessageFile()}}</mat-error>
            </div>

            <div class="col margin-form">
                <button mat-raised-button color="warn" type="button" (click)="back()" class="mr-3">Cancelar</button>
                <button mat-raised-button color="primary" type="submit">Confirmar</button>
            </div>
        </div>


    </form>
</div>

</div>