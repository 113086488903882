import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CharactersService {

    mapaEscapar = {
      '&':'&amp;',
      '"':'&quot;',
      '<':'&lt;',
      'ñ':'&ntilde;'
    };
    mapaReconvertir = {
      '&amp;':'&',
      '&quot;':'"',
      '&lt;':'<',
      '&ntilde;':'ñ'
    };
   

    constructor() {
          
    }
  
    escaparCaracteresEspeciales(texto){
      if(texto || texto == ''){
        const mapa = this.mapaEscapar;
        let textoFiltrado = texto.replace(/&|"|<|ñ/g, function(matched){
          return mapa[matched];
        });

        return textoFiltrado;
      } else {
        return null;

      }
     

    }

    reconvertirCaracteresEspeciales(texto){
     if(texto || texto == ''){
      const mapa = this.mapaReconvertir;
       let textoFiltrado = texto.replace(/&amp;|&quot;|&lt;|&ntilde;/g, function(matched){
         return mapa[matched];
       });

       return textoFiltrado;
     } else {
      return null;
     }
       
 
     }
    
}
