<div class="container pagina" style="margin-top: 1%">
    <div class="container">
        <div class="d-flex justify-content-center" style="margin-top: 10%" *ngIf="loading">
            <mat-spinner [diameter]="60"></mat-spinner>
        </div>
        <div class="row justify-content-center">
            <div style="padding: 0" class="col-xs-12 col-sm-12 col-md-12 col-lg-8 col-xl-6" *ngIf="!loading">
                <div class="d-flex justify-content-center" style="margin-top: 15%" *ngIf="sucursales == null; else elseBlockTemplate">
                    <h6>
                        Ocurrió un problema al cargar los establecimientos del usuario
                    </h6>
                </div>
                <ng-template #elseBlockTemplate>
                    <div class="d-flex justify-content-center" *ngIf="sucursales.length == 0; else elseBlockTemplate2">
                        <h6 class="text-center my-5">
                            El profesional no posee establecimientos, debe crear uno para utilizar el sistema
                        </h6>
                    </div>
                </ng-template>
                <ng-template #elseBlockTemplate2>
                    <mat-list>
                        <div mat-subheader>Establecimientos</div>
                        <mat-list-item *ngFor="let sucursal of sucursales">
                            <span matListAvatar align-middle>
                <mat-icon
                  style="
                    vertical-align: -webkit-baseline-middle;
                    vertical-align: -moz-middle-with-baseline;
                    margin-top: 5px;
                  "
                  >local_hospital</mat-icon
                >
              </span>
                            <div class="mat-list-text">
                                <div mat-line>
                                    {{ sucursal.nombre }}
                                </div>
                            </div>
                            <mat-action-list class=" p-0 m-0">
                                <!-- <button
                  class="menu"
                  mat-mini-fab
                  color="primary"
                  (click)="openDialog(sucursal)"
                >
                  <mat-icon>visibility</mat-icon>
                </button> -->
                                <button class="menu mx-1" mat-mini-fab color="accent" (click)="modificarEstablecimiento(sucursal)">
                  <mat-icon>create</mat-icon>
                </button>
                                <button class="menu mx-1" mat-mini-fab color="warn" (click)="borrarEstablecimiento(sucursal)">
                  <!-- [disabled]="
                    (agenda.vigenciaFin | date: 'dd/MM/yyyy') !== '01/01/1900'
                  " -->
                  <!--  mat-icon-button -->
                  <mat-icon>delete</mat-icon>
                </button>
                            </mat-action-list>
                        </mat-list-item>
                    </mat-list>
                </ng-template>
            </div>
        </div>
        <div class="text-center m-1">
            <button type="button" class="btn btn-outline-primary align-middle m-2" (click)="agregarNuevoEstablecimiento()">
        <span class="material-icons align-middle">add</span>
        <span class="align-middle">Agregar establecimiento</span>
      </button>
            <button type="button" class="btn btn-outline-primary align-middle m-2" *ngIf="this.sucursales && this.sucursales.length > 0" (click)="agregarNuevoConsultorio()">
        <span class="material-icons align-middle">add</span>
        <span class="align-middle">Agregar consultorio</span>
      </button>
        </div>
    </div>
</div>