import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthenticationService } from '../_services/authentication.service';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { PacienteService } from '../_services/paciente.service';
import { InternacionService } from '../_services/internacion.service';
import { AlertService } from '../_services/alert.service';
import { EstudioService } from '../_services/estudios.service';
import { MatDialog } from '@angular/material/dialog';
import { DialogPdfComponent } from '../dialog-pdf/dialog-pdf.component';
import { TurnosService } from '../_services/turnos.service';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-dialog-solicitar-internacion',
  templateUrl: './dialog-solicitar-internacion.component.html',
  styleUrls: ['./dialog-solicitar-internacion.component.css']
})

export class DialogSolicitarInternacionComponent implements OnInit {
  
  currentUser: {};
  cargando: Boolean = true;
  solicitarInternacionForm: UntypedFormGroup = new UntypedFormGroup({
    paciente: new UntypedFormControl(),
    financiadorCarnet: new UntypedFormControl(),
    diagnostico: new UntypedFormControl(),
    pabellon: new UntypedFormControl(),
    cama: new UntypedFormControl(),
    matriculaEfector: new UntypedFormControl()
  });
  listaMutuales: any[];
  listaPabellones: any[];
  listaCamas: any[];
  loadButton: Boolean = false;
  alerta: Boolean = false;
  hayImpresion: Boolean = false;
  nuevoPedido: any;
  listaProfesionales: Observable<any[]>;
  profesionalesFilter: any[] = [];
  valueClose: Boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private authService: AuthenticationService,
    private formBuilder: UntypedFormBuilder,
    private pacienteService: PacienteService,
    private internacionService: InternacionService,
    private alertService: AlertService,
    private estudiosService: EstudioService,
    private dialog: MatDialogRef<DialogSolicitarInternacionComponent>,
    private dialogo: MatDialog,
    private turnosService: TurnosService
  ) {
    this.currentUser = this.authService.currentUserValue.usuario;
    // console.log(this.currentUser);
    // console.log(data);
  }

  async ngOnInit() {
    await this.obtenerMutualesPaciente(this.data.nroDocumento, this.data.tipoDocumento);
    await this.obtenerPabellones();
    await this.cargarProfesionales();
    await this.iniciarSolicitarInternacionForm();
    await this.filtrarProfesionales();
  }

  validarCampo(formControlName) {
    if (!this.solicitarInternacionForm.get(formControlName).value.nroMatricula) {
      console.log('LALA');
      return true;
    } else {
      
    }
    // return this.solicitarInternacionForm.get(formControlName).hasError('required') ? 'Este campo es obligatorio' : '';
  }

  displayFn(profesional: any): string | undefined {
    return profesional ? profesional.apellido+" ("+profesional.nroMatricula+")" : undefined;
  }

  async filtrarProfesionales() {
    this.listaProfesionales = this.solicitarInternacionForm.get('matriculaEfector').valueChanges.pipe(
      startWith(''),
      map(value => typeof value === 'string' ? this._filter(value) : this._filterDos(value.apellido, value.nroMatricula))
    );
  }

  private _filter(value: string): any {
    const filterValue = value.toLowerCase();

    return this.profesionalesFilter.filter(option => 
      option.nroMatricula.toLowerCase().includes(filterValue) || option.apellido.toLowerCase().includes(filterValue)
    );
  }

  private _filterDos(valueApellido: string, valueNroMatricula: string): any {
    const filterValueApellido = valueApellido.toLowerCase();
    const filterValueMatricula = valueNroMatricula.toLowerCase();

    return this.profesionalesFilter.filter(option => 
      option.nroMatricula.toLowerCase().includes(filterValueMatricula) && option.apellido.toLowerCase().includes(filterValueApellido)
    );
  }

  async cargarProfesionales() {

    const filtro = {
      matricula: 'null',
      servicio:'null',
      especialidad: 'null',
      condicion: 'null',
      estado: 'ON',
    }

    await this.turnosService.getProfesionales(filtro).toPromise().then((data) => {
      // console.log(data)
      if (data.ok) {
        this.listaProfesionales = data.paginador.elementos.sort((a, b) => 0 - (a.apellido > b.apellido ? -1 : 1));
        this.profesionalesFilter = data.paginador.elementos.sort((a, b) => 0 - (a.apellido > b.apellido ? -1 : 1));
      } else {
        console.log('Error al obtener los profesionales')
      }
    }, error => {
      console.log('Error al obtener los profesionales')
      console.log(error)
    }); 
  }

  async iniciarSolicitarInternacionForm() {
    this.solicitarInternacionForm = this.formBuilder.group({
      paciente: [{ value: this.data.nombreApellido, disabled: true}],
      financiadorCarnet: [ this.listaMutuales[0], Validators.required],
      diagnostico: [ null, Validators.required],
      pabellon: [ this.listaPabellones[0], Validators.required],
      cama: [ null, Validators.required],
      matriculaEfector: [ this.profesionalesFilter.find(med => med.nroMatricula === this.data.rolMHE.valorTipoID) ? 
        this.profesionalesFilter.find(med => med.nroMatricula === this.data.rolMHE.valorTipoID) : null, Validators.required],
    });
    
    this.cargando = false;
  }

  async obtenerMutualesPaciente(nroDocumento, tipoDocumento) {
    await this.pacienteService.mutualesDePaciente(nroDocumento, tipoDocumento).toPromise().then(resp => {
      // console.log(resp)
      if (resp.ok) {
        this.listaMutuales = resp.paginador.elementos;
      } else {
        console.log('Error al obtener las mutuales')
      }
    }, error => {
      console.log('Error al obtener las mutuales')
      console.log(error)
    })
  }

  async obtenerPabellones() {
    await this.internacionService.obtenerPabellones().toPromise().then(resp => {
      // console.log(resp)
      if (resp.ok) {
        this.listaPabellones = resp.paginador.elementos;
        this.obtenerCamasPorPabellon(this.listaPabellones[0].pabellon);
      } else {
        console.log('Error al obtener los pabellones')
      }
    }, error => {
      console.log('Error al obtener los pabellones')
      console.log(error)
    })
  }

  async obtenerCamasPorPabellon(idPabellon) {
    await this.internacionService.obtenerCamasPorPabellon(idPabellon).toPromise().then(resp => {
      // console.log(resp)
      if (resp.ok) {
        this.listaCamas = resp.data;
      } else {
        console.log('Error al obtener las camas del pabellon ' + idPabellon)
      }
    }, error => {
      console.log('Error al obtener las camas del pabellon ' + idPabellon)
      console.log(error)
    })
  }

  async seleccionarPabellon(idPabellon) {
    this.cargando = true;
    await this.obtenerCamasPorPabellon(idPabellon);
    this.cargando = false;
  }

  getErrorMessage(formControlName) {
    return this.solicitarInternacionForm.get(formControlName).hasError('required') ? 'Este campo es obligatorio' : '';
  }

  async solicitarInternacion() {
    this.loadButton = true;
    this.alerta = false;

    if (this.solicitarInternacionForm.invalid) {
      this.alertService.error('Debe completar todos los campos.');
    } else {
      if (!this.solicitarInternacionForm.get('matriculaEfector').value.nroMatricula) {
        this.alertService.error('Seleccione un medico de cabecera');
      } else {
        const pedido = {
          nroCarpeta: null,
          nroDniPaciente: this.data.nroDocumento,
          tipoDniPaciente: this.data.tipoDocumento,
          urgente: false,
          diagnostico: this.solicitarInternacionForm.get('diagnostico').value,
          usuario: null,
          matriculaProfesionalSolicitante: this.data.rolMHE.valorTipoID,
          codigoServicio: null,
          categoria: 'INTERNADO',
          sectorInforme: null,
          matriculaProfesionalActuante: this.solicitarInternacionForm.get('matriculaEfector').value.nroMatricula,
          codigoServicioSol: null,
          tipoPedido: 9,                                           // SUPONEMOS TIPO 9 ORDEN GENERICA
          idFinanciador: this.solicitarInternacionForm.get('financiadorCarnet').value.Financiador,
          nroCarnet: this.solicitarInternacionForm.get('financiadorCarnet').value.nroCarnet,
          codigoMutual: this.solicitarInternacionForm.get('financiadorCarnet').value.mutual,
          cantDiasInternacion: null,
          pedidoIndicacion: null,
          admisionAdministrativa: null,
          estudiosPaciente: [ {
            numeroItem: 1,
            codigoEstudio: 0,
            nombreEstudio: '',
            motivo: null,
            idModeloCons: null,
            idModeloPrep: null,
            nomenclador: null,
            cantidad: null
          }],
          esOrdenDeInternacion: true,
          emailPaciente: null,
          pieza: this.solicitarInternacionForm.get('cama').value.pieza,
          cama: this.solicitarInternacionForm.get('cama').value.cama,
        }
  
        // console.log(pedido)
  
        await this.estudiosService.guardarPedidoGenerico(pedido).toPromise().then(resp => {
          // console.log(resp)
          if (resp.ok) {
            this.alertService.success(resp.mensaje);
            this.hayImpresion = true;
            this.valueClose = true;
            this.nuevoPedido = {
              idTipoEpisodio: 1,
              referenciaRegistro: resp.idRequerimientoInternacion
            }
          } else {
            console.log(resp)
            this.alertService.error('Error al guardar el pedido');
          }
        }, error => {
          this.alertService.error('Error al guardar el pedido');
          console.log('Error al guardar el pedido')
          console.log(error)
        })
      }
    }

    this.loadButton = false;
    this.alerta = true;
  }

  imprimirOrdenInternacion() {
    const dialogRef = this.dialogo.open(DialogPdfComponent, {
      width: '1300px',
      height: '700px',
      data: {
        url: this.estudiosService.getRegistroHcePdf(this.nuevoPedido),
      },
    });
  }

  cerrar() {
    this.dialog.close(this.valueClose);
  }
}
