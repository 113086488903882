import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { AppComponent } from '../app.component';

@Injectable({ providedIn: 'root' })
export class UserService {
  public secretaria: any;
  private baseUrl: string;
  userData: Observable<any>;
  userDataSubject: BehaviorSubject<any>;


  constructor(private http: HttpClient) {
    this.secretaria = null;
    this.baseUrl = AppComponent.baseUrl;
    this.userDataSubject = new BehaviorSubject<any>(null);
    this.userData = this.userDataSubject.asObservable();
  }

  setSecretaria(perfil: any[]) {
    this.secretaria = perfil;
  }

  public get perfilSecretaria(): any {
    return JSON.parse(sessionStorage.getItem('rolEmpleado'));
  }

  getUserData(): Observable<any> {
    return (this.userData = this.http.get<any>(this.baseUrl + '/seguro/user'));
  }

  guardarEstadistica(usuario, mensaje, funcion) {
    let body = {
      usuario: usuario,
      description: mensaje,
      funcion: funcion,
    }
    return this.http.post<any>(
      this.baseUrl + '/estadisticas/guardarEstadistica',
      body
    );
  }

  recuperarClave(nombreUsuario) {

    let form = {
      usuario: nombreUsuario,
      idAplicacion: 1,
    };

    return this.http.post<any>(this.baseUrl + '/login/enviarMensajeRecuperarContrasenia', form);
  }

  passwordChange(nombreUsuario, formValue) {

    let form = {
      usuario: nombreUsuario,
      oldPassword: formValue.actual,
      newPassword: formValue.confirmPassword,
      nombreApp: 'Consultorios'
    };

    return this.http.post<any>(this.baseUrl + '/usuariosLDAP/cambiarContrasena', form);
  }

  getUsuarioPorUsername(nombreUsuario) {
    return this.http.post<any>(this.baseUrl + '/usuariosLDAP/seguro/getUsuarioPorUsername', nombreUsuario)
  }

  guardarUsuario(jsonUsuario) {
    return this.http.post<any>(this.baseUrl + '/usuariosLDAP/seguro/guardarUsuario', jsonUsuario);
  }

}
