<div class="container-fluid">
  <mat-progress-bar mode="indeterminate" *ngIf="cargando" style="margin-top: 1%;"></mat-progress-bar>
  <form [formGroup]="newForm" (ngSubmit)="guardarFormulario()" *ngIf="!cargando">
    <div class="row" style="justify-content: center;">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-8 col-xl-8">
        <br>
        <mat-form-field>
          <input matInput placeholder="Nombre del Formulario" formControlName="nombreFormulario" required>
          <mat-error *ngIf="newForm.invalid"></mat-error>
        </mat-form-field><br>

        <mat-accordion class="example-headers-align">
          <mat-expansion-panel *ngFor="let seccion of formularioNuevo.listaSecciones" 
            [expanded]="seccion.numSeccion === formularioNuevo.listaSecciones.length">
            <mat-expansion-panel-header>
              <mat-panel-title>
                {{ seccion.nombre }}
              </mat-panel-title>
              <!-- <mat-panel-description>
                Type your name and age
                <mat-icon>account_circle</mat-icon>
              </mat-panel-description> -->
            </mat-expansion-panel-header>

            <mat-form-field>
              <input matInput placeholder="Nombre de la seccion" formControlName="seccion{{seccion.numSeccion}}" (ngModelChange)="cambiarNomSeccion($event, seccion)" required>
              <mat-error *ngIf="newForm.invalid">{{getErrorMessage('seccion'+seccion.numSeccion)}}</mat-error>
            </mat-form-field><br>

            <div *ngFor="let campo of seccion.listaCampos">
              <div class="row">
                <div class="col-xs-1 col-sm-1 col-md-1 col-lg-1 col-xl-1 col-xxl-1" 
                  style="display: flex; align-items: center;" *ngIf="seccion.listaCampos.length > 1">
                    <mat-icon matTooltip="Borrar campo" style="color: red; cursor: pointer; margin: auto;"
                      (click)="eliminarCampo(seccion, campo)">clear</mat-icon>
                </div>
                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 col-xxl-3">
                  <mat-form-field >
                    <input matInput placeholder="Nombre del campo" 
                      formControlName="seccion{{seccion.numSeccion}}campo{{campo.numCampo}}" required>
                    <mat-error *ngIf="newForm.invalid">
                      {{ getErrorMessage('seccion'+seccion.numSeccion+'campo'+campo.numCampo) }}
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 col-xxl-3" 
                  style="display: flex; align-items: center;">
                    <mat-checkbox formControlName="seccion{{seccion.numSeccion}}campo{{campo.numCampo}}ob" 
                      style="margin-left: 2%;">Obligatorio</mat-checkbox>
                </div>
              </div>
              <div class="row">
                <div class="col-xs-1 col-sm-1 col-md-1 col-lg-1 col-xl-1 col-xxl-1" 
                  style="display: flex; align-items: center;" *ngIf="seccion.listaCampos.length > 1">
                    <!-- <mat-icon matTooltip="Borrar campo" style="color: red; cursor: pointer;"
                      (click)="eliminarCampo(seccion, campo)">clear</mat-icon> -->
                </div>
                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 col-xxl-3">
                  <mat-form-field>
                    <mat-label>Tipo de Campo</mat-label>
                    <mat-select [(value)]="campo.idTipo" required (selectionChange)="seleccionarTipoCampo($event.value, seccion, campo)">
                      <mat-option *ngFor="let tipo of listaTiposCampo" [value]="tipo.id">
                        {{tipo.nombre}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div *ngFor="let opc of campo.listaOpciones"
                  class="col-xs-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 col-xxl-3">
                  <mat-form-field>
                    <input matInput placeholder="Valor predeterminado" 
                      formControlName="seccion{{seccion.numSeccion}}campo{{campo.numCampo}}op{{opc.numOp}}">
                    <mat-icon matSuffix style="cursor: pointer;" (click)="eliminarOpcion(seccion, campo, opc)" *ngIf="campo.listaOpciones.length > 1">delete</mat-icon>
                    <!-- <mat-error *ngIf="newForm.invalid">
                      {{ getErrorMessage('seccion'+seccion.numSeccion+'campo'+campo.numCampo) }}
                    </mat-error> -->
                  </mat-form-field>
                </div>
                <div class="col-xs-1 col-sm-1 col-md-1 col-lg-1 col-xl-1 col-xxl-1" 
                  style="display: flex; align-items: center;" *ngIf="campo.idTipo != 1 && campo.idTipo != 3">
                    <mat-icon matTooltip="Agregar opcion" style="color: green; cursor: pointer;"
                      (click)="agregarOpcion(seccion, campo)">add</mat-icon>
                </div>
              </div>

              <div *ngIf="campo.idTipo == 3">
                <div *ngFor="let campoSubSec of campo.listaCamposSubseccion">
                  <div class="row">
                    <div class="col-xs-1 col-sm-1 col-md-1 col-lg-1 col-xl-1 col-xxl-1" 
                      style="display: flex; align-items: center;" *ngIf="campo.listaCamposSubseccion.length > 1">
                        <mat-icon matTooltip="Borrar campo" style="color: red; cursor: pointer; margin: auto;"
                          (click)="eliminarCampoSubseccion(seccion, campo, campoSubSec)">clear</mat-icon>
                    </div>
                    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 col-xxl-3">
                      <mat-form-field >
                        <input matInput placeholder="Nombre del campo" 
                          formControlName="seccion{{seccion.numSeccion}}campo{{campo.numCampo}}ss{{campoSubSec.numCampo}}" required>
                        <mat-error *ngIf="newForm.invalid">
                          {{ getErrorMessage('seccion'+seccion.numSeccion+'campo'+campo.numCampo) }}
                        </mat-error>
                      </mat-form-field>
                    </div>
                    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 col-xxl-3" 
                      style="display: flex; align-items: center;">
                        <mat-checkbox formControlName="seccion{{seccion.numSeccion}}campo{{campo.numCampo}}ss{{campoSubSec.numCampo}}ob" 
                          style="margin-left: 2%;">Obligatorio</mat-checkbox>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-xs-1 col-sm-1 col-md-1 col-lg-1 col-xl-1 col-xxl-1" 
                      style="display: flex; align-items: center;" *ngIf="campo.listaCamposSubseccion.length > 1">
                        <!-- <mat-icon matTooltip="Borrar campo" style="color: red; cursor: pointer;"
                          (click)="eliminarCampo(seccion, campo)">clear</mat-icon> -->
                    </div>
                    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 col-xxl-3">
                      <mat-form-field>
                        <mat-label>Tipo de Campo</mat-label>
                        <mat-select [(value)]="campoSubSec.idTipo" required>
                          <mat-option *ngFor="let tipo of listaTiposCampoSubSeccion" [value]="tipo.id">
                            {{tipo.nombre}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div *ngFor="let opc of campoSubSec.listaOpciones"
                      class="col-xs-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 col-xxl-3">
                      <mat-form-field>
                        <input matInput placeholder="Valor predeterminado" 
                          formControlName="seccion{{seccion.numSeccion}}campo{{campo.numCampo}}ss{{campoSubSec.numCampo}}op{{opc.numOp}}">
                        <mat-icon matSuffix style="cursor: pointer;" (click)="eliminarOpcionCampoSubSeccion(seccion, campo, campoSubSec, opc)" *ngIf="campoSubSec.listaOpciones.length > 1">delete</mat-icon>
                        <!-- <mat-error *ngIf="newForm.invalid">
                          {{ getErrorMessage('seccion'+seccion.numSeccion+'campo'+campo.numCampo) }}
                        </mat-error> -->
                      </mat-form-field>
                    </div>
                    <div class="col-xs-1 col-sm-1 col-md-1 col-lg-1 col-xl-1 col-xxl-1" 
                      style="display: flex; align-items: center;" *ngIf="campoSubSec.idTipo === 2">
                        <mat-icon matTooltip="Agregar opcion" style="color: green; cursor: pointer;"
                          (click)="agregarOpcionCampoSubSeccion(seccion, campo, campoSubSec)">add</mat-icon>
                    </div>
                  </div>
                </div>
                <button mat-flat-button color="primary" (click)="agregarCampoSubseccion(seccion, campo)" type="button">
                  <mat-icon>add</mat-icon> Campo de Subseccion</button><br><br>
              </div>

              <mat-divider></mat-divider><br>
            </div>

            <button mat-flat-button color="primary" (click)="agregarCampo(seccion.numSeccion)" type="button">
              <mat-icon>add</mat-icon> Campo</button>
        
            <mat-action-row>
              <button mat-flat-button color="warn" (click)="eliminarSeccion(seccion)" style="margin-right: 1%;"
                *ngIf="formularioNuevo.listaSecciones.length > 1">
                <mat-icon>delete</mat-icon></button>
              <button mat-flat-button color="primary" (click)="agregarSeccion()" 
                *ngIf="seccion.numSeccion === formularioNuevo.listaSecciones.length">
                  <mat-icon>add</mat-icon> Seccion</button>
            </mat-action-row>
          </mat-expansion-panel>
        </mat-accordion><br>
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
            <alert [hidden]="!alerta" style="text-align: center; margin-top: 1%"></alert>
          </div>
        </div>
        <button mat-flat-button color="primary" type="submit" [class.spinner]="loadButton" 
          [disabled]="loadButton">Guardar</button>
      </div>
    </div>
  </form>
</div>