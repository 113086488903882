import { Component, OnInit } from '@angular/core';
import { FormularioGenerico } from 'src/app/_models/formularioGenerico.interface';
import { SeccionFormulario } from '../_models/seccionFormulario.interface';
import { CampoFormulario } from '../_models/campoFormulario.interface';
import { FormulariosService } from '../_services/formularios.service';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from '../_services/authentication.service';
import { AlertService } from '../_services/alert.service';
import { OpcionesCampo } from '../_models/opcionesCampo.interface';

@Component({
  selector: 'app-crear-formulario',
  templateUrl: './crear-formulario.component.html',
  styleUrls: ['./crear-formulario.component.css']
})
export class CrearFormularioComponent implements OnInit {

  cargando: Boolean = true;
  loadButton: Boolean = false;
  alerta: Boolean = false;
  listaTiposCampo: any[];
  listaTiposCampoSubSeccion: any[] = [];

  newForm = new UntypedFormGroup({
    nombreFormulario: new UntypedFormControl(null, Validators.required),
  });

  formularioNuevo: FormularioGenerico = {
    idFormulario: null,
    nombre: null,
    estado: 'ACT',
    loginEstado: this.authService.currentUserValue.usuario,
    listaSecciones: [ {
      idSeccion: null,
      idFormulario: null,
      nombre: 'Nueva Seccion',
      estado: 'ACT',
      listaCampos: [ {
        idCampo: null,
        idTipo: 1,
        idSeccion: null,
        nombre: null,
        estado: 'ACT',
        valor: null,
        numCampo: 1,
        obligatorio: null,
        listaOpciones: [ {
          id: null,
          idCampo: null,
          informacionCampo: null,
          numOp: 1,
        } ],
        listaCamposSubseccion: [],
      } ],
      numSeccion: 1,
    } ],
  };
  
  constructor(
    public formService: FormulariosService,
    private formBuilder: UntypedFormBuilder,
    private authService: AuthenticationService,
    private alertService: AlertService
  ) { }

  ngOnInit() {
    this.getTiposCampo();
    this.iniciarFormularioNuevo();
  }

  async getTiposCampo() {
    await this.formService.getTiposCampo().toPromise().then(resp => {
      // console.log(resp)
      this.listaTiposCampo = resp.tiposCampo;
      this.listaTiposCampo.forEach(tipoCampo => {
        if (tipoCampo.nombre != 'Subseccion') {
          this.listaTiposCampoSubSeccion.push(tipoCampo);
        }
      })
    }, error => {
      console.log(error)
    })
  }

  async iniciarFormularioNuevo() {
    this.formularioNuevo.listaSecciones.forEach(seccion => {
      this.newForm.addControl("seccion"+seccion.numSeccion.toString(), this.formBuilder.control('',
      'required' === 'required' ? [Validators.required] : []));
      seccion.listaCampos.forEach(campo => {
        this.newForm.addControl("seccion"+seccion.numSeccion.toString()+"campo"+campo.numCampo.toString(), 
          this.formBuilder.control('', 'required' === 'required' ? [Validators.required] : []));
        this.newForm.addControl("seccion"+seccion.numSeccion.toString()+"campo"+campo.numCampo.toString()+"op1", 
          this.formBuilder.control(''));
        this.newForm.addControl("seccion"+seccion.numSeccion.toString()+"campo"+campo.numCampo.toString()+"ob", 
          this.formBuilder.control(true, 'required' === 'required' ? [Validators.required] : []));
      });
    });

    this.cargando = false;
  }

  getErrorMessage(seccionCampo: string) {
    return this.newForm.get(seccionCampo).hasError('required') ? 'Debe ingresar un nombre' : '';
  }

  cambiarNomSeccion(nombreSeccion, seccion: SeccionFormulario) {
    seccion.nombre = nombreSeccion;
  }

  agregarSeccion() {

    let secNueva: SeccionFormulario = {
      idSeccion: null,
      idFormulario: null,
      nombre: 'Nueva Seccion',
      estado: 'ACT',
      listaCampos: [ {
        idCampo: null,
        idTipo: 1,
        idSeccion: null,
        nombre: null,
        estado: 'ACT',
        valor: null,
        numCampo: 1,
        obligatorio: null,
        listaOpciones: [ {
          id: null,
          idCampo: null,
          informacionCampo: null,
          numOp: 1,
        } ],
        listaCamposSubseccion: [],
      } ],
      numSeccion: this.formularioNuevo.listaSecciones.length + 1,
    }

    this.newForm.addControl("seccion"+secNueva.numSeccion.toString(), this.formBuilder.control('',
      'required' === 'required' ? [Validators.required] : []));
    this.newForm.addControl("seccion"+secNueva.numSeccion.toString()+"campo1", 
      this.formBuilder.control('', 'required' === 'required' ? [Validators.required] : []));
    this.newForm.addControl("seccion"+secNueva.numSeccion.toString()+"campo1op1", 
      this.formBuilder.control(''));
    this.newForm.addControl("seccion"+secNueva.numSeccion.toString()+"campo1ob", 
      this.formBuilder.control(true, 'required' === 'required' ? [Validators.required] : []));

    this.formularioNuevo.listaSecciones.push(secNueva);
  }

  agregarCampo(numSeccion: number) {

    let cmpNuevo: CampoFormulario = {
      idCampo: null,
      idTipo: 1,
      idSeccion: null,
      nombre: null,
      estado: 'ACT',
      valor: null,
      obligatorio: null,
      listaOpciones: [{
        id: null,
        idCampo: null,
        informacionCampo: null,
        numOp: 1,
      }],
      listaCamposSubseccion: [],
    }

    this.formularioNuevo.listaSecciones.forEach(seccion => {
      if (seccion.numSeccion === numSeccion) {
        cmpNuevo.numCampo = seccion.listaCampos.length + 1;
        this.newForm.addControl("seccion"+numSeccion.toString()+"campo"+cmpNuevo.numCampo, 
          this.formBuilder.control('', 'required' === 'required' ? [Validators.required] : []));
        this.newForm.addControl("seccion"+numSeccion.toString()+"campo"+cmpNuevo.numCampo+"op1", 
          this.formBuilder.control(''));
        this.newForm.addControl("seccion"+numSeccion.toString()+"campo"+cmpNuevo.numCampo+"ob", 
          this.formBuilder.control(true, 'required' === 'required' ? [Validators.required] : []));

        seccion.listaCampos.push(cmpNuevo);
      }
    })
  }

  agregarCampoSubseccion(seccion: SeccionFormulario, campo: CampoFormulario) {

    let cmpNuevo: CampoFormulario = {
      idCampo: null,
      idTipo: 1,
      idSeccion: null,
      nombre: null,
      estado: 'ACT',
      valor: null,
      obligatorio: null,
      listaOpciones: [{
        id: null,
        idCampo: null,
        informacionCampo: null,
        numOp: 1,
      }],
    }

    let seccionEncontrada = this.formularioNuevo.listaSecciones.find((sec) => sec.numSeccion === seccion.numSeccion);
    let campoEncontrado = seccionEncontrada.listaCampos.find((cmp) => cmp.numCampo === campo.numCampo);

    cmpNuevo.numCampo = campoEncontrado.listaCamposSubseccion.length + 1;

    this.newForm.addControl("seccion"+seccion.numSeccion.toString()+"campo"+campo.numCampo+"ss"+cmpNuevo.numCampo, 
      this.formBuilder.control('', 'required' === 'required' ? [Validators.required] : []));
    this.newForm.addControl("seccion"+seccion.numSeccion.toString()+"campo"+campo.numCampo+"ss"+cmpNuevo.numCampo+"op1", 
      this.formBuilder.control(''));
    this.newForm.addControl("seccion"+seccion.numSeccion.toString()+"campo"+campo.numCampo+"ss"+cmpNuevo.numCampo+"ob", 
      this.formBuilder.control(true, 'required' === 'required' ? [Validators.required] : []));

    campoEncontrado.listaCamposSubseccion.push(cmpNuevo);
  }

  agregarOpcion(seccion: SeccionFormulario, campo: CampoFormulario) {

    let opNueva: OpcionesCampo = {
      id: null,
      idCampo: null,
      informacionCampo: null
    }

    let seccionEncontrada = this.formularioNuevo.listaSecciones.find((sec) => sec.numSeccion === seccion.numSeccion);
    let campoEncontrado = seccionEncontrada.listaCampos.find((cmp) => cmp.numCampo === campo.numCampo);

    opNueva.numOp = campoEncontrado.listaOpciones.length + 1;
    this.newForm.addControl("seccion"+seccion.numSeccion.toString()+"campo"+campo.numCampo+"op"+opNueva.numOp, 
      this.formBuilder.control(''));

    campoEncontrado.listaOpciones.push(opNueva);
  }

  agregarOpcionCampoSubSeccion(seccion: SeccionFormulario, campo: CampoFormulario, campoSubSeccion: CampoFormulario) {

    let opNueva: OpcionesCampo = {
      id: null,
      idCampo: null,
      informacionCampo: null
    }

    opNueva.numOp = campoSubSeccion.listaOpciones.length + 1;
    this.newForm.addControl("seccion"+seccion.numSeccion.toString()+"campo"+campo.numCampo+"ss"+campoSubSeccion.numCampo+"op"+opNueva.numOp, 
      this.formBuilder.control(''));
    
    campoSubSeccion.listaOpciones.push(opNueva);
  }

  eliminarSeccion(seccion: SeccionFormulario) {
    this.formularioNuevo.listaSecciones.splice(this.formularioNuevo.listaSecciones.indexOf(seccion), 1);

    this.newForm.removeControl('seccion'+seccion.numSeccion);
    seccion.listaCampos.forEach(campo => {

      this.newForm.removeControl('seccion'+seccion.numSeccion+'campo'+campo.numCampo);
      this.newForm.removeControl('seccion'+seccion.numSeccion+'campo'+campo.numCampo+'ob');
      campo.listaOpciones.forEach(opcion => {
        this.newForm.removeControl('seccion'+seccion.numSeccion+'campo'+campo.numCampo+'op'+opcion.numOp);
      })

      campo.listaCamposSubseccion.forEach(cmpSubseccion => {
        this.newForm.removeControl('seccion'+seccion.numSeccion+'campo'+campo.numCampo+'ss'+cmpSubseccion.numCampo);
        this.newForm.removeControl('seccion'+seccion.numSeccion+'campo'+campo.numCampo+'ss'+cmpSubseccion.numCampo+'ob');
        cmpSubseccion.listaOpciones.forEach(opcionSubsec => {
          this.newForm.removeControl('seccion'+seccion.numSeccion+'campo'+campo.numCampo+'ss'+cmpSubseccion.numCampo+'op'+opcionSubsec.numOp);
        })
      })
    })
  }

  eliminarCampo(seccion: SeccionFormulario, campo: CampoFormulario) {
    seccion.listaCampos.splice(seccion.listaCampos.indexOf(campo), 1);
    // seccion.listaCampos.forEach(cmp => {
    //   cmp.numCampo = seccion.listaCampos.indexOf(cmp) + 1;
    // })

    this.newForm.removeControl('seccion'+seccion.numSeccion+'campo'+campo.numCampo);
    this.newForm.removeControl('seccion'+seccion.numSeccion+'campo'+campo.numCampo+'ob');
    campo.listaOpciones.forEach(opcion => {
      this.newForm.removeControl('seccion'+seccion.numSeccion+'campo'+campo.numCampo+'op'+opcion.numOp);
    })

    campo.listaCamposSubseccion.forEach(cmpSubseccion => {
      this.newForm.removeControl('seccion'+seccion.numSeccion+'campo'+campo.numCampo+'ss'+cmpSubseccion.numCampo);
      this.newForm.removeControl('seccion'+seccion.numSeccion+'campo'+campo.numCampo+'ss'+cmpSubseccion.numCampo+'ob');
      cmpSubseccion.listaOpciones.forEach(opcionSubsec => {
        this.newForm.removeControl('seccion'+seccion.numSeccion+'campo'+campo.numCampo+'ss'+cmpSubseccion.numCampo+'op'+opcionSubsec.numOp);
      })
    })
  }

  eliminarCampoSubseccion(seccion: SeccionFormulario, campo: CampoFormulario, cmpSubseccion: CampoFormulario) {
    campo.listaCamposSubseccion.splice(campo.listaCamposSubseccion.indexOf(cmpSubseccion), 1);
    // campo.listaCamposSubseccion.forEach(cmpSubSec => {
    //   cmpSubSec.numCampo = campo.listaCamposSubseccion.indexOf(cmpSubSec) + 1;
    // })

    this.newForm.removeControl('seccion'+seccion.numSeccion+'campo'+campo.numCampo+'ss'+cmpSubseccion.numCampo);
    this.newForm.removeControl('seccion'+seccion.numSeccion+'campo'+campo.numCampo+'ss'+cmpSubseccion.numCampo+'ob');
    cmpSubseccion.listaOpciones.forEach(opcionSubsec => {
      this.newForm.removeControl('seccion'+seccion.numSeccion+'campo'+campo.numCampo+'ss'+cmpSubseccion.numCampo+'op'+opcionSubsec.numOp);
    })
  }

  eliminarOpcion(seccion: SeccionFormulario, campo: CampoFormulario, opc: OpcionesCampo) {
    campo.listaOpciones.splice(campo.listaOpciones.indexOf(opc), 1);
    // this.newForm.removeControl('seccion'+seccion.numSeccion+'campo'+campo.numCampo+'op'+opc.numOp);
  }

  eliminarOpcionCampoSubSeccion(seccion: SeccionFormulario, campo: CampoFormulario, cmpSubseccion: CampoFormulario, opc: OpcionesCampo) {
    cmpSubseccion.listaOpciones.splice(cmpSubseccion.listaOpciones.indexOf(opc), 1);
    // this.newForm.removeControl('seccion'+seccion.numSeccion+'campo'+campo.numCampo+'ss'+cmpSubseccion.numCampo+'op'+opc.numOp);
  }

  seleccionarTipoCampo(idTipoCampo, seccion: SeccionFormulario, campo: CampoFormulario) {
    if (idTipoCampo === 3) { // SI ES IGUAL A SUBSECCION AGREGO UN CAMPO A LA SUBSECCION
      this.agregarCampoSubseccion(seccion, campo);
    } else {

      campo.listaCamposSubseccion.forEach(cmpSubseccion => {
        this.newForm.removeControl('seccion'+seccion.numSeccion+'campo'+campo.numCampo+'ss'+cmpSubseccion.numCampo);
        this.newForm.removeControl('seccion'+seccion.numSeccion+'campo'+campo.numCampo+'ss'+cmpSubseccion.numCampo+'ob');
        cmpSubseccion.listaOpciones.forEach(opcionSubsec => {
          this.newForm.removeControl('seccion'+seccion.numSeccion+'campo'+campo.numCampo+'ss'+cmpSubseccion.numCampo+'op'+opcionSubsec.numOp);
        })
      })

      campo.listaCamposSubseccion = [];
    }
  }

  async guardarFormulario() {
    this.alerta = false;
    
    if (this.newForm.invalid) {
      console.log('FALTAN CAMPOS')
      // console.log(this.newForm)
      // console.log(this.formularioNuevo)
    } else {
      // console.log('guarda ok')
      this.loadButton = true;

      this.formularioNuevo.nombre = this.newForm.get('nombreFormulario').value;
      this.formularioNuevo.listaSecciones.forEach(seccion => {
        seccion.nombre = this.newForm.get('seccion'+seccion.numSeccion).value;
        seccion.listaCampos.forEach(campo => {
          campo.nombre = this.newForm.get('seccion'+seccion.numSeccion+'campo'+campo.numCampo).value;
          campo.obligatorio = this.newForm.get('seccion'+seccion.numSeccion+'campo'+campo.numCampo+'ob').value === true ? 'SI' : 'NO';
          campo.listaOpciones.forEach(op => {
            op.informacionCampo = this.newForm.get('seccion'+seccion.numSeccion+'campo'+campo.numCampo+"op"+op.numOp).value
          });

          if (campo.idTipo === 3) {
            campo.listaCamposSubseccion.forEach(cmpSubSec => {
              cmpSubSec.nombre = this.newForm.get('seccion'+seccion.numSeccion+'campo'+campo.numCampo+'ss'+cmpSubSec.numCampo).value;
              cmpSubSec.obligatorio = this.newForm.get('seccion'+seccion.numSeccion+'campo'+campo.numCampo+'ss'+cmpSubSec.numCampo+'ob').value === true ? 'SI' : 'NO';
              cmpSubSec.listaOpciones.forEach(op => {
                op.informacionCampo = this.newForm.get('seccion'+seccion.numSeccion+'campo'+campo.numCampo+'ss'+cmpSubSec.numCampo+"op"+op.numOp).value
              });
            })
          }
        });
      });

      // console.log(this.formularioNuevo)

      await this.formService.crearFormulario(this.formularioNuevo).toPromise().then(resp => {
        // console.log(resp)

        if (resp.ok) {
          this.alertService.success(resp.mensaje)
        } else {
          this.alertService.error(resp.mensaje)
        }

        this.loadButton = false;
        this.alerta = true;
      }, error => {
        console.log(error)
        this.alertService.error('Ocurrio un error al crear el formulario')
        this.alerta = true;
      })
    }
  }
}