import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AgendaComponent } from './agenda/agenda.component';
import { HeaderComponent } from './header/header.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { toTitleCasePipe } from './_pipes/toTitleCase.pipe';
import { Base64DecodePipe } from './_pipes/base64Decode.pipe';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { LoginComponent } from './login/login.component';
import { AlertComponent } from './alert/alert.component';
import { ErrorInterceptor } from './_helpers/error.interceptor';
import { JwtInterceptor } from './_helpers/jwt.interceptor';
import { SearchPacientesDialog } from './agenda/agenda.component';
import { AdministracionAgendasComponent } from './administracion-agendas/administracion-agendas.component';
import { PacienteYaAsignadoDialog } from './agenda/agenda.component';
import { CrearPacienteDialog } from './agenda/agenda.component';
import { EditarEtiquetasDialog } from './paciente/paciente.component';
import { EditarAntecedentesDialog } from './paciente/paciente.component';
import { PacienteComponent } from './paciente/paciente.component';
import { PacienteBioComponent } from './paciente-bio/paciente-bio.component';
import { PacienteHistorialComponent } from './paciente-historial/paciente-historial.component';
import { DialogSeleccionarSubepisodio, PacienteEvolucionComponent } from './paciente-evolucion/paciente-evolucion.component';
import { AgregarAgendaComponent } from './agregar-agenda/agregar-agenda.component';
import { toMinutesPipe } from './_pipes/toMinutes.pipe';
import { SafeUrlPipe } from './_pipes/safeUrl.pipe';
import { ModificarAgendaComponent } from './modificar-agenda/modificar-agenda.component';
import { DialogConfirmacionComponent } from './dialog-confirmacion/dialog-confirmacion.component';
import { DialogEliminarAgendaComponent } from './dialog-eliminar-agenda/dialog-eliminar-agenda.component';
import { DialogMensajeComponent } from './dialog-mensaje/dialog-mensaje.component';
import { DialogDatosAgendaComponent } from './dialog-datos-agenda/dialog-datos-agenda.component';
import { DialogPdfComponent } from './dialog-pdf/dialog-pdf.component';
import { AdministracionEstablecimientosComponent } from './administracion-establecimientos/administracion-establecimientos.component';
import { AgregarEstablecimientoComponent } from './agregar-establecimiento/agregar-establecimiento.component';
import { DialogAgregarConsultorioComponent } from './dialog-agregar-consultorio/dialog-agregar-consultorio.component';
import { ModificarEstablecimientoComponent } from './modificar-establecimiento/modificar-establecimiento.component';
import { DialogSolicitarEstudioComponent } from './dialog-solicitar-estudio/dialog-solicitar-estudio.component';
import { DialogCancelarTurnosComponent } from './dialog-cancelar-turnos/dialog-cancelar-turnos.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { DialogIngresarItemComponent } from './dialog-ingresar-item/dialog-ingresar-item.component';
import { DialogReciboApheComponent } from './dialog-recibo-aphe/dialog-recibo-aphe.component';
import { EscribirInformeComponent } from './escribir-informe/escribir-informe.component';
import { DialogEditarDatosPacienteComponent } from './dialog-editar-datos-paciente/dialog-editar-datos-paciente.component';
import { MenuComponent } from './menu/menu.component';
import { DialogSolicitarBiopsiaComponent } from './dialog-solicitar-biopsia/dialog-solicitar-biopsia.component';
import { TurnosComponent } from './turnos/turnos.component';
import { DiasAtencionComponent } from './dias-atencion/dias-atencion.component';
import { EscribirFormularioComponent } from './escribir-formulario/escribir-formulario.component';
import { CrearFormularioComponent } from './crear-formulario/crear-formulario.component';
import { ListarFormulariosComponent } from './listar-formularios/listar-formularios.component';
import { CrearEpisodioComponent } from './crear-episodio/crear-episodio.component';
import { ListarEpisodiosComponent } from './listar-episodios/listar-episodios.component';
import { VerFormulariosEpisodioComponent } from './ver-formularios-episodio/ver-formularios-episodio.component';
import { EstudiosExternosComponent } from './estudios-externos/estudios-externos.component';
import { HistorialLaboratorioComponent } from './historial-laboratorio/historial-laboratorio.component';
import { TurnosAnuladosComponent } from './turnos-anulados/turnos-anulados.component';
import { DialogSolicitarInternacionComponent } from './dialog-solicitar-internacion/dialog-solicitar-internacion.component';
import { SafePipe } from './safe.pipe';
import { DialogAtencionAgendaComponent } from './dialog-atencion-agenda/dialog-atencion-agenda.component';
import { DocumentosDigitalesComponent } from './documentos-digitales/documentos-digitales.component';
import { CargarDocumentoDigitalComponent } from './cargar-documento-digital/cargar-documento-digital.component';
import { DialogAsignarConsentimientosComponent } from './dialog-asignar-consentimientos/dialog-asignar-consentimientos.component';
import { DialogRecuperarContrasenaComponent } from './dialog-recuperar-contrasena/dialog-recuperar-contrasena.component';
import { DialogCambiarContrasenaComponent } from './dialog-cambiar-contrasena/dialog-cambiar-contrasena.component';
import { DialogCambiarCorreoComponent } from './dialog-cambiar-correo/dialog-cambiar-correo.component';
import { DialogSelectCoberturaComponent } from './dialog-select-cobertura/dialog-select-cobertura.component';

@NgModule({
    declarations: [
        AppComponent,
        AgendaComponent,
        HeaderComponent,
        LoginComponent,
        AlertComponent,
        toTitleCasePipe,
        Base64DecodePipe,
        SafeUrlPipe,
        SearchPacientesDialog,
        CrearPacienteDialog,
        AdministracionAgendasComponent,
        PacienteYaAsignadoDialog,
        EditarAntecedentesDialog,
        EditarEtiquetasDialog,
        PacienteComponent,
        PacienteBioComponent,
        PacienteHistorialComponent,
        PacienteEvolucionComponent,
        AgregarAgendaComponent,
        toMinutesPipe,
        ModificarAgendaComponent,
        DialogConfirmacionComponent,
        DialogEliminarAgendaComponent,
        DialogMensajeComponent,
        DialogDatosAgendaComponent,
        DialogPdfComponent,
        DialogIngresarItemComponent,
        AdministracionEstablecimientosComponent,
        AgregarEstablecimientoComponent,
        DialogAgregarConsultorioComponent,
        ModificarEstablecimientoComponent,
        DialogSolicitarEstudioComponent,
        DialogCancelarTurnosComponent,
        DialogIngresarItemComponent,
        DialogReciboApheComponent,
        EscribirInformeComponent,
        DialogEditarDatosPacienteComponent,
        MenuComponent,
        DialogSolicitarBiopsiaComponent,
        TurnosComponent,
        DiasAtencionComponent,
        EscribirFormularioComponent,
        CrearFormularioComponent,
        ListarFormulariosComponent,
        CrearEpisodioComponent,
        ListarEpisodiosComponent,
        VerFormulariosEpisodioComponent,
        EstudiosExternosComponent,
        HistorialLaboratorioComponent,
        TurnosAnuladosComponent,
        DialogSolicitarInternacionComponent,
        SafePipe,
        DialogAtencionAgendaComponent,
        DocumentosDigitalesComponent,
        CargarDocumentoDigitalComponent,
        DialogAsignarConsentimientosComponent,
        DialogRecuperarContrasenaComponent,
        DialogCambiarContrasenaComponent,
        DialogCambiarCorreoComponent,
        DialogSeleccionarSubepisodio,
        DialogSelectCoberturaComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        MatButtonModule,
        MatMenuModule,
        MatExpansionModule,
        MatToolbarModule,
        MatIconModule,
        MatSlideToggleModule,
        MatBadgeModule,
        MatProgressBarModule,
        MatListModule,
        MatProgressSpinnerModule,
        MatGridListModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatRadioModule,
        MatStepperModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatChipsModule,
        MatTooltipModule,
        MatCheckboxModule,
        MatTableModule,
        MatDialogModule,
        MatCardModule,
        MatTabsModule,
        MatPaginatorModule,
        CKEditorModule,
        NgxMatSelectSearchModule,
        MatAutocompleteModule,
        MatSidenavModule,
        MatSortModule,
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}