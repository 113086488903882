

import { Component, Inject, OnInit } from '@angular/core';
import { TurnosService } from '../_services/turnos.service';
import { SucursalService } from '../_services/sucursal.service';
import { UntypedFormGroup, UntypedFormControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { takeUntil } from 'rxjs/operators';
import { NavigationStart, Router } from '@angular/router';
import { UserService } from '../_services/user.service';



@Component({
  selector: 'app-turnos',
  templateUrl: './turnos.component.html',
  styleUrls: ['./turnos.component.css'],
})
export class TurnosComponent implements OnInit {
 
  turnos: any[];
  profesionales: any[];
  profesionalSeleccionado: any = {nroMatricula: null};
  especialidades: any[];
  especialidadSeleccionada: any = {codigo: null};
  establecimientos: any[];
  establecimientoSeleccionado: any = {codigo: null};
  loading = true;
  loadingLogin = false;
  loadingTurnosProfesional = true;
  existenTurnos: boolean = false;
  todosLosTurnos: any[];  

  /* Variables para los mat-select */
  filtroTurnosForm: UntypedFormGroup;
  bankMultiFilterCtrl: UntypedFormControl = new UntypedFormControl();
  protected _onDestroy = new Subject<void>(); 
  especialidadesFiltradas: any[];
  establecimientosFiltrados: any[];
  profesionalesFiltrados: any[];
  listaSucursales: any[];

  dataTablaItems: any[] = [];
  dataSourceItems = this.dataTablaItems;
  displayedColumnasItems: string[] = ['profesional', 'diasdeatencion','turnosDisponibles'];
  

  constructor(
    private turnosService: TurnosService,
    private sucursalService: SucursalService,
    private userService: UserService,
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    public dialogOferta: MatDialog

  ) {
    router.events.subscribe((event: NavigationStart) => {
      if (event.navigationTrigger === 'popstate') {
        history.pushState(null, null, location.href);
      }
    });
  }

 async ngOnInit() {
    this.cargarFiltroTurnosForm(); 
    await this.cargarEstablecimientosPerfil();
    this.cargarEspecialidades();
    //this.cargarEstablecimientos();
    this.cargarProfesionales();   
    this.cargarTurnos(null,null,this.establecimientoSeleccionado.codigo);
   
    
  }


  
  private cargarFiltroTurnosForm(){
    this.bankMultiFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterBanksMultiEspecialidades();
        this.filterBanksMultiEstablecimientos();
        this.filterBanksMultiProfesionales();
      });
      this.filtroTurnosForm = this.formBuilder.group({
        especialidad: [{value:{nombre: 'Todas', codigo: null}}], 
        establecimiento: [{value:{nombreCompleto: 'Todos', codigo: this.establecimientoSeleccionado.codigo}}],
        profesional: [{value:{apellido: 'Todos', nroMatricula: null}}],      
      });
  }

  protected filterBanksMultiEspecialidades() {
    // get the search keyword
    let search = this.bankMultiFilterCtrl.value;
    if (!search) {
      this.especialidadesFiltradas=this.especialidades;
      return;
    } else {
      search = search.toLowerCase();
    }
    // filtro especialidades
    this.especialidadesFiltradas=
      this.especialidades.filter(
        (especialidad) => especialidad.nombre.toLowerCase().indexOf(search) > -1
      );
  }

  protected filterBanksMultiEstablecimientos() {
    // get the search keyword
    let search = this.bankMultiFilterCtrl.value;
    if (!search) {
      this.establecimientosFiltrados=this.establecimientos;
      return;
    } else {
      search = search.toLowerCase();
    }
     //filtro establecimientos
      this.establecimientosFiltrados=
      this.establecimientos.filter(
        (establecimiento) => establecimiento.nombreCompleto.toLowerCase().indexOf(search) > -1
      );
  }

  protected filterBanksMultiProfesionales() {
    // get the search keyword
    let search = this.bankMultiFilterCtrl.value;
    if (!search) {
      this.profesionalesFiltrados=this.profesionales;
      return;
    } else {
      search = search.toLowerCase();
    }
     //filtro establecimientos
      this.profesionalesFiltrados=
      this.profesionales.filter(
        (profesional) => profesional.apellido.toLowerCase().indexOf(search) > -1
      );
  }

 
  OnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }


  async cargarEspecialidades() {
        
    await this.turnosService.getEspecialidades().toPromise().then(
        (data) => {
          if (data.cantidadTotal > 0 ) {
            this.especialidades = data.especialidades; 
            this.especialidadesFiltradas= data.especialidades;                             
          }
           this.especialidades.splice(0,0,{nombre: 'Todas', codigo: null});
           this.especialidadSeleccionada= this.especialidades[0]; 
           
           this.filtroTurnosForm.get('especialidad').setValue(this.especialidades[0]);
        }
       ); 
  }

  async cargarEstablecimientos(codigo: number) {
    await this.sucursalService.getEstablecimientos().toPromise().then(
        (data) => {               
            if (data.ok ) {        
            this.establecimientos = data.data;   
            this.establecimientosFiltrados= data.data;                                      
          }
          if(codigo !=null){
            this.establecimientos=
            this.establecimientos.filter((establecimiento) => establecimiento.codigo === codigo);  
           this.establecimientosFiltrados=this.establecimientos;
            this.establecimientoSeleccionado= this.establecimientos[0];
          }
          this.filtroTurnosForm.get('establecimiento').setValue(this.establecimientoSeleccionado); 
          });
  }

  perfilSecretaria(): any {
    return this.userService.perfilSecretaria;
  }

  async cargarEstablecimientosPerfil(){
    this.listaSucursales = [];
    let aux = this.perfilSecretaria();   
    if (aux) {
      await aux.forEach((perfil) => {
         this.listaSucursales.push({
           id: parseInt(perfil.codigoSucursal),
           nombre: perfil.nombreSucursal,
         });       
      });
     await this.cargarEstablecimientos(this.listaSucursales[0].id);
    }else{
      this.cargarEstablecimientos(null);
    }
}

  async cargarProfesionales() {

     let matricula='null';
     await this.turnosService.getProfesionalesSelect(matricula).toPromise().then(
         (data) => {     
            if (data.ok ) {       
             this.profesionales = data.paginador.elementos;  
             this.profesionalesFiltrados=data.paginador.elementos;  
                
           }
           this.profesionales.splice(0,0,{apellido: 'Todos', nroMatricula: null});
           this.profesionalSeleccionado= this.profesionales[0];  
           
           this.filtroTurnosForm.get('profesional').setValue(this.profesionales[0]);       
         }
        ); 
   }

   async cargarTurnos(especialidad,matricula,establecimiento) {
        
    await  this.turnosService.getTurnosPorFiltro(especialidad,matricula,establecimiento).toPromise().then(      
        (data) => {
          if (data.ok) {
            this.turnos = data.data;
            this.existenTurnos=(data.data.length>0);
            this.turnos = data.data;
            this.todosLosTurnos = data.data;
            this.dataSourceItems=this.turnos;
            this.loading = false;
          }
        }
       ); 
       
    console.log("cargarTurnos  " + this.filtroTurnosForm.get('profesional').value.nroMatricula );
  }

  async FiltrarHorarios() {
    console.log("filtrarHorarios   " + this.filtroTurnosForm.get('profesional').value.nroMatricula );
      await  this.turnosService.getTurnosPorFiltro(
        this.filtroTurnosForm.get('especialidad').value.codigo,       
        this.filtroTurnosForm.get('profesional').value.nroMatricula,
        this.filtroTurnosForm.get('establecimiento').value.codigo
        ).toPromise().then(
        (data) => {
          if (data.ok) {
            this.establecimientoSeleccionado= this.filtroTurnosForm.get('establecimiento').value;
            this.turnos = data.data;
            this.existenTurnos=(data.data.length>0);
            this.turnos = data.data;
            this.dataSourceItems=this.turnos;
            this.todosLosTurnos = data.data;
            this.loading = false;
            sessionStorage.setItem('nroSucursal', JSON.stringify(this.filtroTurnosForm.get('establecimiento').value.codigo));
          }
          
        }
       ); 
  }

 filtrarTurnos(){
  this.todosLosTurnos = this.turnos.filter(
     turno => turno.tieneHorario
   )
 }

 onClickBuscar() {
  /*Llamar a filtrar*/
  this.FiltrarHorarios();
}
mostrarDiasAtencion(turno: any){
  sessionStorage.setItem('matriculaProfesional', JSON.stringify(turno.nroMatricula));
  sessionStorage.setItem('establecimiento', JSON.stringify(turno.diasAtencion[0].numero));
  this.router.navigate(['/diasAtencion']);
}

FiltroNoSeleccionado(){
 return (this.establecimientoSeleccionado.codigo == null);
}


}

