<mat-accordion>
  <mat-expansion-panel  *ngFor="let link of this.grouped"   >
    <mat-expansion-panel-header>
        <mat-panel-title>        
       <a >{{link[0].nombreMenu}}</a>

  </mat-panel-title>

  <mat-panel-description> 
    
  </mat-panel-description>
 
</mat-expansion-panel-header>
<ul>
  <li *ngFor="let funcion of link" >
    
    <a
      routerLink="{{ funcion.url }}"
      [state]="{ data: funcion.includeUrl }" 
      (click)="inputSideNav.toggle()"
      class="link-menu"
      >{{funcion.nombreSubMenu}}</a
    >
  </li>
</ul>

</mat-expansion-panel>

</mat-accordion>