export class EstadoTurno {

    getDescripcion() {}
    getCodigo() {}
    getSiguienteEstado() {}
    getAnteriorEstado() {}
    getCssClass() {}
    mostrarTv() {
        return false;
    }
    getStepperIndex() {
    }
}

export class EstadoEnEstudios extends EstadoTurno {

    getDescripcion() {
        return 'En Estudios';
    }

    getCodigo() {
        return '1';
    }

    getCodigoTv() {
        return 'ENE';
    }

    getSiguienteEstado() {
        return new EstadoEnAtencion();
    }

    mostrarTv() {
        return true;
    }

    getEstadoSiguienteTriage() {
        return 'ENA'
    }

}

export class EstadoLlamando extends EstadoTurno {

    getDescripcion() {
        return 'Llamando';
    }

    getCodigo() {
        return '9';
    }

    getCodigoTv() {
        return 'LLA';
    }

    getSiguienteEstado() {
        return new EstadoEnAtencion();
    }

    mostrarTv() {
        return true;
    }
    
    getEstadoSiguienteTriage() {
        return 'ENA'
    }
}

export class EstadoRetirado extends EstadoTurno {


    getDescripcion() {
        return 'Se retiró';
    }

    getCodigo() {
        return 'R';
    }


}

export class EstadoFaltaPago extends EstadoTurno {


    getDescripcion() {
        return 'Falta pago';
    }

    getCodigo() {
        return 'F';
    }

}
export class EstadoAusente extends EstadoTurno {


    getDescripcion() {
        return 'Ausente';
    }

    getCodigo() {
        return 'N';
    }

    getSiguienteEstado() {
        return new EstadoEsperando();
    }

    getStepperIndex() {
        return 0;
    }

}

export class EstadoEsperando extends EstadoTurno {

    getDescripcion() {
        return 'Esperando';
    }

    getCodigo() {
        return '8';
    }

    getSiguienteEstado() {
        return new EstadoEnAtencion();
    }

    getCodigoTv() {
        return 'ANU';
    }

    getCssClass() {
        return 'warning';
    }

    mostrarTv() {
        return true;
    }

    getStepperIndex() {
       return 0;
    }

    getEstadoSiguienteTriage() {
        return 'ENA'
    }
}

export class EstadoAtendido extends EstadoTurno {


    getDescripcion() {
        return 'Atendido';
    }

    getCodigo() {
        return '3';
    }

    getCodigoTv() {
        return 'ATE';
    }

    getStepperIndex() {
        return 2;
    }

    getCssClass() {
        return 'atendido';
    }

    getAnteriorEstado() {
        return new EstadoEnAtencion();
    }


}

export class EstadoEnAtencion extends EstadoTurno {


    getDescripcion() {
        return 'En atención';
    }

    getCodigo() {
        return '2';
    }
    getCodigoTv() {
        return 'ENA';
    }

    getCssClass() {
       return 'en-atencion';
    }

    getStepperIndex() {
        return 1;
    }

    getSiguienteEstado() {
        return new EstadoAtendido();
    }

    getAnteriorEstado() {
        return new EstadoEsperando();
    }

}