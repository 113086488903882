import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Adapter } from '../_adapters/adapter';

export class EpisodioHistorial {
  idEpisodio: number;
  fecha: any;
  fechast: any;
  epicrisisUco: boolean;
  especialidadAtencion: string;
  hcdigital: boolean;
  matriculaProfesional: string;
  nombreProfesional: string;
  observacion: string;
  tiene: boolean;
  tipoEpisodio: string;
  estado;
  evolucion;
  idTipoEpisodio: number;
  referenciaRegistro: string;
  sectorInforme: number;
  descripcion: string;
  diagnostico: string;
  fechaFin: any;
  fechaInicio: any;
  subepisodios: any[];
}

@Injectable({
  providedIn: 'root',
})

export class EpisodioHistorialAdapter implements Adapter<EpisodioHistorial> {

  adapt(item: any): EpisodioHistorial {
    const eh = new EpisodioHistorial();

    eh.epicrisisUco = item.epicrisisUco;
    eh.especialidadAtencion = item.especialidadAtencion;
    eh.fecha = moment(item.fecha, 'MMM DD, YYYY, HH:mm:ss A');
    eh.fechast = item.fechast;
    eh.hcdigital = item.hcdigital;
    eh.idEpisodio = item.idEpisodio;
    eh.matriculaProfesional = item.matriculaProfesional;
    eh.nombreProfesional = item.nombreProfesional;
    eh.observacion = item.observacion ? item.observacion: '-';
    eh.tiene = item.tiene;
    eh.tipoEpisodio = item.tipoEpisodio;
    eh.estado = item.estado;
    eh.evolucion = item.evolucion;
    eh.idTipoEpisodio = item.idTipoEpisodio;
    eh.referenciaRegistro = item.referenciaRegistro;
    eh.sectorInforme = item.sectorInforme;
    eh.descripcion = item.descripcion;
    eh.diagnostico = item.diagnostico;
    eh.fechaFin = item.fechaFin;
    eh.fechaInicio = item.fechaInicio;
    eh.subepisodios = item.subepisodios;

    return eh;
  }
  
}
