<div class="container-fluid"  style="border: 0px solid red;">
  <button mat-icon-button style="float: right; top: -12px; right: -12px" [mat-dialog-close]="false">
    <mat-icon class="close-icon" color="warn">close</mat-icon>
  </button>
<h1 mat-dialog-title class="text-center mb-1">Asignación de consentimientos para cirugía</h1>


  <div class="row" style="align-items: center; margin-left: unset;">
    
      <mat-form-field style="margin-right: 3%;width: 70%;" >
        <mat-label>Seleccionar consentimientos</mat-label>
        <mat-select [(ngModel)]="consentimientoSeleccionado" multiple >
          <mat-option >
            <ngx-mat-select-search placeholderLabel="Buscar consentimiento" noEntriesFoundLabel="No se encontraron consentimientos" [formControl]="bankMultiFilterCtrl"> </ngx-mat-select-search>
          </mat-option>
          <mat-option *ngFor="let c of this.consentimientosFiltrados | async " [value]="c" >
            {{c.nombre | toTitleCase}}
          </mat-option>
        </mat-select>
      </mat-form-field>
   
<button mat-raised-button color="primary" style="border-radius: 5px;width: 120px;" [disabled]="!consentimientoSeleccionado || agregando" (click)="agregarConsentimiento(consentimientoSeleccionado)" >
  <mat-icon>add</mat-icon>
  <span> Agregar</span>
 </button>
</div>
<div *ngIf="hayConsentimientos">
<table
mat-table
[dataSource]="dataSourceItems"
class="mat-elevation-z8 "
>
<ng-container matColumnDef="nombre">
  <th mat-header-cell *matHeaderCellDef>Nombre</th>
    <td mat-cell *matCellDef="let element">
      {{element.nombre}}
    </td> 
</ng-container>
<ng-container matColumnDef="fechaAsignacion">
  <th mat-header-cell *matHeaderCellDef>Fecha de Asignacion</th>
    <td mat-cell *matCellDef="let element">
     {{element.fechaestado}} 
    </td>
</ng-container>
<ng-container matColumnDef="acciones">
  <th mat-header-cell *matHeaderCellDef>Acciones</th>
  <div class="row">
    <td mat-cell *matCellDef="let element">
      

      
      <button mat-icon-button 
      matTooltip="Ver consentimiento"
      color="primary" 
      (click)="imprimirConsentimiento(element)"
      >
        <mat-icon>picture_as_pdf</mat-icon>
      </button>
    
      <button mat-icon-button 
      matTooltip="Eliminar consentimiento"
      color="warn" 
      (click)="eliminarConsentimiento(element)"
      >
        <mat-icon>delete</mat-icon>
      </button>
    
    
    </td>
  </div>
  
</ng-container>
<tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true" ></tr>
<tr mat-row *matRowDef="let row; columns: columnsToDisplay"></tr>
</table>
</div>