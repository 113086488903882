import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AgendaComponent } from './agenda/agenda.component';
import { LoginComponent } from './login/login.component';
import { AdministracionAgendasComponent } from './administracion-agendas/administracion-agendas.component';
import { AuthGuard } from './_guards/auth.guard';
import { AgregarAgendaComponent } from './agregar-agenda/agregar-agenda.component';
import { ModificarAgendaComponent } from './modificar-agenda/modificar-agenda.component';
import { PacienteComponent } from './paciente/paciente.component';
import { AdministracionEstablecimientosComponent } from './administracion-establecimientos/administracion-establecimientos.component';
import { AgregarEstablecimientoComponent } from './agregar-establecimiento/agregar-establecimiento.component';
import { ModificarEstablecimientoComponent } from './modificar-establecimiento/modificar-establecimiento.component';
import { EscribirInformeComponent } from './escribir-informe/escribir-informe.component';
import { TurnosComponent } from './turnos/turnos.component';
import { DiasAtencionComponent } from './dias-atencion/dias-atencion.component';
import { EscribirFormularioComponent } from './escribir-formulario/escribir-formulario.component';
import { CrearFormularioComponent } from './crear-formulario/crear-formulario.component';
import { ListarFormulariosComponent } from './listar-formularios/listar-formularios.component';
import { CrearEpisodioComponent } from './crear-episodio/crear-episodio.component';
import { ListarEpisodiosComponent } from './listar-episodios/listar-episodios.component';
import { VerFormulariosEpisodioComponent } from './ver-formularios-episodio/ver-formularios-episodio.component';
import { EstudiosExternosComponent } from './estudios-externos/estudios-externos.component';
import { HistorialLaboratorioComponent } from './historial-laboratorio/historial-laboratorio.component';
import { TurnosAnuladosComponent } from './turnos-anulados/turnos-anulados.component';
import { DocumentosDigitalesComponent } from './documentos-digitales/documentos-digitales.component';
import { CargarDocumentoDigitalComponent } from './cargar-documento-digital/cargar-documento-digital.component';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'login' },
  { path: 'login', component: LoginComponent },
  { path: 'agenda', component: AgendaComponent, canActivate: [AuthGuard] },
  { 
    path: 'paciente', 
    component: PacienteComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'administracionAgendas',
    component: AdministracionAgendasComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'administracionEstablecimientos',
    component: AdministracionEstablecimientosComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'modificarAgenda',
    component: ModificarAgendaComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'agregarAgenda',
    component: AgregarAgendaComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'agregarEstablecimiento',
    component: AgregarEstablecimientoComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'modificarEstablecimiento',
    component: ModificarEstablecimientoComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'escribir-informe/:numeroPedido',
    component: EscribirInformeComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'busquedaTurnos',
    component: TurnosComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'diasAtencion',
    component: DiasAtencionComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'escribir-formulario/:idFormulario',
    component: EscribirFormularioComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'crear-formulario',
    component: CrearFormularioComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'listar-formularios',
    component: ListarFormulariosComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'crear-episodio',
    component: CrearEpisodioComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'listar-episodios',
    component: ListarEpisodiosComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'ver-formularios-episodio',
    component: VerFormulariosEpisodioComponent,
    canActivate: [AuthGuard],
  },
  {
  path: 'estudios-externos',
  component: EstudiosExternosComponent,
  canActivate: [AuthGuard]  
  },
  {
    path: 'historial-laboratorio',
    component: HistorialLaboratorioComponent,
    canActivate: [AuthGuard]  
  },
  {
    path: 'turnos-anulados',
    component: TurnosAnuladosComponent,
    canActivate: [AuthGuard]  
  },
  {
    path: 'documentos-digitales',
    component: DocumentosDigitalesComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'cargar-documento-digital',
    component: CargarDocumentoDigitalComponent,
    canActivate: [AuthGuard]
  }, 
  {
    path: 'listar-episodios/:dniPaciente',
    component: ListarEpisodiosComponent,
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})

export class AppRoutingModule {}
