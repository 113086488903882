import { Component, OnInit } from '@angular/core';
import { EstudioService } from '../_services/estudios.service';
import { PacienteService } from '../_services/paciente.service';
import { NavigationStart, Router } from '@angular/router';

@Component({
  selector: 'app-historial-laboratorio',
  templateUrl: './historial-laboratorio.component.html',
  styleUrls: ['./historial-laboratorio.component.css']
})
export class HistorialLaboratorioComponent implements OnInit {

  constructor(
    private estudioService: EstudioService,
    private pacienteService: PacienteService,
    private router: Router,
  ) { }

  // displayedColumns: string[] = ['key', 'value'];
  // dataSource = ELEMENT_DATA;
  pedido: any = {};
  listaResultados: any = [];
  listaResultadosTodos: any = [];
  listaItems: any = [];
  arregloItems: any;
  valoresDeterminacionSeleccionada: any;
  nombreDeterminacionSeleccionada: any;
  seleccionoDeterminacion: boolean;
  paciente: any;
  currentPatient: any;
  cargoPaciente: boolean;
  cargando: boolean = true;

  ngOnInit() {
    this.currentPatient = this.pacienteService.getCurrentPatient();
    this.getDatosPaciente(this.currentPatient.numeroDoc, this.currentPatient.tipoDoc);
    this.getHistorialLaboratorio(this.currentPatient.numeroDoc, this.currentPatient.tipoDoc);
  }

  async getDatosPaciente(nroDoc, tipoDoc) {
    await this.pacienteService.datosPaciente(nroDoc, tipoDoc).toPromise().then(resp => {
      // console.log(resp)
      this.paciente = resp;
      this.cargoPaciente = true;
    }, error => {
      console.log(error)
    })
  }

  async getHistorialLaboratorio(nroDoc, tipoDoc) {
    await this.estudioService.getHistorialLaboratorio(tipoDoc+nroDoc).toPromise().then( resp => {
      // console.log(resp)
      resp.resultados.forEach(resultado => {
        this.getPedido(resultado);
      });
      this.listaResultados = resp.resultados;
      this.listaResultadosTodos = resp.resultados;

      this.allItems(this.listaResultadosTodos);
    }, error => {
      console.log(error)
    })

  }

  async getPedido(resultado) {

    this.pedido.numero = resultado.idExterno;

    await this.estudioService.getPedidosGenericoSinFechas(this.pedido).toPromise().then(resp => {
      // console.log(resp)
      if (resp.ok) {
        resultado.pedido = resp.pedidos[0];
        resultado.categoria = resp.pedidos[0].categoria.charAt(0);
        resultado.fechaHoraEfec = resp.pedidos[0].fechaHoraEfec;
      }
    }, error => {
      console.log(error)
    })
  }

  agruparItems(resultados) {
    this.arregloItems = {};
    this.listaItems.forEach(item => {
      if (!this.arregloItems.hasOwnProperty(item.descripcion)) {
        this.arregloItems[item.descripcion] = {
          items: []
        };

        resultados.forEach(resultado => {
          this.arregloItems[item.descripcion].items.push({numPedido: resultado.idExterno})
        });
      }

      this.arregloItems[item.descripcion].items.forEach(elemento => {
        if (elemento.numPedido == item.numPedido) {
          elemento.resultado = item.resultado;
          elemento.rangoNormal = item.rangoNormal;
        }
      });
    });

    // console.log(this.arregloItems);
    this.cargando = false;
  }

  async allItems(resultados) {
    await resultados.forEach(estudio => {
      if (estudio.estudios && estudio.estudios.length > 0) {
        estudio.estudios.forEach(elemento => {
          elemento.resultados.forEach(item => {
            item.numPedido = estudio.idExterno;
            this.listaItems.push(item);
          });
        });
      }
    });

    this.agruparItems(resultados);
  }

  seleccionarCategoria(cat) {
    // console.log(cat)

    this.cargando = true;
    this.listaResultados = [];
    this.listaItems = [];

    if (cat != "AMBAS") {
      this.listaResultadosTodos.forEach(async resultado => {
        if (resultado.categoria == cat) {
          this.listaResultados.push(resultado);
        }
      });

      this.allItems(this.listaResultados);
    } else {
      this.listaResultados = this.listaResultadosTodos;
      this.allItems(this.listaResultados);
      this.seleccionoDeterminacion = false;
    }
  }

  seleccionarDeterminacion(det) {
    // console.log(det)

    if (det != 'TODAS') {
      this.valoresDeterminacionSeleccionada = [];
      this.nombreDeterminacionSeleccionada = det.key;
      this.valoresDeterminacionSeleccionada = det.value.items;

      this.seleccionoDeterminacion = true;
    } else {
      this.seleccionarCategoria('AMBAS');
    }
  }

  volver() {
    this.router.navigate(['/paciente']);
  }

}
