import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppComponent } from '../app.component';

@Injectable({
  providedIn: 'root'
})
export class EstadisticasService {
  baseUrl: string;

  constructor(
    private http: HttpClient,
  ) { 
    this.baseUrl = AppComponent.baseUrl;
  }

  guardarEstadistica(estadistica) {
    return this.http.post(this.baseUrl + '/estadisticas/guardarEstadistica', estadistica)
  }
}
