import { Component, OnInit, ViewChild } from '@angular/core';
import { FormulariosService } from '../_services/formularios.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { FormularioGenerico } from '../_models/formularioGenerico.interface';
import { Router } from '@angular/router';
import { AuthenticationService } from '../_services/authentication.service';

@Component({
  selector: 'app-listar-formularios',
  templateUrl: './listar-formularios.component.html',
  styleUrls: ['./listar-formularios.component.css']
})
export class ListarFormulariosComponent implements OnInit {

  cargando: boolean = true;
  displayedColumns: string[] = ['idFormulario', 'nombre', 'estado', 'acciones'];
  dataSource: MatTableDataSource<FormularioGenerico>;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private formService: FormulariosService,
    private router: Router,
    private authService: AuthenticationService,
  ) { }

  ngOnInit() {
    this.getAllFormularios();
  }

  async getAllFormularios() {
    await this.formService.getAllFormularios().toPromise().then(resp => {
      console.log(resp)
      if (resp.ok) {
        this.dataSource = new MatTableDataSource(resp.formularios);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }
      this.cargando = false;
    }, error => {
      console.log(error)
      this.cargando = false;
    })
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  crearFormulario() {
    this.router.navigate(['/crear-formulario']);
  }

  eliminarFormulario(form) {
    this.cargando = true
    form.estado = 'INA'

    this.actualizarForm(form);
  }

  activarFormulario(form) {
    this.cargando = true
    form.estado = 'ACT'

    this.actualizarForm(form);
  }

  async actualizarForm(form) {
    form.loginEstado = this.authService.currentUserValue.usuario
    form.listaSecciones = []

    await this.formService.crearFormulario(form).toPromise().then(resp => {
      console.log(resp)
      this.cargando = false
    }, error => {
      console.log()
      this.cargando = false
    })
  }

  async editarFormulario(form) {
    console.log(form)
  }
}