import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Adapter } from '../_adapters/adapter';
// import { EstadoEsperando } from './estadoEsperando.model';
// import { EstadoAusente } from './estadoAusente.model';
// import { EstadoFaltaPago } from './estadoFaltaPago.model';
// import { EstadoEnAtencion } from './estadoEnAtencion.model';
// import { EstadoAtendido } from './estadoAtendido.model';
import { EstadoTurno, EstadoRetirado, EstadoAtendido, EstadoEnAtencion, EstadoFaltaPago, EstadoAusente, EstadoEsperando, EstadoEnEstudios } from './estadoTurno.model';



export class Turno {
    estado;
    estudios: any;
    numeroPedido: any;
    numeroBono: any;
    numeroDoc: any;
    numeroBoleta: any;
    usuarioTomo: any;
    tipo_reserva: any;
    tipoDoc: any;
    str_paciente: any;
    str_hora: any;
    servicioId: any;
    numero: any;
    nombre: any;
    nombreMutual: any;
    nroAfiliado: any;
    matricula: any;
    libre: any;
    horaDesde: any;
    hora: any;
    frecuencia: any;
    fechaEstablecido: any;
    fecha: any;
    diasAnticipacion: any;
    borrado: any;
    horaLlegada: any;
    llamadoTv: Boolean = false;
    idTriage:any;
    colorTriage:string;
    timeWaiting(){
        return moment
      .utc(moment(new Date(),'MMM DD, YYYY, HH:mm:ss A').add(3,'seconds').
      diff(moment(new Date(this.horaLlegada), 'MMM DD, YYYY, HH:mm:ss A')))
      .format('HH:mm:ss');
    }
}


@Injectable({
    providedIn: 'root'
})
export class TurnoAdapter implements Adapter<Turno> {

 adapt(item: any): Turno {
    
    let t =  new Turno();
    switch(item.vino) { 
        case "N": { 
           t.estado = new EstadoAusente();
           break; 
        } 
        case "F": { 
            t.estado = new EstadoFaltaPago();
            break; 
        }
        case "8": { 
            t.estado = new EstadoEsperando();
            break; 
        } 
        case "2": { 
            t.estado = new EstadoEnAtencion();
            break; 
        } 
        case "3": { 
            t.estado = new EstadoAtendido();
            break; 
        }
        case "R": { 
            t.estado = new EstadoRetirado();
            break; 
        }
        case "1": { 
            t.estado = new EstadoEnEstudios();
            break; 
        }    
        default: { 
           //statements; 
           break; 
        } 
     } 
    t.numero = item.numero
    t.numeroBoleta = item.numeroBoleta
    t.borrado = item.borrado
    t.diasAnticipacion = item.diasAnticipacion
    t.fecha = item.fecha
    t.fechaEstablecido = item.fechaEstablecido
    t.frecuencia = item.frecuencia
    t.hora = item.hora
    t.horaDesde = item.horaDesde
    t.libre = item.libre
    t.matricula = item.matricula
    t.nombre = item.nombre
    t.nombreMutual = item.nombreMutual
    t.nroAfiliado = item.nroAfiliado
    t.numero = item.numero
    t.numeroPedido = item.numeroPedido
    t.servicioId = item.servicioId
    t.str_hora = item.str_hora
    t.str_paciente = item.str_paciente
    t.tipoDoc = item.tipoDoc
    t.tipo_reserva = item.tipo_reserva
    t.usuarioTomo = item.usuarioTomo
    t.numeroDoc = item.numeroDoc
    t.numeroBono = item.numeroBono
    t.numeroPedido = item.numeroPedido
    t.horaLlegada = item.horaLlegada
    t.estudios = item.estudios;   
    t.idTriage = item.idTriage;
    t.colorTriage = item.colorTriage;
    return t
  }

}