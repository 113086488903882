<div class="container pagina" style="margin-top: 1%">
  <div class="row justify-content-md-center" style="text-align: center">
    <div
      class="col col-sm-12 col-md-12 col-lg-12 align-self-center align-middle text-center"
    >
      <div class="row justify-content-center">
        <mat-card class="py-1 my-1 w-100 text-center">
          <mat-card-title class="py-0 my-0"
            >Agregar establecimiento</mat-card-title
          >
          <form
            class="ui form"
            [formGroup]="establecimientoForm"
            enctype="multipart/form-data"
          >
            <mat-card-content>
              <div class="form-row">
                <div
                  class="form-group col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6"
                >
                  <mat-form-field>
                    <mat-label>Nombre establecimiento </mat-label>
                    <input
                      matInput
                      type="text"
                      formControlName="nombre"
                      placeholder="Nombre establecimiento"
                      required
                    />
                  </mat-form-field>
                  <div
                    class="alert alert-danger p-1 m-1"
                    *ngIf="
                      establecimientoForm.get('nombre').hasError('required') &&
                      (establecimientoForm.get('nombre').touched || submitted)
                    "
                  >
                    <p class="p-0 m-0">
                      Debe ingresar el nombre del establecimiento
                    </p>
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div
                  class="form-group col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6"
                >
                  <mat-form-field>
                    <mat-label>Localidad del establecimiento </mat-label>
                    <input
                      matInput
                      type="text"
                      formControlName="localidad"
                      placeholder="Localidad del establecimiento"
                      required
                    />
                  </mat-form-field>
                  <div
                    class="alert alert-danger p-1 m-1"
                    *ngIf="
                      establecimientoForm
                        .get('localidad')
                        .hasError('required') &&
                      (establecimientoForm.get('localidad').touched ||
                        submitted)
                    "
                  >
                    <p class="p-0 m-0">
                      Debe ingresar la localidad del establecimiento
                    </p>
                  </div>
                </div>
                <div
                  class="form-group col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6"
                >
                  <mat-form-field>
                    <mat-label>Dirección del establecimiento </mat-label>
                    <input
                      matInput
                      type="text"
                      formControlName="domicilio"
                      placeholder="Dirección del establecimiento"
                      required
                    />
                  </mat-form-field>
                  <div
                    class="alert alert-danger p-1 m-1"
                    *ngIf="
                      establecimientoForm
                        .get('domicilio')
                        .hasError('required') &&
                      (establecimientoForm.get('domicilio').touched ||
                        submitted)
                    "
                  >
                    <p class="p-0 m-0">
                      Debe ingresar la dirección del establecimiento
                    </p>
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div
                  class="form-group col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6"
                >
                  <mat-form-field>
                    <mat-label>Nombre consultorio </mat-label>
                    <input
                      matInput
                      type="text"
                      formControlName="descripcion"
                      placeholder="Nombre consultorio"
                    />
                  </mat-form-field>
                </div>
                <!-- <div
                  class="form-group col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4"
                >
                  <mat-form-field>
                    <mat-label>Ubicacion del consultorio </mat-label>
                    <input
                      matInput
                      type="text"
                      formControlName="ubicacion"
                      placeholder="Ubicacion del consultorio"
                    />
                  </mat-form-field>
                </div>
                <div
                  class="form-group col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4"
                >
                  <mat-form-field>
                    <mat-label>Observaciones del consultorio </mat-label>
                    <input
                      matInput
                      type="text"
                      formControlName="observacion"
                      placeholder="Observaciones del consultorio"
                    />
                  </mat-form-field>
                </div> -->
              </div>
            </mat-card-content>
            <mat-card-actions class="p-0 m-0">
              <div>
                <button
                  mat-raised-button
                  color="warn"
                  type="button"
                  class="btn btn-primary btn"
                  style="margin: 2px"
                  (click)="cancelar()"
                >
                  Cancelar
                </button>
                <button
                  mat-raised-button
                  color="primary"
                  class="btn btn-primary btn"
                  style="margin: 2px"
                  type="submit"
                  (click)="onSubmit()"
                >
                  Confirmar
                </button>
              </div>
            </mat-card-actions>
          </form>
        </mat-card>
      </div>
    </div>
  </div>
</div>
