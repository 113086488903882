import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormulariosService } from "../_services/formularios.service";
import { UrlResolverService } from "../_services/urlResolver.service";
import { PacienteService } from '../_services/paciente.service';

@Component({
  selector: 'app-dialog-recibo-aphe',
  templateUrl: './dialog-recibo-aphe.component.html',
  styleUrls: ['./dialog-recibo-aphe.component.css']
})
export class DialogReciboApheComponent implements OnInit {
  private filtrosForm: any = {}
  reciboAphe: any = {}
  private urlHorusRestfull: any;
  existeRecAphe: boolean = false;
  cargando: boolean = true;
  urlPdf;
  datosPaciente: any = {};

  constructor(
    private pacienteService: PacienteService,
    private formService: FormulariosService,
    private urlService: UrlResolverService,
    public dialogRef: MatDialogRef<DialogReciboApheComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit() {
    //console.log(this.data)
    
    if (this.data.numeroBoleta && this.data.numeroBoleta != 0) {
      this.getHorusURL();
      this.getReciboAphe();
      this.getDatosPaciente(this.data.tipoDoc, this.data.numeroDoc);
    } else {
      this.getDatosPaciente(this.data.tipoDoc, this.data.numeroDoc);
      this.existeRecAphe = false;
    }
  }

  async getHorusURL() {
    await this.urlService.getHorusfullUrl().toPromise().then(resp => {
      // console.log(resp)
      this.urlHorusRestfull = resp.paginador.elementos[0].valor;
    }, error => {
      console.log(error)
    })
  }

  async getDatosPaciente(tipoDni, nroDni) {
    await this.pacienteService.datosPaciente(nroDni, tipoDni).toPromise().then(rpGetDatosPaciente => {
      // console.log(rpGetDatosPaciente);
      if (rpGetDatosPaciente) {
        if (!rpGetDatosPaciente.fechaNacimiento) {
          this.datosPaciente.edad = "";
        }
        this.datosPaciente = rpGetDatosPaciente;
      }
      this.cargando = false;
    }, error => {
      console.log(error)
    })
  }

  async getReciboAphe() {

    this.filtrosForm = {
      tipo: 'RA',
			numBoleta: this.data.numeroBoleta
    }

    await this.formService.getFormulariosBoleta(this.filtrosForm).toPromise().then(resp => {
      // console.log(resp)
      if (resp.ok) {
        this.reciboAphe = resp.formularios[0];
        if (this.reciboAphe.prefijo == 'NULL' || this.reciboAphe.numero == 'NULL') {
          this.existeRecAphe = false;
        } else {
          this.reciboAphe.nombre = 'RAE'+this.reciboAphe.letra+this.reciboAphe.prefijo+this.reciboAphe.numero;
          this.urlPdf = window.location.protocol +'//'+ this.urlHorusRestfull +'/horus_restfull/api/facturacionPaciente/imprimirPorPaciente/pdf/'+this.reciboAphe.nombre+'.pdf';
          this.existeRecAphe = true;
        }
      } else {
        this.existeRecAphe = false;
      }
    }, error => {
      console.log(error)
    });
  }

  // imprimirRecAphe() {
  //   window.open(window.location.protocol +'//'+ this.urlHorusRestfull +'/horus_restfull/api/facturacionPaciente/imprimirPorPaciente/pdf/'+this.reciboAphe.nombre+'.pdf', 
  //     '_blank');
  // }
}
