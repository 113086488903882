import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AppComponent } from '../app.component';
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class TurnosService {
  baseUrl: string;
  constructor(private http: HttpClient, private router: Router) {
    this.baseUrl = AppComponent.baseUrl;
    
  }

  getTurnosPorFiltro(codigoEsp, matricula, codigoSuc) {
    return this.http.get<any>(this.baseUrl + '/turnos/getTurnosPorFiltro/' + codigoEsp + '/'  + matricula + '/' + codigoSuc);
  }

  getEspecialidades() {
    return this.http.get<any>(this.baseUrl + '/turnos/getEspecialidades');
  }

  getProfesionales(filtro) {
    return this.http.get<any>(this.baseUrl + '/profesionales/seguro/profesionales-filtro/'+ filtro.matricula + '/' + filtro.servicio + '/' + filtro.especialidad + '/' + filtro.condicion + '/' + filtro.estado);
  }

  getProfesionalesSelect(matricula) {
    return this.http.get<any>(this.baseUrl + '/profesionales/seguro/getProfesionales/'+ matricula );
  }
/*
  getProfesional(matricula,servicio ,especialidad , condicion , estado){
    return this.http.get<any>(
      this.baseUrl + '/profesionales/seguro/profesionales-filtro/'+ matricula +'/'+ servicio + '/' + especialidad +'/'+ condicion +'/'+estado);
  }
  */

  getProfesional(matricula) {
    return this.http.get<any>(this.baseUrl + '/profesionales/seguro/recuperarDatosProfesional/'+ matricula );
  }

  getDiasPorMatySuc(nroMatricula,nroSucursal) {
    return this.http.get<any>(this.baseUrl + '/turnos/seguro/getDiasAtencion/'+ nroMatricula + '/' + nroSucursal);
   
  }

  getHorariosProfesional(profesional, sucursal) {
    let body = new FormData();
    body.append('codigoSuc', sucursal);
    body.append('matricula', profesional.nroMatricula);
     return this.http.post<any>(this.baseUrl + '/getHorariosProfesional', body);
  }



  getTurnosReservadosPaciente() {
    return this.http.get<any>(this.baseUrl + '/turnos-paciente');
  }
  getTurnosAtencion(turno) {
    let body = new FormData();
    body.append('matricula', turno.matricula);
    body.append('fecha', turno.str_fecha);
    body.append('desde_hora', turno.str_horaDesde);
    body.append('desde_vigencia', turno.str_desdeVigencia);

    return this.http.post<any>(
      this.baseUrl + '/turnos-atencion',
      body
    );
  }
  cancelarTurno(bloqueTurno) {
    let body = new FormData();
    body.append('matricula', bloqueTurno.matricula);
    body.append('str_horaDesde', bloqueTurno.str_horaDesde);
    body.append('str_hora', bloqueTurno.str_hora);
    body.append('str_fecha', bloqueTurno.str_fecha);
    body.append('nroTurno', bloqueTurno.numero);
    //body.append('usuario', this.userData.usuario);
    body.append('profesional', bloqueTurno.profesional);
    body.append('especialidad', bloqueTurno.especialidad);
    body.append('nombreDia', bloqueTurno.nombre);
    body.append('categoria', bloqueTurno.categoria);
    body.append('sucursal', bloqueTurno.codEstablecimiento);

    return this.http.post<any>(this.baseUrl + '/cancelar-turno', body);
  }

  getTurnosAnulados(filtroBusqueda){
    return this.http.post<any>(this.baseUrl + '/turnos/seguro/getTurnosAnulados', filtroBusqueda); //
  }

}
