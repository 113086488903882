<div class="container-fluid">
    <div class=" align-self-center">
        <div *ngIf="loading && loadingProfesional; else elseBlock">
            <h6>Se estan cargando la información, aguarde un momento</h6>
        </div>
        <ng-template #elseBlock>
            <br>
            <div class="container-fluid">

                <div class="container-fluid">
                    <div class="col">
                        <div class="row-10">
                            <h5>
                                <strong> Profesional: {{profesional.apellido}} </strong>
                            </h5>
                        </div>
                        <div class="row-2">
                            <button mat-stroked-button color="primary" (click)="onVolver()" style="border-radius: 20px; float:right;">
                            <mat-icon>skip_previous</mat-icon>
                            Volver
                            </button>
                        </div>
                        <div class="row">
                            <p> <strong style="color: darkgray;">
                                Especialidad/es:
                                <span *ngFor="let especialidad of profesional.especialidades ">
                                    {{especialidad.nombre | toTitleCase}} |
                                  </span>
                                </strong></p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container px-0 mx-auto ">
                <br>
                <br>
                <div *ngFor="let establecimiento of horarios | keyvalue: unsorted ">
                    <h5> {{establecimiento.key | toTitleCase}} </h5>
                    <table mat-table [dataSource]="establecimiento.value" #mytable class="mat-elevation-z8">
                        <div style="text-align: center; margin-right: 1px">
                            <ng-container matColumnDef="dia">
                                <th mat-header-cell *matHeaderCellDef>
                                    <h6>Día de atención</h6>
                                </th>
                                <td mat-cell *matCellDef="let dia">

                                    <strong> {{dia.nombreDia}}</strong> - {{dia.str_diaPrimerTurnoLibre}} - de {{dia.str_horaInicioAtencion}} a {{dia.str_horaFinAtencion}}
                                </td>
                            </ng-container>
                        </div>

                        <div style="text-align: center; margin-right: 1px">
                            <ng-container matColumnDef="acciones">
                                <th mat-header-cell *matHeaderCellDef>
                                    <h6>Acciones</h6>
                                </th>
                                <td mat-cell *matCellDef="let dia" class="action-link">
                                    <button mat-stroked-button color="primary" (click)="onVerAgenda(dia)" style="border-radius: 20px;">
                                    <mat-icon>assignment</mat-icon>
                                    Ver agenda
                                    </button>
                                </td>
                            </ng-container>
                        </div>
                        <tr mat-header-row *matHeaderRowDef="displayedColumnasItems"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumnasItems"></tr>
                    </table>

                    <br>
                    <br>
                </div>
            </div>
        </ng-template>
    </div>
</div>