<mat-spinner [hidden]="!cargando" style="margin: auto;"></mat-spinner>

<div class="container-fluid" [hidden]="cargando" style="border: 0px solid red;">
  <button mat-icon-button style="float: right; top: -12px; right: -12px" [mat-dialog-close]="false">
    <mat-icon class="close-icon" color="warn">close</mat-icon>
  </button>
  
  <h1 mat-dialog-title>Solicitar Biopsia</h1>
  
  <form [formGroup]="solicitarBiopsiaForm" style="border: 0px solid blue;">
    
    <div class="row" style="border: 0px solid red;">
    <mat-form-field>
      <mat-label>Seleccionar Estudio</mat-label>
      
      <mat-select formControlName="estudio" style="width: 100%; margin-left: 2px" required>
          
          <mat-option *ngFor="let estudio of listaEstudios" [value]="estudio">
              {{estudio.estudio}}
          </mat-option>
        
      </mat-select>
      <mat-error *ngIf="solicitarBiopsiaForm.controls['estudio'].invalid">{{getErrorMessage('estudio')}}</mat-error> 
    </mat-form-field>
   

<div class="col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
  
  <mat-form-field>
    <mat-label>Financiador *</mat-label>
    <mat-select formControlName="financiador" style="width: 100%;">
      <mat-option
        *ngFor="let f of financiadoresPaciente"
        [value]="f.nombre"
        
      >
        {{ f.nombre }}
      </mat-option>
    </mat-select>
    <mat-error *ngIf="solicitarBiopsiaForm.controls['financiador'].invalid 
                         ">{{getErrorMessage('financiador')}}</mat-error>
  </mat-form-field>
  
</div>
</div>
<div class="row" style="border: 0px solid red;">

  <mat-form-field>
    <mat-label>Nro carnet *</mat-label>
    <input
      type="text"
      placeholder="Elija o escriba uno"
      aria-label="Number"
      matInput
      formControlName="nroCarnet"
      [matAutocomplete]="auto"
      [disabled]="loadButton"
    />
    <mat-autocomplete #auto="matAutocomplete">
      <mat-option
        *ngFor="let option of filteredOptions | async"
        [value]="option"
      >
        {{ option }}
      </mat-option>
    </mat-autocomplete>
    <mat-error *ngIf="solicitarBiopsiaForm.controls['nroCarnet'].invalid 
                         ">{{getErrorMessage('nroCarnet')}}</mat-error>
  </mat-form-field>
</div>


    <div class="row" style="border: 0px solid red;">
        <mat-form-field class="example-full-width">
          <textarea
            matInput
            rows="2"
            formControlName="materialEnviado"
            placeholder="Material Enviado"
          ></textarea>
        </mat-form-field>
    </div>
    
    <div class="row" style="border: 0px solid red;">
      <label id="example-radio-group-label">Ubicacion de la lesion:</label>
    </div>
    <div class="row">
      <mat-radio-group class="example-radio-group" aria-labelledby="example-radio-group-label" formControlName="ubicacionLesionRB" >
          <mat-radio-button value="localizada">Localizada</mat-radio-button>
          <mat-radio-button value="generalizada">Generalizada</mat-radio-button>
      </mat-radio-group>
    </div>

    <div class="row">
      <mat-form-field class="example-full-width">
        <textarea
          matInput
          rows="2"
          formControlName="ubicacionLesionTexto"
          placeholder="Ubicaci&oacute;n"
        ></textarea>
      </mat-form-field>
    </div>

    <div class="row" style="border: 0px solid red;">
      <label id="example-radio-group-label">¿Hubo biopsia anterior?</label>
    </div>
    <div class="row">
      <mat-radio-group class="example-radio-group" aria-labelledby="example-radio-group-label" formControlName="biopsiaAnteriorRB" >
          <mat-radio-button value="si">Si</mat-radio-button>
          <mat-radio-button value="no">No</mat-radio-button>
      </mat-radio-group>
    </div>

    <div class="row">
      <mat-form-field class="example-full-width">
        <textarea
          matInput
          rows="2"
          formControlName="biopsiaAnteriorTexto"
          placeholder="Resultado de la biopsia anterior"
        ></textarea>
      </mat-form-field>
    </div>

    <div class="row">
      <mat-form-field class="example-full-width">
        <textarea
          matInput
          rows="3"
          formControlName="datosInteres"
          placeholder="Otros datos de interes"
        ></textarea>
      </mat-form-field>
    </div>

    <div class="row">
      <mat-checkbox formControlName="recibirCorreo">Quiero recibir un correo cuando este disponible el informe</mat-checkbox>
    </div>
  </form>

  <div class="row">
    <alert [hidden]="!alerta" style="text-align: center; margin-top: 1%"></alert>
  </div>

  <div class="row" style="border: 0px solid blue;">
    <button mat-raised-button color="warn" [mat-dialog-close]="false" style="margin-right: 1%;">Cancelar</button>
    <button mat-raised-button color="primary" [hidden]="hayImpresion" [class.spinner]="loadButton" [disabled]="this.solicitarBiopsiaForm.invalid" (click)="guardarPedidoBiopsia()">Guardar</button>
    <button mat-raised-button [hidden]="!hayImpresion" (click)="imprimirPdfConcatenado()">Imprimir</button>
  </div>

</div>