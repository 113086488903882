<mat-spinner [hidden]="!cargando" style="margin: auto;"></mat-spinner>
  
<div class="container-fluid" [hidden]="cargando || existeOrden" style="border: 0px solid red;">
  <p> ¿C&oacute;mo contin&uacute;a el proceso de atenci&oacute;n de el/la paciente {{ paciente.nombre }}?</p>
  <div class="row" style="border: 0px solid blue; margin-top: 1%;">
    <button mat-raised-button color="primary" (click)="realizarAccion('ENE')">Enviar a estudios</button>
    <button mat-raised-button color="primary" (click)="realizarAccion('INT')">Internar Paciente</button>
    <button mat-raised-button color="primary" (click)="realizarAccion('FIN')">Finalizar Atencion</button>
    <button mat-raised-button color="warn" (click)="cancelar()">Cancelar</button>
  </div>
</div>

<div class="container-fluid" [hidden]="cargando || !existeOrden" style="border: 0px solid red;">
  <p> Al paciente se le gener&oacute; una Orden de Internaci&oacute;n recientemente. ¿Desea hacer una nueva? </p>
  <div class="row" style="border: 0px solid blue; margin-top: 1%;">
    <button mat-raised-button color="warn" (click)="realizarAccion('INT')">No</button>
    <button mat-raised-button color="primary" (click)="openDialogSolicitarInternacion()">Si</button>
  </div>
</div>