<div class="container-fluid" style="margin-top: 1%">
  <mat-card [tabindex]="0" class="card-principal">
    <div class="container-fluid">
      <div class="row">
        <div class="col">
          <h3>
            Documentos digitales 
          </h3>
            <ul *ngIf="episodio">
              <li><strong>Episodio:</strong> {{episodio.descripcion}}</li>
              <li><strong>Tipo:</strong> {{episodio.tipoDeEpisodio.descripcion}} </li>
              <li><strong>Diagnóstico:</strong> {{episodio.diagnostico}}</li>
            </ul>
        </div>
        <div class="col">
          <button mat-icon-button 
                  class="float-right"
                  color="primary" 
                  (click)="volver()"
                  style="margin-right: 8%">
            <mat-icon>keyboard_backspace</mat-icon> Volver
          </button>   
        </div>
      </div>

      <div
        class="d-flex justify-content-center"
        style="margin-top: 10%"
        *ngIf="loading">
        <mat-spinner [diameter]="60"></mat-spinner>
      </div>
      

          
      <mat-card-content> 
      <div  class="row" style="margin-top: 1%;">
        <div
        class="d-flex justify-content-center"
        style="margin-top: 10%"
        *ngIf="loading">
        <mat-spinner [diameter]="60"></mat-spinner>
        </div>
        
        <div [hidden]="loading" class="col">
          <div *ngIf="!loading && sinDocumentos">                
            <button
                mat-raised-button
                color="primary"
                (click)="cargarDocumento()">
                <mat-icon>cloud_upload</mat-icon>
                Cargar documento
              </button>               
          </div>

          <div style="text-align: center; margin-top: 5%;" *ngIf="sinDocumentos">
            <mat-card class="sin-formulario-card">
              <h5 style="color:#3F51B5" >No se encontraron documentos</h5>
            </mat-card>
          </div>
          
          
          <div>
            <div class="row">
              <div class="col">
              <mat-form-field *ngIf="!sinDocumentos">
                <mat-label><mat-icon>search</mat-icon> Buscar</mat-label>
                <input matInput (keyup)="applyFilter($event)" placeholder="Nombre o fecha del pdf" #input>
              </mat-form-field>
              </div>

              <div *ngIf="!loading && !sinDocumentos" class="col">                
                      <button
                          id="boton_cargar_documento"
                          mat-raised-button
                          color="primary"
                          (click)="cargarDocumento()">
                          <mat-icon>cloud_upload</mat-icon>
                          Cargar documento
                        </button>               
              </div>
            </div>


            <div class="mat-elevation-z8">
              
              <table id="tabla_documentos_digitales" mat-table [dataSource]="datasrc_doc_digitales" matSort>
                <!-- Nombre Column -->
                <ng-container matColumnDef="nombre" *ngIf="!sinDocumentos">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Nombre </th>
                  <td mat-cell *matCellDef="let row"> {{row.nombre}} </td>
                </ng-container>

                <!-- Fecha Column -->
                <ng-container matColumnDef="fecha" *ngIf="!sinDocumentos" id="columna_fecha">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha </th>
                  <td mat-cell *matCellDef="let row"> {{row.fecha}}</td>
                </ng-container>

                <!-- Acciones Column -->
                <ng-container matColumnDef="acciones" *ngIf="!sinDocumentos">
                  <th mat-header-cell *matHeaderCellDef> Acciones </th>
                  <td mat-cell *matCellDef="let row"> 
                    <button 
                      mat-icon-button
                      (click)="openDialog(row.referenciaDocumento)"
                      matTooltip="Imprimir">                      
                      <mat-icon class="boton_imprimir">picture_as_pdf</mat-icon>
                    </button>
                    <button 
                    mat-icon-button
                    (click)="borrarDocumento(row.idRegistro, row.referenciaDocumento)">
                      <mat-icon class="boton_borrar">delete_forever</mat-icon>
                    </button>
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                <!-- Row shown when there is no matching data. -->
                <tr class="mat-row" *matNoDataRow>
                  <td class="mat-cell" colspan="4">No hay documentos bajo el filtro "{{input.value}}"</td>
                </tr>
                
              </table>
              <div [hidden]="sinDocumentos">
                <mat-paginator [pageSizeOptions]="[5, 10]" aria-label="Seleccionar cantidad de documentos"></mat-paginator>
              </div>
            </div>  
          </div>
          
        </div>
      </div>
      </mat-card-content>  
    
    
</div>