import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'toMinutes',
})
export class toMinutesPipe implements PipeTransform {
  transform(tiempo: any): number {
    return tiempo !== null
      ? +tiempo.split(':')[0] * 60 + +tiempo.split(':')[1]
      : null;
  }
}
