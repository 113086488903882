<!-- <app-header></app-header>
<router-outlet></router-outlet> -->

<div class="example-container">
    <app-header class="w-100"></app-header>
    <mat-sidenav-container class="example-sidenav-container">
        <mat-sidenav #sidenav fixedTopGap="64">
        <p>
            <app-menu [inputSideNav]="sidenav"></app-menu>
        </p>
        </mat-sidenav>

        <mat-sidenav-content (click)="sidenav.close()">
            <router-outlet></router-outlet>
        </mat-sidenav-content>
    </mat-sidenav-container>
</div>