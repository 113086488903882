import { Component, OnInit, NgZone, ViewChild} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import { AuthenticationService } from '../_services/authentication.service';
import { PacienteService } from '../_services/paciente.service';
import { EpisodiosPacienteService } from '../_services/episodiosPaciente.service';
import { Episodio } from '../_models/episodio.interface';
import { MatDialog } from '@angular/material/dialog';
import { DialogMensajeComponent } from '../dialog-mensaje/dialog-mensaje.component';
import * as moment from 'moment';
import { MAT_DATE_LOCALE, DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import {
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import { Router } from '@angular/router';
import { UntypedFormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DialogConfirmacionComponent } from '../dialog-confirmacion/dialog-confirmacion.component';

@Component({
  selector: 'app-crear-episodio',
  templateUrl: './crear-episodio.component.html',
  styleUrls: ['./crear-episodio.component.css'],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'es_AR' },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],
})
export class CrearEpisodioComponent implements OnInit {

  episodioForm: UntypedFormGroup;
  datosPaciente: any;
  episodio: Episodio;
  fechaTomorrow: Date = new Date();
  submitted: boolean;
  formValido: boolean;
  currentEpisodio: any;
  mostrarItemsHistorial = false;
  loadingSubmit = false;

  editar = false;

  /* Variables para el filtro de  ngx-mat-select tiposEpisodio*/
  tiposEpisodio: any[];
  filterCtrl: UntypedFormControl = new UntypedFormControl();
  tiposEpisodioFiltrados: any[];

   /* Variables para el filtro de  ngx-mat-select itemHistorialClinico*/
   itemsHistorialClinico: any[];
   filterHistorialCtrl: UntypedFormControl = new UntypedFormControl();
   itemsHistorialFiltrados: any[];

  protected onDestroyProperty = new Subject<void>();

  constructor(
    private formBuilder: UntypedFormBuilder,
    private pacienteService: PacienteService,
    private authService: AuthenticationService,
    private episodiosPacienteService: EpisodiosPacienteService,
    public dialog: MatDialog,
    private router: Router) { }

  ngOnInit() {
    this.datosPaciente = this.pacienteService.getCurrentPatient();
    this.currentEpisodio = this.episodiosPacienteService.getCurrentEpisodio();
    if (this.currentEpisodio && this.currentEpisodio.editar){
      this.editar=true;
    }
    this.fechaTomorrow.setDate(this.fechaTomorrow.getDate() + 1);
    this.cargarTiposEpisodio();
    this.crearFormEpisodio();
    this.listenFilter();
    this.listenValueChange();
  }
  listenValueChange() {
    this.episodioForm.get('tipoEpisodio').valueChanges.subscribe(tipo => {
      if (tipo.opcion === 'carpetas') {
        this.cargarCarpetasDePaciente(this.datosPaciente.tipoDoc, this.datosPaciente.numeroDoc);
      } else {
        this.mostrarItemsHistorial = false;
      }
    });
  }

  listenFilter() {
    this.filterCtrl.valueChanges
      .pipe(takeUntil(this.onDestroyProperty))
      .subscribe(() => {
        this.filtroTipos();
      });

    this.filterHistorialCtrl.valueChanges
      .pipe(takeUntil(this.onDestroyProperty))
      .subscribe(() => {
        this.filtroItemsHistorial();
      });
  }

  protected filtroTipos() {
    if (!this.tiposEpisodio) {
      return;
    }
    let search = this.filterCtrl.value;
    if (!search) {
      this.tiposEpisodioFiltrados = this.tiposEpisodio;
      return;
    } else {
      search = search.toLowerCase();
    }
    // filtro los tipos de episodio
    this.tiposEpisodioFiltrados =
      this.tiposEpisodio.filter(
        (tipoEpisodio) => tipoEpisodio.descripcion.toLowerCase().indexOf(search) > -1
      );
  }
  protected filtroItemsHistorial() {
    if (!this.itemsHistorialClinico) {
      return;
    }
    let search = this.filterHistorialCtrl.value;
    if (!search) {
      this.itemsHistorialFiltrados = this.itemsHistorialClinico;
      return;
    } else {
      search = search.toLowerCase();
    }
    // filtro los items de historial clinico
    this.itemsHistorialFiltrados =
      this.itemsHistorialClinico.filter(
        (itemHistorial) => itemHistorial.idEpisodio.toString().toLowerCase().indexOf(search) > -1
      );
  }

  cargarTiposEpisodio() {
    this.episodiosPacienteService.getTiposEpisodio().subscribe(
      (respuesta) => {
        if (respuesta.ok) {
          this.tiposEpisodio = respuesta.elementos;
          this.tiposEpisodioFiltrados = respuesta.elementos;
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  cargarCarpetasDePaciente(tipoDoc, numDoc) {
    this.episodiosPacienteService.getCarpetasDePaciente(tipoDoc, numDoc).subscribe((respuesta) => {
      if (respuesta.length > 0) {
          this.itemsHistorialClinico = respuesta;
          this.itemsHistorialFiltrados = this.itemsHistorialClinico;
          this.mostrarItemsHistorial = true;
        }
      });
  }

  crearFormEpisodio() {
    this.episodioForm = this.formBuilder.group({
      paciente: [{value: this.datosPaciente.str_paciente, disabled: true},
                  Validators.required],
      dni: [{value: this.datosPaciente.numeroDoc, disabled: true },
            Validators.required],
      tipoDoc: [{ value: this.datosPaciente.tipoDoc, disabled: true },
                Validators.required],
      descripcion: [this.editar ? this.currentEpisodio.descripcion : null, Validators.required],
      diagnostico: [this.currentEpisodio ? this.currentEpisodio.diagnostico : null, Validators.required],
      matriculaResponsable: [this.authService.currentUserValue.roles.find(
                              (rol) => rol.codigo === 'MHE').valorTipoID ,
                            Validators.required],
      fechaInicio: [null, []],
      fechaFin: [(this.editar && this.currentEpisodio.fechaFin!=='')? moment(this.currentEpisodio.fechaFin,'DD/MM/YYYY').toDate() : null, [] ],
      tipoEpisodio: [null, [] ],
      itemHistorialClinico: [null, []]
    });
    if(this.editar && this.currentEpisodio.fechaFin!==''){
      this.episodioForm.controls['fechaFin'].markAsDirty();
    }
  }

  onSubmit() {
    this.submitted = true;
    this.loadingSubmit = true;
    if (!this.episodioForm.valid) {
      this.loadingSubmit=false;
      return;
    }
    if (this.mostrarItemsHistorial) {
      this.episodioForm.get('matriculaResponsable').setValue(this.episodioForm.get('itemHistorialClinico').value.matriculaProfesional);
      this.episodioForm.get('fechaInicio').setValue(moment(this.episodioForm.get('itemHistorialClinico').value.fecha).format('DD-MM-YYYY'));
     /*  this.episodioForm.get('fechaFin').setValue(moment(this.episodioForm.get('itemHistorialClinico').value.fechast,'DD/MM/YYYY'));
      this.episodioForm.controls['fechaFin'].markAsDirty(); */
    }

    this.episodio = {
      idEpisodio: this.editar ? this.currentEpisodio.idEpisodio : -1,
      estado:'ACT',
      loginEstado: this.authService.currentUserValue.usuario,
      tipoDoc: this.episodioForm.get('tipoDoc').value,
      numDoc: this.episodioForm.get('dni').value,
      descripcion: this.episodioForm.get('descripcion').value,
      diagnostico: this.episodioForm.get('diagnostico').value,
      matriculaProfesional: this.episodioForm.get('matriculaResponsable').value,
      fechaInicio: this.episodioForm.get('fechaInicio').value,
      fechaFin: this.episodioForm.controls['fechaFin'].pristine ?
                null : moment(this.episodioForm.get('fechaFin').value).format('DD-MM-YYYY'),
      tipoDeEpisodio: {idTipoEpisodio: this.episodioForm.get('tipoEpisodio').value ?
               this.episodioForm.get('tipoEpisodio').value.idTipoEpisodio : null},
      idEpisodioPadre: this.currentEpisodio && !this.editar ? this.currentEpisodio.idEpisodio : null,
      idHistorial: this.mostrarItemsHistorial ? this.episodioForm.get('itemHistorialClinico').value.idEpisodio : null
    };

    if (this.episodio.fechaFin === 'Invalid date') {
     // console.log('es invalid date');
      this.episodio.fechaFin = null;
    }

    //console.log('onSubmit -> this.episodio', this.episodio);

    this.episodiosPacienteService.setEpisodio(this.episodio).subscribe(
    (data) => {
    //  console.log(data);
      if (data.ok) {
        this.submitted = false;
      }
      this.abrirDialogMensaje(data);
    },
    (error) => {
      console.log(error);
    }
    );

  }

  abrirDialogMensaje(resp) {
    this.loadingSubmit = false;
    const dialogRef = this.dialog.open(DialogMensajeComponent, {
      width: '500px',
      height: '100px',
      data: resp,
    }).afterClosed()
    .subscribe(
      (confirmado: boolean) => {
        if (confirmado) {
          this.dialog.closeAll();
          if(this.currentEpisodio && !this.editar && resp.ok && !this.episodio.idHistorial){
            this.episodio.idEpisodio = resp.id;
            this.episodio.tipoDeEpisodio.descripcion = (this.episodioForm.get('tipoEpisodio').value ?
                                      this.episodioForm.get('tipoEpisodio').value.descripcion : '');
            this.episodio.idHistorial = 0;
            this.episodio.fechaInicio = moment(new Date()).format('DD/MM/YYYY');
            this.irVerFormularios(this.episodio);
          } else {
            this.router.navigate(['/listar-episodios']);
          }
         
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  irVerFormularios(episodio:Episodio) {
    this.episodiosPacienteService.removeCurrentEpisodio();
    this.episodiosPacienteService.setCurrentEpisodio(episodio);
    this.router.navigate(['/ver-formularios-episodio']);
  }

  getErrorMessage(nomCampo) {
     return this.episodioForm.get(nomCampo)
     .hasError('required') ? 'Este campo es obligatorio' : '';
   }

  volver() {
    this.episodiosPacienteService.removeCurrentEpisodio();
    this.router.navigate(['/listar-episodios']);
  }

  eliminarEpisodio(){
    this.episodio = {
      idEpisodio: this.currentEpisodio.idEpisodio,
      estado:'INA',
      loginEstado: this.authService.currentUserValue.usuario
    };
    this.episodiosPacienteService.setEpisodio(this.episodio).subscribe(
      (data) => {
      //  console.log(data);
        this.abrirDialogMensaje(data);
      },
      (error) => {
        console.log(error);
      }
      );
  }
  
  abrirDialogConfirmacionEliminar() {
    let textoAdicional='';
    if(this.currentEpisodio.subepisodios && this.currentEpisodio.subepisodios.length!=0){
      textoAdicional=' También se eliminarán los subepisodios asociados.';
    }
    this.dialog.open( DialogConfirmacionComponent, {
      width: '500px',
      data: '¿Está seguro que desea eliminar el episodio? '+ textoAdicional,
    })
    .afterClosed()
      .subscribe(
        (confirmado: boolean) => {
          if (confirmado) {
            this.eliminarEpisodio();
          } else {
            this.dialog.closeAll();
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }


}
