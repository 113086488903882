<button
  mat-icon-button
  style="float: right; top: -24px; right: -24px"

  (click)="cerrar()"
>
  <mat-icon class="close-icon" color="warn">close</mat-icon>
</button>
<div mat-dialog-content>
  <div *ngIf="data.ok; else resultadoNegativo">
    <p class="alert alert-success p-1 m-1 align-middle text-center">
      {{ data.mensaje }}
    </p>
  </div>
  <ng-template #resultadoNegativo>
    <p class="alert alert-danger p-1 m-1 align-middle text-center">
      {{ data.mensaje }}
    </p>
  </ng-template>
</div>
