import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Adapter } from '../_adapters/adapter';

export class EvolucionPaciente {
  anotacion: string;
  borrado: boolean;
  carpeta: number;
  desdeHoraTurno: string;
  fecha: string;
  id: number;
  idEpisodioGuardia: number;
  id_reporte: number;
  matricula: string;
  nombreMedico: string;
  numeroDocumento: number;
  orden: number;
  privada: boolean;
  servicio: string;
  tipo: { id: number; borrado: boolean; descripcion: string };
  tipoDocumento: number;
  usuario: string;
  version: number;
}

@Injectable({
  providedIn: 'root',
})
export class EvolucionPacienteAdapter implements Adapter<EvolucionPaciente> {
  adapt(item: any): EvolucionPaciente {
    const ep = new EvolucionPaciente();
    ep.anotacion = item.anotacion;
    ep.borrado = item.borrado;
    ep.carpeta = item.carpeta;
    ep.desdeHoraTurno = item.desdeHoraTurno;
    ep.fecha = moment(item.fecha, 'MMM DD, YYYY, HH:mm:ss A').format('DD/MM/YYYY HH:mm');
    ep.id = item.id;
    ep.idEpisodioGuardia = item.idEpisodioGuardia;
    ep.id_reporte = item.id_reporte;
    ep.matricula = item.matricula;
    ep.nombreMedico = item.nombreMedico;
    ep.numeroDocumento = item.numeroDocumento;
    ep.orden = item.orden;
    ep.privada = item.privada;
    ep.servicio = item.servicio;
    ep.tipo = item.tipo;
    ep.tipoDocumento = item.tipoDocumento;
    ep.usuario = item.usuario;
    ep.version = item.version;
    return ep;
  }
}
