<div class="container-fluid" style="margin-top: 1%">
    <form [formGroup]="evolucionForm">
        <div class="row">
            <div class="col">
                <mat-form-field style="width: 100%; font-size: 14px">
                    <mat-label>Plantillas</mat-label>
                    <mat-select [(ngModel)]="plantillaSeleccionada" [ngModelOptions]="{ standalone: true }">
                        <mat-option>
                            <ngx-mat-select-search placeholderLabel="Buscar plantilla" noEntriesFoundLabel="No se encontraron plantillas" [formControl]="bankMultiFilterCtrl"></ngx-mat-select-search>
                        </mat-option>
                        <mat-option *ngFor="let p of this.plantillasFiltradas | async" [value]="p" (click)="insertarPlantilla(p)">
                            {{ !p.privada ? (p.titulo | toTitleCase) : (p.titulo + ' (Privada)' | toTitleCase) }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <ckeditor formControlName="evolucion" [editor]="editor" ></ckeditor>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 text-left">
                <button mat-raised-button class="btn-block mt-2" (click)="onSubmitForm()" color="primary">
                    Guardar
                </button>
            </div>
            <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 text-center">
                <mat-slide-toggle color="primary" class="mt-2" formControlName="privada">
                    <span *ngIf="evolucionForm.value.privada">Privada</span>
                    <span *ngIf="!evolucionForm.value.privada">Pública</span>
                </mat-slide-toggle>
                <mat-slide-toggle color="primary" class="mt-2" formControlName="publicarEnTrasplante" *ngIf="tieneEpiActivo">
                    <span>Publicar en trasplante</span>
                </mat-slide-toggle>
            </div>
            <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 text-right">
                <button mat-raised-button class="btn-block mt-2" (click)="resetForm()" color="warn">
                    Limpiar
                </button>
            </div>
        </div>
        <div class="alert alert-success" role="alert" *ngIf="guardadaEvolucion">
            Esta evolución se guardó en el subepisodio {{ subepiActivo.tipoDeEpisodio.descripcion }}
        </div>
    </form>
</div>